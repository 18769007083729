<!--
<h3>Liegenschaftsdaten</h3>
1.) Dropdown Katastralgemeinde (Elemente können statisch hinterlegt werden -> Object: Gemeindename, KGNr)
2. Einlagezahl: Integer (5stellig)
3. Grundstücksnummer: Text (da Sonderzeichen möglich) -> Button für Hinzufügen von Grundstücksnummern muss möglich sein

<h3> Daten zur Wohnung oder Geschäftsräumlichkeit: </h3>
Anzahl der zu verkaufenden Parkplätze (welche im Grundbuch ausgewiesen sind): Integer, Default: 1

TOP: Textfeld (Text), bis 10stellig
Anteil (Bruch, zB. 36/2000):  //TODO: Validierung auf Bruch und korrekte Schreibweise -> vermeidet Schönheitsfehler
B-Laufende Nummer: Integer 3stellig //Info: Aus Grundbuchauszug ersichtlich

Pro Parkplatz:
- TOP
- Anteil:
- B-Laufende Nummer:

-->

<form fxLayout="column" [formGroup]="immodetail">
  <div class="col-12">
    <div class="yellow-line-2 mb-4 mt-4"></div>
  </div>
  <div>
    <label>
      <h2 style="font-size: 22px; font-weight: bold; text-transform: uppercase;">
        Daten zur Liegenschaft
        <fa-icon style="float: none; margin-left: 2px;" (click)="openWEGDatenzLSDlg()"
          class="accent-color" [icon]="faUniversity">
        </fa-icon>
      </h2>
    </label>
  </div>

    <div class="half-field">
      <mat-form-field appearance="none" class="no-padding no-line  customshit" [class.error]="submitted && immodetail.controls.katastralGemeinde.invalid">
        <mat-label>Katastralgemeinde (KG)</mat-label>
        <input required matInput #autoCompleteInput formControlName="katastralGemeinde" [matAutocomplete]="auto" class="katastralGemeinde" pattern="^[^\s]+(\s+[^\s]+)*$" />
        <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn" (optionSelected)='onSelectKg("l1", $event.option.value)'>
          <mat-option *ngIf="isLoading" class="is-loading">
            <mat-spinner diameter="50"></mat-spinner>
          </mat-option>
          <mat-option *ngFor="let village of filteredVillages" [value]="village">
            <span>{{ village.KGName}}</span> |
            <small>KGNr: {{ village.KGNr }}</small>
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </div>
    <div>
      <div formArrayName="einlageZahlObject">
        <div *ngFor="let gstNr of immodetail.controls.einlageZahlObject['controls']; let i=index ">
          <div class="yellow-line-2 mb-4 mt-4 w-50" *ngIf="i != 0"></div>
          <h2 class="mb-4 mt-4" style="font-size: 20px; font-weight: bold; text-transform: uppercase;">Einlagezahl (EZ)  {{ i + 1 }}</h2>
          <div [formGroupName]="i">
            <div style="display: flex">
              <mat-form-field appearance="none" class="no-padding no-line half-field">
                <mat-label>Einlagezahl (EZ)  {{ i + 1 }}</mat-label>
                <div [formGroup]="gstNr" style="display: inline-block; position: relative">
                  <input required matInput maxlength="5" formControlName="einlageZahl" pattern="^[^\s]+(\s+[^\s]+)*$" />
                </div>
              </mat-form-field>
              <button *ngIf="0 == i" mat-raised-button color="primary" (click)="addEinlageZahl()" style="margin-top: 30px; margin-left: 10px;" class="small-height-button">
                Neue Einlagezahl hinzufügen
              </button>
            </div>
            <!-- Heading start -->
            <div class="mt-4 mb-4">
              <!-- <h3 *ngIf="parkplatz">Daten zum Parkplatz for Einlagezahl (EZ)  {{ i + 1 }}</h3> -->
              <h3 *ngIf="parkplatz">Daten zum Parkplatz</h3>
              <div *ngIf="!parkplatz">
                <div *ngIf="contractData.primeType == 'miet'">
                  <!-- <h3>Daten zum Mietobjekt for Einlagezahl (EZ)  {{ i + 1 }}</h3> -->
                  <h3>Daten zum Mietobjekt</h3>
                </div>
                <div *ngIf="contractData.primeType != 'miet'">
                  <div *ngIf="(contractData.masterData.sellersPrivate.length + contractData.masterData.sellersCompany.length)==1" class="half-field">
                    <label>
                      <h3>
                        <!-- Daten zur Wohnung oder Geschäftsräumlichkeit  for Einlagezahl (EZ)  {{ i + 1 }} -->
                        Daten zur Wohnung oder Geschäftsräumlichkeit
                        <fa-icon style="float: none; margin-left: 2px;"
                          (click)="openWEGDatenzWohnodGeschOneVerDlg()" class="accent-color" [icon]="faUniversity">
                        </fa-icon>
                      </h3>
                    </label>
                  </div>

                  <div *ngIf="(contractData.masterData.sellersPrivate.length + contractData.masterData.sellersCompany.length)>1"  class="half-field">
                    <label>
                      <h3>
                        <!-- Daten zur Wohnung oder Geschäftsräumlichkeit  for Einlagezahl (EZ)  {{ i + 1 }} -->
                        Daten zur Wohnung oder Geschäftsräumlichkeit
                        <fa-icon style="float: none; margin-left: 2px;"
                          (click)="openWEGDatenzWohnodGeschMoreVerDlg()" class="accent-color" [icon]="faUniversity">
                        </fa-icon>
                      </h3>
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <!-- Heading end -->
            <div formArrayName="grundstuecksnummerAr">
              <div *ngFor="let einss of gstNr.controls.grundstuecksnummerAr['controls']; let j = index">
                <div style="display: flex">
                  <!-- {{ logg(immodetail.controls.einlageZahlObject['controls'][i].controls.grundstuecksnummerAr.controls[j]['controls']['grundstuecksnummer']) }} -->
                  <!-- {{ logg(einss) }} -->
                  <mat-form-field [formGroup]="einss" appearance="none" class="no-padding no-line  half-field">
                  <!-- <mat-form-field [formGroup]="einss" appearance="none" class="no-padding no-line  half-field"  [class.error]="submitted && formGroup.get('features')"> -->
                    <mat-label>Grundstücksnummer (GST-NR) {{ j + 1 }}</mat-label>
                    <div style="display: inline-block; position: relative">
                      <input required matInput maxlength="10" formControlName="grundstuecksnummer" pattern="^[^\s]+(\s+[^\s]+)*$" />
                    </div>
                  </mat-form-field>
                  <button *ngIf="j == 0" mat-raised-button color="primary" (click)="addGstNr(i)" style="margin-top: 30px; margin-left: 10px;" class="small-height-button">
                    GST-NR hinzufügen
                  </button>
                </div>
                <div class="text-center half-field">
                  <button *ngIf="gstNr.controls.grundstuecksnummerAr['controls'].length > 1" mat-raised-button color="primary" style="margin: 15px;" (click)="removeGstNr(i, j)">Löschen</button>
                </div>
              </div>
            </div>
            <div class="half-field">
              <mat-form-field appearance="none" class="no-padding no-line"  [class.error]="submitted && immodetail.controls.einlageZahlObject['controls'][i].controls.top.invalid">
                <mat-label>TOP</mat-label>
                <input required matInput maxlength="10" formControlName="top" pattern="^[^\s]+(\s+[^\s]+)*$"/>
              </mat-form-field>
              <!-- <button type="button" name="button" (click)="save()">Save</button> -->
              <mat-form-field appearance="none" class="no-padding no-line" [class.error]="submitted && immodetail.controls.einlageZahlObject['controls'][i].controls.anteil.invalid">
                <mat-label>Anteil</mat-label>
                <input required matInput formControlName="anteil" pattern="\d{1,9}/\d{1,9}"/>
                <label class="text-danger text-left error-message">Der Wert muss im m/n-Format vorliegen</label>
              </mat-form-field>
              <!-- <div *ngFor="let gstNr of immodetail.controls.einlageZahlObject['controls']; let i=index "> -->
              <div formArrayName="bLaufendeNrAr">
                <div *ngFor="let einss of gstNr.controls.bLaufendeNrAr['controls']; let j = index">
                  <mat-form-field [formGroup]="einss" appearance="none" class="no-padding no-line">
                    <mat-label>B-LNR {{ j + 1 }}</mat-label>
                    <div>
                      <input required matInput maxlength="10" formControlName="bLaufendeNr" pattern="^[^\s]+(\s+[^\s]+)*$"/>
                    </div>
                  </mat-form-field>
                </div>
              </div>
            </div>

            <div class="text-center half-field">
              <button *ngIf="immodetail.controls.einlageZahlObject['controls'].length > 1" mat-raised-button color="primary" (click)="removeEinlageZahl(i)" style="margin: 15px;">Löschen</button>
            </div>
            <br>
            <!-- <h3 *ngIf="parkplatz">Weitere Parkplätze for Einlagezahl (EZ)  {{ i + 1 }}</h3>
            <h3 *ngIf="!parkplatz">Parkplätze oder weitere Einheiten for Einlagezahl (EZ)  {{ i + 1 }}</h3> -->
            <h3 *ngIf="parkplatz">Weitere Parkplätze for Einlagezahl</h3>
            <h3 *ngIf="!parkplatz">Parkplätze oder weitere Einheiten</h3>
            <div class="half-field">
              <mat-form-field appearance="none" class="no-padding no-line" [class.error]="submitted && immodetail.controls.einlageZahlObject['controls'][i].controls.parkingPlaceCnt.invalid">
                <mat-label *ngIf="parkplatz">Anzahl weitere Parkplätze</mat-label>
                <mat-label *ngIf="!parkplatz">Anzahl Parkplätze oder weitere Einheiten</mat-label>
                <input required matInput maxlength="1" formControlName="parkingPlaceCnt" pattern="[0-8]" (input)="changePlaceCnt(i)" />
              </mat-form-field>

              <div *ngFor="let parkingPlace of immodetail.controls.einlageZahlObject['controls'][i].controls.parkingPlace.controls; let k = index">
                <h5 *ngIf="!parkplatz">Parkplatz oder weitere Einheit {{ k + 1 }}:</h5>
                <h5 *ngIf="parkplatz">Parkplatz {{ k + 1 }}:</h5>

                <app-immo-detail-info [submitted]="submitted" [parent]="parkingPlace" *ngIf="!BLaufendeNr2Visible"></app-immo-detail-info>
                <app-immo-detail-info-blnr2 [submitted]="submitted" [parent]="parkingPlace" *ngIf="BLaufendeNr2Visible"></app-immo-detail-info-blnr2>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  <br>
</form>
