import { Component, OnInit } from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-dlg-weg-daten-zur-liegenschaft',
  templateUrl: './dlg-weg-daten-zur-liegenschaft.component.html',
  styleUrls: ['./dlg-weg-daten-zur-liegenschaft.component.css']
})
export class DlgWegDatenZurLiegenschaftComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<DlgWegDatenZurLiegenschaftComponent>) { }

  ngOnInit() {
  }
  
  closeDlg(): void {
    this.dialogRef.close();
  }
}
