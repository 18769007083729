<mat-dialog-content class="popup">
  <div class="custom-dlg-head">
    <h3 style="color: white !important; padding: 2%;">Bitte &uuml;berpr&uuml;fen Sie die Daten</h3>
  </div>

  <div class="custom-dlg-body">
    <div *ngIf="drittebeteiligte && false">
      <h4 class="custom-dlg-h">MITBETEILIGTE PARTEIEN</h4>
      <div *ngIf="drittesonstige">
        <label><b>Berechtigte</b></label>
        <div *ngIf="drittesonstige2; else other_Partei">
          <label><b>&nbsp;Parteien:</b></label>
        </div>
        <ng-template #other_Partei>
          <label><b>&nbsp;Partei:</b></label>
        </ng-template>
        <div *ngFor="let person of contractData.wegLogic.lssonstigeRechte.rightPersonArray">
          <p>{{person.firstname}} {{person.lastname}} ({{person.birthday}})</p>
          <p>{{person.address}}, {{person.postalCode}} {{person.city}}</p>
        </div>
        <div *ngFor="let comp of contractData.wegLogic.lssonstigeRechte.rightCompanyArray">
          <p>{{comp.companyName}} ({{comp.companiesRegisterNumber}})</p>
          <p>{{comp.address}}, {{comp.postalCode}} {{city}}</p>
        </div>
      </div>
      <br>
      <div *ngIf="drittelastenloeschen">
        <label><b>Verzichtende</b></label>
        <div *ngIf="drittelastenloeschen2; else other_Partei1">
          <label><b>&nbsp;Parteien:</b></label>
        </div>
        <ng-template #other_Partei1>
          <label><b>&nbsp;Partei:</b></label>
        </ng-template>
        <div *ngFor="let dritter of contractData.wegLogic.LastenLoeschenDritteBeteiligte.rightPersonList">
          {{dritter.firstname}} {{dritter.lastname}} ({{dritter.birthday}})
          {{dritter.address}}, {{dritter.postalCode}} {{dritter.city}}
        </div>
        <div *ngFor="let dritter of contractData.wegLogic.LastenLoeschenDritteBeteiligte.rightCompanyList">
          {{dritter.companyName}} ({{dritter.companiesRegisterNumber}})
          {{dritter.address}}, {{dritter.postalCode}} {{dritter.city}}
        </div>
      </div>
      <br>
      <div *ngIf="EPPartner">
        <b>Wohnungseigent&uuml;merpartner:</b>
        <div *ngIf="EPPartnerPriv">
          <p>{{contractData.wegLogic.EPList.buyersPrivate[0].firstname}}
            {{contractData.wegLogic.EPList.buyersPrivate[0].lastname}}
            ({{contractData.wegLogic.EPList.buyersPrivate[0].birthday}})</p>
          <p>{{contractData.wegLogic.EPList.buyersPrivate[0].address}},
            {{contractData.wegLogic.EPList.buyersPrivate[0].postalCode}}
            {{contractData.wegLogic.EPList.buyersPrivate[0].city}}</p>
        </div>
        <div *ngIf="EPPartnerComp">
          <p>{{contractData.wegLogic.EPList.buyersCompany[0].companyName}}
            ({{contractData.wegLogic.EPList.buyersCompany[0].companiesRegisterNumber}})</p>
          <p>{{contractData.wegLogic.EPList.buyersCompany[0].address}},
            {{contractData.wegLogic.EPList.buyersCompany[0].postalCode}}
            {{contractData.wegLogic.EPList.buyersCompany[0].city}}</p>
        </div>
      </div>
    </div>

    <div *ngIf="this.contractData.primeType!='kfz'">
      <!-- allgemeines -->
      <div>
        <h4 class="custom-dlg-h mt-2">ALLGEMEINES</h4>

        <div *ngIf="contractData.primeType =='miet'">
          <!-- Art des Objekts -->
          <div>
            <b>Art des Objekts:&nbsp;</b>
            <label *ngIf="contractData.wegLogic.mietObjType == 'wohnung'
            && contractData.wegLogic.gefoerdertewohnung">gef&ouml;rderte Wohnung</label>
            <label *ngIf="contractData.wegLogic.mietObjType == 'wohnung'
            && !contractData.wegLogic.gefoerdertewohnung">Wohnung</label>
            <label *ngIf="contractData.wegLogic.mietObjType == 'sonstigeflaeche'">sonstige unbebaute Fl&auml;che</label>
            <label *ngIf="contractData.wegLogic.mietObjType == 'lwpachtvertrag'">landwirtschaftlicher Pachtvertrag</label>
            <label *ngIf="contractData.wegLogic.mietObjType == 'geschaeftsraum'">Gesch&auml;ftsraum</label>
            <label *ngIf="contractData.wegLogic.mietObjType == 'unternehmenspacht'">Unternehmenspacht</label>
            <label *ngIf="contractData.wegLogic.mietObjType == 'dienstwohnung'">Dienstwohnung</label>
            <label *ngIf="contractData.wegLogic.mietObjType == 'zweitwohnsitz'">Wohnung für Zweitwohnsitz</label>
            <label *ngIf="contractData.wegLogic.mietObjType == 'parkplatz'">Parkplatz oder Garage</label>
            <br>
          </div>

          <!-- Gewerblich -->
          <div *ngIf="contractData.wegLogic.mietObjType == 'parkplatz' || contractData.wegLogic.mietObjType=='sonstigeflaeche'
          || contractData.wegLogic.mietObjType=='lwpachtvertrag'">
            <p><b>Erfolg die Vermietung gewerblich:</b> {{contractData.wegLogic.gewerblich}}</p>
          </div>

          <!-- Welche Räume -->
          <div>
            <label><b>Beschreibung des Mietgegenstandes:&nbsp;</b>{{contractData.wegLogic.mietRaeume}}</label>
            <br>
          </div>
          <br>

          <h4 class="custom-dlg-h">Mietobjekt</h4>
          <!-- Anschrift -->
          <div>
            <label><b>Stra&szlig;e:&nbsp;</b> {{contractData.wegLogic.strasse}}</label>
            <br>

            <label><b>PLZ:&nbsp;</b> {{contractData.wegLogic.plz}}</label>
            <br>

            <label><b>Ort:&nbsp;</b> {{contractData.wegLogic.ort}}</label>
            <br>
          </div>

          <!-- Nutzfläche -->
          <div>
            <label><b>Nutzfl&auml;che des Mietobjekts in m²:&nbsp;</b> {{contractData.wegLogic.nutzflaeche}}</label>
            <br>
          </div>

          <!-- Wohnung oder Geschäftsräumlichkeit -->
          <div
            *ngIf="contractData.wegLogic.mietObjType == 'wohnung'||contractData.wegLogic.mietObjType=='geschaeftsraum'">

            <!-- 2>Miet Objekte -->
            <div>
              <label><b>mehr als zwei vermietbare
                  Objekte:&nbsp;</b>{{contractData.wegLogic.mehrals2VermietbareObj}}</label>
              <br>
            </div>

            <div *ngIf="contractData.wegLogic.mehrals2VermietbareObj == 'Ja'">

              <!-- ursprüngliche Baubewilligung -->
              <div>
                <div *ngIf="contractOption == 'WEG'">
                  <label><b>ursprüngliche Baubewilligung vor dem 08.05.1945
                      erteilt:&nbsp;</b>{{contractData.wegLogic.bewilligungVor08051945}}</label>
                  <br>
                </div>

                <div *ngIf="contractOption == 'BBL'">
                  <label><b>ursprüngliche Baubewilligung vor dem 30.06.1953
                      erteilt:&nbsp;</b>{{contractData.wegLogic.bewilligungVor08051945}}</label>
                  <br>
                </div>
              </div>

              <!-- Baujahr < 45 -->
              <div *ngIf="contractData.wegLogic.bewilligungVor08051945 == 'Ja' && contractOption == 'BBL'">
                <label><b>ursprüngliche Baubewilligung vor dem 08.05.1945
                    erteilt:&nbsp;</b>{{contractData.wegLogic.zusatzfragebj45}}</label>
                <br>
              </div>

              <!-- Denkmalschutz -->
              <div *ngIf="contractData.wegLogic.bewilligungVor08051945 == 'Ja'">
                <label><b>Am Gebäude besteht Denkmalschutz:&nbsp;</b>{{contractData.wegLogic.denkmalschutz}}</label>
                <br>
              </div>

              <!-- Kategorie -->
              <div *ngIf="contractData.wegLogic.denkmalschutz == 'Nein'">
                <label><b>Kategorie des Mietgegenstandes:&nbsp;</b>{{contractData.wegLogic.kategorieHelper}}</label>
                <br>
              </div>

              <!-- Lage -->
              <div *ngIf="contractOption == 'BBL'&& contractData.wegLogic.zusatzfragebj45 == 'Ja'">
                <label><b>Mietgegenstand befindet sich in &uuml;berdurchschnittlich guten Lage:&nbsp;</b>
                  {{contractData.wegLogic.lagezuschlag}}</label>
                <br>
                <div *ngIf="contractData.wegLogic.lagezuschlag == 'Ja'">
                  <label><b>Beschreibung der Lage:&nbsp;</b>{{contractData.wegLogic.lagebeschreibung}}</label>
                  <br>
                </div>
              </div>

              <!-- Räumung -->
              <div
                *ngIf="(contractData.wegLogic.kategorieHelper == 'A' || contractData.wegLogic.kategorieHelper == 'B') && contractData.wegLogic.nutzflaeche > 130">
                <label><b>Weitervermietung innert von 6 Monaten:&nbsp;</b>
                  {{contractData.wegLogic.raeumung6monate}}</label>
                <br>
              </div>
            </div>
          </div>

          <!-- Förderungen -->
          <div *ngIf="contractData.wegLogic.mehrals2VermietbareObj == 'Ja'">
            <div *ngIf="contractData.wegLogic.bewilligungVor08051945 == 'Nein'">

              <div *ngIf="contractOption == 'WEG' && contractData.wegLogic.mietObjType == 'wohnung'">
                <label><b>&ouml;ffentliche F&ouml;rderungen bei urspr&uuml;nglicher Errichtung:&nbsp;</b>
                  {{contractData.wegLogic.oeffentlicheFoerderungen}}</label>
                <br />
              </div>

              <div
                *ngIf="contractOption == 'BBL'
              && ( contractData.wegLogic.mietObjType == 'wohnung' || contractData.wegLogic.mietObjType == 'geschaeftsraum' )">
                <label><b>&ouml;ffentliche F&ouml;rderungen bei urspr&uuml;nglicher Errichtung:&nbsp;</b>
                  {{contractData.wegLogic.oeffentlicheFoerderungen}}</label>
                <br />
              </div>
            </div>

            <div *ngIf="contractOption == 'BBL'
            && contractData.wegLogic.bewilligungVor08051945 == 'Ja' && contractData.wegLogic.zusatzfragebj45 == 'Ja'">
              <label><b>&ouml;ffentliche F&ouml;rderungen bei urspr&uuml;nglicher Errichtung:&nbsp;</b>
                {{contractData.wegLogic.oeffentlicheFoerderungen}}</label>
              <br>
            </div>
          </div>
          <br>

          <h4 class="custom-dlg-h">Mietdauer</h4>
          <!-- Beginn Mietverhältniss -->
          <div>
            <label><b>Beginn des Mietverhältnisses:&nbsp;</b>{{contractData.wegLogic.uebergabeDatum}}</label>
            <br>
          </div>

          <!-- Mietdauer -->
          <div *ngIf="contractData.wegLogic.mietObjType != 'dienstwohnung'">
            <div *ngIf="contractData.wegLogic.mietdauerinjahre">
              <label><b>Mietdauer (in Jahren):&nbsp;</b>{{contractData.wegLogic.mietdauerinjahre}}</label>
              <br>
            </div>
            <div *ngIf="contractData.wegLogic.mietdauerinmonate">
              <label><b>Mietdauer (in Monaten):&nbsp;</b>{{contractData.wegLogic.mietdauerinmonate}}</label>
              <br>
            </div>
          </div>

          <!-- Kündigung -->
          <div *ngIf="(
                contractData.wegLogic.mietObjType=='wohnung' && contractData.wegLogic.mehrals2VermietbareObj == 'Ja'
              &&(contractData.wegLogic.bewilligungVor08051945 == 'Ja'||contractData.wegLogic.bewilligungVor08051945 == 'Nein' && contractData.wegLogic.oeffentlicheFoerderungen == 'Ja' )
              )
              ||(
                false
                )">
            <br>
            <h4 class="custom-dlg-h">vorzeitiges K&uuml;ndigungsrecht</h4>
            <div *ngIf="contractData.wegLogic.kuendigungJaNein == 'Ja'">
              <label><b>vorzeitiges K&uuml;ndigungsrecht wird einger&auml;umt:&nbsp;</b>
                <label *ngIf="contractData.wegLogic.kuendigung == 'beide'">beide Vertragsparteien</label>
                <label *ngIf="contractData.wegLogic.kuendigung == 'mieter'">Mieterseite</label>
              </label>
              <br>
              <label>Dieses K&uuml;ndigungsrecht soll nach Ablauf von {{contractData.wegLogic.kuendigunginmonaten}}
                Monaten
                greifen, mit einer K&uuml;ndigungsfrist von {{contractData.wegLogic.kuendigungsfrist}} Monaten.</label>
              <br>
            </div>
            <div *ngIf="contractData.wegLogic.kuendigungJaNein=='Nein'">
              <label>&nbsp;es bestehen keine vorzeitigen K&Uuml;ndigungsrechte</label>
            </div>
          </div>
          <br>
          <h4 class="custom-dlg-h">Mietzins</h4>
          <!-- Umsatzsteuerverpflichtung -->
          <div>
            <label><b>Vermieterseite ist
                Umsatzsteuerpflichtig:&nbsp;</b>{{contractData.wegLogic.umsatzsteuerverpflichtung}}</label>
            <br>
          </div>

          <!-- Hauptzins -->
          <div>
            <label><b>Hauptzins f&uuml;r die Wohnung (ohne
                Betriebskosten):&nbsp;</b>{{contractData.wegLogic.HauptmietzinsWohnungEUR}}</label>
            <br>
          </div>

          <!-- Parkzins -->
          <div *ngIf="contractData.contactData.contractOption == 'WEG'">
            <label><b>Welcher Hauptzins f&uuml;r den
                Parkplatz:&nbsp;</b>{{contractData.wegLogic.HauptmietzinsParkplaetzeEUR}}</label>
            <br>
          </div>

          <!-- Tabelle Mietzins -->
          <div style="padding:1%;">
            <p><b>Berechnung des Mietzinses</b></p>
            <div *ngIf="this.contractData.contactData.contractOption === 'WEG'">
              <div *ngIf="this.contractData.wegLogic.umsatzsteuerverpflichtung != 'Ja'">
                <table style="width: 75%;">
                  <tr *ngIf="!contractData.wegLogic.parkplatz">
                    <td *ngIf="contractData.wegLogic.mietObjType == 'geschaeftsraum'">Hauptzins Gesch&auml;ftsraum Netto
                    </td>
                    <td *ngIf="contractData.wegLogic.mietObjType == 'unternehmenspacht'">Hauptzins Pachtobjekt Netto</td>
                    <td
                      *ngIf="contractData.wegLogic.mietObjType != 'geschaeftsraum' && contractData.wegLogic.mietObjType != 'unternehmenspacht'">
                      Hauptzins Wohnung Netto</td>
                    <td>{{ this.contractData.wegLogic.HauptmietzinsWohnungEUR }}</td>
                  </tr>
                  <tr>
                    <td>Hauptzins Parkplatz&#47;Parkpl&auml;tze Netto</td>
                    <td>{{ this.contractData.wegLogic.HauptmietzinsParkplaetzeEUR }}</td>
                  </tr>
                  <tr>
                    <td>gesamter Hauptmietzins</td>
                    <td>{{ this.contractData.wegLogic.GesamtHauptmietzinsEURmitUSt }}</td>
                  </tr>
                </table>
              </div>

              <div *ngIf="this.contractData.wegLogic.umsatzsteuerverpflichtung == 'Ja'">
                <table style="width: 75%;">
                  <tr *ngIf="!contractData.wegLogic.parkplatz">
                    <td *ngIf="contractData.wegLogic.mietObjType == 'geschaeftsraum'">Hauptzins Gesch&auml;ftsraum Netto
                    </td>
                    <td *ngIf="contractData.wegLogic.mietObjType == 'unternehmenspacht'">Hauptzins Pachtobjekt Netto</td>
                    <td
                      *ngIf="contractData.wegLogic.mietObjType != 'geschaeftsraum' && contractData.wegLogic.mietObjType != 'unternehmenspacht'">
                      Hauptzins Wohnung Netto</td>
                    <td>{{ this.contractData.wegLogic.HauptmietzinsWohnungEUR }}</td>
                  </tr>
                  <tr *ngIf="!contractData.wegLogic.parkplatz">
                    <td *ngIf="contractData.wegLogic.mietObjType == 'geschaeftsraum'">Umsatzsteuer Gesch&auml;ftsraum
                      {{this.UStSatzMiet * 100}}&#37;</td>
                    <td *ngIf="contractData.wegLogic.mietObjType == 'unternehmenspacht'">Umsatzsteuer Pachtobjekt
                      {{this.UStSatzMiet
                      * 100}}&#37;</td>
                    <td
                      *ngIf="contractData.wegLogic.mietObjType != 'geschaeftsraum' && contractData.wegLogic.mietObjType != 'unternehmenspacht'">
                      Umsatzsteuer Wohnung {{this.UStSatzMiet * 100}}&#37;</td>
                    <td>{{ this.contractData.wegLogic.UStWohnungEUR }}</td>
                  </tr>
                  <tr>
                    <td>Hauptzins Parkplatz&#47;Parkpl&auml;tze Netto</td>
                    <td>{{ this.contractData.wegLogic.HauptmietzinsParkplaetzeEUR }}</td>
                  </tr>
                  <tr>
                    <td>Umsatzsteuer Parkplatz&#47;Parkpl&auml;tze 20&#37;</td>
                    <td>{{ this.contractData.wegLogic.UStParkplaetzeEUR }}</td>
                  </tr>
                  <tr>
                    <td>gesamter Hauptmietzins</td>
                    <td>{{ this.contractData.wegLogic.GesamtHauptmietzinsEURmitUSt }}</td>
                  </tr>
                </table>
              </div>
            </div>

            <div *ngIf="this.contractData.contactData.contractOption === 'BBL'">
              <div *ngIf="this.contractData.wegLogic.umsatzsteuerverpflichtung != 'Ja'">
                <table style="width: 75%;">
                  <tr *ngIf="!contractData.wegLogic.parkplatz">
                    <td *ngIf="contractData.wegLogic.mietObjType == 'geschaeftsraum'">Hauptzins Gesch&auml;ftsraum Netto
                    </td>
                    <td *ngIf="contractData.wegLogic.mietObjType == 'unternehmenspacht'">Hauptzins Pachtobjekt Netto</td>
                    <td
                      *ngIf="contractData.wegLogic.mietObjType != 'geschaeftsraum' && contractData.wegLogic.mietObjType != 'unternehmenspacht'">
                      Hauptzins Wohnung Netto</td>
                    <td>{{ this.contractData.wegLogic.HauptmietzinsWohnungEUR }}</td>
                  </tr>
                  <tr>
                    <td>gesamter Hauptmietzins</td>
                    <td>{{ this.contractData.wegLogic.GesamtHauptmietzinsEURmitUSt }}</td>
                  </tr>
                </table>
              </div>

              <div *ngIf="this.contractData.wegLogic.umsatzsteuerverpflichtung == 'Ja'">
                <table style="width: 75%;">
                  <tr *ngIf="!contractData.wegLogic.parkplatz">
                    <td *ngIf="contractData.wegLogic.mietObjType == 'geschaeftsraum'">Hauptzins Gesch&auml;ftsraum Netto
                    </td>
                    <td *ngIf="contractData.wegLogic.mietObjType == 'unternehmenspacht'">Hauptzins Pachtobjekt Netto</td>
                    <td
                      *ngIf="contractData.wegLogic.mietObjType != 'geschaeftsraum' && contractData.wegLogic.mietObjType != 'unternehmenspacht'">
                      Hauptzins Wohnung Netto</td>
                    <td>{{ this.contractData.wegLogic.HauptmietzinsWohnungEUR }}</td>
                  </tr>
                  <tr *ngIf="!contractData.wegLogic.parkplatz">
                    <td *ngIf="contractData.wegLogic.mietObjType == 'geschaeftsraum'">Umsatzsteuer Gesch&auml;ftsraum
                      {{this.UStSatzMiet * 100}}&#37;</td>
                    <td *ngIf="contractData.wegLogic.mietObjType == 'unternehmenspacht'">Umsatzsteuer Pachtobjekt
                      {{this.UStSatzMiet
                      * 100}}&#37;</td>
                    <td
                      *ngIf="contractData.wegLogic.mietObjType != 'geschaeftsraum' && contractData.wegLogic.mietObjType != 'unternehmenspacht'">
                      Umsatzsteuer Wohnung {{this.UStSatzMiet * 100}}&#37;</td>
                    <td>{{ this.contractData.wegLogic.UStWohnungEUR }}</td>
                  </tr>
                  <tr>
                    <td>gesamter Hauptmietzins</td>
                    <td>{{ this.contractData.wegLogic.GesamtHauptmietzinsEURmitUSt }}</td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
          <br>
          <h4 class="custom-dlg-h">Betriebskosten</h4>
          <!-- Betriebskosten -->
          <div>
            <label><b>monatliche Betriebskosten:&nbsp;</b>{{contractData.wegLogic.monatlicheBetriebskosten}}</label>
            <br>
          </div>
          <br>
          <h4 class="custom-dlg-h">Kaution</h4>
          <!-- Kaution -->
          <div>
            <label><b>Art der Kaution:</b> {{contractData.wegLogic.kautionType}}</label>
            <br>

            <div *ngIf="contractData.wegLogic.kautionType != 'keine' && contractData.wegLogic.kautionType">
              <label><b>H&ouml;he der Kaution:&nbsp;</b>{{contractData.wegLogic.kaution}}</label>
              <br>
            </div>
          </div>
          <br>
          <h4 class="custom-dlg-h">Sonstiges</h4>
          <!-- verpflichtungen mieter -->
          <div>
            <label><b>Verpflichtungen der Mieterseite:&nbsp;</b>
              <label *ngIf="!contractData.wegLogic.verpflichtungenMieter || contractData.wegLogic.verpflichtungenMieter.length == 0">keine Verpflichtungen</label>
              <div *ngIf="contractData.wegLogic.verpflichtungenMieter && contractData.wegLogic.verpflichtungenMieter.length">
                <label
                  *ngFor="let asdf of contractData.wegLogic.verpflichtungenMieter"><span>-&nbsp;{{VerpflichtungenMieterHelper(asdf)}}&nbsp;</span></label>
              </div>
            </label>
            <br>
          </div>

          <!-- werKostenVertragserrichtung -->
          <div>
            <label><b>Wer bezahlt Kosten der
                Mietvertragserrichtung:&nbsp;</b>
              <label *ngIf="contractData.wegLogic.werKostenVertragserrichtung == 'kaeufer'">Mieterseite</label>
              <label *ngIf="contractData.wegLogic.werKostenVertragserrichtung == 'verkaeufer'">Vermieterseite</label>
              <label *ngIf="contractData.wegLogic.werKostenVertragserrichtung == 'haelfte'">je zur H&auml;lfte</label>
            </label>
            <br>
          </div>

          <!-- <div *ngIf="contractOption == 'BBL'" class="custom-top-line">
          <label>Bildet die gesamte Liegenschaft den Kaufgegenstand oder nur einzelne Grundstücke?</label>
          <br>
          <mat-radio-group required aria-labelledby="example-radio-group-label" class="example-radio-group"
            [(ngModel)]="contractData.wegLogic.mietuebertragungsumfang" name="mietuebertragungsumfang">
            <mat-radio-button class="example-radio-button" *ngFor="let uebertragung of uebertragsumfangArten"
              [value]="uebertragung">
              {{uebertragung}}&emsp;
            </mat-radio-button>&emsp;
          </mat-radio-group><br />

          <div *ngIf="this.contractData.wegLogic.mietuebertragungsumfang === 'einzelne Grundstücke'">
            <label>Bitte geben Sie nur die Grundstücke ein, die verkauft werden!</label>
          </div>
          <br>
        </div> -->
        </div>

        <div *ngIf="contractData.primeType !='miet'">
          <p *ngIf="contractData.primeType !='schenkung'"><b>Kaufpreis:</b>
            {{contractData.wegLogic.kaufpreisinEUR}}</p>
          <div *ngIf="contractData.wegLogic.inventar == 'Nein'">
            <p><b>Inventarwert:</b> kein Inventar</p>
          </div>
          <div *ngIf="contractData.wegLogic.inventarWertinEUR">
            <p><b>Inventarwert:</b> {{contractData.wegLogic.inventarWertinEUR}}</p>
          </div>
          <p *ngIf="contractData.primeType !='schenkung'"><b>Datum der &Uuml;bergabe: </b>
            {{contractData.wegLogic.uebergabeDatum}}</p>
          <p *ngIf="contractData.primeType !='schenkung'"><b>Gew&auml;hrleistung:</b>
            {{contractData.wegLogic.umfangGewaehrleistung}}</p>
          <div *ngIf="contractData.primeType !='schenkung'">
            <label><b>Immobilienertragsteuer:</b> </label>
            <label *ngIf="contractData.wegLogic.ausnahmeIEST == 'hauptWohnsitzBefreiung2'">&nbsp;Hauptwohnsitzbefreiung –
              2-Jahres-Regelung</label>
            <label
              *ngIf="contractData.wegLogic.ausnahmeIEST == 'hauptWohnsitzBefreiung5aus10'">&nbsp;Hauptwohnsitzbefreiung
              –
              5
              aus
              10 Regelung</label>
            <label *ngIf="contractData.wegLogic.ausnahmeIEST == 'verkaeuferKoerperschaft'">&nbsp;Verk&auml;uferseite ist
              eine
              K&ouml;rperschaft</label>
            <label *ngIf="contractData.wegLogic.ausnahmeIEST == 'keineBefreiung'">&nbsp;keine Befreiung</label>
            <label *ngIf="contractData.wegLogic.ausnahmeIEST == 'herstellerbefreiung'">&nbsp;Herstellerbefreiung</label>
            <br>
          </div>
          <div *ngIf="contractData.primeType !='schenkung'">
            <label><b>&Uuml;bernahme Geldlasten:</b>&nbsp;</label>
            <label *ngIf="contractData.wegLogic.uebertragung == 'Geldlastenfrei'">Nein</label>
            <div *ngIf="contractData.wegLogic.uebertragung == 'Verbindlichkeiten werden mitübernommen'">
              <label>mit&uuml;bernommen werden</label>
              <label *ngFor="let clnr of contractData.wegLogic.uebertragungCLfdNr; let i = index;">&nbsp;C-LNR
                {{clnr}}{{uebertragungKommaUnd[i]}}<br></label>
            </div>
            <br>
          </div>
          <label><b>Verzicht auf Rechte:</b>&nbsp;</label>
          <label *ngIf="contractData.wegLogic.lastenLoeschen == 'Nein'">keine sonstigen Lasten (nicht Geldlasten) werden
            gel&ouml;scht
          </label>
          <div *ngIf="contractData.wegLogic.lastenLoeschen == 'Ja'">
            <label>Gel&ouml;scht&nbsp;</label>
            <label *ngIf="lastenloeschenIS1"> wird die zu C-LNR {{contractData.wegLogic.lastenLoeschenCLfdNr[0]}}
              ({{contractData.wegLogic.lastenloeschenGrundbuch[0]}})
              <label *ngIf="!LSGleicheKGNr">{{contractData.wegLogic.lastenloeschenKG[0]}}</label>
              einverleibte Last.
            </label>
            <div *ngIf="lastenloeschenISBigger1">
              <label>werden die </label>
              <div *ngFor="let clnr of contractData.wegLogic.lastenLoeschenCLfdNr; let i = index;">
                zu C-LNR {{clnr}} ({{contractData.wegLogic.lastenloeschenGrundbuch[i]}})
                <label *ngIf="!LSGleicheKGNr">{{contractData.wegLogic.lastenloeschenKG[i]}}</label>
                <label *ngIf="lastenloeschenUND[i]"> und </label>
                <label *ngIf="lastenloeschenBEISTRICH[i]">, </label>
                <br>
              </div>
              <div *ngIf="LSGleicheKGNr">
                <label>, jeweils Grundbuch {{contractData.wegLogic.immodetail.katastralGemeinde.KGNr}}
                  {{contractData.wegLogic.immodetail.katastralGemeinde.KGName}} einverleibte Lasten</label>
              </div>
            </div>
          </div>
          <br>
          <b>Außerb&uuml;cherliche Rechte:&nbsp;</b>
          <label
            *ngIf="ausserbuechelicherechte1; else other_NOABR">{{contractData.wegLogic.ausserbuecherlicheRechteDetail[0]}}</label>
          <label *ngIf="ausserbuechelicherechte2">{{contractData.wegLogic.ausserbuecherlicheRechteDetail[1]}}</label>
          <label *ngIf="ausserbuechelicherechte3">{{contractData.wegLogic.ausserbuecherlicheRechteDetail[2]}}</label>
          <ng-template #other_NOABR>
            <label>Es bestehen keine außerb&uuml;cherlichen Rechte.</label>
          </ng-template>
          <br>
          <p><b>Beg&uuml;nstigte:</b> {{contractData.wegLogic.KVzwischenAngehoerigen}}</p>

          <!-- Widumg -->
          <div *ngIf="contractData.contactData.AnzahlLS >= 2 || this.contractData.contactData.contractOption === 'UBL'">
            <b>Widmung:</b>
            <p *ngIf="contractData.wegLogic.fragebauflaeche == 'Ja' then other_bauflaeche; else other_NObauflaeche">dat
              laeuft
            </p>
            <ng-template #other_bauflaeche>
              <div *ngIf="contractData.wegLogic.fragebauerwartungsflaeche == 'Ja'">
                <div *ngIf="contractData.wegLogic.fragefreiflaeche == 'Ja'">
                  <div *ngIf="contractData.wegLogic.fragelufflaeche == 'Ja'">
                    - Baufl&auml;che (&sect; 13 Vorarlberger Rauplanungsgesetz)<br>
                    - Bauerwartungsland (&sect; 17 Vorarlberger Rauplanungsgesetz)<br>
                    - land- & forstwirtschaftliche Fl&auml;che (&sect; 18 Abs 3 Vorarlberger -Rauplanungsgesetz)<br>
                    - sonstige Freifl&auml;che (&sect; 18 Abs 4 bzw. Abs 5 Vorarlberger Rauplanungsgesetz)<br>
                  </div>
                  <div *ngIf="contractData.wegLogic.fragelufflaeche == 'Nein'">
                    - Baufl&auml;che (&sect; 13 Vorarlberger Rauplanungsgesetz)<br>
                    - Bauerwartungsland (&sect; 17 Vorarlberger Rauplanungsgesetz)<br>
                    - sonstige Freifl&auml;che (&sect; 18 Abs 4 bzw. Abs 5 Vorarlberger Rauplanungsgesetz)<br>
                  </div>
                </div>
                <div *ngIf="contractData.wegLogic.fragefreiflaeche == 'Nein'">
                  <div *ngIf="contractData.wegLogic.fragelufflaeche == 'Ja'">
                    - Baufl&auml;che (&sect; 13 Vorarlberger Rauplanungsgesetz)<br>
                    - Bauerwartungsland (&sect; 17 Vorarlberger Rauplanungsgesetz)<br>
                    - land- & forstwirtschaftliche Fl&auml;che (&sect; 18 Abs 3 Vorarlberger -Rauplanungsgesetz)<br>
                  </div>
                  <div *ngIf="contractData.wegLogic.fragelufflaeche == 'Nein'">
                    - Baufl&auml;che (&sect; 13 Vorarlberger Rauplanungsgesetz)<br>
                    - Bauerwartungsland (&sect; 17 Vorarlberger Rauplanungsgesetz)<br>
                  </div>
                </div>
              </div>
              <div *ngIf="contractData.wegLogic.fragebauerwartungsflaeche == 'Nein'">
                <div *ngIf="contractData.wegLogic.fragefreiflaeche == 'Ja'">
                  <div *ngIf="contractData.wegLogic.fragelufflaeche == 'Ja'">
                    - Baufl&auml;che (&sect; 13 Vorarlberger Rauplanungsgesetz)<br>
                    - land- & forstwirtschaftliche Fl&auml;che (&sect; 18 Abs 3 Vorarlberger -Rauplanungsgesetz)<br>
                    - sonstige Freifl&auml;che (&sect; 18 Abs 4 bzw. Abs 5 Vorarlberger Rauplanungsgesetz)<br>
                  </div>
                  <div *ngIf="contractData.wegLogic.fragelufflaeche == 'Nein'">
                    - Baufl&auml;che (&sect; 13 Vorarlberger Rauplanungsgesetz)<br>
                    - sonstige Freifl&auml;che (&sect; 18 Abs 4 bzw. Abs 5 Vorarlberger Rauplanungsgesetz)<br>
                  </div>
                </div>
                <div *ngIf="contractData.wegLogic.fragefreiflaeche == 'Nein'">
                  <div *ngIf="contractData.wegLogic.fragelufflaeche == 'Ja'">
                    - Baufl&auml;che (&sect; 13 Vorarlberger Rauplanungsgesetz)<br>
                    - land- & forstwirtschaftliche Fl&auml;che (&sect; 18 Abs 3 Vorarlberger -Rauplanungsgesetz)<br>
                  </div>
                  <div *ngIf="contractData.wegLogic.fragelufflaeche == 'Nein'">
                    - Baufl&auml;che (&sect; 13 Vorarlberger Rauplanungsgesetz)<br>
                  </div>
                </div>
              </div>
            </ng-template>
            <ng-template #other_NObauflaeche>
              <div *ngIf="contractData.wegLogic.fragebauerwartungsflaeche == 'Ja'">
                <div *ngIf="contractData.wegLogic.fragefreiflaeche == 'Ja'">
                  <div *ngIf="contractData.wegLogic.fragelufflaeche == 'Ja'">
                    - Bauerwartungsland (&sect; 17 Vorarlberger Rauplanungsgesetz)<br>
                    - land- & forstwirtschaftliche Fl&auml;che (&sect; 18 Abs 3 Vorarlberger -Rauplanungsgesetz)<br>
                    - sonstige Freifl&auml;che (&sect; 18 Abs 4 bzw. Abs 5 Vorarlberger Rauplanungsgesetz)<br>
                  </div>
                  <div *ngIf="contractData.wegLogic.fragelufflaeche == 'Nein'">
                    - Bauerwartungsland (&sect; 17 Vorarlberger Rauplanungsgesetz)<br>
                    - sonstige Freifl&auml;che (&sect; 18 Abs 4 bzw. Abs 5 Vorarlberger Rauplanungsgesetz)<br>
                  </div>
                </div>
                <div *ngIf="contractData.wegLogic.fragefreiflaeche == 'Nein'">
                  <div *ngIf="contractData.wegLogic.fragelufflaeche == 'Ja'">
                    - Bauerwartungsland (&sect; 17 Vorarlberger Rauplanungsgesetz)<br>
                    - land- & forstwirtschaftliche Fl&auml;che (&sect; 18 Abs 3 Vorarlberger -Rauplanungsgesetz)<br>
                  </div>
                  <div *ngIf="contractData.wegLogic.fragelufflaeche == 'Nein'">
                    - Bauerwartungsland (&sect; 17 Vorarlberger Rauplanungsgesetz)<br>
                  </div>
                </div>
              </div>
              <div *ngIf="contractData.wegLogic.fragebauerwartungsflaeche == 'Nein'">
                <div *ngIf="contractData.wegLogic.fragefreiflaeche == 'Ja'">
                  <div *ngIf="contractData.wegLogic.fragelufflaeche == 'Ja'">
                    - land- & forstwirtschaftliche Fl&auml;che (&sect; 18 Abs 3 Vorarlberger -Rauplanungsgesetz)<br>
                    - sonstige Freifl&auml;che (&sect; 18 Abs 4 bzw. Abs 5 Vorarlberger Rauplanungsgesetz)<br>
                  </div>
                  <div *ngIf="contractData.wegLogic.fragelufflaeche == 'Nein'">
                    Festgehalten wird, dass der Vertragsgegenstand im rechtskr&auml;ftigen Fl&auml;chenwidmungsplan
                    als sonstige Freifl&auml;che (&sect; 18 Abs 4 bzw. Abs 5 Vorarlberger Rauplanungsgesetz) ausgewiesen
                    ist.<br>
                  </div>
                </div>
                <div *ngIf="contractData.wegLogic.fragefreiflaeche == 'Nein'">
                  <div *ngIf="contractData.wegLogic.fragelufflaeche == 'Ja'">
                    - land- & forstwirtschaftliche Fl&auml;che (&sect; 18 Abs 3 Vorarlberger -Rauplanungsgesetz)<br>
                    - sonstige Freifl&auml;che (&sect; 18 Abs 4 bzw. Abs 5 Vorarlberger Rauplanungsgesetz)<br>
                  </div>
                </div>
              </div>
            </ng-template>
            <br>
          </div>

        </div>
      </div>
      <!-- LS-Details -->
      <div>
        <h4 class="custom-dlg-h">LIEGENSCHAFTSDETAILS</h4>
        <!-- UBBL -->
        <div
          *ngIf="this.contractData.contactData.contractOption === 'UBL' || this.contractData.contactData.contractOption === 'BBL'">
          <h5 *ngIf="contractData.contactData.AnzahlLS >= 2 ">Liegenschaft&nbsp;1:<br></h5>
          <div *ngIf="contractData.wegLogic.immodetail.uebertragungsumfang == 'gesamte Liegenschaft'">
            <label>EZ {{contractData.wegLogic.immodetail.einlageZahl}}, Grundbuch
              {{contractData.wegLogic.immodetail.katastralGemeinde.KGNr}}
              {{contractData.wegLogic.immodetail.katastralGemeinde.KGName}}, mit
            </label>
          </div>
          <div *ngIf="contractData.wegLogic.immodetail.grundstuecksnummer && contractData.wegLogic.immodetail.grundstuecksnummer.length == 1; else other_moreGSTNR">
            <label>GST-Nr {{contractData.wegLogic.immodetail.grundstuecksnummer[0]}}</label>
          </div>
          <ng-template #other_moreGSTNR>
            <div *ngFor="let gstnr of contractData.wegLogic.immodetail.grundstuecksnummer; let i = index;">
              <label>GST-NR {{gstnr}}</label>
              <label *ngIf="GstNrUND[i]">&nbsp;und</label>
              <label *ngIf="GstNrBEISTRICH[i]">, </label>
            </div>
          </ng-template>
          <div *ngIf="contractData.wegLogic.immodetail.uebertragungsumfang == 'einzelne Grundstücke'">
            <label>
              , derzeit vorgetragen in EZ {{contractData.wegLogic.immodetail.einlageZahl}},
              Grundbuch {{contractData.wegLogic.immodetail.katastralGemeinde.KGNr}}
              {{contractData.wegLogic.immodetail.katastralGemeinde.KGName}}
            </label>
          </div>

          <!-- Käufer/Verkäufer/Anteile LS>=2 -->
          <b *ngIf="contractData.primeType == 'miet'">Mietgegenst&auml;ndliche Anteile</b>
          <b *ngIf="contractData.primeType != 'miet'">Verkaufte Anteile</b>
          <div *ngFor="let anteil of contractData.wegLogic.immodetail.alleAnteile; let i = index;">
            <p><b>Anteil:</b> {{anteil}}, B-LNR: {{contractData.wegLogic.immodetail.alleBlnr[i]}}</p>
          </div>
          <br>
          <div *ngIf="LS4||LS3||LS2">
            <div>
              <b>Verk&auml;uferseite:</b>
              <div *ngIf="SellPriv1">
                <div *ngFor="let chef of contractData.anteildata.l1.seller.sellersPrivate">
                  {{chef.firstname}} {{chef.lastname}} ({{chef.birthday}})<br>
                  {{chef.address}}, {{chef.postalCode}} {{chef.city}}
                </div>
              </div>
              <div *ngIf="SellComp1">
                <div *ngFor="let chef of contractData.anteildata.l1.seller.sellersCompany">
                  {{chef.companyName}} ({{chef.companiesRegisterNumber}})<br>
                  {{chef.address}}, {{chef.postalCode}} {{chef.city}}
                </div>
              </div>
              <br>
              <b>K&auml;uferseite:</b>
              <div *ngIf="BuyPriv1">
                <div *ngFor="let chef of contractData.anteildata.l1.buyer.buyersPrivate">
                  {{chef.firstname}} {{chef.lastname}} ({{chef.birthday}})<br>
                  {{chef.address}}, {{chef.postalCode}} {{chef.city}}
                  <div *ngIf="KBOOL">
                    <p>Gekaufter Anteil: {{chef.calcanteil}}</p>
                  </div>
                </div>
              </div>
              <div *ngIf="BuyComp1">
                <div *ngFor="let chef of contractData.anteildata.l1.buyer.buyersCompany">
                  {{chef.companyName}} ({{chef.companiesRegisterNumber}})<br>
                  {{chef.address}}, {{chef.postalCode}} {{chef.city}}
                  <div *ngIf="KBOOL">
                    <p>Gekaufter Anteil: {{chef.calcanteil}}</p>
                  </div>
                  <br>
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="this.contractData.primeType != 'miet'">
            <b>Sonstige Rechte:&nbsp;</b>
            <div
              *ngIf="this.contractData.wegLogic.lssonstigeRechte.frageSonstigeRechte == 'Ja'; else other_NOL1SonstigeRechte">
              <br>
              <!-- -----LS 1 -------- -->
              <!-- Wohnrecht -->
              <div *ngIf="contractData.wegLogic.lssonstigeRechte.frageWohnrecht == 'Ja'">
                <b>Wohnrecht f&uuml;r:</b>
                <div *ngIf="contractData.wegLogic.lssonstigeRechte.wohnrecht.personArray.length > 0">
                  <div *ngFor="let chef of contractData.wegLogic.lssonstigeRechte.wohnrecht.personArray">
                    <p>{{chef.firstname}}&nbsp;{{chef.lastname}}&nbsp;({{chef.birthday}})</p>
                    <p>{{chef.address}},&nbsp;{{chef.postalCode}}&nbsp;{{chef.city}}</p>
                    <br>
                  </div>
                </div>
                <div *ngIf="contractData.wegLogic.lssonstigeRechte.wohnrecht.companyArray.length > 0">
                  <div *ngFor="let chef of contractData.wegLogic.lssonstigeRechte.wohnrecht.companyArray">
                    <p>{{chef.companyName}}&nbsp;({{chef.companiesRegisterNumber}})</p>
                    <p>{{chef.address}},&nbsp;{{chef.postalCode}}&nbsp;{{chef.city}}</p>
                    <br>
                  </div>
                </div>
                <br>
                <div *ngIf="contractData.wegLogic.lssonstigeRechte.wohnrecht.option == 'teile'">
                  <label>Vom Wohnungsgebrauchsrecht umfasst sind
                    {{contractData.wegLogic.lssonstigeRechte.wohnrecht.freitext}}</label>
                </div>
                <div *ngIf="contractData.wegLogic.lssonstigeRechte.wohnrecht.option == 'gesamte'">
                  <label>Vom Wohnungsgebrauchsrecht erfasst</label>
                  <div *ngIf="contractData.wegLogic.EPPartner == 'Nein'">
                    sind die gesamten Anteile.
                  </div>
                  <div *ngIf="contractData.wegLogic.EP == 'gesamte Wohnung oder Geschäftsräumlichkeit'
                || wegLogic.EPPartner == 'Ja'
                || wegLogic.EP == 'eigentuemeruebertraghalbeanteile'">
                    <label>ist der gesamte Kaufgegenstand.</label>
                  </div>
                </div>
              </div>
              <!-- Fruchtgenussrecht -->
              <div *ngIf="contractData.wegLogic.lssonstigeRechte.frageFruchtgenussrecht == 'Ja'">
                <b>Fruchtgenussrecht f&uuml;r:</b>
                <div *ngIf="contractData.wegLogic.lssonstigeRechte.fruchtgenussrecht.personArray.length > 0">
                  <div *ngFor="let chef of contractData.wegLogic.lssonstigeRechte.fruchtgenussrecht.personArray">
                    <p>{{chef.firstname}}&nbsp;{{chef.lastname}}&nbsp;({{chef.birthday}})</p>
                    <p>{{chef.address}},&nbsp;{{chef.postalCode}}&nbsp;{{chef.city}}</p>
                    <br>
                  </div>
                </div>
                <div *ngIf="contractData.wegLogic.lssonstigeRechte.fruchtgenussrecht.companyArray.length > 0">
                  <div *ngFor="let chef of contractData.wegLogic.lssonstigeRechte.fruchtgenussrecht.companyArray">
                    <p>{{chef.companyName}}&nbsp;({{chef.companiesRegisterNumber}})</p>
                    <p>{{chef.address}},&nbsp;{{chef.postalCode}}&nbsp;{{chef.city}}</p>
                    <br>
                  </div>
                </div>
                <br>
                <div *ngIf="contractData.wegLogic.lssonstigeRechte.fruchtgenussrecht.option == 'teile'">
                  <label>Vom Wohnungsgebrauchsrecht umfasst sind
                    {{contractData.wegLogic.lssonstigeRechte.fruchtgenussrecht.freitext}}</label>
                </div>
                <div *ngIf="contractData.wegLogic.lssonstigeRechte.fruchtgenussrecht.option == 'gesamte'">
                  <label>Vom Fruchtgenussrecht erfasst</label>
                  <div *ngIf="contractData.wegLogic.EPPartner == 'Nein'">
                    sind die gesamten Anteile.
                  </div>
                  <div *ngIf="contractData.wegLogic.EP == 'gesamte Wohnung oder Geschäftsräumlichkeit' || wegLogic.EPPartner == 'Ja' ||
        wegLogic.EP == 'eigentuemeruebertraghalbeanteile'">
                    <label>ist der gesamte Kaufgegenstand.</label>
                  </div>
                </div>
                <br>
              </div>
              <!-- Wiederkaufsrecht -->
              <div *ngIf="contractData.wegLogic.lssonstigeRechte.frageWiederkaufsrecht == 'Ja'">
                <p>Wiederkaufsrecht f&uuml;r:</p>
                <div *ngIf="contractData.wegLogic.lssonstigeRechte.wiederkaufsrecht.personArray.length > 0">
                  <div *ngFor="let chef of contractData.wegLogic.lssonstigeRechte.wiederkaufsrecht.personArray">
                    <p>{{chef.firstname}}&nbsp;{{chef.lastname}}&nbsp;({{chef.birthday}})</p>
                    <p>{{chef.address}},&nbsp;{{chef.postalCode}}&nbsp;{{chef.city}}</p>
                    <br>
                  </div>
                </div>
                <div *ngIf="contractData.wegLogic.lssonstigeRechte.wiederkaufsrecht.companyArray.length > 0">
                  <div *ngFor="let chef of contractData.wegLogic.lssonstigeRechte.wiederkaufsrecht.companyArray">
                    <p>{{chef.companyName}}&nbsp;({{chef.companiesRegisterNumber}})</p>
                    <p>{{chef.address}},&nbsp;{{chef.postalCode}}&nbsp;{{chef.city}}</p>
                    <br>
                  </div>
                </div>
                <p>Der Wiederkauffall tritt ein, wenn {{contractData.wegLogic.lssonstigeRechte.wiederkaufsrecht.text}}</p>
                <div *ngIf="contractData.wegLogic.lssonstigeRechte.wiederkaufsrecht.preisinEUR">
                  <p>Kaufpreis bei Wiederkauf: {{contractData.wegLogic.lssonstigeRechte.wiederkaufsrecht.preisinEUR}}</p>
                </div>
                <div *ngIf="contractData.wegLogic.lssonstigeRechte.wiederkaufsrecht.schaetzpreis">
                  <p>Kaufpreis bei Wiederkauf: wird durch ein Sachverst&auml;ndigengutachten ermittelt</p>
                </div>
                <br>
              </div>
              <!-- Vorkaufsrecht -->
              <div *ngIf="contractData.wegLogic.lssonstigeRechte.frageVorkaufsrecht == 'Ja'">
                <b>Vorkaufsrecht f&uuml;r:</b>
                <div *ngIf="contractData.wegLogic.lssonstigeRechte.vorkaufsrecht.personArray.length > 0">
                  <div *ngFor="let chef of contractData.wegLogic.lssonstigeRechte.vorkaufsrecht.personArray">
                    <p>{{chef.firstname}}&nbsp;{{chef.lastname}}&nbsp;({{chef.birthday}})</p>
                    <p>{{chef.address}},&nbsp;{{chef.postalCode}}&nbsp;{{chef.city}}</p>
                  </div>
                </div>
                <div *ngIf="contractData.wegLogic.lssonstigeRechte.vorkaufsrecht.companyArray.length > 0">
                  <div *ngFor="let chef of contractData.wegLogic.lssonstigeRechte.vorkaufsrecht.companyArray">
                    <p>{{chef.companyName}}&nbsp;({{chef.companiesRegisterNumber}})</p>
                    <p>{{chef.address}},&nbsp;{{chef.postalCode}}&nbsp;{{chef.city}}</p>
                  </div>
                </div>
                <div *ngIf="contractData.wegLogic.lssonstigeRechte.vorkaufsrecht.option == '1072abgb'">
                  <p>Vorkaufsrecht gem. § 1072 ABGB</p>
                </div>
                <div *ngIf="contractData.wegLogic.lssonstigeRechte.vorkaufsrecht.option == 'alle'">
                  <p>Vorkaufsrecht für alle Ver&auml;ußerungsarten</p>
                  <label>Kaufpreis im Vorkaufsfall:</label>
                  <div *ngIf="contractData.wegLogic.lssonstigeRechte.vorkaufsrecht.fixpreis">
                    <label>{{contractData.wegLogic.lssonstigeRechte.vorkaufsrecht.preisinEUR}}</label>
                  </div>
                  <div *ngIf="contractData.wegLogic.lssonstigeRechte.vorkaufsrecht.schaetzpreis">
                    <label>wird durch ein Sachverst&auml;ndigengutachten ermittelt</label>
                  </div>
                </div>
                <br>
              </div>
              <!-- belastungsundveraeusserungsverbot -->
              <div *ngIf="contractData.wegLogic.lssonstigeRechte.frageBelastungsverbot == 'Ja'">
                <div *ngIf="contractData.wegLogic.sub.belastungsundveraeusserungsverbot">
                  <b>Belastungs- und Ver&auml;ußerungsverbot</b>
                </div>
                <div *ngIf="contractData.wegLogic.sub.belastungsverbot">
                  <b>Belastungsverbot</b>
                </div>
                <div *ngIf="contractData.wegLogic.sub.veraeusserungsverbot">
                  <b>Ver&auml;ußerungsverbot</b>
                </div>
                <b>f&uuml;r:&nbsp;</b>
                <br>
                <div class="dcc"
                  *ngIf="contractData.wegLogic.lssonstigeRechte.belastungsUndVeraeusserungsverbot.personArray.length > 0">
                  <div
                    *ngFor="let chef of contractData.wegLogic.lssonstigeRechte.belastungsUndVeraeusserungsverbot.personArray">
                    <p>{{chef.firstname}}&nbsp;{{chef.lastname}}&nbsp;({{chef.birthday}})</p>
                    <p>{{chef.address}},&nbsp;{{chef.postalCode}}&nbsp;{{chef.city}}</p>
                    <br>
                  </div>
                </div>
                <div class="dc"
                  *ngIf="contractData.wegLogic.lssonstigeRechte.belastungsUndVeraeusserungsverbot.companyArray.length > 0">
                  <div
                    *ngFor="let chef of contractData.wegLogic.lssonstigeRechte.belastungsUndVeraeusserungsverbot.companyArray">
                    <p>{{chef.companyName}}&nbsp;({{chef.companiesRegisterNumber}})</p>
                    <p>{{chef.address}},&nbsp;{{chef.postalCode}}&nbsp;{{chef.city}}</p>
                  </div>
                </div>
                <br>
                <label>Rechtseinr&auml;umung unter nahen Angehörigen:</label>
                <div
                  *ngIf="contractData.wegLogic.lssonstigeRechte.belastungsUndVeraeusserungsverbot.naheAngehoerige == 'Nein'">
                  <label>nein</label>
                </div>
                <div
                  *ngIf="contractData.wegLogic.lssonstigeRechte.belastungsUndVeraeusserungsverbot.naheAngehoerige == 'Ja'">
                  <label>ja</label>
                </div>
                <br>
              </div>
            </div>
            <ng-template #other_NOL1SonstigeRechte><label>Keine Sonstigen Rechte</label></ng-template>
          </div>
          <br>

          <div *ngIf="LS2">
            <b>Liegenschaft 2:</b>
            <div *ngIf="contractData.wegLogic.l2immodetail.l2uebertragungsumfang == 'gesamte Liegenschaft'">
              EZ {{contractData.wegLogic.l2immodetail.l2einlageZahl}}, Grundbuch
              {{contractData.wegLogic.l2immodetail.l2katastralGemeinde.KGNr}}
              {{contractData.wegLogic.l2immodetail.l2katastralGemeinde.KGName}}, mit
            </div>
            <div *ngIf="contractData.wegLogic.l2immodetail.uebertragungsumfang == 'einzelne Grundstücke'">
              <p>&Uuml;bertragungsumfang: nur die oben genannten Grundst&uuml;cke werden &uuml;bertragen</p>
            </div>
            <div *ngFor="let gstnr of contractData.wegLogic.l2immodetail.l2grundstuecksnummer; let i = index;">
              <label>GST-NR {{gstnr}}</label>
              <label *ngIf="GstNr2UND[i]">&nbsp;und</label>
              <label *ngIf="GstNr2BEISTRICH[i]">, </label>
            </div>
            <div *ngIf="contractData.wegLogic.l2immodetail.l2uebertragungsumfang == 'einzelne Grundstücke'">
              <label>
                , derzeit vorgetragen in EZ {{contractData.wegLogic.l2immodetail.l2einlageZahl}}, Grundbuch
                {{contractData.wegLogic.l2immodetail.l2katastralGemeinde.KGNr}}
                {{contractData.wegLogic.l2immodetail.l2katastralGemeinde.KGName}}
              </label>
            </div>

            <!-- Käufer/Verkäufer/Anteile LS>=2 -->
            <b *ngIf="contractData.primeType == 'miet'">Mietgegenst&auml;ndliche Anteile</b>
            <b *ngIf="contractData.primeType != 'miet'">Verkaufte Anteile</b>
            <div *ngFor="let anteil of contractData.wegLogic.l2immodetail.alleAnteile; let i = index;">
              <p>Anteil: {{anteil}}, B-LNR: {{contractData.wegLogic.l2immodetail.alleBlnr[i]}}</p>
            </div>
            <br>
            <div>
              <p>Verk&auml;uferseite:</p>
              <div *ngIf="SellPriv2">
                <div *ngFor="let chef of contractData.anteildata.l2.seller.sellersPrivate">
                  {{chef.firstname}} {{chef.lastname}} ({{chef.birthday}})<br>
                  {{chef.address}}, {{chef.postalCode}} {{chef.city}}
                </div>
              </div>
              <div *ngIf="SellComp2">
                <div *ngFor="let chef of contractData.anteildata.l2.seller.sellersCompany">
                  {{chef.companyName}} ({{chef.companiesRegisterNumber}})<br>
                  {{chef.address}}, {{chef.postalCode}} {{chef.city}}
                </div>
              </div>
              <br>
              <p>K&auml;uferseite:</p>
              <div *ngIf="BuyPriv2">
                <div *ngFor="let chef of contractData.anteildata.l2.buyer.buyersPrivate">
                  {{chef.firstname}} {{chef.lastname}} ({{chef.birthday}})<br>
                  {{chef.address}}, {{chef.postalCode}} {{chef.city}}
                  <div *ngIf="KBOOL">
                    <p>Gekaufter Anteil: {{chef.calcanteil}}</p>
                  </div>
                </div>
              </div>
              <div *ngIf="BuyComp2">
                <div *ngFor="let chef of contractData.anteildata.l2.buyer.buyersCompany">
                  {{chef.companyName}} ({{chef.companiesRegisterNumber}})<br>
                  {{chef.address}}, {{chef.postalCode}} {{chef.city}}
                  <div *ngIf="KBOOL">
                    <p>Gekaufter Anteil: {{chef.calcanteil}}</p>
                  </div>
                  <br>
                </div>
              </div>
            </div>
            <br>
            <div *ngIf="this.contractData.primeType !='miet'">
              <h5>Sonstige Rechte:&nbsp;</h5>
              <div
                *ngIf="this.contractData.wegLogic.lsl2sonstigeRechte.frageSonstigeRechte == 'Ja'; else other_NOL2SonstigeRechte">
                <!-- Wohnrecht -->
                <div *ngIf="contractData.wegLogic.lsl2sonstigeRechte.frageWohnrecht == 'Ja'">
                  <b>Wohnrecht f&uuml;r:</b>
                  <div *ngIf="contractData.wegLogic.lsl2sonstigeRechte.wohnrecht.personArray.length > 0">
                    <div *ngFor="let chef of contractData.wegLogic.lsl2sonstigeRechte.wohnrecht.personArray">
                      <p>{{chef.firstname}}&nbsp;{{chef.lastname}}&nbsp;({{chef.birthday}})</p>
                      <p>{{chef.address}},&nbsp;{{chef.postalCode}}&nbsp;{{chef.city}}</p>
                      <br>
                    </div>
                  </div>
                  <div *ngIf="contractData.wegLogic.lsl2sonstigeRechte.wohnrecht.companyArray.length > 0">
                    <div *ngFor="let chef of contractData.wegLogic.lsl2sonstigeRechte.wohnrecht.companyArray">
                      <p>{{chef.companyName}}&nbsp;({{chef.companiesRegisterNumber}})</p>
                      <p>{{chef.address}},&nbsp;{{chef.postalCode}}&nbsp;{{chef.city}}</p>
                      <br>
                    </div>
                  </div>
                  <br>
                  <div *ngIf="contractData.wegLogic.lsl2sonstigeRechte.wohnrecht.option == 'teile'">
                    <label>Vom Wohnungsgebrauchsrecht umfasst sind
                      {{contractData.wegLogic.lsl2sonstigeRechte.wohnrecht.freitext}}</label>
                  </div>
                  <div *ngIf="contractData.wegLogic.lsl2sonstigeRechte.wohnrecht.option == 'gesamte'">
                    <label>Vom Wohnungsgebrauchsrecht erfasst</label>
                    <div *ngIf="contractData.wegLogic.EPPartner == 'Nein'">
                      sind die gesamten Anteile.
                    </div>
                    <div *ngIf="contractData.wegLogic.EP == 'gesamte Wohnung oder Geschäftsräumlichkeit' || wegLogic.EPPartner == 'Ja' ||
              wegLogic.EP == 'eigentuemeruebertraghalbeanteile'">
                      <label>ist der gesamte Kaufgegenstand.</label>
                    </div>
                  </div>
                  <br>
                </div>
                <!-- Fruchtgenussrecht -->
                <div *ngIf="contractData.wegLogic.lsl2sonstigeRechte.frageFruchtgenussrecht == 'Ja'">
                  <b>Fruchtgenussrecht f&uuml;r:</b>
                  <div *ngIf="contractData.wegLogic.lsl2sonstigeRechte.fruchtgenussrecht.personArray.length > 0">
                    <div *ngFor="let chef of contractData.wegLogic.lsl2sonstigeRechte.fruchtgenussrecht.personArray">
                      <p>{{chef.firstname}}&nbsp;{{chef.lastname}}&nbsp;({{chef.birthday}})</p>
                      <p>{{chef.address}},&nbsp;{{chef.postalCode}}&nbsp;{{chef.city}}</p>
                      <br>
                    </div>
                  </div>
                  <div *ngIf="contractData.wegLogic.lsl2sonstigeRechte.fruchtgenussrecht.companyArray.length > 0">
                    <div *ngFor="let chef of contractData.wegLogic.lsl2sonstigeRechte.fruchtgenussrecht.companyArray">
                      <p>{{chef.companyName}}&nbsp;({{chef.companiesRegisterNumber}})</p>
                      <p>{{chef.address}},&nbsp;{{chef.postalCode}}&nbsp;{{chef.city}}</p>
                      <br>
                    </div>
                  </div>
                  <br>
                  <div *ngIf="contractData.wegLogic.lsl2sonstigeRechte.fruchtgenussrecht.option == 'teile'">
                    <label>Vom Wohnungsgebrauchsrecht umfasst sind
                      {{contractData.wegLogic.lsl2sonstigeRechte.fruchtgenussrecht.freitext}}</label>
                  </div>
                  <div *ngIf="contractData.wegLogic.lsl2sonstigeRechte.fruchtgenussrecht.option == 'gesamte'">
                    <label>Vom Fruchtgenussrecht erfasst</label>
                    <div *ngIf="contractData.wegLogic.EPPartner == 'Nein'">
                      sind die gesamten Anteile.
                    </div>
                    <div *ngIf="contractData.wegLogic.EP == 'gesamte Wohnung oder Geschäftsräumlichkeit' || wegLogic.EPPartner == 'Ja' ||
              wegLogic.EP == 'eigentuemeruebertraghalbeanteile'">
                      <label>ist der gesamte Kaufgegenstand.</label>
                    </div>
                  </div>
                </div>
                <!-- Wiederkaufsrecht -->
                <div *ngIf="contractData.wegLogic.lsl2sonstigeRechte.frageWiederkaufsrecht == 'Ja'">
                  <b>Wiederkaufsrecht f&uuml;r:</b>
                  <div *ngIf="contractData.wegLogic.lsl2sonstigeRechte.wiederkaufsrecht.personArray.length > 0">
                    <div *ngFor="let chef of contractData.wegLogic.lsl2sonstigeRechte.wiederkaufsrecht.personArray">
                      <p>{{chef.firstname}}&nbsp;{{chef.lastname}}&nbsp;({{chef.birthday}})</p>
                      <p>{{chef.address}},&nbsp;{{chef.postalCode}}&nbsp;{{chef.city}}</p>
                      <br>
                    </div>
                  </div>
                  <div *ngIf="contractData.wegLogic.lsl2sonstigeRechte.wiederkaufsrecht.companyArray.length > 0">
                    <div *ngFor="let chef of contractData.wegLogic.lsl2sonstigeRechte.wiederkaufsrecht.companyArray">
                      <p>{{chef.companyName}}&nbsp;({{chef.companiesRegisterNumber}})</p>
                      <p>{{chef.address}},&nbsp;{{chef.postalCode}}&nbsp;{{chef.city}}</p>
                    </div>
                  </div>
                  <p>Der Wiederkauffall tritt ein, wenn {{contractData.wegLogic.lsl2sonstigeRechte.wiederkaufsrecht.text}}
                  </p>
                  <div *ngIf="contractData.wegLogic.lsl2sonstigeRechte.wiederkaufsrecht.preisinEUR">
                    <p>Kaufpreis bei Wiederkauf: {{contractData.wegLogic.lsl2sonstigeRechte.wiederkaufsrecht.preisinEUR}}
                    </p>
                  </div>
                  <div *ngIf="contractData.wegLogic.lsl2sonstigeRechte.wiederkaufsrecht.schaetzpreis">
                    <p>Kaufpreis bei Wiederkauf: wird durch ein Sachverst&auml;ndigengutachten ermittelt</p>
                  </div>
                  <br>
                </div>
                <!-- Vorkaufsrecht -->
                <div *ngIf="contractData.wegLogic.lsl2sonstigeRechte.frageVorkaufsrecht == 'Ja'">
                  <b>Vorkaufsrecht f&uuml;r:</b>
                  <div *ngIf="contractData.wegLogic.lsl2sonstigeRechte.vorkaufsrecht.personArray.length > 0">
                    <div *ngFor="let chef of contractData.wegLogic.lsl2sonstigeRechte.vorkaufsrecht.personArray">
                      <p>{{chef.firstname}}&nbsp;{{chef.lastname}}&nbsp;({{chef.birthday}})</p>
                      <p>{{chef.address}},&nbsp;{{chef.postalCode}}&nbsp;{{chef.city}}</p>
                      <br>
                    </div>
                  </div>
                  <div *ngIf="contractData.wegLogic.lsl2sonstigeRechte.vorkaufsrecht.companyArray.length > 0">
                    <div *ngFor="let chef of contractData.wegLogic.lsl2sonstigeRechte.vorkaufsrecht.companyArray">
                      <p>{{chef.companyName}}&nbsp;({{chef.companiesRegisterNumber}})</p>
                      <p>{{chef.address}},&nbsp;{{chef.postalCode}}&nbsp;{{chef.city}}</p>
                      <br>
                    </div>
                  </div>
                  <div *ngIf="contractData.wegLogic.lsl2sonstigeRechte.vorkaufsrecht.option == '1072abgb'">
                    <p>Vorkaufsrecht gem. § 1072 ABGB</p>
                  </div>
                  <div *ngIf="contractData.wegLogic.lsl2sonstigeRechte.vorkaufsrecht.option == 'alle'">
                    <p>Vorkaufsrecht für alle Ver&auml;ußerungsarten</p>
                    <label>Kaufpreis im Vorkaufsfall:</label>
                    <div *ngIf="contractData.wegLogic.lsl2sonstigeRechte.vorkaufsrecht.fixpreis">
                      <label>{{contractData.wegLogic.lsl2sonstigeRechte.vorkaufsrecht.preisinEUR}}</label>
                    </div>
                    <div *ngIf="contractData.wegLogic.lsl2sonstigeRechte.vorkaufsrecht.schaetzpreis">
                      <label>wird durch ein Sachverst&auml;ndigengutachten ermittelt</label>
                    </div>
                  </div>
                  <br>
                </div>
                <!-- belastungsundveraeusserungsverbot -->
                <div *ngIf="contractData.wegLogic.lsl2sonstigeRechte.frageBelastungsverbot == 'Ja'">
                  <div *ngIf="contractData.wegLogic.sub.belastungsundveraeusserungsverbot">
                    <b>Belastungs- und Ver&auml;ußerungsverbot</b>
                  </div>
                  <div *ngIf="contractData.wegLogic.sub.belastungsverbot">
                    <b>Belastungsverbot</b>
                  </div>
                  <div *ngIf="contractData.wegLogic.sub.veraeusserungsverbot">
                    <b>Ver&auml;ußerungsverbot</b>
                  </div>
                  <b>f&uuml;r:&nbsp;</b>
                  <br>
                  <div class="dssc"
                    *ngIf="contractData.wegLogic.lsl2sonstigeRechte.belastungsUndVeraeusserungsverbot.personArray.length > 0">
                    <div
                      *ngFor="let chef of contractData.wegLogic.lsl2sonstigeRechte.belastungsUndVeraeusserungsverbot.personArray">
                      <p>{{chef.firstname}}&nbsp;{{chef.lastname}}&nbsp;({{chef.birthday}})</p>
                      <p>{{chef.address}},&nbsp;{{chef.postalCode}}&nbsp;{{chef.city}}</p>
                      <br>
                    </div>
                  </div>
                  <div class="dcxx"
                    *ngIf="contractData.wegLogic.lsl2sonstigeRechte.belastungsUndVeraeusserungsverbot.companyArray.length > 0">
                    <div
                      *ngFor="let chef of contractData.wegLogic.lsl2sonstigeRechte.belastungsUndVeraeusserungsverbot.companyArray">
                      <p>{{chef.companyName}}&nbsp;({{chef.companiesRegisterNumber}})</p>
                      <p>{{chef.address}},&nbsp;{{chef.postalCode}}&nbsp;{{chef.city}}</p>
                    </div>
                  </div>
                  <br>
                  <label>Rechtseinr&auml;umung unter nahen Angehörigen:</label>
                  <div
                    *ngIf="contractData.wegLogic.lsl2sonstigeRechte.belastungsUndVeraeusserungsverbot.naheAngehoerige == 'Nein'">
                    <label>nein</label>
                  </div>
                  <div
                    *ngIf="contractData.wegLogic.lsl2sonstigeRechte.belastungsUndVeraeusserungsverbot.naheAngehoerige == 'Ja'">
                    <label>ja</label>
                  </div>
                  <br>
                </div>
              </div>
              <ng-template #other_NOL2SonstigeRechte><label>Keine Sonstigen Rechte</label></ng-template>
              <br>
            </div>
          </div>

          <div *ngIf="LS3">
            <p>Liegenschaft 3:</p>
            <div *ngIf="contractData.wegLogic.l3immodetail.l3uebertragungsumfang == 'gesamte Liegenschaft'">
              EZ {{contractData.wegLogic.l3immodetail.l3einlageZahl}}, Grundbuch
              {{contractData.wegLogic.l3immodetail.l3katastralGemeinde.KGNr}}
              {{contractData.wegLogic.l3immodetail.l3katastralGemeinde.KGName}}, mit
            </div>
            <div *ngFor="let gstnr of contractData.wegLogic.l3immodetail.l3grundstuecksnummer; let i = index;">
              <label>GST-NR {{gstnr}}</label>
              <label *ngIf="GstNr3UND[i]">&nbsp;und</label>
              <label *ngIf="GstNr3BEISTRICH[i]">, </label>
            </div>
            <div *ngIf="contractData.wegLogic.l3immodetail.l3uebertragungsumfang == 'einzelne Grundstücke'">
              <label>
                , derzeit vorgetragen in EZ {{contractData.wegLogic.l3immodetail.l3einlageZahl}}, Grundbuch
                {{contractData.wegLogic.l3immodetail.l3katastralGemeinde.KGNr}}
                {{contractData.wegLogic.l3immodetail.l3katastralGemeinde.KGName}}
              </label>
            </div>

            <!-- Käufer/Verkäufer/Anteile LS>=2 -->
            <b *ngIf="contractData.primeType == 'miet'">Mietgegenst&auml;ndliche Anteile</b>
            <b *ngIf="contractData.primeType != 'miet'">Verkaufte Anteile</b>
            <div *ngFor="let anteil of contractData.wegLogic.l3immodetail.alleAnteile; let i = index;">
              <p>Anteil: {{anteil}}, B-LNR: {{contractData.wegLogic.l3immodetail.alleBlnr[i]}}</p>
            </div>
            <br>
            <div>
              <p>Verk&auml;uferseite:</p>
              <div *ngIf="SellPriv3">
                <div *ngFor="let chef of contractData.anteildata.l3.seller.sellersPrivate">
                  {{chef.firstname}} {{chef.lastname}} ({{chef.birthday}})<br>
                  {{chef.address}}, {{chef.postalCode}} {{chef.city}}
                </div>
              </div>
              <div *ngIf="SellComp3">
                <div *ngFor="let chef of contractData.anteildata.l3.seller.sellersCompany">
                  {{chef.companyName}} ({{chef.companiesRegisterNumber}})<br>
                  {{chef.address}}, {{chef.postalCode}} {{chef.city}}
                </div>
              </div>
              <br>
              <p>K&auml;uferseite:</p>
              <div *ngIf="BuyPriv3">
                <div *ngFor="let chef of contractData.anteildata.l3.buyer.buyersPrivate">
                  {{chef.firstname}} {{chef.lastname}} ({{chef.birthday}})<br>
                  {{chef.address}}, {{chef.postalCode}} {{chef.city}}
                  <div *ngIf="KBOOL">
                    <p>Gekaufter Anteil: {{chef.calcanteil}}</p>
                  </div>
                </div>
              </div>
              <div *ngIf="BuyComp3">
                <div *ngFor="let chef of contractData.anteildata.l3.buyer.buyersCompany">
                  {{chef.companyName}} ({{chef.companiesRegisterNumber}})<br>
                  {{chef.address}}, {{chef.postalCode}} {{chef.city}}
                  <div *ngIf="KBOOL">
                    <p>Gekaufter Anteil: {{chef.calcanteil}}</p>
                  </div>
                  <br>
                </div>
              </div>
            </div>
            <br>
            <div *ngIf="this.contractData.primeType !='miet'">
              <label>Sonstige Rechte:&nbsp;</label>
              <div
                *ngIf="this.contractData.wegLogic.lsl3sonstigeRechte.frageSonstigeRechte == 'Ja'; else other_NOL3SonstigeRechte">
                <!-- Wohnrecht -->
                <div *ngIf="contractData.wegLogic.lsl3sonstigeRechte.frageWohnrecht == 'Ja'">
                  <b>Wohnrecht f&uuml;r:</b>
                  <div *ngIf="contractData.wegLogic.lsl3sonstigeRechte.wohnrecht.personArray.length > 0">
                    <div *ngFor="let chef of contractData.wegLogic.lsl3sonstigeRechte.wohnrecht.personArray">
                      <p>{{chef.firstname}}&nbsp;{{chef.lastname}}&nbsp;({{chef.birthday}})</p>
                      <p>{{chef.address}},&nbsp;{{chef.postalCode}}&nbsp;{{chef.city}}</p>
                      <br>
                    </div>
                  </div>
                  <div *ngIf="contractData.wegLogic.lsl3sonstigeRechte.wohnrecht.companyArray.length > 0">
                    <div *ngFor="let chef of contractData.wegLogic.lsl3sonstigeRechte.wohnrecht.companyArray">
                      <p>{{chef.companyName}}&nbsp;({{chef.companiesRegisterNumber}})</p>
                      <p>{{chef.address}},&nbsp;{{chef.postalCode}}&nbsp;{{chef.city}}</p>
                      <br>
                    </div>
                  </div>
                  <br>
                  <div *ngIf="contractData.wegLogic.lsl3sonstigeRechte.wohnrecht.option == 'teile'">
                    <label>Vom Wohnungsgebrauchsrecht umfasst sind
                      {{contractData.wegLogic.lsl3sonstigeRechte.wohnrecht.freitext}}</label>
                  </div>
                  <div *ngIf="contractData.wegLogic.lsl3sonstigeRechte.wohnrecht.option == 'gesamte'">
                    <label>Vom Wohnungsgebrauchsrecht erfasst</label>
                    <div *ngIf="contractData.wegLogic.EPPartner == 'Nein'">
                      sind die gesamten Anteile.
                    </div>
                    <div *ngIf="contractData.wegLogic.EP == 'gesamte Wohnung oder Geschäftsräumlichkeit' || wegLogic.EPPartner == 'Ja' ||
              wegLogic.EP == 'eigentuemeruebertraghalbeanteile'">
                      <label>ist der gesamte Kaufgegenstand.</label>
                    </div>
                  </div>
                </div>
                <!-- Fruchtgenussrecht -->
                <div *ngIf="contractData.wegLogic.lsl3sonstigeRechte.frageFruchtgenussrecht == 'Ja'">
                  <b>Fruchtgenussrecht f&uuml;r:</b>
                  <div *ngIf="contractData.wegLogic.lsl3sonstigeRechte.fruchtgenussrecht.personArray.length > 0">
                    <div *ngFor="let chef of contractData.wegLogic.lsl3sonstigeRechte.fruchtgenussrecht.personArray">
                      <p>{{chef.firstname}}&nbsp;{{chef.lastname}}&nbsp;({{chef.birthday}})</p>
                      <p>{{chef.address}},&nbsp;{{chef.postalCode}}&nbsp;{{chef.city}}</p>
                      <br>
                    </div>
                  </div>
                  <div *ngIf="contractData.wegLogic.lsl3sonstigeRechte.fruchtgenussrecht.companyArray.length > 0">
                    <div *ngFor="let chef of contractData.wegLogic.lsl3sonstigeRechte.fruchtgenussrecht.companyArray">
                      <p>{{chef.companyName}}&nbsp;({{chef.companiesRegisterNumber}})</p>
                      <p>{{chef.address}},&nbsp;{{chef.postalCode}}&nbsp;{{chef.city}}</p>
                      <br>
                    </div>
                  </div>
                  <br>
                  <div *ngIf="contractData.wegLogic.lsl3sonstigeRechte.fruchtgenussrecht.option == 'teile'">
                    <label>Vom Wohnungsgebrauchsrecht umfasst sind
                      {{contractData.wegLogic.lsl3sonstigeRechte.fruchtgenussrecht.freitext}}</label>
                  </div>
                  <div *ngIf="contractData.wegLogic.lsl3sonstigeRechte.fruchtgenussrecht.option == 'gesamte'">
                    <label>Vom Fruchtgenussrecht erfasst</label>
                    <div *ngIf="contractData.wegLogic.EPPartner == 'Nein'">
                      sind die gesamten Anteile.
                    </div>
                    <div *ngIf="contractData.wegLogic.EP == 'gesamte Wohnung oder Geschäftsräumlichkeit' || wegLogic.EPPartner == 'Ja' ||
              wegLogic.EP == 'eigentuemeruebertraghalbeanteile'">
                      <label>ist der gesamte Kaufgegenstand.</label>
                    </div>
                  </div>
                  <br>
                </div>
                <!-- Wiederkaufsrecht -->
                <div *ngIf="contractData.wegLogic.lsl3sonstigeRechte.frageWiederkaufsrecht == 'Ja'">
                  <p>Wiederkaufsrecht f&uuml;r:</p>
                  <div *ngIf="contractData.wegLogic.lsl3sonstigeRechte.wiederkaufsrecht.personArray.length > 0">
                    <div *ngFor="let chef of contractData.wegLogic.lsl3sonstigeRechte.wiederkaufsrecht.personArray">
                      <p>{{chef.firstname}}&nbsp;{{chef.lastname}}&nbsp;({{chef.birthday}})</p>
                      <p>{{chef.address}},&nbsp;{{chef.postalCode}}&nbsp;{{chef.city}}</p>
                      <br>
                    </div>
                  </div>
                  <div *ngIf="contractData.wegLogic.lsl3sonstigeRechte.wiederkaufsrecht.companyArray.length > 0">
                    <div *ngFor="let chef of contractData.wegLogic.lsl3sonstigeRechte.wiederkaufsrecht.companyArray">
                      <p>{{chef.companyName}}&nbsp;({{chef.companiesRegisterNumber}})</p>
                      <p>{{chef.address}},&nbsp;{{chef.postalCode}}&nbsp;{{chef.city}}</p>
                      <br>
                    </div>
                  </div>
                  <p>Der Wiederkauffall tritt ein, wenn {{contractData.wegLogic.lsl3sonstigeRechte.wiederkaufsrecht.text}}
                  </p>
                  <div *ngIf="contractData.wegLogic.lsl3sonstigeRechte.wiederkaufsrecht.preisinEUR">
                    <p>Kaufpreis bei Wiederkauf: {{contractData.wegLogic.lsl3sonstigeRechte.wiederkaufsrecht.preisinEUR}}
                    </p>
                  </div>
                  <div *ngIf="contractData.wegLogic.lsl3sonstigeRechte.wiederkaufsrecht.schaetzpreis">
                    <p>Kaufpreis bei Wiederkauf: wird durch ein Sachverst&auml;ndigengutachten ermittelt</p>
                  </div>
                  <br>
                </div>
                <!-- Vorkaufsrecht -->
                <div *ngIf="contractData.wegLogic.lsl3sonstigeRechte.frageVorkaufsrecht == 'Ja'">
                  <b>Vorkaufsrecht f&uuml;r:</b>
                  <div *ngIf="contractData.wegLogic.lsl3sonstigeRechte.vorkaufsrecht.personArray.length > 0">
                    <div *ngFor="let chef of contractData.wegLogic.lsl3sonstigeRechte.vorkaufsrecht.personArray">
                      <p>{{chef.firstname}}&nbsp;{{chef.lastname}}&nbsp;({{chef.birthday}})</p>
                      <p>{{chef.address}},&nbsp;{{chef.postalCode}}&nbsp;{{chef.city}}</p>
                      <br>
                    </div>
                  </div>
                  <div *ngIf="contractData.wegLogic.lsl3sonstigeRechte.vorkaufsrecht.companyArray.length > 0">
                    <div *ngFor="let chef of contractData.wegLogic.lsl3sonstigeRechte.vorkaufsrecht.companyArray">
                      <p>{{chef.companyName}}&nbsp;({{chef.companiesRegisterNumber}})</p>
                      <p>{{chef.address}},&nbsp;{{chef.postalCode}}&nbsp;{{chef.city}}</p>
                      <br>
                    </div>
                  </div>
                  <div *ngIf="contractData.wegLogic.lsl3sonstigeRechte.vorkaufsrecht.option == '1072abgb'">
                    <p>Vorkaufsrecht gem. § 1072 ABGB</p>
                  </div>
                  <div *ngIf="contractData.wegLogic.lsl3sonstigeRechte.vorkaufsrecht.option == 'alle'">
                    <p>Vorkaufsrecht für alle Ver&auml;ußerungsarten</p>
                    <label>Kaufpreis im Vorkaufsfall:</label>
                    <div *ngIf="contractData.wegLogic.lsl3sonstigeRechte.vorkaufsrecht.fixpreis">
                      <label>{{contractData.wegLogic.lsl3sonstigeRechte.vorkaufsrecht.preisinEUR}}</label>
                    </div>
                    <div *ngIf="contractData.wegLogic.lsl3sonstigeRechte.vorkaufsrecht.schaetzpreis">
                      <label>wird durch ein Sachverst&auml;ndigengutachten ermittelt</label>
                    </div>
                  </div>
                  <br>
                </div>
                <!-- belastungsundveraeusserungsverbot -->
                <div *ngIf="contractData.wegLogic.lsl3sonstigeRechte.frageBelastungsverbot == 'Ja'">
                  <div *ngIf="contractData.wegLogic.sub.belastungsundveraeusserungsverbot">
                    <b>Belastungs- und Ver&auml;ußerungsverbot</b>
                  </div>
                  <div *ngIf="contractData.wegLogic.sub.belastungsverbot">
                    <b>Belastungsverbot</b>
                  </div>
                  <div *ngIf="contractData.wegLogic.sub.veraeusserungsverbot">
                    <b>Ver&auml;ußerungsverbot</b>
                  </div>
                  <b>f&uuml;r:&nbsp;</b>
                  <br>
                  <div class="qqqas"
                    *ngIf="contractData.wegLogic.lsl3sonstigeRechte.belastungsUndVeraeusserungsverbot.personArray.length > 0">
                    <div
                      *ngFor="let chef of contractData.wegLogic.lsl3sonstigeRechte.belastungsUndVeraeusserungsverbot.personArray">
                      <p>{{chef.firstname}}&nbsp;{{chef.lastname}}&nbsp;({{chef.birthday}})</p>
                      <p>{{chef.address}},&nbsp;{{chef.postalCode}}&nbsp;{{chef.city}}</p>
                      <br>
                    </div>
                  </div>
                  <div class="qs"
                    *ngIf="contractData.wegLogic.lsl3sonstigeRechte.belastungsUndVeraeusserungsverbot.companyArray.length > 0">
                    <div
                      *ngFor="let chef of contractData.wegLogic.lsl3sonstigeRechte.belastungsUndVeraeusserungsverbot.companyArray">
                      <p>{{chef.companyName}}&nbsp;({{chef.companiesRegisterNumber}})</p>
                      <p>{{chef.address}},&nbsp;{{chef.postalCode}}&nbsp;{{chef.city}}</p>
                    </div>
                  </div>
                  <br>
                  <label>Rechtseinr&auml;umung unter nahen Angehörigen:</label>
                  <div
                    *ngIf="contractData.wegLogic.lsl3sonstigeRechte.belastungsUndVeraeusserungsverbot.naheAngehoerige == 'Nein'">
                    <label>nein</label>
                  </div>
                  <div
                    *ngIf="contractData.wegLogic.lsl3sonstigeRechte.belastungsUndVeraeusserungsverbot.naheAngehoerige == 'Ja'">
                    <label>ja</label>
                  </div>
                  <br>
                </div>
              </div>
              <ng-template #other_NOL3SonstigeRechte><label>Keine Sonstigen Rechte</label></ng-template>
              <br>
            </div>
          </div>
          <div *ngIf="LS4">
            <p>Liegenschaft 4:</p>
            <div *ngIf="contractData.wegLogic.l4immodetail.l4uebertragungsumfang == 'gesamte Liegenschaft'">
              EZ {{contractData.wegLogic.l4immodetail.l4einlageZahl}}, Grundbuch
              {{contractData.wegLogic.l4immodetail.l4katastralGemeinde.KGNr}}
              {{contractData.wegLogic.l4immodetail.l4katastralGemeinde.KGName}}, mit
            </div>
            <div *ngFor="let gstnr of contractData.wegLogic.l4immodetail.l4grundstuecksnummer; let i = index;">
              <label>GST-NR {{gstnr}}</label>
              <label *ngIf="GstNr4UND[i]">&nbsp;und</label>
              <label *ngIf="GstNr4BEISTRICH[i]">, </label>
            </div>
            <div *ngIf="contractData.wegLogic.l4immodetail.l4uebertragungsumfang == 'einzelne Grundstücke'">
              <label>
                , derzeit vorgetragen in EZ {{contractData.wegLogic.l4immodetail.l4einlageZahl}}, Grundbuch
                {{contractData.wegLogic.l4immodetail.l4katastralGemeinde.KGNr}}
                {{contractData.wegLogic.l4immodetail.l4katastralGemeinde.KGName}}
              </label>
            </div>

            <!-- Käufer/Verkäufer/Anteile LS>=2 -->
            <b *ngIf="contractData.primeType == 'miet'">Mietgegenst&auml;ndliche Anteile</b>
            <b *ngIf="contractData.primeType != 'miet'">Verkaufte Anteile</b>
            <div *ngFor="let anteil of contractData.wegLogic.l4immodetail.alleAnteile; let i = index;">
              <p>Anteil: {{anteil}}, B-LNR: {{contractData.wegLogic.l4immodetail.alleBlnr[i]}}</p>
            </div>
            <br>
            <div>
              <p>Verk&auml;uferseite:</p>
              <div *ngIf="SellPriv4">
                <div *ngFor="let chef of contractData.anteildata.l4.seller.sellersPrivate">
                  {{chef.firstname}} {{chef.lastname}} ({{chef.birthday}})<br>
                  {{chef.address}}, {{chef.postalCode}} {{chef.city}}
                </div>
              </div>
              <div *ngIf="SellComp4">
                <div *ngFor="let chef of contractData.anteildata.l4.seller.sellersCompany">
                  {{chef.companyName}} ({{chef.companiesRegisterNumber}})<br>
                  {{chef.address}}, {{chef.postalCode}} {{chef.city}}
                </div>
              </div>
              <br>
              <p>K&auml;uferseite:</p>
              <div *ngIf="BuyPriv4">
                <div *ngFor="let chef of contractData.anteildata.l4.buyer.buyersPrivate">
                  {{chef.firstname}} {{chef.lastname}} ({{chef.birthday}})<br>
                  {{chef.address}}, {{chef.postalCode}} {{chef.city}}
                  <div *ngIf="KBOOL">
                    <p>Gekaufter Anteil: {{chef.calcanteil}}</p>
                  </div>
                </div>
              </div>
              <div *ngIf="BuyComp4">
                <div *ngFor="let chef of contractData.anteildata.l4.buyer.buyersCompany">
                  {{chef.companyName}} ({{chef.companiesRegisterNumber}})<br>
                  {{chef.address}}, {{chef.postalCode}} {{chef.city}}
                  <div *ngIf="KBOOL">
                    <p>Gekaufter Anteil: {{chef.calcanteil}}</p>
                  </div>
                  <br>
                </div>
              </div>
            </div>
            <br>
            <div *ngIf="this.contractData.primeType != 'miet'">
              <label>Sonstige Rechte:&nbsp;</label>
              <div
                *ngIf="this.contractData.wegLogic.lsl4sonstigeRechte.frageSonstigeRechte == 'Ja'; else other_NOL4SonstigeRechte">
                <!-- Wohnrecht -->
                <div *ngIf="contractData.wegLogic.lsl4sonstigeRechte.frageWohnrecht == 'Ja'">
                  <b>Wohnrecht f&uuml;r:</b>
                  <div *ngIf="contractData.wegLogic.lsl4sonstigeRechte.wohnrecht.personArray.length > 0">
                    <div *ngFor="let chef of contractData.wegLogic.lsl4sonstigeRechte.wohnrecht.personArray">
                      <p>{{chef.firstname}}&nbsp;{{chef.lastname}}&nbsp;({{chef.birthday}})</p>
                      <p>{{chef.address}},&nbsp;{{chef.postalCode}}&nbsp;{{chef.city}}</p>
                      <br>
                    </div>
                  </div>
                  <div *ngIf="contractData.wegLogic.lsl4sonstigeRechte.wohnrecht.companyArray.length > 0">
                    <div *ngFor="let chef of contractData.wegLogic.lsl4sonstigeRechte.wohnrecht.companyArray">
                      <p>{{chef.companyName}}&nbsp;({{chef.companiesRegisterNumber}})</p>
                      <p>{{chef.address}},&nbsp;{{chef.postalCode}}&nbsp;{{chef.city}}</p>
                      <br>
                    </div>
                  </div>
                  <br>
                  <div *ngIf="contractData.wegLogic.lsl4sonstigeRechte.wohnrecht.option == 'teile'">
                    <label>Vom Wohnungsgebrauchsrecht umfasst sind
                      {{contractData.wegLogic.lsl4sonstigeRechte.wohnrecht.freitext}}</label>
                  </div>
                  <div *ngIf="contractData.wegLogic.lsl4sonstigeRechte.wohnrecht.option == 'gesamte'">
                    <label>Vom Wohnungsgebrauchsrecht erfasst</label>
                    <div *ngIf="contractData.wegLogic.EPPartner == 'Nein'">
                      sind die gesamten Anteile.
                    </div>
                    <div *ngIf="contractData.wegLogic.EP == 'gesamte Wohnung oder Geschäftsräumlichkeit' || wegLogic.EPPartner == 'Ja' ||
              wegLogic.EP == 'eigentuemeruebertraghalbeanteile'">
                      <label>ist der gesamte Kaufgegenstand.</label>
                    </div>
                  </div>
                </div>
                <!-- Fruchtgenussrecht -->
                <div *ngIf="contractData.wegLogic.lsl4sonstigeRechte.frageFruchtgenussrecht == 'Ja'">
                  <b>Fruchtgenussrecht f&uuml;r:</b>
                  <div *ngIf="contractData.wegLogic.lsl4sonstigeRechte.fruchtgenussrecht.personArray.length > 0">
                    <div *ngFor="let chef of contractData.wegLogic.lsl4sonstigeRechte.fruchtgenussrecht.personArray">
                      <p>{{chef.firstname}}&nbsp;{{chef.lastname}}&nbsp;({{chef.birthday}})</p>
                      <p>{{chef.address}},&nbsp;{{chef.postalCode}}&nbsp;{{chef.city}}</p>
                      <br>
                    </div>
                  </div>
                  <div *ngIf="contractData.wegLogic.lsl4sonstigeRechte.fruchtgenussrecht.companyArray.length > 0">
                    <div *ngFor="let chef of contractData.wegLogic.lsl4sonstigeRechte.fruchtgenussrecht.companyArray">
                      <p>{{chef.companyName}}&nbsp;({{chef.companiesRegisterNumber}})</p>
                      <p>{{chef.address}},&nbsp;{{chef.postalCode}}&nbsp;{{chef.city}}</p>
                      <br>
                    </div>
                  </div>
                  <br>
                  <div *ngIf="contractData.wegLogic.lsl4sonstigeRechte.fruchtgenussrecht.option == 'teile'">
                    <label>Vom Wohnungsgebrauchsrecht umfasst sind
                      {{contractData.wegLogic.lsl4sonstigeRechte.fruchtgenussrecht.freitext}}</label>
                  </div>
                  <div *ngIf="contractData.wegLogic.lsl4sonstigeRechte.fruchtgenussrecht.option == 'gesamte'">
                    <label>Vom Fruchtgenussrecht erfasst</label>
                    <div *ngIf="contractData.wegLogic.EPPartner == 'Nein'">
                      sind die gesamten Anteile.
                    </div>
                    <div *ngIf="contractData.wegLogic.EP == 'gesamte Wohnung oder Geschäftsräumlichkeit' || wegLogic.EPPartner == 'Ja' ||
              wegLogic.EP == 'eigentuemeruebertraghalbeanteile'">
                      <label>ist der gesamte Kaufgegenstand.</label>
                    </div>
                  </div>
                  <br>
                </div>
                <!-- Wiederkaufsrecht -->
                <div *ngIf="contractData.wegLogic.lsl4sonstigeRechte.frageWiederkaufsrecht == 'Ja'">
                  <p>Wiederkaufsrecht f&uuml;r:</p>
                  <div *ngIf="contractData.wegLogic.lsl4sonstigeRechte.wiederkaufsrecht.personArray.length > 0">
                    <div *ngFor="let chef of contractData.wegLogic.lsl4sonstigeRechte.wiederkaufsrecht.personArray">
                      <p>{{chef.firstname}}&nbsp;{{chef.lastname}}&nbsp;({{chef.birthday}})</p>
                      <p>{{chef.address}},&nbsp;{{chef.postalCode}}&nbsp;{{chef.city}}</p>
                    </div>
                  </div>
                  <div *ngIf="contractData.wegLogic.lsl4sonstigeRechte.wiederkaufsrecht.companyArray.length > 0">
                    <div *ngFor="let chef of contractData.wegLogic.lsl4sonstigeRechte.wiederkaufsrecht.companyArray">
                      <p>{{chef.companyName}}&nbsp;({{chef.companiesRegisterNumber}})</p>
                      <p>{{chef.address}},&nbsp;{{chef.postalCode}}&nbsp;{{chef.city}}</p>
                    </div>
                  </div>
                  <p>Der Wiederkauffall tritt ein, wenn {{contractData.wegLogic.lsl4sonstigeRechte.wiederkaufsrecht.text}}
                  </p>
                  <div *ngIf="contractData.wegLogic.lsl4sonstigeRechte.wiederkaufsrecht.preisinEUR">
                    <p>Kaufpreis bei Wiederkauf: {{contractData.wegLogic.lsl4sonstigeRechte.wiederkaufsrecht.preisinEUR}}
                    </p>
                  </div>
                  <div *ngIf="contractData.wegLogic.lsl4sonstigeRechte.wiederkaufsrecht.schaetzpreis">
                    <p>Kaufpreis bei Wiederkauf: wird durch ein Sachverst&auml;ndigengutachten ermittelt</p>
                  </div>
                </div>
                <!-- Vorkaufsrecht -->
                <div *ngIf="contractData.wegLogic.lsl4sonstigeRechte.frageVorkaufsrecht == 'Ja'">
                  <b>Vorkaufsrecht f&uuml;r:</b>
                  <div *ngIf="contractData.wegLogic.lsl4sonstigeRechte.vorkaufsrecht.personArray.length > 0">
                    <div *ngFor="let chef of contractData.wegLogic.lsl4sonstigeRechte.vorkaufsrecht.personArray">
                      <p>{{chef.firstname}}&nbsp;{{chef.lastname}}&nbsp;({{chef.birthday}})</p>
                      <p>{{chef.address}},&nbsp;{{chef.postalCode}}&nbsp;{{chef.city}}</p>
                      <br>
                    </div>
                  </div>
                  <div *ngIf="contractData.wegLogic.lsl4sonstigeRechte.vorkaufsrecht.companyArray.length > 0">
                    <div *ngFor="let chef of contractData.wegLogic.lsl4sonstigeRechte.vorkaufsrecht.companyArray">
                      <p>{{chef.companyName}}&nbsp;({{chef.companiesRegisterNumber}})</p>
                      <p>{{chef.address}},&nbsp;{{chef.postalCode}}&nbsp;{{chef.city}}</p>
                      <br>
                    </div>
                  </div>
                  <div *ngIf="contractData.wegLogic.lsl4sonstigeRechte.vorkaufsrecht.option == '1072abgb'">
                    <p>Vorkaufsrecht gem. § 1072 ABGB</p>
                  </div>
                  <div *ngIf="contractData.wegLogic.lsl4sonstigeRechte.vorkaufsrecht.option == 'alle'">
                    <p>Vorkaufsrecht für alle Ver&auml;ußerungsarten</p>
                    <label>Kaufpreis im Vorkaufsfall:</label>
                    <div *ngIf="contractData.wegLogic.lsl4sonstigeRechte.vorkaufsrecht.fixpreis">
                      <label>{{contractData.wegLogic.lsl4sonstigeRechte.vorkaufsrecht.preisinEUR}}</label>
                    </div>
                    <div *ngIf="contractData.wegLogic.lsl4sonstigeRechte.vorkaufsrecht.schaetzpreis">
                      <label>wird durch ein Sachverst&auml;ndigengutachten ermittelt</label>
                    </div>
                  </div>
                  <br>
                </div>
                <!-- belastungsundveraeusserungsverbot -->
                <div *ngIf="contractData.wegLogic.lsl4sonstigeRechte.frageBelastungsverbot == 'Ja'">
                  <div *ngIf="contractData.wegLogic.sub.belastungsundveraeusserungsverbot">
                    <b>Belastungs- und Ver&auml;ußerungsverbot</b>
                  </div>
                  <div *ngIf="contractData.wegLogic.sub.belastungsverbot">
                    <b>Belastungsverbot</b>
                  </div>
                  <div *ngIf="contractData.wegLogic.sub.veraeusserungsverbot">
                    <b>Ver&auml;ußerungsverbot</b>
                  </div>
                  <b>f&uuml;r:&nbsp;</b>
                  <br>
                  <div class="qiis"
                    *ngIf="contractData.wegLogic.lsl4sonstigeRechte.belastungsUndVeraeusserungsverbot.personArray.length > 0">
                    <div
                      *ngFor="let chef of contractData.wegLogic.lsl4sonstigeRechte.belastungsUndVeraeusserungsverbot.personArray">
                      <p>{{chef.firstname}}&nbsp;{{chef.lastname}}&nbsp;({{chef.birthday}})</p>
                      <p>{{chef.address}},&nbsp;{{chef.postalCode}}&nbsp;{{chef.city}}</p>
                      <br>
                    </div>
                  </div>
                  <div class="qsp"
                    *ngIf="contractData.wegLogic.lsl4sonstigeRechte.belastungsUndVeraeusserungsverbot.companyArray.length > 0">
                    <div
                      *ngFor="let chef of contractData.wegLogic.lsl4sonstigeRechte.belastungsUndVeraeusserungsverbot.companyArray">
                      <p>{{chef.companyName}}&nbsp;({{chef.companiesRegisterNumber}})</p>
                      <p>{{chef.address}},&nbsp;{{chef.postalCode}}&nbsp;{{chef.city}}</p>
                    </div>
                  </div>
                  <br>
                  <label>Rechtseinr&auml;umung unter nahen Angehörigen:</label>
                  <div
                    *ngIf="contractData.wegLogic.lsl4sonstigeRechte.belastungsUndVeraeusserungsverbot.naheAngehoerige == 'Nein'">
                    <label>nein</label>
                  </div>
                  <div
                    *ngIf="contractData.wegLogic.lsl4sonstigeRechte.belastungsUndVeraeusserungsverbot.naheAngehoerige == 'Ja'">
                    <label>ja</label>
                  </div>
                  <br>
                </div>
              </div>
              <ng-template #other_NOL4SonstigeRechte><label>Keine Sonstigen Rechte</label></ng-template>
              <br>
            </div>
          </div>
        </div>
        <!-- WEG -->
        <div *ngIf="this.contractData.contactData.contractOption === 'WEG'">
          <b>&Uuml;bertragung:</b>
          <div *ngIf="contractData.wegLogic.EP == 'gesamte Wohnung oder Geschäftsräumlichkeit'">
            <label>gesamte Einheit</label>
          </div>
          <div *ngIf="contractData.wegLogic.EP == 'eigentuemeruebertraghalbeanteile'">
            <label>Eigentümer der ganzen Anteile übertragt seine halben Anteile</label>
          </div>
          <div *ngIf="contractData.wegLogic.EPPartner == 'Ja'">
            <label>Anteil eines Eigentümerpartners, welcher bereits Eigentümer des anderen Anteils ist</label>
          </div>
          <div *ngIf="contractData.wegLogic.EPPartner == 'Nein'">
            <label>Anteil eines Eigentümerpartners, welcher nicht Eigentümer des anderen Anteils ist</label>
          </div>
          <div>
            <label>
              Grundbuch: {{contractData.wegLogic.immodetail.katastralGemeinde.KGNr}}
              {{contractData.wegLogic.immodetail.katastralGemeinde.KGName}}
              <br>
              Einlagezahl: {{contractData.wegLogic.immodetail.einlageZahl}}
            </label>
          </div>
          <div *ngIf="contractData.wegLogic.immodetail.grundstuecksnummer && contractData.wegLogic.immodetail.grundstuecksnummer.length == 1; else other_moreGSTNR">
            <label>Grundst&uuml;ck: {{contractData.wegLogic.immodetail.grundstuecksnummer[0]}}</label>
          </div>
          <ng-template #other_moreGSTNR>
            <label>Grundst&uuml;ck: </label>
            <div *ngFor="let gstnr of contractData.wegLogic.immodetail.grundstuecksnummer; let i = index;">
              <label>{{gstnr}}</label>
              <label *ngIf="GstNrUND[i]">&nbsp;und</label>
              <label *ngIf="GstNrBEISTRICH[i]">, </label>
            </div>
          </ng-template>
          <br>
          <p><b>Anteile:</b></p>
          <div *ngIf="!((contractData.masterData.sellersPrivate.length == 2  || contractData.masterData.sellersCompany.length == 2)
          || (contractData.masterData.sellersPrivate.length && contractData.masterData.sellersCompany.length > 0))">
            <label>{{contractData.wegLogic.immodetail.anteil}}-Anteile
              (B-LNR {{contractData.wegLogic.immodetail.bLaufendeNr}}), verbunden mit
              {{contractData.wegLogic.immodetail.top}}</label>
            <div *ngFor="let parki of contractData.wegLogic.parkingPlaces">
              <label>{{parki.pAnteil}}-Anteile (B-LNR {{parki.pBLaufendeNr}}), verbunden mit {{parki.pTOP}}</label>
            </div>
          </div>
          <div *ngIf="((contractData.masterData.sellersPrivate.length == 2
          || contractData.masterData.sellersCompany.length == 2)
          || (contractData.masterData.sellersPrivate.length
          && contractData.masterData.sellersCompany.length > 0))">
            <label>je {{contractData.wegLogic.immodetail.anteil}}-Anteile (B-LNR
              {{contractData.wegLogic.immodetail.bLaufendeNr}}
              und B-LNR {{contractData.wegLogic.immodetail.bLaufendeNr2}}), verbunden mit
              {{contractData.wegLogic.immodetail.top}}</label>
            <div *ngFor="let parki of contractData.wegLogic.parkingPlaces">
              <label>{{parki.pAnteil}}-Anteile (B-LNR {{parki.pBLaufendeNr}} und B-LNR {{parki.pBLaufendeNr2}}), verbunden
                mit {{parki.pTOP}}</label>
            </div>
          </div>
          <br>
          <div *ngIf="this.contractData.primeType !='miet'">
            <label>Sonstige Rechte:&nbsp;</label>
            <div
              *ngIf="this.contractData.wegLogic.sonstigeRechteWEG.frageSonstigeRechte == 'Ja'; else other_NOWEGSonstigeRechte">
              <!-- Wohnrecht -->
              <div *ngIf="contractData.wegLogic.sonstigeRechteWEG.frageWohnrecht == 'Ja'">
                <b>Wohnrecht f&uuml;r:</b>
                <div *ngIf="contractData.wegLogic.sonstigeRechteWEG.wohnrecht.personArray.length > 0">
                  <div *ngFor="let chef of contractData.wegLogic.sonstigeRechteWEG.wohnrecht.personArray">
                    <p>{{chef.firstname}}&nbsp;{{chef.lastname}}&nbsp;({{chef.birthday}})</p>
                    <p>{{chef.address}},&nbsp;{{chef.postalCode}}&nbsp;{{chef.city}}</p>
                    <br>
                  </div>
                </div>
                <div *ngIf="contractData.wegLogic.sonstigeRechteWEG.wohnrecht.companyArray.length > 0">
                  <div *ngFor="let chef of contractData.wegLogic.sonstigeRechteWEG.wohnrecht.companyArray">
                    <p>{{chef.companyName}}&nbsp;({{chef.companiesRegisterNumber}})</p>
                    <p>{{chef.address}},&nbsp;{{chef.postalCode}}&nbsp;{{chef.city}}</p>
                    <br>
                  </div>
                </div>
                <div *ngIf="contractData.wegLogic.sonstigeRechteWEG.wohnrecht.option == 'teile'">
                  <label>Vom Wohnungsgebrauchsrecht umfasst sind
                    {{contractData.wegLogic.sonstigeRechteWEG.wohnrecht.freitext}}</label>
                </div>
                <div *ngIf="contractData.wegLogic.sonstigeRechteWEG.wohnrecht.option == 'gesamte'">
                  <label>Vom Wohnungsgebrauchsrecht erfasst</label>
                  <div *ngIf="contractData.wegLogic.EPPartner == 'Nein'">
                    sind die gesamten Anteile.
                  </div>
                  <div *ngIf="contractData.wegLogic.EP == 'gesamte Wohnung oder Geschäftsräumlichkeit' || wegLogic.EPPartner == 'Ja' ||
              wegLogic.EP == 'eigentuemeruebertraghalbeanteile'">
                    <label>ist der gesamte Kaufgegenstand.</label>
                  </div>
                </div>
              </div>
              <!-- Fruchtgenussrecht -->
              <div *ngIf="contractData.wegLogic.sonstigeRechteWEG.frageFruchtgenussrecht == 'Ja'">
                <b>Fruchtgenussrecht f&uuml;r:</b>
                <div *ngIf="contractData.wegLogic.sonstigeRechteWEG.fruchtgenussrecht.personArray.length > 0">
                  <div *ngFor="let chef of contractData.wegLogic.sonstigeRechteWEG.fruchtgenussrecht.personArray">
                    <p>{{chef.firstname}}&nbsp;{{chef.lastname}}&nbsp;({{chef.birthday}})</p>
                    <p>{{chef.address}},&nbsp;{{chef.postalCode}}&nbsp;{{chef.city}}</p>
                    <br>
                  </div>
                </div>
                <div *ngIf="contractData.wegLogic.sonstigeRechteWEG.fruchtgenussrecht.companyArray.length > 0">
                  <div *ngFor="let chef of contractData.wegLogic.sonstigeRechteWEG.fruchtgenussrecht.companyArray">
                    <p>{{chef.companyName}}&nbsp;({{chef.companiesRegisterNumber}})</p>
                    <p>{{chef.address}},&nbsp;{{chef.postalCode}}&nbsp;{{chef.city}}</p>
                    <br>
                  </div>
                </div>
                <br>
                <div *ngIf="contractData.wegLogic.sonstigeRechteWEG.fruchtgenussrecht.option == 'teile'">
                  <label>Vom Wohnungsgebrauchsrecht umfasst sind
                    {{contractData.wegLogic.sonstigeRechteWEG.fruchtgenussrecht.freitext}}</label>
                </div>
                <div *ngIf="contractData.wegLogic.sonstigeRechteWEG.fruchtgenussrecht.option == 'gesamte'">
                  <label>Vom Fruchtgenussrecht erfasst</label>
                  <div *ngIf="contractData.wegLogic.EPPartner == 'Nein'">
                    sind die gesamten Anteile.
                  </div>
                  <div *ngIf="contractData.wegLogic.EP == 'gesamte Wohnung oder Geschäftsräumlichkeit' || wegLogic.EPPartner == 'Ja' ||
              wegLogic.EP == 'eigentuemeruebertraghalbeanteile'">
                    <label>ist der gesamte Kaufgegenstand.</label>
                  </div>
                </div>
                <br>
              </div>
              <!-- Wiederkaufsrecht -->
              <div *ngIf="contractData.wegLogic.sonstigeRechteWEG.frageWiederkaufsrecht == 'Ja'">
                <p>Wiederkaufsrecht f&uuml;r:</p>
                <div *ngIf="contractData.wegLogic.sonstigeRechteWEG.wiederkaufsrecht.personArray.length > 0">
                  <div *ngFor="let chef of contractData.wegLogic.sonstigeRechteWEG.wiederkaufsrecht.personArray">
                    <p>{{chef.firstname}}&nbsp;{{chef.lastname}}&nbsp;({{chef.birthday}})</p>
                    <p>{{chef.address}},&nbsp;{{chef.postalCode}}&nbsp;{{chef.city}}</p>
                    <br>
                  </div>
                </div>
                <div *ngIf="contractData.wegLogic.sonstigeRechteWEG.wiederkaufsrecht.companyArray.length > 0">
                  <div *ngFor="let chef of contractData.wegLogic.sonstigeRechteWEG.wiederkaufsrecht.companyArray">
                    <p>{{chef.companyName}}&nbsp;({{chef.companiesRegisterNumber}})</p>
                    <p>{{chef.address}},&nbsp;{{chef.postalCode}}&nbsp;{{chef.city}}</p>
                    <br>
                  </div>
                </div>
                <p>Der Wiederkauffall tritt ein, wenn {{contractData.wegLogic.sonstigeRechteWEG.wiederkaufsrecht.text}}
                </p>
                <div *ngIf="contractData.wegLogic.sonstigeRechteWEG.wiederkaufsrecht.preisinEUR">
                  <p>Kaufpreis bei Wiederkauf: {{contractData.wegLogic.sonstigeRechteWEG.wiederkaufsrecht.preisinEUR}}</p>
                </div>
                <div *ngIf="contractData.wegLogic.sonstigeRechteWEG.wiederkaufsrecht.schaetzpreis">
                  <p>Kaufpreis bei Wiederkauf: wird durch ein Sachverst&auml;ndigengutachten ermittelt</p>
                </div>
              </div>
              <!-- Vorkaufsrecht -->
              <div *ngIf="contractData.wegLogic.sonstigeRechteWEG.frageVorkaufsrecht == 'Ja'">
                <b>Vorkaufsrecht f&uuml;r:</b>
                <div *ngIf="contractData.wegLogic.sonstigeRechteWEG.vorkaufsrecht.personArray.length > 0">
                  <div *ngFor="let chef of contractData.wegLogic.sonstigeRechteWEG.vorkaufsrecht.personArray">
                    <p>{{chef.firstname}}&nbsp;{{chef.lastname}}&nbsp;({{chef.birthday}})</p>
                    <p>{{chef.address}},&nbsp;{{chef.postalCode}}&nbsp;{{chef.city}}</p>
                    <br>
                  </div>
                </div>
                <div *ngIf="contractData.wegLogic.sonstigeRechteWEG.vorkaufsrecht.companyArray.length > 0">
                  <div *ngFor="let chef of contractData.wegLogic.sonstigeRechteWEG.vorkaufsrecht.companyArray">
                    <p>{{chef.companyName}}&nbsp;({{chef.companiesRegisterNumber}})</p>
                    <p>{{chef.address}},&nbsp;{{chef.postalCode}}&nbsp;{{chef.city}}</p>
                    <br>
                  </div>
                </div>
                <div *ngIf="contractData.wegLogic.sonstigeRechteWEG.vorkaufsrecht.option == '1072abgb'">
                  <p>Vorkaufsrecht gem. § 1072 ABGB</p>
                </div>
                <div *ngIf="contractData.wegLogic.sonstigeRechteWEG.vorkaufsrecht.option == 'alle'">
                  <p>Vorkaufsrecht für alle Ver&auml;ußerungsarten</p>
                  <label>Kaufpreis im Vorkaufsfall:</label>
                  <div *ngIf="contractData.wegLogic.sonstigeRechteWEG.vorkaufsrecht.fixpreis">
                    <label>{{contractData.wegLogic.sonstigeRechteWEG.vorkaufsrecht.preisinEUR}}</label>
                  </div>
                  <div *ngIf="contractData.wegLogic.sonstigeRechteWEG.vorkaufsrecht.schaetzpreis">
                    <label>wird durch ein Sachverst&auml;ndigengutachten ermittelt</label>
                  </div>
                </div>
              </div>
              <!-- belastungsundveraeusserungsverbot -->
              <div *ngIf="contractData.wegLogic.sonstigeRechteWEG.frageBelastungsverbot == 'Ja'">
                <div *ngIf="contractData.wegLogic.sub.belastungsundveraeusserungsverbot">
                  <b>Belastungs- und Ver&auml;ußerungsverbot</b>
                </div>
                <div *ngIf="contractData.wegLogic.sub.belastungsverbot">
                  <b>Belastungsverbot</b>
                </div>
                <div *ngIf="contractData.wegLogic.sub.veraeusserungsverbot">
                  <b>Ver&auml;ußerungsverbot</b>
                </div>
                <b>f&uuml;r:&nbsp;</b>
                <br>
                <div class="qxcs"
                  *ngIf="contractData.wegLogic.sonstigeRechteWEG.belastungsUndVeraeusserungsverbot.personArray.length > 0">
                  <div
                    *ngFor="let chef of contractData.wegLogic.sonstigeRechteWEG.belastungsUndVeraeusserungsverbot.personArray">
                    <p>{{chef.firstname}}&nbsp;{{chef.lastname}}&nbsp;({{chef.birthday}})</p>
                    <p>{{chef.address}},&nbsp;{{chef.postalCode}}&nbsp;{{chef.city}}</p>
                  </div>
                </div>
                <div class="qzzzs"
                  *ngIf="contractData.wegLogic.sonstigeRechteWEG.belastungsUndVeraeusserungsverbot.companyArray.length > 0">
                  <div
                    *ngFor="let chef of contractData.wegLogic.sonstigeRechteWEG.belastungsUndVeraeusserungsverbot.companyArray">
                    <p>{{chef.companyName}}&nbsp;({{chef.companiesRegisterNumber}})</p>
                    <p>{{chef.address}},&nbsp;{{chef.postalCode}}&nbsp;{{chef.city}}</p>
                  </div>
                </div>
                <label>Rechtseinr&auml;umung unter nahen Angehörigen:</label>
                <div
                  *ngIf="contractData.wegLogic.sonstigeRechteWEG.belastungsUndVeraeusserungsverbot.naheAngehoerige == 'Nein'">
                  <label>nein</label>
                </div>
                <div
                  *ngIf="contractData.wegLogic.sonstigeRechteWEG.belastungsUndVeraeusserungsverbot.naheAngehoerige == 'Ja'">
                  <label>ja</label>
                </div>
                <br>
              </div>
            </div>
            <ng-template #other_NOWEGSonstigeRechte><label>keine Sonstigen Rechte</label></ng-template>
          </div>
        </div>
      </div>

      <!-- Vermittlungsprovision -->
      <div *ngIf="contractData.primeType !='schenkung' && contractData.primeType !='miet'">
        <h4 class="custom-dlg-h">Vermittlungsprovision</h4>
        <b>Abwicklung Vermittlungsprovision:&nbsp;</b>
        <label *ngIf="contractData.wegLogic.fragevermittlungsprovision == 'Nein'">nein</label>
        <div *ngIf="contractData.wegLogic.fragevermittlungsprovision == 'Ja'">
          <label *ngIf="contractData.wegLogic.vermittlungsprovision.wer == 'beides'">
            <b>Verk&auml;uferseitig:</b> {{contractData.wegLogic.vermittlungsprovision.verkaeuferinEUR}} und&nbsp;
            <b>K&auml;uferseitig:</b> {{contractData.wegLogic.vermittlungsprovision.kaeuferinEUR}}
          </label>
          <label *ngIf="contractData.wegLogic.vermittlungsprovision.wer == 'verkaeufer'">
            <b>Verk&auml;uferseitig:</b> {{contractData.wegLogic.vermittlungsprovision.verkaeuferinEUR}}
          </label>
          <label *ngIf="contractData.wegLogic.vermittlungsprovision.wer == 'kaeufer'">
            <b>K&auml;uferseitig:</b> {{contractData.wegLogic.vermittlungsprovision.kaeuferinEUR}}
          </label>
          <label>zu bezahlen an: {{contractData.wegLogic.vermittlungsprovision.makler}}</label>
        </div>
      </div>
    </div>

    <div *ngIf="this.contractData.primeType=='kfz'">
      <h4 class="custom-dlg-h">ALLGEMEINES</h4>
      <b>Kaufpreis:&nbsp;</b>
      <label>{{contractData.wegLogic.kaufpreis}}</label>
      <br>
      <div *ngIf="contractData.wegLogic.ratenJaNein == 'Ja'">
        <b>Anzahl der Raten:&nbsp;</b>
        <label>{{contractData.wegLogic.ratenAnzahl}}</label>
        <br>
        <b>H&ouml;he einer Rate:&nbsp;</b>
        <label>{{contractData.wegLogic.ratenHoehe}}</label>
        <br>
        <b>Datum der ersten Rate:&nbsp; </b>
        <label>{{contractData.wegLogic.ersteRateDatum}}</label>
        <br>
      </div>

      <div *ngIf="contractData.wegLogic.ratenJaNein == 'Nein'">
        <b>keine Ratenzahlung</b>
        <br>
      </div>

      <b>Datum der F&auml;lligkeit des vollen Kaufpreises:&nbsp;</b>
      <label>{{contractData.wegLogic.datumRestorUebergabe}}</label>
      <br>
      <br>

      <h4 class="custom-dlg-h">ZUM KRAFTFAHRZEUG</h4>

      <b>Marke des KFZ:&nbsp;</b>
      <label>{{contractData.wegLogic.marke}}</label>
      <br>

      <b>Type des KFZ:&nbsp;</b>
      <label>{{contractData.wegLogic.type}}</label>
      <br>

      <b>km-Stand:&nbsp;</b>
      <label>{{contractData.wegLogic.kmstand}}&nbsp;km</label>
      <br>

      <b>Datum der Erstzulassung:&nbsp;</b>
      <label>{{contractData.wegLogic.erstzulassungRestore}}</label>
      <br>

    </div>
    <!-- Kosten der Vertragserrichtung -->
    <div>
      <div *ngIf="this.contractData.primeType=='miet'">
        <div
          *ngIf="contractData.wegLogic.mietObjType == 'unternehmenspacht' || contractData.wegLogic.mietObjType == 'geschaeftsraum'">
          <h3>Kosten der Vertragserrichtung:</h3>
          <div class="inner-table">
            <table style="width:100%">
              <tr>
                <td><b>Netto:</b></td>
                <td>{{'157.50'| currency:'EUR'}}</td>
              </tr>
              <tr>
                <td><b>USt:</b></td>
                <td>{{'157.50' * 0.2 | currency:'EUR'}}</td>
              </tr>
              <tr>
                <td><b>Brutto:</b></td>
                <td>{{'157.50' * 1.2 | currency:'EUR'}}</td>
              </tr>
            </table>
          </div>
        </div>
        <div
          *ngIf="contractData.wegLogic.mietObjType != 'unternehmenspacht' && contractData.wegLogic.mietObjType != 'geschaeftsraum'">
          <h3>Kosten der Vertragserrichtung:</h3>
          <div class="inner-table">
            <table style="width:100%">
              <tr>
                <td><b>Netto:</b></td>
                <td>{{'82.50'| currency:'EUR'}}</td>
              </tr>
              <tr>
                <td><b>USt:</b></td>
                <td>{{'82.5' * 0.2 | currency:'EUR'}}</td>
              </tr>
              <tr>
                <td><b>Brutto:</b></td>
                <td>{{'82.5' * 1.2 | currency:'EUR'}}</td>
              </tr>
            </table>
          </div>
        </div>
      </div>

      <div
        *ngIf="this.contractData.primeType!='schenkung' && this.contractData.primeType!='miet' && this.contractData.primeType!='kfz'">
        <div *ngIf="transformCurrencyToNumber(this.contractData.wegLogic.kaufpreis) < 400000" class="custom-kosten">
          <h3>Kosten der Vertragserrichtung:</h3>
          <div class="inner-table">
            <table style="width:100%">
              <tr>
                <td><b>Netto:</b></td>
                <td>{{'2000'| currency:'EUR'}}</td>
              </tr>
              <tr>
                <td><b>USt:</b></td>
                <td>{{'2000' * 0.2 | currency:'EUR'}}</td>
              </tr>
              <tr>
                <td><b>Brutto:</b></td>
                <td>{{'2000' * 1.2 | currency:'EUR'}}</td>
              </tr>
            </table>
          </div>
        </div>

        <div *ngIf="transformCurrencyToNumber(this.contractData.wegLogic.kaufpreis) >= 400000" class="custom-kosten">
          <h3>Kosten der Vertragserrichtung:</h3>
          <div class="inner-table">
            <table style="width:100%">
              <tr>
                <td><b>Netto:</b></td>
                <td>{{transformCurrencyToNumber(this.contractData.wegLogic.kaufpreis) * 0.005 | currency:'EUR'}}</td>
              </tr>
              <tr>
                <td><b>USt:</b></td>
                <td>{{transformCurrencyToNumber(this.contractData.wegLogic.kaufpreis) * 0.2* 0.005 | currency:'EUR'}}</td>
              </tr>
              <tr>
                <td><b>Brutto:</b></td>
                <td>{{transformCurrencyToNumber(this.contractData.wegLogic.kaufpreis) * 1.2* 0.005 | currency:'EUR'}}</td>
              </tr>
            </table>
          </div>
        </div>
      </div>

      <div *ngIf="this.contractData.primeType=='kfz'" class="custom-kosten">
        Kosten der Vertragserrichtung: GRATIS
      </div>

      <div *ngIf="this.contractData.primeType=='schenkung'" class="custom-kosten">
        <h3>Kosten der Vertragserrichtung:</h3>
        <div class="inner-table">
          <table style="width:100%">
            <tr>
              <td><b>Netto:</b></td>
              <td>{{'1500'| currency:'EUR'}}</td>
            </tr>
            <tr>
              <td><b>USt:</b></td>
              <td>{{'1500' * 0.2 | currency:'EUR'}}</td>
            </tr>
            <tr>
              <td><b>Brutto:</b></td>
              <td>{{'1500' * 1.2 | currency:'EUR'}}</td>
            </tr>
          </table>
        </div>
      </div>
      <br>
    </div>
  </div>

  <div class="custom-dlg-footer">
    <button class="small-btn" mat-raised-button style="padding: 1%;" (click)="closeDlg()">
      <fa-icon style="color: inherit; background-color: inherit;" [icon]="faArrowLeft"></fa-icon>&nbsp;Daten
      &Auml;ndern
    </button>
    <br>
    <button class="bg-button mt-1" mat-raised-button *ngIf="contractData.primeType!='kfz'"
      style="padding: 1%; color: rgb(0, 0, 0) !important;" [disabled]="disableSubmit" (click)="closeOKDlg()">
      <span *ngIf="disableSubmit">
        <img src="assets/loading-gif.gif" alt="Loading">
      </span>
      <span *ngIf="!disableSubmit">
        Vertrag kostenpflichtig
        bestellen&nbsp;
        <fa-icon style="color: inherit; background-color: inherit;" [icon]="faCheck"></fa-icon>
      </span>
    </button>
    <button class="bg-button mt-1" mat-raised-button *ngIf="contractData.primeType=='kfz'"
      style="padding: 1%;  color: rgb(0, 0, 0) !important;" [disabled]="disableSubmit" (click)="closeOKDlg()">
      <span *ngIf="disableSubmit">
        <img src="assets/loading-gif.gif" alt="Loading">
      </span>
      <span *ngIf="!disableSubmit">
        Vertrag gratis
        bestellen&nbsp;
        <fa-icon style="color: inherit; background-color: inherit;" [icon]="faCheck"></fa-icon>
      </span>
    </button>
  </div>
  <!-- vcx -->



</mat-dialog-content>
<!-- <br> -->
