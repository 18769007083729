<app-header *ngIf="!newUi"></app-header>
<div class="router-container" [class.dashboard]="page == 'dashboard'">
  <router-outlet>
  </router-outlet>
</div>

<div class="full-loader" *ngIf="loading">
  <div class="text-center">
    <div class="spinner-border" style="width: 7rem; height: 7rem;">
      <span class="sr-only"></span>
    </div>
    <h4>Submitting...</h4>
  </div>
</div>
