<div class="yellow-line mt-5"></div>
<form [formGroup]="immodata" fxLayout="column">
  <div>
    <label>
      <h3 class="mt-4">Daten zu den Liegenschaften
        <!-- <fa-icon style="float: none; margin-left: 2px;" (click)="openUBBLDatenzLSDlg()"
          class="accent-color" [icon]="faUniversity"></fa-icon> -->
      </h3>
    </label>
  </div>
  <div *ngIf="LSBOOL[0]" class="liegenschaft-box" [class.custom-box]="LSBOOL[1]" formGroupName="immodetail" fxLayout="column">
    <h3>Daten zur Liegenschaft 1</h3>
    <div>
      <div>
        <mat-form-field appearance="none" class="no-padding no-line marginRight half-field" [class.validation-error]="submitted">
          <mat-label>Katastralgemeinde (KG)</mat-label>
          <input required matInput #autoCompleteInput formControlName="katastralGemeinde" [matAutocomplete]="auto" autoComplete  class="katastralGemeinde" pattern="^[^\s]+(\s+[^\s]+)*$"/>
          <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn" (optionSelected)='onSelectKg("l1", $event.option.value)'>
            <mat-option *ngIf="isLoading" class="is-loading">
              <mat-spinner diameter="50"></mat-spinner>
            </mat-option>
            <mat-option *ngFor="let village of filteredVillages" [value]="village">
              <span>{{ village.KGName}}</span> |
              <small>KGNr: {{ village.KGNr }}</small>
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>

        <mat-form-field appearance="none" class="no-padding no-line marginRight half-field" [class.validation-error]="submitted">
          <mat-label>Einlagezahl (EZ)</mat-label>
          <input required matInput maxlength="5" formControlName="einlageZahl" pattern="^[^\s]+(\s+[^\s]+)*$"/>
        </mat-form-field>
        <br>
      </div>

      <div *ngIf="contractData.primeType !='miet' " class="custom-top-line text-left radio-left-right">
        <div class="row">
          <div class="col-6">
            <p>
              <label>Bildet die gesamte Liegenschaft den Kaufgegenstand oder nur einzelne Grundstücke?</label>
            </p>
          </div>
          <div class="col-6">
            <mat-radio-group required aria-labelledby="example-radio-group-label"
              class="example-radio-group" formControlName="uebertragungsumfang" name="uebertragungsumfang" [class.validation-error]="submitted">
              <mat-radio-button class="example-radio-button" *ngFor="let uebertragung of uebertragsumfangArten"
                [value]="uebertragung">
                {{uebertragung}}&emsp;
              </mat-radio-button>&emsp;
            </mat-radio-group>
          </div>
        </div>
        <div *ngIf="immodata.get('immodetail.uebertragungsumfang').value === 'einzelne Grundstücke'">
          <label>Bitte geben Sie nur die Grundstücke ein, die verkauft werden!</label>
        </div>
      </div>
      <div>
        <div *ngFor="let gstNr of grundstuecksnummerAr.controls; let i = index" class="half-field">
          <mat-form-field appearance="none" class="no-padding no-line marginRight radio-left-right" style="width: 330px" [class.validation-error]="submitted">
            <mat-label>Grundstücksnummer (GST-NR) {{ i + 1 }}</mat-label>
            <div [formGroup]="gstNr" style="display: inline-block; position: relative">
              <input required matInput maxlength="10" formControlName="grundstuecksnummer"
                style="padding-right: 4.6em" pattern="^[^\s]+(\s+[^\s]+)*$"/>
            </div>
          </mat-form-field>
          <br>
          <button *ngIf="grundstuecksnummerAr.length > 1" mat-raised-button color="primary"
            (click)="removeGstNr(i)">Löschen</button>
        </div>
        <button mat-raised-button color="primary" (click)="addGstNr()">
          GST-NR hinzufügen
        </button>
        <br>
      </div>
      <br>
      <h3 *ngIf="contractData.primeType != 'miet' && contractData.primeType!='schenkung'">Anteile der Verkäufer</h3>
      <h3 *ngIf="contractData.primeType == 'miet'">Anteile der Vermieterseite</h3>
      <h3 *ngIf="contractData.primeType == 'schenkung'">Anteile der Geschenkgeberseite</h3>
      <div>
        <div *ngFor="let seller of contractData.anteildata.l1.seller.sellersPrivate, let i = index">
          <h4>{{seller.firstname}} {{seller.lastname}}</h4>
          <div>
            <button *ngIf="contractData.primeType != 'miet' && contractData.primeType!='schenkung'" mat-raised-button
              color="primary" (click)="addSharePrivate(i)">
              {{seller.firstname}} {{seller.lastname}} verkauft weitere Anteile
            </button>
            <button *ngIf="contractData.primeType == 'miet'" mat-raised-button color="primary"
              (click)="addSharePrivate(i)">
              {{seller.firstname}} {{seller.lastname}} vermietet weitere Anteile
            </button>
            <button *ngIf="contractData.primeType == 'schenkung'" mat-raised-button color="primary"
              (click)="addSharePrivate(i)">
              {{seller.firstname}} {{seller.lastname}} schenkt weitere Anteile
            </button>
          </div>
          <div *ngFor="let anteil of seller.shareArDev.controls; let j = index">
            <app-bbl-logic-share [parent]="anteil" [submitted]="submitted"></app-bbl-logic-share>
            <button *ngIf="ZweiPlusAnteil[0]" mat-raised-button color="primary" (click)="removeSharePrivate(i, j)">
              Anteil löschen
            </button>
          </div>
          <br>
        </div>

        <div *ngFor="let seller of contractData.anteildata.l1.seller.sellersCompany, let i = index">
          <h4>{{seller.companyName}}</h4>
          <div>
            <button *ngIf="contractData.primeType != 'miet' && contractData.primeType!='schenkung'" mat-raised-button
              color="primary" (click)="addShareCompany(i)">
              {{seller.companyName}} verkauft weitere Anteile
            </button>
            <button *ngIf="contractData.primeType == 'miet'" mat-raised-button color="primary"
              (click)="addShareCompany(i)">
              {{seller.companyName}} vermietet weitere Anteile
            </button>
            <button *ngIf="contractData.primeType == 'schenkung'" mat-raised-button color="primary"
              (click)="addShareCompany(i)">
              {{seller.companyName}} schenkt weitere Anteile
            </button>
          </div>

          <div *ngFor="let anteil of seller.shareArDev.controls; let j = index">
            <app-bbl-logic-share [parent]="anteil" [submitted]="submitted"></app-bbl-logic-share>
            <button mat-raised-button color="primary" *ngIf="ZweiPlusAnteil[0]" (click)="removeShareCompany(i, j)">
              Anteil löschen
            </button>
          </div>
          <br>
        </div>
      </div>

      <div *ngIf="KBOOL">
        <h3 *ngIf="contractData.primeType!='schenkung' && contractData.primeType!='miet'">Anteile der Käufer</h3>
        <h3 *ngIf="contractData.primeType=='miet'">Anteile der Mieter</h3>
        <h3 *ngIf="contractData.primeType=='schenkung'">Anteile der Geschenknehmer</h3>
        <div *ngFor="let buyer of contractData.anteildata.l1.buyer.buyersPrivate, let i = index">
          <h4>{{buyer.firstname}} {{buyer.lastname}}</h4>
          <div>
            <button mat-raised-button color="primary"
              *ngIf="buyer.shareArDev.controls.length == 0 && contractData.primeType!='schenkung' && contractData.primeType!='miet'"
              (click)="KaddSharePrivate(i)">
              {{buyer.firstname}} {{buyer.lastname}} erwirbt weitere Anteile
            </button>
            <button mat-raised-button color="primary"
              *ngIf="buyer.shareArDev.controls.length == 0 && contractData.primeType=='miet'"
              (click)="KaddSharePrivate(i)">
              {{buyer.firstname}} {{buyer.lastname}} mietet weitere Anteile
            </button>
            <button mat-raised-button color="primary"
              *ngIf="buyer.shareArDev.controls.length == 0 && contractData.primeType=='schenkung'"
              (click)="KaddSharePrivate(i)">
              {{buyer.firstname}} {{buyer.lastname}} nimmt weitere Anteile entgegen
            </button>
          </div>
          <div *ngFor="let Kanteil of buyer.shareArDev.controls; let j = index">
            <app-kaeufer-share-logic [parent]="Kanteil"></app-kaeufer-share-logic>
            <button *ngIf="KZweiPlusAnteil[0]" mat-raised-button color="primary" (click)="KremoveSharePrivate(i, j)">
              Anteil löschen
            </button>
          </div>
          <br>
        </div>

        <div *ngFor="let buyer of contractData.anteildata.l1.buyer.buyersCompany, let i = index">
          <h4>{{buyer.companyName}}</h4>
          <div>
            <button mat-raised-button color="primary"
              *ngIf="buyer.shareArDev.controls.length == 0 && contractData.primeType!='miet' && contractData.primeType!='schenkung'"
              (click)="KaddShareCompany(i)">
              {{buyer.companyName}} erwirbt weitere Anteile
            </button>
            <button mat-raised-button color="primary"
              *ngIf="buyer.shareArDev.controls.length == 0 && contractData.primeType=='miet'"
              (click)="KaddShareCompany(i)">
              {{buyer.companyName}} mietet weitere Anteile
            </button>
            <button mat-raised-button color="primary"
              *ngIf="buyer.shareArDev.controls.length == 0 && contractData.primeType=='schenkung'"
              (click)="KaddShareCompany(i)">
              {{buyer.companyName}} nimmt weitere Anteile entgegen
            </button>
          </div>
          <div *ngFor="let anteil of buyer.shareArDev.controls; let j = index">
            <app-kaeufer-share-logic [parent]="anteil" [submitted]="submitted"></app-kaeufer-share-logic>
            <button mat-raised-button color="primary" *ngIf="KZweiPlusAnteil[0]" (click)="KremoveShareCompany(i, j)">
              Anteil löschen
            </button>
          </div>

          <br>
        </div>
      </div>

    </div>
    <div *ngIf="!miet">
      <app-sonstige-rechte-logic id="l1" name="sonstigerechtel1" IDIN="l1" #l1 [submitted]="submitted"></app-sonstige-rechte-logic>
    </div>
  </div>

  <!-- Schlimm aber goht ned gschied zum andrsch macha :) <3 -->
  <!-- <div *ngIf="LSBOOL[1]"><br></div> -->

  <div *ngIf="LSBOOL[1]" class="custom-box liegenschaft-box" fxLayout="column" formGroupName="l2immodetail">
    <h3>Daten zur Liegenschaft 2</h3>
    <div>
      <div>
        <mat-form-field appearance="none" class="no-padding no-line marginRight half-field" [class.validation-error]="submitted">
          <mat-label>Katastralgemeinde (KG)</mat-label>
          <!-- <input required matInput formControlName="l2katastralGemeinde" [matAutocomplete]="auto" /> -->
          <input required matInput #autoCompleteInput2 formControlName="l2katastralGemeinde" [matAutocomplete]="auto" class="katastralGemeinde" pattern="^[^\s]+(\s+[^\s]+)*$"/>
          <mat-autocomplete #auto="matAutocomplete" [displayWith]="l2displayFn" (optionSelected)="onSelectKg('l2', $event.option.value)">
            <mat-option *ngIf="l2isLoading" class="is-loading">
              <mat-spinner diameter="50"></mat-spinner>
            </mat-option>
            <mat-option *ngFor="let l2village of l2filteredVillages" [value]="l2village">
              <span>{{ l2village.KGName}}</span> |
              <small>KGNr: {{ l2village.KGNr }}</small>
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>

        <mat-form-field appearance="none" class="no-padding no-line marginRight half-field" [class.validation-error]="submitted">
          <mat-label>Einlagezahl (EZ)</mat-label>
          <input required matInput maxlength="5" formControlName="l2einlageZahl" pattern="^[^\s]+(\s+[^\s]+)*$"/>
        </mat-form-field>
        <br>
      </div>

      <div *ngIf="contractData.primeType!='miet'" class="custom-top-line text-left radio-left-right">
          <div class="row">
            <div class="col-6">
              <p>
                <label>Bildet die gesamte Liegenschaft den Kaufgegenstand oder nur einzelne Grundstücke?</label>
              </p>
            </div>
            <div class="col-6">
              <mat-radio-group required aria-labelledby="example-radio-group-label"
                class="example-radio-group" formControlName="l2uebertragungsumfang" name="l2uebertragungsumfang" [class.validation-error]="submitted">
                <mat-radio-button class="example-radio-button" *ngFor="let l2uebertragung of uebertragsumfangArten"
                  [value]="l2uebertragung">
                  {{l2uebertragung}}&emsp;
                </mat-radio-button>&emsp;
              </mat-radio-group>
            </div>
          </div>
        <div *ngIf="immodata.get('l2immodetail.l2uebertragungsumfang').value === 'einzelne Grundstücke'">
          <label>Bitte geben Sie nur die Grundstücke ein, die verkauft werden!</label>
        </div>
      </div>
      <div *ngFor="let l2gstNr of l2grundstuecksnummerAr.controls; let i = index">
        <mat-form-field appearance="none" class="no-padding no-line marginRight half-field" style="width: 330px" [class.validation-error]="submitted">
          <mat-label>Grundstücksnummer (GST-NR) {{ i + 1 }}</mat-label>
          <div [formGroup]="l2gstNr" style="display: inline-block; position: relative">
            <input required matInput maxlength="10" formControlName="l2grundstuecksnummer"
              style="padding-right: 4.6em" pattern="^[^\s]+(\s+[^\s]+)*$"/>
          </div>
        </mat-form-field>
        <br>
        <button *ngIf="l2grundstuecksnummerAr.length > 1" mat-raised-button color="primary"
          (click)="l2removeGstNr(i)">Löschen</button>
      </div>
      <button mat-raised-button color="primary" (click)="l2addGstNr()">
        GST-NR hinzufügen
      </button>
      <br>
    </div>
    <br>
    <h3 *ngIf="contractData.primeType != 'miet' && contractData.primeType!='schenkung'">Anteile der Verkäufer</h3>
    <h3 *ngIf="contractData.primeType == 'miet'">Anteile der Vermieterseite</h3>
    <h3 *ngIf="contractData.primeType == 'schenkung'">Anteile der Geschenkgeberseite</h3>
    <div>
      <div *ngFor="let seller of contractData.anteildata.l2.seller.sellersPrivate, let i = index">
        <h4>{{seller.firstname}} {{seller.lastname}}</h4>
        <div>
          <button *ngIf="contractData.primeType != 'miet' && contractData.primeType!='schenkung'" mat-raised-button
            color="primary" (click)="l2addSharePrivate(i)">
            {{seller.firstname}} {{seller.lastname}} verkauft weitere Anteile
          </button>
          <button *ngIf="contractData.primeType == 'miet'" mat-raised-button color="primary"
            (click)="l2addSharePrivate(i)">
            {{seller.firstname}} {{seller.lastname}} vermietet weitere Anteile
          </button>
          <button *ngIf="contractData.primeType == 'schenkung'" mat-raised-button color="primary"
            (click)="l2addSharePrivate(i)">
            {{seller.firstname}} {{seller.lastname}} schenkt weitere Anteile
          </button>
        </div>
        <div *ngFor="let l2anteil of seller.shareArDev.controls; let j = index">
          <app-bbl-logic-share [parent]="l2anteil" [submitted]="submitted"></app-bbl-logic-share>
          <button *ngIf="ZweiPlusAnteil[1]" mat-raised-button color="primary" (click)="l2removeSharePrivate(i, j)">
            Anteil löschen
          </button>
        </div>
        <br>
      </div>

      <div *ngFor="let seller of contractData.anteildata.l2.seller.sellersCompany, let i = index">
        <h4>{{seller.companyName}}</h4>
        <div>
          <button *ngIf="contractData.primeType != 'miet' && contractData.primeType!='schenkung'" mat-raised-button
            color="primary" (click)="l2addShareCompany(i)">
            {{seller.companyName}} verkauft weitere Anteile
          </button>
          <button *ngIf="contractData.primeType == 'miet'" mat-raised-button color="primary"
            (click)="l2addShareCompany(i)">
            {{seller.companyName}} vermietet weitere Anteile
          </button>
          <button *ngIf="contractData.primeType == 'schenkung'" mat-raised-button color="primary"
            (click)="l2addShareCompany(i)">
            {{seller.companyName}} schenkt weitere Anteile
          </button>
        </div>
        <div *ngFor="let l2anteil of seller.shareArDev.controls; let j = index">
          <app-bbl-logic-share [parent]="l2anteil" [submitted]="submitted"></app-bbl-logic-share>
          <button mat-raised-button color="primary" *ngIf="ZweiPlusAnteil[1]" (click)="l2removeShareCompany(i, j)">
            Anteil löschen
          </button>
          <br>
        </div>
      </div>


      <div *ngIf="KBOOL">
        <h3 *ngIf="contractData.primeType!='schenkung' && contractData.primeType!='miet'">Anteile der Käufer</h3>
        <h3 *ngIf="contractData.primeType=='miet'">Anteile der Mieter</h3>
        <h3 *ngIf="contractData.primeType=='schenkung'">Anteile der Geschenknehmer</h3>
        <div *ngFor="let buyer of contractData.anteildata.l2.buyer.buyersPrivate, let i = index">
          <h4>{{buyer.firstname}} {{buyer.lastname}}</h4>
          <div>
            <button mat-raised-button color="primary"
              *ngIf="buyer.shareArDev.controls.length == 0 && contractData.primeType!='schenkung' && contractData.primeType!='miet'"
              (click)="l2KaddSharePrivate(i)">
              {{buyer.firstname}} {{buyer.lastname}} erwirbt weitere Anteile
            </button>
            <button mat-raised-button color="primary"
              *ngIf="buyer.shareArDev.controls.length == 0 && contractData.primeType=='miet'"
              (click)="l2KaddSharePrivate(i)">
              {{buyer.firstname}} {{buyer.lastname}} mietet weitere Anteile
            </button>
            <button mat-raised-button color="primary"
              *ngIf="buyer.shareArDev.controls.length == 0 && contractData.primeType=='schenkung'"
              (click)="l2KaddSharePrivate(i)">
              {{buyer.firstname}} {{buyer.lastname}} nimmt weitere Anteile entgegen
            </button>
          </div>
          <div *ngFor="let anteil of buyer.shareArDev.controls; let j = index">
            <app-kaeufer-share-logic [parent]="anteil" [submitted]="submitted"></app-kaeufer-share-logic>
            <button *ngIf="KZweiPlusAnteil[1]" mat-raised-button color="primary" (click)="l2KremoveSharePrivate(i, j)">
              Anteil löschen
            </button>
          </div>
          <br>
        </div>

        <div *ngFor="let buyer of contractData.anteildata.l2.buyer.buyersCompany, let i = index">
          <h4>{{buyer.companyName}}</h4>
          <div>
            <button mat-raised-button color="primary"
              *ngIf="buyer.shareArDev.controls.length == 0 && contractData.primeType!='miet' && contractData.primeType!='schenkung'"
              (click)="l2KaddShareCompany(i)">
              {{buyer.companyName}} erwirbt weitere Anteile
            </button>
            <button mat-raised-button color="primary"
              *ngIf="buyer.shareArDev.controls.length == 0 && contractData.primeType=='miet'"
              (click)="l2KaddShareCompany(i)">
              {{buyer.companyName}} mietet weitere Anteile
            </button>
            <button mat-raised-button color="primary"
              *ngIf="buyer.shareArDev.controls.length == 0 && contractData.primeType=='schenkung'"
              (click)="l2KaddShareCompany(i)">
              {{buyer.companyName}} nimmt weitere Anteile entgegen
            </button>
          </div>
          <div *ngFor="let anteil of buyer.shareArDev.controls; let j = index">
            <app-kaeufer-share-logic [parent]="anteil" [submitted]="submitted"></app-kaeufer-share-logic>
            <button mat-raised-button color="primary" *ngIf="KZweiPlusAnteil[1]" (click)="l2KremoveShareCompany(i, j)">
              Anteil löschen
            </button>
          </div>
          <br>
        </div>
      </div>

    </div>

    <div *ngIf="!miet">
      <app-sonstige-rechte-logic id="l2" name="sonstigerechtel1" IDIN="l2" #l2 [submitted]="submitted"></app-sonstige-rechte-logic>
    </div>
    <br>
  </div>

  <div *ngIf="LSBOOL[2]" class="custom-box liegenschaft-box" fxLayout="column" formGroupName="l3immodetail">
    <h3>Daten zur Liegenschaft 3</h3>
    <div>
      <div>
        <mat-form-field appearance="none" class="no-padding no-line marginRight half-field" [class.validation-error]="submitted">
          <mat-label>Katastralgemeinde (KG)</mat-label>
          <input required matInput #autoCompleteInput3 formControlName="l3katastralGemeinde" [matAutocomplete]="auto"  class="katastralGemeinde" pattern="^[^\s]+(\s+[^\s]+)*$"/>
          <mat-autocomplete #auto="matAutocomplete" [displayWith]="l3displayFn" (optionSelected)="onSelectKg('l3', $event.option.value)">
            <mat-option *ngIf="l3isLoading" class="is-loading">
              <mat-spinner diameter="50"></mat-spinner>
            </mat-option>
            <mat-option *ngFor="let l3village of l3filteredVillages" [value]="l3village">
              <span>{{ l3village.KGName}}</span> |
              <small>KGNr: {{ l3village.KGNr }}</small>
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>

        <mat-form-field appearance="none" class="no-padding no-line marginRight half-field" [class.validation-error]="submitted">
          <mat-label>Einlagezahl (EZ)</mat-label>
          <input required matInput maxlength="5" formControlName="l3einlageZahl" pattern="^[^\s]+(\s+[^\s]+)*$"/>
        </mat-form-field>
        <br>
      </div>

      <div *ngIf="contractData.primeType!='miet'" class="custom-top-line text-left radio-left-right">
        <div class="row">
          <div class="col-6">
            <p>
              <label>Bildet die gesamte Liegenschaft den Kaufgegenstand oder nur einzelne Grundstücke?</label>
            </p>
          </div>
          <div class="col-6">
            <mat-radio-group required aria-labelledby="example-radio-group-label"
              class="example-radio-group" formControlName="l3uebertragungsumfang" name="l3uebertragungsumfang" [class.validation-error]="submitted">
              <mat-radio-button class="example-radio-button" *ngFor="let l3uebertragung of uebertragsumfangArten"
                [value]="l3uebertragung">
                {{l3uebertragung}}&emsp;
              </mat-radio-button>&emsp;
            </mat-radio-group>
          </div>
        </div>
        <div *ngIf="immodata.get('l3immodetail.l3uebertragungsumfang').value === 'einzelne Grundstücke'">
          <label>Bitte geben Sie nur die Grundstücke ein, die verkauft werden!</label>
        </div>
      </div>
      <div>
        <div *ngFor="let l3gstNr of l3grundstuecksnummerAr.controls; let i = index">
          <mat-form-field appearance="none" class="no-padding no-line marginRight half-field" style="width: 330px" [class.validation-error]="submitted">
            <mat-label>Grundstücksnummer (GST-NR) {{ i + 1 }}</mat-label>
            <div [formGroup]="l3gstNr" style="display: inline-block; position: relative">
              <input required matInput maxlength="10" formControlName="l3grundstuecksnummer"
                style="padding-right: 4.6em" pattern="^[^\s]+(\s+[^\s]+)*$"/>
            </div>
          </mat-form-field>
          <br>
          <button *ngIf="l3grundstuecksnummerAr.length > 1" mat-raised-button color="primary"
            (click)="l3removeGstNr(i)">Löschen</button>
        </div>
        <button mat-raised-button color="primary" (click)="l3addGstNr()">
          GST-NR hinzufügen
        </button>
        <br>
      </div>
      <br>
    </div>
    <h3 *ngIf="contractData.primeType != 'miet' && contractData.primeType!='schenkung'">Anteile der Verkäufer</h3>
    <h3 *ngIf="contractData.primeType == 'miet'">Anteile der Vermieterseite</h3>
    <h3 *ngIf="contractData.primeType == 'schenkung'">Anteile der Geschenkgeberseite</h3>
    <div>
      <div *ngFor="let seller of contractData.anteildata.l3.seller.sellersPrivate, let i = index">
        <h4>{{seller.firstname}} {{seller.lastname}}</h4>
        <div>
          <button *ngIf="contractData.primeType != 'miet' && contractData.primeType!='schenkung'" mat-raised-button
            color="primary" (click)="l3addSharePrivate(i)">
            {{seller.firstname}} {{seller.lastname}} verkauft weitere Anteile
          </button>
          <button *ngIf="contractData.primeType == 'miet'" mat-raised-button color="primary"
            (click)="l3addSharePrivate(i)">
            {{seller.firstname}} {{seller.lastname}} vermietet weitere Anteile
          </button>
          <button *ngIf="contractData.primeType == 'schenkung'" mat-raised-button color="primary"
            (click)="l3addSharePrivate(i)">
            {{seller.firstname}} {{seller.lastname}} schenkt weitere Anteile
          </button>
        </div>
        <div *ngFor="let l3anteil of seller.shareArDev.controls; let j = index">
          <app-bbl-logic-share [parent]="l3anteil" [submitted]="submitted"></app-bbl-logic-share>
          <button *ngIf="ZweiPlusAnteil[2]" mat-raised-button color="primary" (click)="l3removeSharePrivate(i, j)">
            Anteil löschen
          </button>
        </div>

        <br>
      </div>

      <div *ngFor="let seller of contractData.anteildata.l3.seller.sellersCompany, let i = index">
        <h4>{{seller.companyName}}</h4>
        <div>
          <button *ngIf="contractData.primeType != 'miet' && contractData.primeType!='schenkung'" mat-raised-button
            color="primary" (click)="l3addShareCompany(i)">
            {{seller.companyName}} verkauft weitere Anteile
          </button>
          <button *ngIf="contractData.primeType == 'miet'" mat-raised-button color="primary"
            (click)="l3addShareCompany(i)">
            {{seller.companyName}} vermietet weitere Anteile
          </button>
          <button *ngIf="contractData.primeType == 'schenkung'" mat-raised-button color="primary"
            (click)="l3addShareCompany(i)">
            {{seller.companyName}} schenkt weitere Anteile
          </button>
        </div>
        <div *ngFor="let l3anteil of seller.shareArDev.controls; let j = index">
          <app-bbl-logic-share [parent]="l3anteil" [submitted]="submitted"></app-bbl-logic-share>
          <button mat-raised-button color="primary" *ngIf="ZweiPlusAnteil[2]" (click)="l3removeShareCompany(i, j)">
            Anteil löschen
          </button>
        </div>

        <br>
      </div>


      <div *ngIf="KBOOL">
        <h3 *ngIf="contractData.primeType!='schenkung' && contractData.primeType!='miet'">Anteile der Käufer</h3>
        <h3 *ngIf="contractData.primeType=='miet'">Anteile der Mieter</h3>
        <h3 *ngIf="contractData.primeType=='schenkung'">Anteile der Geschenknehmer</h3>
        <div *ngFor="let buyer of contractData.anteildata.l3.buyer.buyersPrivate, let i = index">
          <h4>{{buyer.firstname}} {{buyer.lastname}}</h4>
          <div>
            <button mat-raised-button color="primary"
              *ngIf="buyer.shareArDev.controls.length == 0 && contractData.primeType!='schenkung' && contractData.primeType!='miet'"
              (click)="l3KaddSharePrivate(i)">
              {{buyer.firstname}} {{buyer.lastname}} erwirbt weitere Anteile
            </button>
            <button mat-raised-button color="primary"
              *ngIf="buyer.shareArDev.controls.length == 0 && contractData.primeType=='miet'"
              (click)="l3KaddSharePrivate(i)">
              {{buyer.firstname}} {{buyer.lastname}} mietet weitere Anteile
            </button>
            <button mat-raised-button color="primary"
              *ngIf="buyer.shareArDev.controls.length == 0 && contractData.primeType=='schenkung'"
              (click)="l3KaddSharePrivate(i)">
              {{buyer.firstname}} {{buyer.lastname}} nimmt weitere Anteile entgegen
            </button>
          </div>
          <div *ngFor="let anteil of buyer.shareArDev.controls; let j = index">
            <app-kaeufer-share-logic [parent]="anteil" [submitted]="submitted"></app-kaeufer-share-logic>
            <button *ngIf="KZweiPlusAnteil[2]" mat-raised-button color="primary" (click)="l3KremoveSharePrivate(i, j)">
              Anteil löschen
            </button>
          </div>

          <br>
        </div>

        <div *ngFor="let buyer of contractData.anteildata.l3.buyer.buyersCompany, let i = index">
          <h4>{{buyer.companyName}}</h4>
          <div>
            <button mat-raised-button color="primary"
              *ngIf="buyer.shareArDev.controls.length == 0 && contractData.primeType!='miet' && contractData.primeType!='schenkung'"
              (click)="l3KaddShareCompany(i)">
              {{buyer.companyName}} erwirbt weitere Anteile
            </button>
            <button mat-raised-button color="primary"
              *ngIf="buyer.shareArDev.controls.length == 0 && contractData.primeType=='miet'"
              (click)="l3KaddShareCompany(i)">
              {{buyer.companyName}} mietet weitere Anteile
            </button>
            <button mat-raised-button color="primary"
              *ngIf="buyer.shareArDev.controls.length == 0 && contractData.primeType=='schenkung'"
              (click)="l3KaddShareCompany(i)">
              {{buyer.companyName}} nimmt weitere Anteile entgegen
            </button>
          </div>
          <div *ngFor="let anteil of buyer.shareArDev.controls; let j = index">
            <app-kaeufer-share-logic [parent]="anteil" [submitted]="submitted"></app-kaeufer-share-logic>
            <button mat-raised-button color="primary" *ngIf="KZweiPlusAnteil[2]" (click)="l3KremoveShareCompany(i, j)">
              Anteil löschen
            </button>
          </div>
          <br>
        </div>
      </div>
    </div>

    <div *ngIf="!miet">
      <app-sonstige-rechte-logic id="l3" name="sonstigerechtel3" IDIN="l3" #l3 [submitted]="submitted"></app-sonstige-rechte-logic>
    </div>
    <br>
  </div>

  <div *ngIf="LSBOOL[3]" class="custom-box liegenschaft-box" fxLayout="column" formGroupName="l4immodetail">
    <h3>Daten zur Liegenschaft 4</h3>
    <div>
      <div>
        <mat-form-field appearance="none" class="no-padding no-line marginRight half-field" [class.validation-error]="submitted">
          <mat-label>Katastralgemeinde (KG)</mat-label>
          <input required matInput #autoCompleteInput4 formControlName="l4katastralGemeinde" [matAutocomplete]="auto" class="katastralGemeinde" pattern="^[^\s]+(\s+[^\s]+)*$"/>
          <mat-autocomplete #auto="matAutocomplete" [displayWith]="l4displayFn" (optionSelected)="onSelectKg('l4', $event.option.value)">
            <mat-option *ngIf="l4isLoading" class="is-loading">
              <mat-spinner diameter="50"></mat-spinner>
            </mat-option>
            <mat-option *ngFor="let l4village of l4filteredVillages" [value]="l4village">
              <span>{{ l4village.KGName}}</span> |
              <small>KGNr: {{ l4village.KGNr }}</small>
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>

        <mat-form-field appearance="none" class="no-padding no-line marginRight half-field" [class.validation-error]="submitted">
          <mat-label>Einlagezahl (EZ)</mat-label>
          <input required matInput maxlength="5" formControlName="l4einlageZahl" pattern="^[^\s]+(\s+[^\s]+)*$"/>
        </mat-form-field>
        <br>
      </div>

      <div *ngIf="contractData.primeType!='miet'" class="custom-top-line text-left radio-left-right">
        <div class="row">
          <div class="col-6">
            <p>
              <label>Bildet die gesamte Liegenschaft den Kaufgegenstand oder nur einzelne Grundstücke?</label>
            </p>
          </div>
          <div class="col-6">
            <mat-radio-group required aria-labelledby="example-radio-group-label"
              class="example-radio-group" formControlName="l4uebertragungsumfang" name="l4uebertragungsumfang" [class.validation-error]="submitted">
              <mat-radio-button class="example-radio-button" *ngFor="let l4uebertragung of uebertragsumfangArten"
                [value]="l4uebertragung">
                {{l4uebertragung}}&emsp;
              </mat-radio-button>&emsp;
            </mat-radio-group>
          </div>
        </div>
        <div *ngIf="immodata.get('l4immodetail.l4uebertragungsumfang').value === 'einzelne Grundstücke'">
          <label>Bitte geben Sie nur die Grundstücke ein, die verkauft werden!</label>
        </div>
      </div>
      <div>
        <div *ngFor="let l4gstNr of l4grundstuecksnummerAr.controls; let i = index">
          <mat-form-field appearance="none" class="no-padding no-line marginRight half-field" style="width: 330px" [class.validation-error]="submitted">
            <mat-label>Grundstücksnummer (GST-NR) {{ i + 1 }}</mat-label>
            <div [formGroup]="l4gstNr" style="display: inline-block; position: relative">
              <input required matInput maxlength="10" formControlName="l4grundstuecksnummer"
                style="padding-right: 4.6em" pattern="^[^\s]+(\s+[^\s]+)*$"/>
            </div>
          </mat-form-field>
          <br>
          <button *ngIf="l4grundstuecksnummerAr.length > 1" mat-raised-button color="primary"
            (click)="l4removeGstNr(i)">Löschen</button>
        </div>
        <button mat-raised-button color="primary" (click)="l4addGstNr()">
          GST-NR hinzufügen
        </button>
        <br>
      </div>
      <br>
    </div>
    <h3 *ngIf="contractData.primeType != 'miet' && contractData.primeType!='schenkung'">Anteile der Verkäufer</h3>
    <h3 *ngIf="contractData.primeType == 'miet'">Anteile der Vermieterseite</h3>
    <h3 *ngIf="contractData.primeType == 'schenkung'">Anteile der Geschenkgeberseite</h3>
    <div>
      <div *ngFor="let seller of contractData.anteildata.l4.seller.sellersPrivate, let i = index">
        <h4>{{seller.firstname}} {{seller.lastname}}</h4>
        <div>
          <button *ngIf="contractData.primeType != 'miet' && contractData.primeType!='schenkung'" mat-raised-button
            color="primary" (click)="l4addSharePrivate(i)">
            {{seller.firstname}} {{seller.lastname}} verkauft weitere Anteile
          </button>
          <button *ngIf="contractData.primeType == 'miet'" mat-raised-button color="primary"
            (click)="l4addSharePrivate(i)">
            {{seller.firstname}} {{seller.lastname}} vermietet weitere Anteile
          </button>
          <button *ngIf="contractData.primeType == 'schenkung'" mat-raised-button color="primary"
            (click)="l4addSharePrivate(i)">
            {{seller.firstname}} {{seller.lastname}} schenkt weitere Anteile
          </button>
        </div>
        <div *ngFor="let l4anteil of seller.shareArDev.controls; let j = index">
          <app-bbl-logic-share [parent]="l4anteil" [submitted]="submitted"></app-bbl-logic-share>
          <button *ngIf="ZweiPlusAnteil[3]" mat-raised-button color="primary" (click)="l4removeSharePrivate(i, j)">
            Anteil löschen
          </button>
        </div>

        <br>
      </div>

      <div *ngFor="let seller of contractData.anteildata.l4.seller.sellersCompany, let i = index">
        <h4>{{seller.companyName}}</h4>
        <div>
          <button *ngIf="contractData.primeType != 'miet' && contractData.primeType!='schenkung'" mat-raised-button
            color="primary" (click)="l4addShareCompany(i)">
            {{seller.companyName}} verkauft weitere Anteile
          </button>
          <button *ngIf="contractData.primeType == 'miet'" mat-raised-button color="primary"
            (click)="l4addShareCompany(i)">
            {{seller.companyName}} vermietet weitere Anteile
          </button>
          <button *ngIf="contractData.primeType == 'schenkung'" mat-raised-button color="primary"
            (click)="l4addShareCompany(i)">
            {{seller.companyName}} schenkt weitere Anteile
          </button>
        </div>
        <div *ngFor="let l4anteil of seller.shareArDev.controls; let j = index">
          <app-bbl-logic-share [parent]="l4anteil" [submitted]="submitted"></app-bbl-logic-share>
          <button mat-raised-button color="primary" *ngIf="ZweiPlusAnteil[3]" (click)="l4removeShareCompany(i, j)">
            Anteil löschen
          </button>
        </div>
        <br>
      </div>


      <div *ngIf="KBOOL">
        <h3 *ngIf="contractData.primeType!='schenkung' && contractData.primeType!='miet'">Anteile der Käufer</h3>
        <h3 *ngIf="contractData.primeType=='miet'">Anteile der Mieter</h3>
        <h3 *ngIf="contractData.primeType=='schenkung'">Anteile der Geschenknehmer</h3>
        <div *ngFor="let buyer of contractData.anteildata.l4.buyer.buyersPrivate, let i = index">
          <h4>{{buyer.firstname}} {{buyer.lastname}}</h4>
          <div>
            <button mat-raised-button color="primary"
              *ngIf="buyer.shareArDev.controls.length == 0 && contractData.primeType!='schenkung' && contractData.primeType!='miet'"
              (click)="l4KaddSharePrivate(i)">
              {{buyer.firstname}} {{buyer.lastname}} erwirbt weitere Anteile
            </button>
            <button mat-raised-button color="primary"
              *ngIf="buyer.shareArDev.controls.length == 0 && contractData.primeType=='miet'"
              (click)="l4KaddSharePrivate(i)">
              {{buyer.firstname}} {{buyer.lastname}} mietet weitere Anteile
            </button>
            <button mat-raised-button color="primary"
              *ngIf="buyer.shareArDev.controls.length == 0 && contractData.primeType=='schenkung'"
              (click)="l4KaddSharePrivate(i)">
              {{buyer.firstname}} {{buyer.lastname}} nimmt weitere Anteile entgegen
            </button>
          </div>
          <div *ngFor="let anteil of buyer.shareArDev.controls; let j = index">
            <app-kaeufer-share-logic [parent]="anteil" [submitted]="submitted"></app-kaeufer-share-logic>
            <button *ngIf="KZweiPlusAnteil[3]" mat-raised-button color="primary" (click)="l4KremoveSharePrivate(i, j)">
              Anteil löschen
            </button>
          </div>
          <br>
        </div>

        <div *ngFor="let buyer of contractData.anteildata.l4.buyer.buyersCompany, let i = index">
          <h4>{{buyer.companyName}}</h4>
          <div>
            <button mat-raised-button color="primary"
              *ngIf="buyer.shareArDev.controls.length == 0 && contractData.primeType!='miet' && contractData.primeType!='schenkung'"
              (click)="l4KaddShareCompany(i)">
              {{buyer.companyName}} erwirbt weitere Anteile
            </button>
            <button mat-raised-button color="primary"
              *ngIf="buyer.shareArDev.controls.length == 0 && contractData.primeType=='miet'"
              (click)="l4KaddShareCompany(i)">
              {{buyer.companyName}} mietet weitere Anteile
            </button>
            <button mat-raised-button color="primary"
              *ngIf="buyer.shareArDev.controls.length == 0 && contractData.primeType=='schenkung'"
              (click)="l4KaddShareCompany(i)">
              {{buyer.companyName}} nimmt weitere Anteile entgegen
            </button>
          </div>
          <div *ngFor="let anteil of buyer.shareArDev.controls; let j = index">
            <app-kaeufer-share-logic [parent]="anteil" [submitted]="submitted"></app-kaeufer-share-logic>
            <button mat-raised-button color="primary" *ngIf="KZweiPlusAnteil[3]" (click)="l4KremoveShareCompany(i, j)">
              Anteil löschen
            </button>
          </div>
        </div>
      </div>
      <div *ngIf="!miet">
        <app-sonstige-rechte-logic id="l4" name="sonstigerechtel4" IDIN="l4" #l4 [submitted]="submitted"></app-sonstige-rechte-logic>
      </div>
    </div>
  </div>
</form>
