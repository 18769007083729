import { Router, RoutesRecognized, ActivationStart } from '@angular/router';
import { Component, OnInit, AfterViewInit } from '@angular/core';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { MatDialog } from '@angular/material/dialog';
import { first } from "rxjs/operators";
import { Store } from "@ngxs/store";

import { ConfirmDialogModel, ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { ContractDataService } from '../shared/contractData.service';
import { VertragState } from '../core/state/vertrag/state';
import { AuthService } from '../shared/auth.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})

export class HeaderComponent implements OnInit, AfterViewInit {

  page;
  user;
  isUserLoggedIn;
  userRole = 0;
  heading = "";
  faUser = faUser;
  subscription?: Subscription;

  constructor(private store: Store, private auth : AuthService, private router: Router, public contractDataService: ContractDataService, public dialog: MatDialog) { }

  ngOnInit() {
    this.user = this.auth.getUser();
    this.isUserLoggedIn = this.auth.isUserLoggedIn();
    this.router.events.subscribe((data) => {
      if (data instanceof ActivationStart) {
         if(data.snapshot.data.heading && data.snapshot.data.heading !== "") {
          this.heading = data.snapshot.data.heading;
        }
      }
    });

    this.subscription = this.store.select(VertragState.auth).subscribe(vertrag => {
      // console.log(vertrag.primeType);
      this.page = vertrag.primeType;
    });
  }

  ngAfterViewInit() {
    // this.contractDataService.currentPage.subscribe(data => {
    //   this.heading = "";
    //   this.page = data.page;
    // });

    this.auth.currentUser.subscribe((user : any) => {
      if(user) {
        this.user = user;
        this.userRole = user.userRole;
        this.isUserLoggedIn = true;
      } else {
        this.user = null;
        this.isUserLoggedIn = false;
        this.userRole = 0;
      }
      // this.page = data.user;
    });
  }

  redirect() {
    const message = `Wollen Sie die bisher eingetragenen Daten zurücksetzen?`;
    const dialogData = new ConfirmDialogModel("Aktion bestätigen", message);

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: "400px",
      data: dialogData
    });

    dialogRef.afterClosed().subscribe(dialogResult => {
      if(dialogResult) {
        this.contractDataService.clearContractData();
        this.router.navigate(['/start'], { queryParams: { reset: true }});
      }
    });
  }

  redirect2() {
    const message = `Wollen Sie die bisher eingetragenen Daten zurücksetzen?`;
    const dialogData = new ConfirmDialogModel("Aktion bestätigen", message);

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: "400px",
      data: dialogData
    });

    dialogRef.afterClosed().subscribe(dialogResult => {
      if(dialogResult) {
        this.contractDataService.clearContractData();
        this.router.navigate(['/new-contract'], { queryParams: { reset: true }});
      }
    });
  }

  logOut() {
    this.auth.logOut();
    this.router.navigate(['/auth/login']);
  }

  title(t) {
    if(t == 'Users') {
      return "Ansprechpartner";
    } else if(t == 'Lawyers') {
      return "Benutzer";
    } else if(t == 'Add lawyer') {
      return "Benutzer";
    } else {
      return "Ansprechpartner";
    }

  }

}
