import { Component, OnInit } from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-dlg-geldlasten',
  templateUrl: './dlg-geldlasten.component.html',
  styleUrls: ['./dlg-geldlasten.component.css']
})
export class DlgGeldlastenComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<DlgGeldlastenComponent>) { }

  ngOnInit() {
  }

  closeDlg(): void {
    this.dialogRef.close();
  }

}
