import { Component, OnInit } from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-dlg-ubbl-daten-z-ls',
  templateUrl: './dlg-ubbl-daten-z-ls.component.html',
  styleUrls: ['./dlg-ubbl-daten-z-ls.component.css']
})
export class DlgUbblDatenZLsComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<DlgUbblDatenZLsComponent>) { }

  ngOnInit() {
  }

  closeDlg(): void {
    this.dialogRef.close();
  }
}
