import { Component, OnInit } from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-dlg-loeschen-sonstiger-laster',
  templateUrl: './dlg-loeschen-sonstiger-laster.component.html',
  styleUrls: ['./dlg-loeschen-sonstiger-laster.component.css']
})
export class DlgLoeschenSonstigerLasterComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<DlgLoeschenSonstigerLasterComponent>) { }

  ngOnInit() {
  }

  closeDlg(): void {
    this.dialogRef.close();
  }

}
