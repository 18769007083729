import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { faArrowLeft, faArrowRight, faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { ContractDataService } from '../shared/contractData.service';

@Component({
  selector: 'app-dlg-vorschau-personaldata',
  templateUrl: './dlg-vorschau-personaldata.component.html',
  styleUrls: ['./dlg-vorschau-personaldata.component.css']
})
export class DlgVorschauPersonaldataComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<DlgVorschauPersonaldataComponent>,
    @Inject(ContractDataService)
    private contractDataService: ContractDataService) { }

  public contractData;
  faArrowRight = faArrowRight;
  faArrowLeft = faArrowLeft;
  ngOnInit() {
    this.contractData = this.contractDataService.getContractData();
    console.log(this.contractData);
  }

  closeDlg(): void {
    this.dialogRef.close(false);
  }

  closeOKDlg(): void {
    this.dialogRef.close(true);
  }

}
