<div class="container">
  <h2> Bitte gewünschte Option auswählen </h2>
  <mat-grid-list [cols]="breakpoint" rowHeight="150px">
    <mat-grid-tile class="start-grid">
      <mat-card>
        <h3>Mietvertrag</h3>
        <button mat-raised-button color="primary" (click)="gotoMiet()">Start</button>
      </mat-card>
    </mat-grid-tile>
    <mat-grid-tile class="matFigurepad15">
      <mat-card>
        <h3>Kaufvertrag</h3>
        <button mat-raised-button color="primary" (click)="gotoKauf()">Start</button>
      </mat-card>
    </mat-grid-tile>
    <mat-grid-tile class="start-grid">
      <mat-card>
        <h3>Schenkung</h3>
        <button mat-raised-button color="primary" (click)="gotoSchenk()">Start</button>
      </mat-card>
    </mat-grid-tile>
    <mat-grid-tile class="start-grid">
      <mat-card>
        <h3>Kaufanbot</h3>
        <button mat-raised-button color="primary" (click)="gotoAnbot()">Start</button>
      </mat-card>
    </mat-grid-tile>
    <!-- <mat-grid-tile class="matFigurepad15 d-none">
      <mat-card>
        <h3>KFZ-Kaufvertrag</h3>
        <button mat-raised-button color="primary" (click)="gotokfz()">Start</button>
      </mat-card>
    </mat-grid-tile> -->
  </mat-grid-list>
  <br/><br/>
</div>
