import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { CurrencyPipe, DatePipe } from '@angular/common';
import { Observable, BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';
import { map, retry } from 'rxjs/operators';
import { LooseCurrencyPipe } from './looseCurrency.pipe';
import * as _ from 'lodash';
import { environment } from 'src/environments/environment';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';

@Injectable({
    providedIn: 'root'
})

export class SharedService {

  private dataSource = new BehaviorSubject({ page: 'kauf' });
  horizontalPosition: MatSnackBarHorizontalPosition = 'end';
  verticalPosition: MatSnackBarVerticalPosition = 'top';

    constructor(
    private http: HttpClient,
    private _snackBar: MatSnackBar,
  ) { }

  private httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };

  openSnackBar(data) {
    this._snackBar.open(data, "" , {
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
      duration: 2 * 1000
    });
  }

  getApi(url) {
    return this.http
      .get(environment.NodeAppUrl + "/" + url, this.httpOptions);
  }

  postApi(url, data) {
    return this.http
      .post(environment.NodeAppUrl + "/" + url,data, this.httpOptions);
  }

  postApiFile(url, data) {
    return this.http
      .post(environment.NodeAppUrl + "/" + url,data);
  }

  uploadFile(url, fileToUpload: File) {
    const formData: FormData = new FormData();
    formData.append('file', fileToUpload, fileToUpload.name);
    return this.http
      .post(environment.NodeAppUrl + "/" + url, formData);
  }

  deleteApi(url) {
    return this.http
      .delete(environment.NodeAppUrl + "/" + url, this.httpOptions);
  }



}
