import { Component, Inject, OnInit, Input } from '@angular/core';
import {
  UntypedFormArray,
  UntypedFormBuilder,
  FormControl,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { ContractDataService } from '../shared/contractData.service';
import { faTimes, faSave } from '@fortawesome/free-solid-svg-icons';
import { MatSnackBar } from '@angular/material/snack-bar';

export interface Variant {
  value: string;
  viewValue: string;
  toolTip: string;
}

@Component({
  selector: 'app-sonstige-rechte-logic',
  templateUrl: './sonstige-rechte-logic.component.html',
  styleUrls: ['./sonstige-rechte-logic.component.css']
})
export class SonstigeRechteLogicComponent implements OnInit {
  constructor(private formBuilder: UntypedFormBuilder,
    @Inject(ContractDataService)
    private contractDataService: ContractDataService,
    public snackBar: MatSnackBar
  ) { }

  @Input() IDIN: string;
  @Input() submitted: boolean = false;

  faTimes = faTimes;
  faSave = faSave;

  contractData;
  public primeType = undefined;

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 4000,
    });
  }

  ngOnInit() {
    this.contractData = this.contractDataService.getContractData();
    console.log('this.contractData');
    console.log(this.contractData);
    this.primeType = this.contractDataService.getContractPrimeType();

    this.initRightData();
    this.setNaheAngehorigeOptionen();
    this.initSonstigeRechte();


    if (this.contractData.wegLogic) {
      switch (this.IDIN) {
        case 'l1':
          if (this.contractData.wegLogic.lssonstigeRechte) {
            if (this.contractData.wegLogic.lssonstigeRechte.frageSonstigeRechte) this.SonstigeRechteData = this.contractData.wegLogic.lssonstigeRechte;
          }
          break;

        case 'l2':
          if (this.contractData.wegLogic.lsl2sonstigeRechte) {
            if (this.contractData.wegLogic.lsl2sonstigeRechte.frageSonstigeRechte) this.SonstigeRechteData = this.contractData.wegLogic.lsl2sonstigeRechte;
          }

          break;

        case 'l3':
          if (this.contractData.wegLogic.lsl3sonstigeRechte) {
            if (this.contractData.wegLogic.lsl3sonstigeRechte.frageSonstigeRechte) this.SonstigeRechteData = this.contractData.wegLogic.lsl3sonstigeRechte;
          }
          break;

        case 'l4':
          if (this.contractData.wegLogic.lsl4sonstigeRechte) {
            if (this.contractData.wegLogic.lsl4sonstigeRechte.frageSonstigeRechte) this.SonstigeRechteData = this.contractData.wegLogic.lsl4sonstigeRechte;
          }
          break;
        case 'WEG':
          if (this.contractData.wegLogic.sonstigeRechteWEG) {
            if (this.contractData.wegLogic.sonstigeRechteWEG.frageSonstigeRechte) this.SonstigeRechteData = this.contractData.wegLogic.sonstigeRechteWEG;
          }
          break;
      }
    }
  }

  public getData() {
    console.log(this.SonstigeRechteData);
    return this.SonstigeRechteData;
  }

  //#region Gmaüs zum Radio fülla
  public uebertragsumfangArten: string[] = [
    'gesamte Liegenschaft',
    'einzelne Grundstücke'
  ];

  frageSonstigeRechteOptionen: string[] = ['Ja', 'Nein'];

  public wohnrechtOptionen: Variant[] = [
    {
      value: 'gesamte',
      viewValue: 'Das Wohnrecht umfasst den gesamten Kaufgegenstand',
      toolTip: 'Lorem ipsum...'
    },
    {
      value: 'teile',
      viewValue: 'Das Wohnrecht umfasst nur gewisse Teile des Kaufgegenstandes',
      toolTip: 'Lorem ipsum...'
    },
  ];

  public nutzungsRechtOptionen: Variant[] = [
    {
      value: 'gesamte',
      viewValue: 'Das Nutzungsrecht umfasst den gesamten Kaufgegenstand',
      toolTip: 'Lorem ipsum...'
    },
    {
      value: 'teile',
      viewValue: 'Das Nutzungsrecht umfasst nur gewisse Teile des Kaufgegenstandes',
      toolTip: 'Lorem ipsum...'
    },
  ];

  public fruchtgenussrechtOptionen: Variant[] = [
    {
      value: 'gesamte',
      viewValue: 'Das Fruchtgenussrecht umfasst den gesamten Kaufgegenstand',
      toolTip: 'Lorem ipsum...'
    },
    {
      value: 'teile',
      viewValue: 'Das Fruchgenussrecht umfasst nur gewisse Teile des Kaufgegenstandes',
      toolTip: 'Lorem ipsum...'
    },
  ];

  vorkaufsrechtOptionen: Variant[] = [
    {
      value: '1072abgb',
      viewValue: 'Vorkaufsrecht gem. § 1072 ABGB',
      toolTip: 'Lorem ipsum...'
    },
    {
      value: 'alle',
      viewValue: 'Vorkaufsrecht für alle Veräußerungsarten',
      toolTip: 'Lorem ipsum...'
    },
  ];


  vorkaufsrechtPreisOptionen: Variant[] = [
    {
      value: 'schaetzpreis',
      viewValue: 'Schätzpreis zum Eintritt der Veräußerung',
      toolTip: 'Lorem ipsum...'
    },
    {
      value: 'fixpreis',
      viewValue: 'Fixer Preis eingeben',
      toolTip: 'Lorem ipsum...'
    },
  ];

  wiederkaufsrechtPreisOptionen: Variant[] = [
    {
      value: 'schaetzpreis',
      viewValue: 'Schätzpreis zum Eintritt der Veräußerung',
      toolTip: 'Lorem ipsum...'
    },
    {
      value: 'fixpreis',
      viewValue: 'Fixer Preis eingeben',
      toolTip: 'Lorem ipsum...'
    },
  ];

  belastungsUndVeraeusserungsverbotOptionen: Variant[] = [
    {
      value: 'belastungUndVerauesserung',
      viewValue: 'Belastungs- und Veräusserungsverbot',
      toolTip: 'Lorem ipsum...'
    },
    {
      value: 'belastung',
      viewValue: 'Belastungsverbot',
      toolTip: 'Lorem ipsum...'
    },
    {
      value: 'veraeusserung',
      viewValue: 'Veräusserungsverbot',
      toolTip: 'Lorem ipsum...'
    },
  ];

  naheAngehorigeOptionen: Variant[] = [
    {
      value: 'Ja',
      viewValue: 'Ja',
      toolTip: 'Lorem ipsum...'
    },
    {
      value: 'Nein',
      viewValue: 'Nein',
      toolTip: 'Lorem ipsum...'
    },
  ];
  private sonstigeRechteOptionen: Variant[] = [
    {
      value: 'wohnrecht',
      viewValue: 'Wohnrecht',
      toolTip: 'Unbefristetes Wohnrecht in der Wohnung'
    },
    {
      value: 'fruchtgenussrecht',
      viewValue: 'Fruchtgenussrecht',
      toolTip: 'Lorem ipsum dolor....'
    },
    {
      value: 'belastungUndVeraeusserungsverbot',
      viewValue: 'Belastungs- und Veräußerungsverbot',
      toolTip: 'Lorem ipsum dolor....'
    },
    {
      value: 'belastungsverbot',
      viewValue: 'Belastungsverbot',
      toolTip: 'Lorem ipsum dolor....'
    },
    {
      value: 'veraeußerungsverbot',
      viewValue: 'Veräußerungsverbot',
      toolTip: 'Lorem ipsum dolor....'
    },
    {
      value: 'vorkaufsrecht',
      viewValue: 'Vorkaufsrecht',
      toolTip: 'Lorem ipsum dolor....'
    },
    {
      value: 'wiederkaufsrecht',
      viewValue: 'Wiederkaufsrecht',
      toolTip: 'Lorem ipsum dolor....'
    },
    {
      value: 'dienstbarkeitsrecht',
      viewValue: 'Dienstbarkeitsrecht',
      toolTip: 'Lorem ipsum dolor....'
    }
  ];
  //#endregion

  public SonstigeRechteData = {
    frageSonstigeRechte: undefined,
    frageRechteDritter: undefined,
    frageWohnrecht: undefined,
    frageFruchtgenussrecht: undefined,
    frageBelastungsverbot: undefined,
    frageVorkaufsrecht: undefined,
    frageWiederkaufsrecht: undefined,
    sonstigeRechte: {},
    wohnrecht: {
      option: undefined,
      freitext: undefined,
      personArray: [],
      companyArray: []
    },
    fruchtgenussrecht: {
      option: undefined,
      freitext: undefined,
      personArray: [],
      companyArray: [],
    },
    wiederkaufsrecht: {
      option: undefined,
      personArray: [],
      companyArray: [],
      preisoption: undefined,
      preisinEUR: undefined,
      text: undefined
    },
    vorkaufsrecht: {
      option: undefined,
      personArray: [],
      companyArray: [],
      preisoption: undefined,
      preisinEUR: undefined
    },
    belastungsUndVeraeusserungsverbot: {
      option: undefined,
      personArray: [],
      companyArray: [],
      naheAngehoerige: undefined,
    },
    rightPersonArray: [],
    rightCompanyArray: [],
  };

  RightForm: UntypedFormGroup;

  //#region Remove, Add, get... Rechte Dritter
  removeRightCompany(index: number) {
    this.rightsCompanyFormArray.removeAt(index);
  }

  removeRightPrivate(index: number) {
    this.rightsPrivateFormArray.removeAt(index);
  }
  private initBuyerPrivateEmpty(): UntypedFormGroup {
    return this.formBuilder.group({
      firstname: ['', Validators.required],
      lastname: ['', Validators.required],
      formatedBirthday: ['', Validators.required],
      address: ['', Validators.required],
      city: ['', Validators.required],
      postalCode: ['', Validators.required]
    });
  }

  private initBuyerCompanyEmpty(): UntypedFormGroup {
    return this.formBuilder.group({
      companyName: ['', Validators.required],
      companiesRegisterNumber: ['', Validators.required],
      address: ['', Validators.required],
      city: ['', Validators.required],
      postalCode: ['', Validators.required]
    });
  }

  addRightOwnerComplex(buyerType): void {
    switch (buyerType) {
      case 'person': {
        this.rightsPrivateFormArray.push(this.initBuyerPrivateEmpty());

        break;
      }
      case 'company': {
        this.rightsCompanyFormArray.push(this.initBuyerCompanyEmpty());
      }
    }
    console.log('Added Dritter');
  }

  refreshRightOwners() {
    console.log('Rechteform:');
    console.log(this.RightForm.value);

    if (this.RightForm.valid) {
      /*   rPrivateTemp.forEach( data => {
           data.onlyRightOwner = true;
         });

         rCompanyTemp.forEach( data => {
           data.onlyRightOwner = true;
         });
     */
      this.contractData.masterData.dritte.rightPersonList = this.contractData.masterData.dritte.rightPersonList.concat(this.RightForm.value.rightsPrivate);
      this.contractData.masterData.dritte.rightCompanyList = this.contractData.masterData.dritte.rightCompanyList.concat(this.RightForm.value.rightsCompany);
      this.SonstigeRechteData.rightPersonArray = this.RightForm.value.rightsPrivate;
      this.SonstigeRechteData.rightCompanyArray = this.RightForm.value.rightsCompany;
      console.log('Dritte');
      console.log(this.contractData.masterData.dritte);

      //Bugfix Linus 8.6.
      // this.wegLogicData.rightCompanyArray = this.rightCompanyList;
      // this.wegLogicData.rightPersonArray = this.rightPersonList;

      this.setNaheAngehorigeOptionen();
      this.openSnackBar('Erfolgreich gespeichert.', 'schließen');
    }
    else {
      console.log("Rightform invalid");
      this.openSnackBar('Bitte alles ausfüllen um zu speichern.', 'schließen');
    }

  }

  get rightsPrivateFormArray(): UntypedFormArray {
    return this.RightForm.get('rightsPrivate') as UntypedFormArray;
  }

  get rightsCompanyFormArray(): UntypedFormArray {
    return this.RightForm.get('rightsCompany') as UntypedFormArray;
  }

  public setNaheAngehorigeOptionen() {

    if (this.contractData.masterData.dritte.rightPersonList.length > 1) {

      this.naheAngehorigeOptionen = [
        {
          value: 'Ja',
          viewValue: 'Ja',
          toolTip: 'Lorem ipsum...'
        },
        {
          value: 'Nein',
          viewValue: 'Nein',
          toolTip: 'Lorem ipsum...'
        },
        {
          value: 'teilweise',
          viewValue: 'teilweise',
          toolTip: 'Lorem ipsum...'
        },
      ];
    }

  }
  //#endregion

  public getValidation() {

    if (this.SonstigeRechteData.frageSonstigeRechte === "Nein") {
      console.log("Validation: No sonstigeRechte: true");
      return true;
    }
    else {
      let validalles = this.getValidationAlles();
      console.log("Validation: rightsCompanyFormArray, RightsPrivate..., alles");
      console.log(this.rightsCompanyFormArray.valid);
      console.log(this.rightsPrivateFormArray.valid);
      console.log(validalles);
      return this.rightsCompanyFormArray.valid && this.rightsPrivateFormArray.valid && validalles;
    }
  }

  //Schlimmsta Würg-around sit 19 vor am Kriag, goht aba ned andrsch
  private getValidationAlles(): boolean {
    console.log("getValidationAlles: Data");
    console.log(this.SonstigeRechteData);

    if (this.SonstigeRechteData.frageSonstigeRechte) {
      if (this.SonstigeRechteData.frageSonstigeRechte === "Ja") {
        if (!this.SonstigeRechteData.frageRechteDritter) return false;

        if (this.SonstigeRechteData.frageVorkaufsrecht) {
          if (this.SonstigeRechteData.frageVorkaufsrecht == "Ja") {
            if (this.SonstigeRechteData.vorkaufsrecht) {
              let sum = this.SonstigeRechteData.vorkaufsrecht.companyArray.length + this.SonstigeRechteData.vorkaufsrecht.personArray.length;
              if (sum == 0) return false;

              if (this.SonstigeRechteData.vorkaufsrecht.option) {
                if (this.SonstigeRechteData.vorkaufsrecht.option === "alle") {
                  if (this.SonstigeRechteData.vorkaufsrecht.preisoption) {
                    if (this.SonstigeRechteData.vorkaufsrecht.preisoption === "fixpreis") {
                      if (!this.SonstigeRechteData.vorkaufsrecht.preisinEUR) return false;
                    }
                  }
                  else return false;
                }
              }
              else return false;
            }
            else return false;
          }
        }
        else return false;

        if (this.SonstigeRechteData.frageBelastungsverbot) {
          if (this.SonstigeRechteData.frageBelastungsverbot === "Ja") {
            if (this.SonstigeRechteData.belastungsUndVeraeusserungsverbot) {
              let sum = this.SonstigeRechteData.belastungsUndVeraeusserungsverbot.companyArray.length +
                this.SonstigeRechteData.belastungsUndVeraeusserungsverbot.personArray.length;
              if (sum == 0) return false;

              if (!this.SonstigeRechteData.belastungsUndVeraeusserungsverbot.naheAngehoerige) return false;
              if (!this.SonstigeRechteData.belastungsUndVeraeusserungsverbot.option) return false;
            }
          }
        }
        else return false;

        if (this.SonstigeRechteData.frageFruchtgenussrecht) {
          if (this.SonstigeRechteData.frageFruchtgenussrecht === "Ja") {
            if (this.SonstigeRechteData.fruchtgenussrecht) {
              let sum = this.SonstigeRechteData.fruchtgenussrecht.companyArray.length + this.SonstigeRechteData.fruchtgenussrecht.personArray.length;
              if (sum == 0) return false;

              if (this.SonstigeRechteData.fruchtgenussrecht.option) {
                if (this.SonstigeRechteData.fruchtgenussrecht.option != "gesamte") {
                  if (!this.SonstigeRechteData.fruchtgenussrecht.freitext) return false;
                }
              }
              else return false;
            }
          }
        }
        else return false;

        if (this.SonstigeRechteData.frageWiederkaufsrecht) {
          if (this.SonstigeRechteData.frageWiederkaufsrecht === "Ja") {
            if (this.SonstigeRechteData.wiederkaufsrecht) {
              let sum = this.SonstigeRechteData.wiederkaufsrecht.companyArray.length + this.SonstigeRechteData.wiederkaufsrecht.personArray.length;
              if (sum == 0) return false;

              if (!this.SonstigeRechteData.wiederkaufsrecht.text) return false;

              if (this.SonstigeRechteData.wiederkaufsrecht.preisoption) {
                if (this.SonstigeRechteData.wiederkaufsrecht.preisoption !== "schaetzpreis") {
                  if (!this.SonstigeRechteData.wiederkaufsrecht.preisinEUR) return false;
                }
              }
              else return false;
            }
            else return false;
          }
        }
        else return false;

        if (this.SonstigeRechteData.frageWohnrecht) {
          if (this.SonstigeRechteData.frageWohnrecht === "Ja") {
            if (this.SonstigeRechteData.wohnrecht) {
              let sum = this.SonstigeRechteData.wohnrecht.personArray.length + this.SonstigeRechteData.wohnrecht.companyArray.length;
              if (sum == 0) return false;

              if (this.SonstigeRechteData.wohnrecht.option) {
                if (this.SonstigeRechteData.wohnrecht.option !== "gesamte") {
                  if (!this.SonstigeRechteData.wohnrecht.freitext) return false;
                }
              }
            }
          }
        }
        else return false;
      }
    }
    else return false;

    console.log("SonstigeRechte ist valid");
    return true;
  }

  initRightData() {
    this.RightForm = this.formBuilder.group({
      rightsPrivate: this.formBuilder.array([]),
      rightsCompany: this.formBuilder.array([])
    });
  }

  initSonstigeRechte() {
    this.sonstigeRechteOptionen.forEach(e => {

      const temp = {
        text: undefined,
        person: undefined
      };

      this.SonstigeRechteData.sonstigeRechte[e.value] = temp;
    });
  }
}
