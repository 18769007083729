<mat-dialog-content>
  <div class="custom-dlg-head">
  </div>
  <div class="custom-dlg-body">
    <h1>Daten zur Liegenschaft&nbsp;</h1>
    <p>
      <img src="/assets/daten_liegenschaft.jpg" alt="" width="550" />
    </p>
  </div>
  <div class="custom-dlg-footer">
    <button mat-raised-button (click)="closeDlg()">OK</button>
  </div>
</mat-dialog-content>
