import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { SharedService } from '../shared/shared.service';
import { MatSort, SortDirection} from '@angular/material/sort';
import { ContractDataService } from '../shared/contractData.service';
import { MatTableDataSource } from '@angular/material/table';
import { DataSource } from '@angular/cdk/table';
import { MatPaginator} from '@angular/material/paginator';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';

@Component({
  selector: 'app-contracts',
  templateUrl: './contracts.component.html',
  styleUrls: ['./contracts.component.css']
})
export class ContractsComponent implements OnInit {

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private shared : SharedService,
    @Inject(ContractDataService) private contractDataService: ContractDataService
  ) { }

  contracts:any = [];
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  users:any = [];
  user = 0;
  resultsLength = 0;
  perPage = 10;
  pageIndex = 0;
  sortKey = 'createdAt';
  searchText = '';
  sortDirection = 'desc';
  displayedColumns: string[] = [
    'contractID',
    'createdAt',
    'contactId',
    'contactEmail',
    'postID',
    'status',
    'contractType',
    'userID',
    'action'
  ];

  displayedColumnLabels = { _id : 'Id',
    contractID : 'Vertrags ID',
    contactId : 'Ansprechpartner',
    contactEmail : 'Mail',
    postID : 'Post ID',
    status : 'Status',
    contractType : 'Vertragsart',
    userID : 'Benutzer',
    createdAt : 'Erstellungsdatum',
    action : 'Optionen' };

  searchTextUpdate = new Subject<string>();
  loading = false;
  contractType = "all";
  status = "all";

  ngOnInit(): void {
    this.user = this.route.snapshot.queryParams['user'];
    this.contractDataService.changePage({ page: 'contracts'});
    this.list(this.user);
    this.getUsers();
    setTimeout(() => {
      // this.list();
    }, 5000);

    this.searchTextUpdate.pipe(
      debounceTime(400),
      distinctUntilChanged())
      .subscribe(v => {
        console.log(v);
        this.loading = true;
        this.list(this.user);
      });

  }

  list(user = null) {
    this.loading = true;
    this.shared.getApi((user ? 'contract/list/' + user : 'contract/list') + '?page=' + this.pageIndex + '&sort=' + this.sortKey  + '&sortDirection=' + this.sortDirection + '&searchText=' + this.searchText + '&contractType=' + this.contractType + '&status=' + this.status + '&perPage=' + this.perPage)
      .subscribe((res:any) => {
        this.resultsLength = res.count;
        this.contracts     = new MatTableDataSource(res.data);
        this.loading = false;
      }, (err:any) => {

      });
  }

  getUsers() {
    this.shared.getApi('contacts/all')
      .subscribe((res:any) => {
        this.users = res;
      }, (err:any) => {

      });
  }

  editContract(id) {
    this.shared.openSnackBar("Loading Edit screen");
    this.shared.getApi('contract/' + id)
      .subscribe((res:any) => {
        console.log(res);
        console.log(res.acf);
        console.log(JSON.parse(res.acf.json));
        localStorage.removeItem('contractData');
        localStorage.removeItem('primeType');

        try{
          let json = JSON.parse(res.acf.json);
          if(json.wegLogic && json.wegLogic.lastenLoeschenCLfdNr && json.wegLogic.lastenLoeschenCLfdNr.length) {
            let lastenclnr = json.wegLogic.lastenLoeschenCLfdNr;
            console.log(lastenclnr);
            if(lastenclnr) {
              let clnrs = [];
              for(let clnr of lastenclnr) {
                console.log(clnr);
                if(clnr && clnr['CLNR'] && clnr['CLNR'] != "") {
                  let cla = clnr['CLNR'].split(" in EZ ");
                  if(cla[0] && cla[0] != "") {
                  	clnrs.push(cla[0]);
                  }
                }
              }
              json.wegLogic.lastenLoeschenCLfdNr = clnrs;
            }
          }

          if(json.wegLogic && json.wegLogic) {
            var einlageZahlArs = [];
            var grundstuecksnummerAr = [];
            for (let index = 0; index < 10; index++) {
              var title = "";
              if(index == 0) {
                title = 'immodetail';
              } else {
                title = 'immodetail' + index;
              }
              // if(json.wegLogic[title]) {
              //   var immo = json.wegLogic[title];
              //   console.log(immo);
              //   if(immo.einlageZahlAr && immo.einlageZahlAr.length)
              //     einlageZahlArs.push(immo['einlageZahlAr'][0]);
              //
              //   if(immo.grundstuecksnummerAr && immo.grundstuecksnummerAr.length)
              //     grundstuecksnummerAr.push(immo['grundstuecksnummerAr'][0]);
              // }

              // if(index > 0) {
              //   if(json['wegLogic'][title]) {
              //     delete json['wegLogic'][title];
              //   }
              // }
            }

            // json['wegLogic']['immodetail']['einlageZahlAr']        = JSON.parse(JSON.stringify(einlageZahlArs));
            // json['wegLogic']['immodetail']['grundstuecksnummerAr'] = JSON.parse(JSON.stringify(grundstuecksnummerAr));
          }

          console.log("json");
          console.log(json);
          console.log(einlageZahlArs);
          console.log(grundstuecksnummerAr);

          if(!json.wegLogic.admin.faggVerzicht)
          json['wegLogic']['admin'] = json.admin;

          localStorage.setItem('contractData', JSON.stringify(json));
          localStorage.setItem('contractDataSafe', JSON.stringify(json));
          var primeType = "";
          if(json.primeType) {
            primeType = json.primeType;
          } else if(json.contactData.primeType) {
            primeType = json.contactData.primeType;
          }

          localStorage.setItem('primeType', primeType);
          document.body.className = "";


          if(primeType == 'kauf')
            this.router.navigate(['kaufvertrag/personaldata']);

          else if(primeType == 'schenkung')
            this.router.navigate(['schenkung/personaldata']);

          else if(primeType == 'miet')
            this.router.navigate(['mietvertrag/personaldata']);

          else
            this.router.navigate(['kaufvertrag/personaldata']);
        }
        catch(e) {
          console.log(e);
        }
      }, (err:any) => {
      });
  }

  downloadContract(id, link) {
    this.shared.getApi('contract/' + id)
      .subscribe((res:any) => {
        console.log(res.acf[link]);
        window.open(res.acf[link], "_blank");
      }, (err:any) => {

      });
  }

  filter(e) {
    this.user = e.value;
    this.list(e.value);
  }

  filterContractType(e) {
    this.contractType = e.value;
    this.list(this.user);
  }

  filterStatus(e) {
    this.status = e.value;
    this.list(this.user);
  }

  search() {
    console.log(this.searchText);
    // this.user = e.value;
    this.list(this.user);
  }

  handlePageEvent(event) {
    console.log(event);
    this.pageIndex = event.pageIndex;
    this.perPage = event.pageSize;
    this.list(this.user);
  }

  sortChange(event) {
    this.sortKey = event.active;
    this.sortDirection = event.direction;
    this.list(this.user);
    console.log(event);
  }

}
