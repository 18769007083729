import { OnDestroy, AfterViewInit, Component, HostListener, Inject, OnInit, ElementRef, } from '@angular/core';
import { UntypedFormBuilder, UntypedFormArray, UntypedFormGroup, Validators, } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatRadioChange } from '@angular/material/radio';
import { Router, ActivatedRoute } from '@angular/router';
import { ContractDataService } from '../shared/contractData.service';
import { faTimes, faInfo, faArrowRight, faUniversity, faQuestion } from '@fortawesome/free-solid-svg-icons';
import { DlgImmoSteuerComponent } from '../dlg-immo-steuer/dlg-immo-steuer.component';
import { DlgWEGComponent } from '../dlg-weg/dlg-weg.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as _ from 'lodash';
import { DlgVorschauPersonaldataComponent } from '../dlg-vorschau-personaldata/dlg-vorschau-personaldata.component';
import { SaveDlgComponent } from '../save-dlg/save-dlg.component';
import { interval, Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import * as data from '../../../data.json';
import { SharedService } from '../shared/shared.service';
import { AuthService } from '../shared/auth.service';

// TODO: Unterscheidung gewerblich / privat bei Verkäufer & Käufer -> bei Firmen statt Gebdatum Firmenbuchnummmer, nur Firmennamme

export interface Variant {
  value: string;
  image: string;
  viewValue: string;
  toolTip: string;
}
export interface SellerPerson {
  fNAme: string;
  llName: string;
  street: string;
  postalCode: number;
  town: string;
  birthDate: string;
}
export interface BuyerPerson {
  fNAme: string;
  llName: string;
  street: string;
  postalCode: number;
  town: string;
  birthDate: string;
}
export interface SellerCompany {
  Name: string;
  llName: string;
  street: string;
  postalCode: number;
  town: string;
}
export interface BuyerCompany {
  Name: string;
  llName: string;
  street: string;
  postalCode: number;
  town: string;
}

@Component({
  selector: 'app-masterdata',
  templateUrl: './masterdata.component.html',
  styleUrls: ['./masterdata.component.css'],
})
export class MasterdataComponent implements OnInit, AfterViewInit, OnDestroy {
  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: any) {
    this.saveDB();
  }

  testValues: any = (data as any).default;
  masterForm: UntypedFormGroup;
  selectedSellerType: string;
  selectedBuyerType: string;
  faTimes = faTimes;
  faInfoCircle = faInfo;
  faArrowRight = faArrowRight;
  faUniversity = faQuestion;
  submitted = false;
  variantTouched = false;
  breakpoint = (window.innerWidth <= 768) ? 1 : 2;
  rowHeight = (window.innerWidth <= 768) ? '140px' : '75px';

  public kaeuferanteileOptionen: string[] = ['Ja', 'Nein'];
  public AnzahlLSOptionen: string[] = ['1', '2', '3', '4'];
  primeType;
  isUserLoggedIn;

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 4000,
      panelClass: 'custom-snackBar',
    });
  }

  ngAfterViewInit() {
    let scrolloptions = { top: 0, left: 0 }
    window.scrollTo(scrolloptions);
  }

  variants: Variant[] = [
    {
      value: 'WEG',
      viewValue: 'Wohnungseigentum',
      image: 'building',
      toolTip: 'Eigentumswohnung oder Geschäftsräumlichkeiten'
    },
    {
      value: 'BBL',
      viewValue: 'bebaute Liegenschaft',
      image: 'house',
      toolTip: 'Ein Grundstück inkl. Gebäude - z.B. Haus oder Geschäftslokal'
    },
    {
      value: 'UBL',
      viewValue: 'unbebaute Liegenschaft',
      image: 'land',
      toolTip: 'Ein Grundstück mit Baurecht'
    }
  ];

  mietvariants: Variant[] = [
    {
      value: 'WEG',
      image: '',
      viewValue: 'Mietobjekt im Wohnungseigentum',
      toolTip: 'Eigentumswohnung oder Geschäftsräumlichkeiten'
    },
    {
      value: 'BBL',
      image: '',
      viewValue: 'Mietobjekt ohne Wohnungseigentum',
      toolTip: 'Ein Grundstück inkl. Gebäude - z.B. Haus oder Geschäftslokal'
    }
  ];

  kauferanteiles: Variant[] = [
    {
      value: 'Ja',
      image: '',
      viewValue: 'Ja',
      toolTip: 'Käufer kaufen zu gleichen Anteilen'
    },
    {
      value: 'Nein',
      image: '',
      viewValue: 'Nein',
      toolTip: 'Käufer kaufen zu ungleichen Anteilen'
    },
  ];

  AnzahlLSs: Variant[] = [
    {
      value: '1',
      image: '',
      viewValue: '1',
      toolTip: 'eine Liegenschaft'
    },
    {
      value: '2',
      image: '',
      viewValue: '2',
      toolTip: 'zwei Liegenschaften'
    },
    {
      value: '3',
      image: '',
      viewValue: '3',
      toolTip: 'drei Liegenschaften'
    },
    {
      value: '4',
      image: '',
      viewValue: '4',
      toolTip: 'vier Liegenschaften'
    }
  ];

  // Geht das schöner zum initalisieren?
  variant: Variant = new (class implements Variant {
    toolTip: string;
    value: string;
    viewValue: string;
    image: string;
  })();

  kaeuferanteile: Variant = new (class implements Variant {
    toolTip: string;
    value: string;
    viewValue: string;
    image: string;
  })();

  AnzahlLS: number = 1;
  moreLSBT: boolean = true;
  lessLSBT: boolean = false;

  contactData = {
    eMail: undefined,
    firstName: undefined,
    lastName: undefined,
    contactPerson: undefined,
    phoneNumber: undefined,
    contractOption: undefined,
    eMailCC: undefined,
    estate: undefined,
    kaeuferanteile: undefined,
    AnzahlLS: undefined
  };

  contractPartnerTypes: Variant[] = [
    {
      value: 'person',
      image: '',
      viewValue: 'Privatperson',
      toolTip: 'Natürliche Einzelperson'
    },
    {
      value: 'company',
      image: '',
      viewValue: 'Firma',
      toolTip: 'Firma oder juristische Person'
    },
    {
      value: 'estate',
      image: '',
      viewValue: 'Verlassenschaft',
      toolTip: 'Verlassenschaft'
    }
  ];

  buyerContractPartnerType: Variant = new (class implements Variant {
    toolTip: string;
    value: string;
    viewValue: string;
    image: string;
  })();

  sellerContractPartnerType: Variant = new (class implements Variant {
    toolTip: string;
    value: string;
    viewValue: string;
    image: string;
  })();

  timerSub: Subscription;
  public schenkung: boolean;
  public miet: boolean;
  public kfz: boolean;
  public OneBuyer: boolean;
  public kaeuferanteileBOOL: boolean;
  public SellerAndBuyerBOOL: boolean;
  public contractData: any;
  public UBLorBBL: boolean;
  public userId: any;
  users : any = [];

  constructor(
    private auth : AuthService,
    private shared : SharedService,
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    @Inject(ContractDataService)
    private contractDataService: ContractDataService,
    public WEGDlg: MatDialog,
    public VorschauDlg: MatDialog,
    public SaveDlg: MatDialog,
    public snackBar: MatSnackBar,
    private el: ElementRef,
    private route:ActivatedRoute
  ) {
    this.contractDataService.changePage({ page : route.snapshot.data.type ? route.snapshot.data.type : localStorage.getItem('page') });
    console.log(route.snapshot.data.type);
    switch (route.snapshot.data.type) {
      case 'kauf':
        this.contractDataService.changePage({ page: 'kauf'});
        localStorage.setItem('primeType','kauf');
        this.contractDataService.setContractPrimeType(undefined);
        break;
      case 'miet':
        this.contractDataService.changePage({ page: 'miet'});
        localStorage.setItem('primeType','miet');
        this.contractDataService.setContractPrimeType('miet');
        break;
      case 'schenk':
        this.contractDataService.changePage({ page: 'schenk'});
        localStorage.setItem('primeType','schenkung');
        this.contractDataService.setContractPrimeType('schenkung');
        break;
      case 'anbot':
        this.contractDataService.changePage({ page: 'anbot'});
        localStorage.setItem('primeType','kaufanbot');
        this.contractDataService.setContractPrimeType('kaufanbot');
        break;
    }
    this.userId = localStorage.getItem('userId');
    // this.timerSub = interval(environment.savetime).subscribe((func => {
    //   this.saveDB();
    // }));
  }

  SetOneBuyerBool() {
    if ((this.buyersPrivateFormArray.length + this.buyersCompanyFormArray.length) > 1) this.OneBuyer = false;
    else this.OneBuyer = true;
  }

  ifMaximumBuyers() {
    if((this.buyersPrivateFormArray.length + this.buyersCompanyFormArray.length) >= 8) {
      return true
    } else {
      return false;
    }
  }

  ifMaximumSellers() {
    if((this.sellersPrivateFormArray.length + this.sellersCompanyFormArray.length + this.sellersEstateFormArray.length) >= 8) {
      return true
    } else {
      return false;
    }
  }

  ngOnDestroy() {
    console.log('unsubascaribe');
    // this.timerSub.unsubscribe();
    if (!this.nextClicked) this.saveDB();
  }

  ngOnInit() {
    this.isUserLoggedIn = this.auth.isUserLoggedIn();
    if(this.isUserLoggedIn)
    this.getUsers();
    // this.contractDataService.Page='kauf';
    this.contractDataService.State = 1;
    this.nextClicked = false;
    this.contractData = this.contractDataService.getContractData();
    this.primeType = localStorage.getItem('primeType');
    if (this.primeType === 'schenkung') this.schenkung = true;
    else this.schenkung = false;

    if (this.primeType === 'miet') this.miet = true;
    else this.miet = false;

    if (this.primeType === 'kfz') this.kfz = true;
    else this.kfz = false;

    this.masterForm = this.formBuilder.group({
      sellersPrivate: this.formBuilder.array([]),
      sellersCompany: this.formBuilder.array([]),
      sellersEstate: this.formBuilder.array([]),
      buyersPrivate: this.formBuilder.array([]),
      buyersCompany: this.formBuilder.array([])
    });

    const contactData = this.contractDataService.getContractContactData();
    const masterData = this.contractDataService.getContractMasterData();
    if (contactData && masterData) {
      this.fillFormWithData(contactData, masterData);
    }

    if(!environment.production) {
      // this.fillMasterData(this.testValues.masterData);
      // this.contactData = this.testValues.contactData;
    }

    this.SetOneBuyerBool();
    this.VariantChange();
  }

  private fillMasterData(masterData: any): void {
    const {
      buyersCompany,
      buyersPrivate,
      sellersCompany,
      sellersPrivate
    } = masterData;

    if (buyersCompany.length > 0) {
      this.selectedBuyerType = 'company';
      buyersCompany.forEach(buyer =>
        this.buyersCompanyFormArray.push(this.initBuyerCompanyWithData(buyer))
      );
    }

    if (buyersPrivate.length > 0) {
      this.selectedBuyerType = 'person';
      buyersPrivate.forEach(buyer => {
        this.buyersPrivateFormArray.push(this.initBuyerPrivateWithData(buyer))
        }
      );
    }

    if (sellersCompany.length > 0) {
      this.selectedSellerType = 'company';
      sellersCompany.forEach(seller =>
        this.sellersCompanyFormArray.push(
          this.initSellerCompanyWithData(seller)
        )
      );
    }

    if (sellersPrivate.length > 0) {
      this.selectedSellerType = 'person';
      sellersPrivate.forEach(seller =>
        this.sellersPrivateFormArray.push(
          this.initSellerPrivateWithData(seller)
        )
      );
    }
  }

  private fillFormWithData(contactData: any, masterData: any): void {
    const {
      buyersCompany,
      buyersPrivate,
      sellersCompany,
      sellersPrivate
    } = masterData;

    this.contactData = { ...contactData };
    this.variant = this.contactData.contractOption;
    this.kaeuferanteile = this.contactData.kaeuferanteile;
    this.AnzahlLS = this.contactData.AnzahlLS;

    if (buyersCompany.length > 0) {
      this.selectedBuyerType = 'company';
      buyersCompany.forEach(buyer =>
        this.buyersCompanyFormArray.push(this.initBuyerCompanyWithData(buyer))
      );
    }

    if (buyersPrivate.length > 0) {
      this.selectedBuyerType = 'person';
      buyersPrivate.forEach(buyer => {
        this.buyersPrivateFormArray.push(this.initBuyerPrivateWithData(buyer))
        }
      );
    }

    if (sellersCompany.length > 0) {
      this.selectedSellerType = 'company';
      sellersCompany.forEach(seller =>
        this.sellersCompanyFormArray.push(
          this.initSellerCompanyWithData(seller)
        )
      );
    }

    if (sellersPrivate.length > 0) {
      this.selectedSellerType = 'person';
      sellersPrivate.forEach(seller =>
        this.sellersPrivateFormArray.push(
          this.initSellerPrivateWithData(seller)
        )
      );
    }
  }

  onSelectionSellerTypeChanged(change: MatRadioChange) {
    switch (change.value) {
      case 'person': {
        this.sellersPrivateFormArray.push(this.initSellerPrivateEmpty());
        this.sellersCompanyFormArray.controls.splice(0);
        this.contactData.estate = false;
        break;
      }
      case 'company': {
        this.sellersCompanyFormArray.push(this.initSellerCompanyEmpty());
        this.sellersPrivateFormArray.controls.splice(0);
        break;
      }
      case 'estate': {
        this.sellersEstateFormArray.push(this.initSellerCompanyEmpty());
        this.contactData.estate = true;
        this.sellersPrivateFormArray.controls.splice(0);
        break;
      }
    }
  }

  onSelectionBuyerTypeChanged(change: MatRadioChange) {
    switch (change.value) {
      case 'person': {
        this.buyersPrivateFormArray.push(this.initBuyerPrivateEmpty());
        this.buyersCompanyFormArray.controls.splice(0);
        break;
      }
      case 'company': {
        this.buyersCompanyFormArray.push(this.initBuyerCompanyEmpty());
        this.buyersPrivateFormArray.controls.splice(0);
        break;
      }
    }
  }

  addBuyer(): void {
    switch (this.selectedBuyerType) {
      case 'person': {
        this.buyersPrivateFormArray.push(this.initBuyerPrivateEmpty());
        break;
      }
      case 'company': {
        this.buyersCompanyFormArray.push(this.initBuyerCompanyEmpty());
      }
    }
    this.SetOneBuyerBool();
  }

  addBuyerComplex(buyerType): void {
    switch (buyerType) {
      case 'person': {
        this.buyersPrivateFormArray.push(this.initBuyerPrivateEmpty());
        break;
      }
      case 'company': {
        this.buyersCompanyFormArray.push(this.initBuyerCompanyEmpty());
      }
    }
    this.SetOneBuyerBool();
  }

  get buyersPrivateFormArray(): UntypedFormArray {
    return this.masterForm.get('buyersPrivate') as UntypedFormArray;
  }

  get buyersCompanyFormArray(): UntypedFormArray {
    return this.masterForm.get('buyersCompany') as UntypedFormArray;
  }

  addSeller(): void {
    switch (this.selectedSellerType) {
      case 'person': {
        this.sellersPrivateFormArray.push(this.initSellerPrivateEmpty());
        break;
      }
      case 'company': {
        this.sellersCompanyFormArray.push(this.initSellerCompanyEmpty());
        break;
      }
      case 'estate': {
        this.sellersEstateFormArray.push(this.initSellerCompanyEmpty());
      }
    }
  }

  addSellerComplex(sellerType): void {
    switch (sellerType) {
      case 'person': {
        this.sellersPrivateFormArray.push(this.initSellerPrivateEmpty());
        break;
      }
      case 'company': {
        this.sellersCompanyFormArray.push(this.initSellerCompanyEmpty());
        break;
      }
      case 'estate': {
        this.sellersEstateFormArray.push(this.initSellerCompanyEmpty());
        this.contactData.estate = true;
      }
    }
  }

  logConsole() {
    this.contractDataService.getContractData();
  }

  gotoNext(IsValid: boolean) {
    this.submitted = true;
    let kaeuf;
    if (this.kaeuferanteile.valueOf() == "Ja" || this.kaeuferanteile.valueOf() == "Nein" || this.OneBuyer || !this.UBLorBBL || this.kfz) {
      kaeuf = true;
    }
    else {
      kaeuf = false;
    }

    if (IsValid) {
      let buypriv, buycomp, sellcomp, sellpriv, sellestate: number;
      sellpriv = this.sellersPrivateFormArray.length;
      sellcomp = this.sellersCompanyFormArray.length;
      buypriv = this.buyersPrivateFormArray.length;
      buycomp = this.buyersCompanyFormArray.length;
      sellestate = this.sellersEstateFormArray.length;

      if (sellestate === 0) {
        this.sellersEstateFormArray.removeAt(0);
      }

      if (sellpriv === 0) {
        this.sellersPrivateFormArray.removeAt(0);
      }

      if (buypriv === 0) {
        this.buyersPrivateFormArray.removeAt(0);
      }

      if (sellcomp === 0) {
        this.sellersCompanyFormArray.removeAt(0);
      }

      if (buycomp === 0) {
        this.buyersCompanyFormArray.removeAt(0);
      }

      if ((sellcomp > 0 || sellpriv > 0 || sellestate > 0) && (buycomp > 0 || buypriv > 0))
        this.SellerAndBuyerBOOL = true;
      else
        this.SellerAndBuyerBOOL = false;

      if (this.kfz) {
        this.contactData.contractOption = 'kfz';
      }

      if (!this.kfz) {
        this.contactData.contractOption = this.variant;
      }

      if (this.miet) this.contactData.kaeuferanteile = 'Ja';
      if (!this.miet && !this.kfz) this.contactData.kaeuferanteile = this.kaeuferanteile.valueOf();

      this.contactData.AnzahlLS = this.AnzahlLS;
      this.contractDataService.setContractContactData(this.contactData);
      this.contractDataService.setContractMasterData(this.masterForm.value);

      if (this.contactData.kaeuferanteile == "Ja" || this.contactData.kaeuferanteile == "Nein" || this.OneBuyer == true || this.contactData.contractOption === 'WEG' || this.kfz)
        this.kaeuferanteileBOOL = true;
      else
        this.kaeuferanteileBOOL = false;

      if (this.variantTouched || this.kfz) {
        if (this.kaeuferanteileBOOL) {
          if (this.SellerAndBuyerBOOL) {
            this.openVorschau();
          }
          else {
            this.scrollToFirstInvalidControl(kaeuf);
            this.openSnackBar('Bitte mindestens einen Eintrag pro Vertragspartei eingeben.', 'schließen');
          }
        }
        else {
          this.scrollToFirstInvalidControl(kaeuf);
          this.openSnackBar('Bitte alles ausfüllen und jeweils eine Option auswählen.', 'schließen');
        }
      }
      else {
        this.scrollToFirstInvalidControl(kaeuf);
        this.openSnackBar('Bitte eine Variante auswählen.', 'schließen');
      }
    }
    else {
      this.scrollToFirstInvalidControl(kaeuf);
      this.openSnackBar('Bitte alles ausfüllen und jeweils eine Option auswählen.', 'schließen');
    }
  }

  private scrollToFirstInvalidControl(kaeuf: boolean) {
    var firstInvalidControl: HTMLElement;
    var formInvalid = this.el.nativeElement.querySelector("form .ng-invalid");
    if(formInvalid) {
      firstInvalidControl = this.el.nativeElement.querySelector("form .ng-invalid");
    }
    else if (!(this.variantTouched || this.kfz)) {
      firstInvalidControl = document.getElementById('contractoption');
    }
    else if (!kaeuf) {
      firstInvalidControl = document.getElementById('kaeufoption');
    }
    if (firstInvalidControl) {
      window.scroll({
        top: this.getTopOffset(firstInvalidControl),
        left: 0,
        behavior: "smooth"
      });
    }
  }

  private getTopOffset(controlEl: HTMLElement): number {
    const labelOffset = 5;
    return controlEl ? controlEl.getBoundingClientRect().top + window.scrollY - labelOffset : 0;
  }

  private nextClicked = false;

  openVorschau() {
    // this.timerSub.unsubscribe();

    const dialogRef = this.VorschauDlg.open(DlgVorschauPersonaldataComponent);

    dialogRef.afterClosed().subscribe(
      data => {
        if (data) {
          this.nextClicked = true;
          if(this.userId && this.userId !== '')
            this.router.navigate(['./weglogic/'+this.userId]);
          else
            this.router.navigate(['./weglogic']);
        }
        else {
          this.contractDataService.ResetMasterDates();
          // this.contractDataService.setMietNaviBool(true);
          // this.router.navigate(['/miet']);
          // this.timerSub = interval(environment.savetime).subscribe((func => {
          //   this.saveDB();
          // }));
        }
      }
    );
  }


  saveDB() {
    console.log('Wilkommen zum speichern der Daten auf DB Masterdata');
    console.log("Speichern!");

    try {
       let buypriv, buycomp, sellcomp, sellpriv, sellestate: number;
      sellpriv = this.sellersPrivateFormArray.length;
      sellcomp = this.sellersCompanyFormArray.length;
      buypriv = this.buyersPrivateFormArray.length;
      buycomp = this.buyersCompanyFormArray.length;
      sellestate = this.sellersEstateFormArray.length;


      if (sellestate === 0) {
        console.log('Lösche Verlassenschaft...');
        this.sellersEstateFormArray.removeAt(0);
      }

      if (sellpriv === 0) {
        console.log('Lösche Privat VK....');
        this.sellersPrivateFormArray.removeAt(0);
      }

      if (buypriv === 0) {
        console.log('Lösche Privat K....');
        this.buyersPrivateFormArray.removeAt(0);
      }

      if (sellcomp === 0) {
        console.log('Lösche Firma VK....');
        this.sellersCompanyFormArray.removeAt(0);
      }

      if (buycomp === 0) {
        console.log('Lösche Firma K....');
        this.buyersCompanyFormArray.removeAt(0);
      }

      if (this.kfz) {
        this.contactData.contractOption = 'kfz';
      }

      if (!this.kfz) {
        this.contactData.contractOption = this.variant;
      }

      if (this.miet) this.contactData.kaeuferanteile = 'Ja';

      if (!this.miet && !this.kfz) this.contactData.kaeuferanteile = this.kaeuferanteile.valueOf();

      this.contactData.AnzahlLS = this.AnzahlLS;

      this.contractDataService.setContractContactData(this.contactData);
      this.contractDataService.setContractMasterData(this.masterForm.value);

      // if (this.contractDataService.saveFromPage('personaldata')) this.openSnackBar('Die Daten wurden erfolgreich gespeichert!', 'schließen');
      // else console.log('speichern hat nicht geklappt')
      //this.openSnackBar('Das Speichern hat leider nicht geklappt!', 'schließen');
    } catch (error) {
      console.log('speichern hat nicht geklappt')  //this.openSnackBar('Das Speichern hat leider nicht geklappt!', 'schließen');
    }

    // const dialogRef = this.SaveDlg.open(SaveDlgComponent);

    // dialogRef.afterClosed().subscribe(
    //   data => {
    //     if (data) {

    //     }
    //     else {
    //       console.log("Abbruch, Korrekturen!");
    //     }
    //   }
    // );
    this.contractDataService.ResetMasterDates();
  }

  moreLS() {
    if (this.AnzahlLS < 4) {
      this.AnzahlLS++;
    }

    if (this.AnzahlLS == 4) this.moreLSBT = false;
    else this.moreLSBT = true;

    if (this.AnzahlLS == 1) this.lessLSBT = false;
    else this.lessLSBT = true;
  }

  lessLS() {
    if (this.AnzahlLS > 1) {
      this.AnzahlLS--;
    }

    if (this.AnzahlLS == 4) this.moreLSBT = false;
    else this.moreLSBT = true;

    if (this.AnzahlLS == 1) this.lessLSBT = false;
    else this.lessLSBT = true;
  }

  removeBuyerCompany(index: number) {
    this.buyersCompanyFormArray.removeAt(index);
    this.SetOneBuyerBool();
  }

  removeBuyerPrivate(index: number) {
    this.buyersPrivateFormArray.removeAt(index);
    this.SetOneBuyerBool();
  }

  removeSellerCompany(index: number) {
    this.sellersCompanyFormArray.removeAt(index);
  }

  removeSellerEstate(index: number) {
    this.sellersEstateFormArray.removeAt(index);
  }

  removeSellerPrivate(index: number) {
    this.sellersPrivateFormArray.removeAt(index);
  }

  get sellersPrivateFormArray(): UntypedFormArray {
    return this.masterForm.get('sellersPrivate') as UntypedFormArray;
  }

  get sellersCompanyFormArray(): UntypedFormArray {
    return this.masterForm.get('sellersCompany') as UntypedFormArray;
  }

  get sellersEstateFormArray(): UntypedFormArray {
    return this.masterForm.get('sellersEstate') as UntypedFormArray;
  }

  private initSellerPrivateWithData(sellerPrivat: any): UntypedFormGroup {
    return this.formBuilder.group({
      firstname: [sellerPrivat.firstname, Validators.required],
      lastname: [sellerPrivat.lastname, Validators.required],
      formatedBirthday: [new Date(sellerPrivat.formatedBirthday), Validators.required],
      address: [sellerPrivat.address, Validators.required],
      city: [sellerPrivat.city, Validators.required],
      postalCode: [sellerPrivat.postalCode, Validators.required],
      id: [sellerPrivat.uid ? sellerPrivat.uid : ( sellerPrivat.id ? sellerPrivat.id : this.makeuid(9))]
    });
  }

  private initSellerPrivateEmpty(): UntypedFormGroup {
    return this.formBuilder.group({
      firstname: ['', Validators.required],
      lastname: ['', Validators.required],
      formatedBirthday: ['', Validators.required],
      address: ['', Validators.required],
      city: ['', Validators.required],
      postalCode: ['', Validators.required],
      id: [this.makeuid(9)]
    });
  }

  private initSellerCompanyEmpty(): UntypedFormGroup {
    return this.formBuilder.group({
      companyName: ['', Validators.required],
      companiesRegisterNumber: ['', Validators.required],
      address: ['', Validators.required],
      city: ['', Validators.required],
      postalCode: ['', Validators.required],
      id: [this.makeuid(9)]
    });
  }

  private initSellerEstateEmpty(): UntypedFormGroup {
    return this.formBuilder.group({
      companyName: ['', Validators.required],
      companiesRegisterNumber: ['', Validators.required],
      address: ['', Validators.required],
      city: ['', Validators.required],
      postalCode: ['', Validators.required],
      id: [this.makeuid(9)]
    });
  }

  private initSellerCompanyWithData(sellerCompany: any): UntypedFormGroup {
    return this.formBuilder.group({
      companyName: [sellerCompany.companyName, Validators.required],
      companiesRegisterNumber: [
        sellerCompany.companiesRegisterNumber,
        Validators.required
      ],
      address: [sellerCompany.address, Validators.required],
      city: [sellerCompany.city, Validators.required],
      postalCode: [sellerCompany.postalCode, Validators.required],
      id: [sellerCompany.uid ? sellerCompany.uid : ( sellerCompany.id ? sellerCompany.id : this.makeuid(9))]
    });
  }

  private initBuyerPrivateEmpty(): UntypedFormGroup {
    return this.formBuilder.group({
      firstname: ['', Validators.required],
      lastname: ['', Validators.required],
      formatedBirthday: ['', Validators.required],
      address: ['', Validators.required],
      city: ['', Validators.required],
      postalCode: ['', Validators.required],
      id: [this.makeuid(9)]
    });
  }

  private initBuyerPrivateWithData(buyerPrivate: any): UntypedFormGroup {
    return this.formBuilder.group({
      firstname: [buyerPrivate.firstname, Validators.required],
      lastname: [buyerPrivate.lastname, Validators.required],
      formatedBirthday: [new Date(buyerPrivate.formatedBirthday), Validators.required],
      address: [buyerPrivate.address, Validators.required],
      city: [buyerPrivate.city, Validators.required],
      postalCode: [buyerPrivate.postalCode, Validators.required],
      id: [buyerPrivate.uid ? buyerPrivate.uid : ( buyerPrivate.id ? buyerPrivate.id : this.makeuid(9))]
    });
  }

  private initBuyerCompanyEmpty(): UntypedFormGroup {
    return this.formBuilder.group({
      companyName: ['', Validators.required],
      companiesRegisterNumber: ['', Validators.required],
      address: ['', Validators.required],
      city: ['', Validators.required],
      postalCode: ['', Validators.required],
      id: [this.makeuid(9)]
    });
  }

  private initBuyerCompanyWithData(buyerCompany: any): UntypedFormGroup {
    return this.formBuilder.group({
      companyName: [buyerCompany.companyName, Validators.required],
      companiesRegisterNumber: [
        buyerCompany.companiesRegisterNumber,
        Validators.required
      ],
      address: [buyerCompany.address, Validators.required],
      city: [buyerCompany.city, Validators.required],
      postalCode: [buyerCompany.postalCode, Validators.required],
      id: [buyerCompany.uid ? buyerCompany.uid : ( buyerCompany.id ? buyerCompany.id : this.makeuid(9))]
    });
  }


  VariantChange() {
    if (this.variant.valueOf() === 'BBL' || this.variant.valueOf() === 'UBL' || this.variant.valueOf() === 'WEG') {
      this.variantTouched = true;
    }
    else this.variantTouched = false;

    if (this.variant.valueOf() === 'BBL' || this.variant.valueOf() === 'UBL') {
      this.UBLorBBL = true;
    }
    else {
      this.UBLorBBL = false;
    }
  }

  openVariantDlg(): void {
    const dialogRef = this.WEGDlg.open(DlgWEGComponent);
  }

  formChange(e) {
    console.log(e);
  }

  makeuid(length) {
    var result           = '';
    var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  getUsers() {
    this.shared.getApi('contacts/all')
      .subscribe((res:any) => {
        this.users = res;
      }, (err:any) => {

      });
  }

  getUser(id) {
    this.shared.getApi('contacts/' + id)
      .subscribe((res:any) => {
        // this.users = res;
        const { firstName, lastName, email, phoneNumber } = res;
        this.contactData['firstName'] = firstName;
        this.contactData['lastName']  = lastName;
        this.contactData['eMail']  = email;
        this.contactData['phoneNumber']  = phoneNumber;
      }, (err:any) => {

      });
  }


}
