import { Component, OnInit } from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-dlg-widmung',
  templateUrl: './dlg-widmung.component.html',
  styleUrls: ['./dlg-widmung.component.css']
})
export class DlgWidmungComponent implements OnInit {
  constructor(public dialogRef: MatDialogRef<DlgWidmungComponent>) { }

  ngOnInit() {
  }

  closeDlg(): void {
    this.dialogRef.close();
  }
}
