<mat-dialog-content>
  <div class="custom-dlg-head">
    <h1>Hinweis Wohungseigentum</h1>
  </div>
  <div class="custom-dlg-body">
    <p>Aus dem Grundbuchsauszug ist ersichtlich, ob Wohnungseigentum begr&uuml;ndet ist. Sofern Wohnungseigentum begr&uuml;ndet ist, ist zwingend die Variante &bdquo;Wohnungseigentum&ldquo; zu w&auml;hlen.</p>
    <p>&nbsp;</p>
    <p><img src="/assets/weg.png" alt="" width="800" /></p>
  </div>
  <div class="custom-dlg-footer">
    <button mat-raised-button color="primary" mat-button (click)="closeDlg()">OK</button>
  </div>
</mat-dialog-content>
