import { AfterViewInit, Component, Inject, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ContractDataService } from '../shared/contractData.service';

@Component({
  selector: 'app-start',
  templateUrl: './start.component.html',
  styleUrls: ['./start.component.css']
})

export class StartComponent implements OnInit, AfterViewInit {
  userId: any = '';
  breakpoint = (window.innerWidth <= 500) ? 1 : 3;

  constructor(private route: ActivatedRoute, private router: Router,
    @Inject(ContractDataService) private contractDataService: ContractDataService
  ) {
    let userId = this.route.snapshot.paramMap.get('userId');
    if(userId) {
      this.userId = userId;
      localStorage.setItem('userId', userId);
    }

    this.route.queryParams
    .subscribe(params => {
      console.log(params);
      if(params.reset) {
        localStorage.removeItem("contractData");
      }
    }
  )}

  ngOnInit() {
    this.contractDataService.State = 0;
    this.contractDataService.changePage({ page: 'kauf'});
  }

  ngAfterViewInit() {
    let scrolloptions = { top: 0, left: 0 }
    window.scrollTo(scrolloptions);
  }

  public gotoKauf() {
    this.contractDataService.clearContractData();
    this.contractDataService.changePage({ page: 'kauf'});
    // this.router.navigate(['/welcome']);
    localStorage.setItem('primeType','kauf');
    this.contractDataService.setContractPrimeType(undefined);
    if(this.userId && this.userId !== '')
      this.router.navigate(['/kaufvertrag/personaldata/'+this.userId]);
    else
      this.router.navigate(['/kaufvertrag/personaldata']);
  }

  public gotoMiet() {
    this.contractDataService.clearContractData();
    this.contractDataService.changePage({ page: 'miet'});
    // this.router.navigate(['/miet']);
    localStorage.setItem('primeType','miet');
    this.contractDataService.setContractPrimeType('miet');
    if(this.userId && this.userId !== '')
      this.router.navigate(['/mietvertrag/personaldata/'+this.userId]);
    else
      this.router.navigate(['/mietvertrag/personaldata']);
  }

  public gotoSchenk() {
    this.contractDataService.clearContractData();
    this.contractDataService.changePage({ page: 'schenk'});
    // this.router.navigate(['/schenkung']);
    localStorage.setItem('primeType','schenkung');
    this.contractDataService.setContractPrimeType('schenkung');
    if(this.userId && this.userId !== '')
      this.router.navigate(['/schenkung/personaldata/'+this.userId]);
    else
      this.router.navigate(['/schenkung/personaldata']);
  }

  public gotoAnbot() {
    this.contractDataService.clearContractData();
    this.contractDataService.changePage({ page: 'anbot'});
    // this.router.navigate(['/kaufanbot']);
    localStorage.setItem('primeType','kaufanbot');
    this.contractDataService.setContractPrimeType('kaufanbot');
    this.router.navigate(['/kaufanbot/personaldata']);
  }

  public gotokfz() {
    this.contractDataService.clearContractData();
    this.contractDataService.changePage({ page: 'kfz'});
    // this.router.navigate(['/kfz']);
    localStorage.setItem('primeType','kfz');
    this.contractDataService.setContractPrimeType('kfz');
    if(this.userId && this.userId !== '')
      this.router.navigate(['/personaldata/'+this.userId]);
    else
      this.router.navigate(['/personaldata']);
  }

}
