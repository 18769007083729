<mat-dialog-content>
  <div class="custom-dlg-head">
    <h1>L&ouml;schen sonstinger Lasten</h1>
  </div>
  <div class="custom-dlg-body">
    <p>Sollen sonstige Lasten, also nicht in Geld bestehenden Lasten, die im Grundbuch stehen, gel&ouml;scht werden? Es handelt sich meist um Dienstbarkeiten, Wohnrechte, Fruchtgenussrecht, Belastungs- und Ver&auml;u&szlig;erungsverbote, Vorkaufsrechte oder Wiederkaufsrechte.</p>
    <p>Im untenstehenden Beispiel w&auml;re das Dienstbarkeitsrecht zu l&ouml;schen, daher w&auml;re C-LNR <strong>9</strong> anzugeben. Das Wohnungsrecht bleibt im gegenst&auml;ndlichen Fall bestehen.</p>
    <p>&nbsp;</p>
    <p><img src="/assets/dienstbarkeit.png" alt="" width="800" /></p>
  </div>
  <div class="custom-dlg-footer">
    <button mat-raised-button (click)="closeDlg()">OK</button>
  </div>
</mat-dialog-content>
