import { Component, Inject, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { ContractDataService } from '../shared/contractData.service';

@Component({
  selector: 'app-load-logic',
  templateUrl: './load-logic.component.html',
  styleUrls: ['./load-logic.component.css']
})
export class LoadLogicComponent implements OnInit {

  constructor(@Inject(ContractDataService) private contractDataService: ContractDataService,
    private router: Router,
    private route: ActivatedRoute,
    public snackBar: MatSnackBar
  ) { }

  //0-->warten;1-->ok;2-->fehler
  public success = 0;

  ngOnInit() {
    this.contractDataService.State = 0;
    this.success = 0;
    let page;
    let user;
    let contract;


    this.route.paramMap.subscribe((params: ParamMap) => {
      console.log(params);
      page = params.get('page');
      user = params.get('user');
      contract = params.get('contract');
    });


    console.log('user ' + user);
    console.log('contract ' + contract);
    console.log('page ' + page);

    // this.contractDataService.Page = page;
    this.contractDataService.changePage({ page });

    switch (page) {
      case 'kauf':
        this.contractDataService.setPrimeTypeFixed(undefined);
        break;
      case 'miet':
        this.contractDataService.setPrimeTypeFixed('miet');
        break;
      case 'kfz':
        this.contractDataService.setPrimeTypeFixed('kfz');
        break;
      case 'schenk':
        this.contractDataService.setPrimeTypeFixed('schenkung');
        break;
      case 'anbot':
        this.contractDataService.setPrimeTypeFixed('kaufanbot');
        break;

      default:
        this.contractDataService.setPrimeTypeFixed(undefined);
        break;
    }

    this.contractDataService.setUser(user);
    this.contractDataService.setContract(contract);
    if(contract){
      let res = this.contractDataService.LoadFromDB();

      if (res == null) {
        this.success = 2;
        // this.openSnackBar('Beim Laden ist ein Fehler aufgetreten', 'schließen');
      }
      else if (res == undefined) {
        this.success = 1;
        this.router.navigate(['/personaldata']);
      }
      else {
        this.success = 1;
        this.router.navigate(['/' + res]);
      }
    }
    else this.router.navigate(['/personaldata']);
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 4000,
      panelClass: 'custom-snackBar',
    });
  }

}
