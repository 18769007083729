<mat-dialog-content>
  <div class="custom-dlg-head">
    <h1>Daten zur Liegenschaft&nbsp;</h1>
  </div>
  <div class="custom-dlg-body">
    <h1>Immobilienertragsteuer</h1>
    <p>Grunds&auml;tzlich ist die Verk&auml;uferseite verpflichtet, Immobilienertragsteuer auf Ver&auml;u&szlig;erungsgewinne zu bezahlen. Es gibt jedoch folgende Ausnahmen, die von der Immobilienbesteuerungspflicht absehen:</p>
    <p><u>Hauptwohnsitzbefreiung &ndash; 2 Jahre</u></p>
    <p>Steuerfrei ist die Ver&auml;u&szlig;erung von Eigenheimen und Eigentumswohnungen samt Grund und Boden, wenn es sich dabei um den <strong>Hauptwohnsitz der Verk&auml;uferseite</strong> handelt. Der Hauptwohnsitz (Mittelpunkt der Lebensinteressen) ist das&nbsp;Eigenheim oder die Eigentumswohnung, in dem die Verk&auml;uferseite <strong>seit der Anschaffung und bis zur Ver&auml;u&szlig;erung</strong> <strong>durchgehend f&uuml;r mindestens zwei Jahre</strong> gewohnt hat.</p>
    <p><u>Hauptwohnsitzbefreiung &ndash; 5 aus 10 Jahre</u></p>
    <p>Die Hauptwohnsitzbefreiung kommt auch dann zum Tragen, wenn die Verk&auml;uferseite innerhalb der letzten zehn Jahre (vor der Ver&auml;u&szlig;erung) mindestens f&uuml;nf Jahre durchgehend in diesem Haus oder dieser Wohnung als "Hauptwohnsitzer" gewohnt hat ("5 aus 10-Regelung"). Beide Befreiungstatbest&auml;nde liegen nur vor, wenn der Auszug der Verk&auml;uferseite ab Kaufvertragsschluss innert eines Jahres stattfindet.</p>
    <p><u>Herstellerbefreiung:</u></p>
    <p>Steuerfrei sind auch Gewinne aus der Ver&auml;u&szlig;erung eines selbst hergestellten Geb&auml;udes. Der Grund und Boden ist hier jedoch steuerpflichtig, sofern nicht auch die Hauptwohnsitzbefreiung anwendbar ist. Ein selbst hergestelltes Geb&auml;ude liegt vor, wenn die Verk&auml;uferseite das Geb&auml;ude von Grund auf neu errichtet (also ein "Hausbau" und keine &ndash; auch umfassende &ndash; Fertigstellung oder Renovierung vorliegt) und das (finanzielle) Baurisiko hinsichtlich der Errichtung tr&auml;gt. Selbst hergestellt ist ein Geb&auml;ude auch dann, wenn es durch einen beauftragten Unternehmer errichtet wurde, der Eigent&uuml;mer aber das Risiko allf&auml;lliger Kosten&uuml;berschreitungen zu tragen hatte. Die Verk&auml;uferseite muss selbst Hersteller des Geb&auml;udes sein; vom Rechtsvorg&auml;nger selbst hergestellte Geb&auml;ude sind daher von der Herstellerbefreiung nicht erfasst.</p>
    <p><u>K&ouml;rperschaft:</u></p>
    <p>Ist die Verk&auml;uferseite eine K&ouml;rperschaft i.S.d. &sect;&nbsp;7 Abs. 3 KStG, gelangen die Bestimmungen &uuml;ber die Immobilienertragsteuer nicht zur Anwendung. Es ist K&ouml;rperschaftssteuer auf Ver&auml;u&szlig;erungsgewinne zu bezahlen.</p>
    <p><strong><u>&nbsp;</u></strong></p>
    <p><em>N&auml;here Informationen finden Sie unter:</em></p>
    <p><a href="https://www.help.gv.at/Portal.Node/hlpd/public/content/242/Seite.2420000.html" target="_blank"><em>https://www.help.gv.at/Portal.Node/hlpd/public/content/242/Seite.2420000.html</em></a></p>
  </div>
  <div class="custom-dlg-footer">
    <button mat-raised-button (click)="closeDlg()">OK</button>
  </div>
</mat-dialog-content>
