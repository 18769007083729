import { AfterViewInit, Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ContractDataService } from '../shared/contractData.service';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-welcome-kaufanbot',
  templateUrl: './welcome-kaufanbot.component.html',
  styleUrls: ['./welcome-kaufanbot.component.css']
})
export class WelcomeKaufanbotComponent implements OnInit, AfterViewInit {

  constructor(private router: Router, @Inject(ContractDataService) private contractDataService: ContractDataService) { }
  faArrowRight = faArrowRight;
  ngOnInit() {
    this.contractDataService.State = 0;
  }

  ngAfterViewInit() {
    const scrolloptions = { top: 0, left: 0 }
    window.scrollTo(scrolloptions);
  }

  gotoMasterData() {
    this.contractDataService.setContractPrimeType('kaufanbot');
    this.router.navigate(['/personaldata']);
  }

}
