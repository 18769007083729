import { AfterViewInit, Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-finished-error',
  templateUrl: './finished-error.component.html',
  styleUrls: ['./finished-error.component.css']
})
export class FinishedErrorComponent implements OnInit, AfterViewInit {

  constructor() { }

  ngOnInit() {
  }
  ngAfterViewInit() {
    let scrolloptions = { top: 0, left: 0 }
    window.scrollTo(scrolloptions);
  }
}
