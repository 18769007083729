import { AfterViewInit, Component, Inject, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { ContractDataService } from '../shared/contractData.service';

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.css']
})
export class WelcomeComponent implements AfterViewInit, OnInit {
  userId: any = '';

  constructor(private route: ActivatedRoute, private router: Router, @Inject(ContractDataService) private contractDataService: ContractDataService) {
    const userId = this.route.snapshot.paramMap.get('userId');
    if(userId) {
      this.userId = userId;
      localStorage.setItem('userId', userId);
    }
  }

  faArrowRight = faArrowRight;

  ngOnInit() {
    this.contractDataService.changePage({ page : 'kauf' });
    this.contractDataService.State = 0;
  }

  gotoMasterData() {
    localStorage.setItem('primeType','kauf');
    this.contractDataService.setContractPrimeType(undefined);
    if(this.userId && this.userId !== '')
      this.router.navigate(['/kaufvertrag/personaldata/'+this.userId]);
    else
      this.router.navigate(['/kaufvertrag/personaldata']);
  }

  ngAfterViewInit() {
    const scrolloptions = { top: 0, left: 0 }
    window.scrollTo(scrolloptions);
  }

}
