import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MasterdataComponent } from './masterdata/masterdata.component';
import { WEGlogicComponent } from './weglogic/weglogic.component';
import { WEGlogicImmoDetailComponent } from './weglogic-immo-detail/weglogic-immo-detail.component';
import { WelcomeComponent } from './welcome/welcome.component';
import { FinishedComponent } from './finished/finished.component';
import { FinishedErrorComponent } from './finished-error/finished-error.component';
import { WelcomeKaufanbotComponent } from './welcome-kaufanbot/welcome-kaufanbot.component';
import { WelcomeSchenkungComponent } from './welcome-schenkung/welcome-schenkung.component';
import { WelcomeMietComponent } from './welcome-miet/welcome-miet.component';
import { StartComponent } from './start/start.component';
import { WelcomeKfzComponent } from './welcome-kfz/welcome-kfz.component';
import { LoadLogicComponent } from './load-logic/load-logic.component';
import { LoadJsonComponent } from './load-json/load-json.component';
import { AuthComponent } from './auth/auth.component';
import { AuthGuard } from './shared/authGuard.service';
import { AuthModule } from './auth/auth.module';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ContractsComponent } from './contracts/contracts.component';
import { ContractDetailsComponent } from './contract-details/contract-details.component';

export const routes: Routes = [
  {
    path: 'kaufvertrag/personaldata',
    component: MasterdataComponent,
    pathMatch: 'full',
    data: {type : 'kauf'},
    canActivate: [AuthGuard]
  },
  {
    path: 'kaufvertrag/personaldata/:userId',
    component: MasterdataComponent,
    pathMatch: 'full',
    data: {type : 'kauf'},
    canActivate: [AuthGuard]
  },
  {
    path: 'mietvertrag/personaldata',
    component: MasterdataComponent,
    pathMatch: 'full',
    data: {type : 'miet'},
    canActivate: [AuthGuard]
  },
  {
    path: 'mietvertrag/personaldata/:userId',
    component: MasterdataComponent,
    pathMatch: 'full',
    data: {type : 'miet'},
    canActivate: [AuthGuard]
  },
  {
    path: 'schenkung/personaldata',
    component: MasterdataComponent,
    pathMatch: 'full',
    data: {type : 'schenk'},
    canActivate: [AuthGuard]
  },
  {
    path: 'schenkung/personaldata/:userId',
    component: MasterdataComponent,
    pathMatch: 'full',
    data: {type : 'schenk'},
    canActivate: [AuthGuard]
  },
  {
    path: 'kaufanbot/personaldata',
    component: MasterdataComponent,
    pathMatch: 'full',
    data: {type : 'anbot'},
    canActivate: [AuthGuard]
  },
  {
    path: 'kaufanbot/personaldata/:userId',
    component: MasterdataComponent,
    pathMatch: 'full',
    data: {type : 'anbot'},
    canActivate: [AuthGuard]
  },
  {
    path: 'personaldata',
    component: MasterdataComponent,
    pathMatch: 'full',
    canActivate: [AuthGuard]
  },
  {
    path: 'weglogic',
    component: WEGlogicComponent,
    pathMatch: 'full',
    canActivate: [AuthGuard]
  },
  {
    path: 'weglogic/:userId',
    component: WEGlogicComponent,
    pathMatch: 'full',
    canActivate: [AuthGuard]
  },
  {
    path: 'weglogicdetail',
    component: WEGlogicImmoDetailComponent,
    pathMatch: 'full',
    canActivate: [AuthGuard]
  },
  {
    path: 'welcome',
    component: WelcomeComponent,
    pathMatch: 'full',
  },
  {
    path: 'kaufanbot/:userId',
    component: WelcomeKaufanbotComponent,
    pathMatch: 'full',
  },
  {
    path: 'kaufanbot',
    component: WelcomeKaufanbotComponent,
    pathMatch: 'full',
  },
  {
    path: 'schenkung/:userId',
    component: WelcomeSchenkungComponent,
    pathMatch: 'full',
  },
  {
    path: 'schenkung',
    component: WelcomeSchenkungComponent,
    pathMatch: 'full',
  },
  {
    path: 'miet/:userId',
    component: WelcomeMietComponent,
    pathMatch: 'full',
  },
  {
    path: 'miet',
    component: WelcomeMietComponent,
    pathMatch: 'full',
  },
  {
    path: '',
    component: StartComponent,
    pathMatch: 'full',
    canActivate: [AuthGuard]
  },
  {
    path: 'finish',
    component: FinishedComponent,
    pathMatch: 'full',
  },
  {
    path: 'submiterror',
    component: FinishedErrorComponent,
    pathMatch: 'full',
  },
  {
    path: 'start',
    component: StartComponent,
    pathMatch: 'full',
    canActivate: [AuthGuard]
  },
  {
    path: 'new-contract',
    component: StartComponent,
    pathMatch: 'full',
    canActivate: [AuthGuard]
  },
  {
    path: 'kfz',
    component: WelcomeKfzComponent,
    pathMatch: 'full',
  },
  {
    path: 'load/:page/:user/:contract', //existierender Vertrag
    component: LoadLogicComponent,
    pathMatch: 'full',
  },
  {
    path: 'new/:page/:user', //neuer Vertrag
    component: LoadLogicComponent,
    pathMatch: 'full',
  },
  {
    path: 'load-json',
    component: LoadJsonComponent,
    pathMatch: 'full',
    canActivate: [AuthGuard]
  },
  {
    path: 'load-json/:postId',
    component: LoadJsonComponent,
    pathMatch: 'full',
    canActivate: [AuthGuard]
  },
  {
    path: 'authentication',
    component: AuthComponent,
    pathMatch: 'full',
  },
  {
    path: 'contracts',
    component: ContractsComponent,
    pathMatch: 'full',
    canActivate: [AuthGuard],
    data: { heading: 'Contracts' }
  },
  {
    path: 'contracts/:id',
    component: ContractDetailsComponent,
    pathMatch: 'full',
    canActivate: [AuthGuard],
    data: { heading: 'Contract details' }
  },
  {
    path: 'dashboard',
    component: DashboardComponent,
    pathMatch: 'full',
    canActivate: [AuthGuard]
  },
  {
    path: 'admin-panel/users',
    loadChildren: () => import('./contacts/contacts.module').then(m => m.ContactsModule)
  },
  {
    path: 'users',
    loadChildren: () => import('./contacts/contacts.module').then(m => m.ContactsModule)
  },
  {
    path: 'admin-panel/contacts',
    loadChildren: () => import('./contacts/contacts.module').then(m => m.ContactsModule)
  },
  {
    path: 'admin-panel',
    loadChildren: () => import('./superAdmin/super-admin.module').then(m => m.SuperAdminModule)
  },
  {
    path: 'auth',
    loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule)
  },
  {
    path: 'admin',
    redirectTo : 'auth/login'
  },
  {
    path: 'admin/login',
    redirectTo : 'auth/login'
  },
  {
    path: '',
    loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule)
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
