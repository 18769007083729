<!--
<h3>Liegenschaftsdaten</h3>
1.) Dropdown Katastralgemeinde (Elemente können statisch hinterlegt werden -> Object: Gemeindename, KGNr)
2. Einlagezahl: Integer (5stellig)
3. Grundstücksnummer: Text (da Sonderzeichen möglich) -> Button für Hinzufügen von Grundstücksnummern muss möglich sein

<h3> Daten zur Wohnung oder Geschäftsräumlichkeit: </h3>
Anzahl der zu verkaufenden Parkplätze (welche im Grundbuch ausgewiesen sind): Integer, Default: 1

TOP: Textfeld (Text), bis 10stellig
Anteil (Bruch, zB. 36/2000):  //TODO: Validierung auf Bruch und korrekte Schreibweise -> vermeidet Schönheitsfehler
B-Laufende Nummer: Integer 3stellig //Info: Aus Grundbuchauszug ersichtlich

Pro Parkplatz:
- TOP
- Anteil:
- B-Laufende Nummer:

-->

<form fxLayout="column" [formGroup]="immodetail">
  <div class="col-12">
    <div class="yellow-line-2 mb-4 mt-4"></div>
  </div>
  <div>
    <label>
      <h3>
        Daten zur Liegenschaft
        <fa-icon style="float: none; margin-left: 2px;" (click)="openWEGDatenzLSDlg()"
          class="accent-color" [icon]="faUniversity">
        </fa-icon>
      </h3>
    </label>
  </div>

    <div class="half-field">
      <mat-form-field appearance="none" class="no-padding no-line  customshit" [class.error]="submitted && immodetail.controls.katastralGemeinde.invalid">
        <mat-label>Katastralgemeinde (KG)</mat-label>
        <input required matInput #autoCompleteInput formControlName="katastralGemeinde" [matAutocomplete]="auto" class="katastralGemeinde" pattern="^[^\s]+(\s+[^\s]+)*$" />
        <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn" (optionSelected)='onSelectKg("l1", $event.option.value)'>
          <mat-option *ngIf="isLoading" class="is-loading">
            <mat-spinner diameter="50"></mat-spinner>
          </mat-option>
          <mat-option *ngFor="let village of filteredVillages" [value]="village">
            <span>{{ village.KGName}}</span> |
            <small>KGNr: {{ village.KGNr }}</small>
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </div>
    <div style="display: flex">
      <mat-form-field appearance="none" class="no-padding no-line half-field" [class.error]="submitted && immodetail.controls.einlageZahl.invalid">
        <mat-label>Einlagezahl (EZ)</mat-label>
        <input required matInput maxlength="5" formControlName="einlageZahl" pattern="^[^\s]+(\s+[^\s]+)*$" />
      </mat-form-field>
      <button mat-raised-button color="primary" (click)="addGstNr()" style="margin-top: 30px; margin-left: 10px;" class="small-height-button">
        GST-NR hinzufügen
      </button>
    </div>
    <div class="half-field" formArrayName="grundstuecksnummerAr">
      <div *ngFor="let gstNr of grundstuecksnummerAr.controls; let i = index">
        <mat-form-field appearance="none" class="no-padding no-line " style="width: 330px" [class.validation-error]="submitted">
          <mat-label>Grundstücksnummer (GST-NR) {{ i + 1 }}</mat-label>
          <div [formGroup]="gstNr" style="display: inline-block; position: relative">
            <input required matInput maxlength="10" formControlName="grundstuecksnummer" style="padding-right: 4.6em" pattern="^[^\s]+(\s+[^\s]+)*$" />
          </div>
        </mat-form-field>
        <br>
        <button *ngIf="grundstuecksnummerAr.length > 1" mat-raised-button color="primary"
          (click)="removeGstNr(i)">Löschen</button>
      </div>
    </div>
  <br>

  <div *ngIf="!parkplatz">
    <div *ngIf="contractData.primeType == 'miet'">
      <h3 style="margin-top: 2% !important">Daten zum Mietobjekt</h3>
    </div>

    <div *ngIf="contractData.primeType != 'miet'">
      <div *ngIf="(contractData.masterData.sellersPrivate.length + contractData.masterData.sellersCompany.length)==1" class="half-field">
        <label>
          <h3>Daten zur Wohnung oder Geschäftsräumlichkeit<fa-icon style="float: none; margin-left: 2px;"
              (click)="openWEGDatenzWohnodGeschOneVerDlg()" class="accent-color" [icon]="faUniversity">
            </fa-icon>
          </h3>
        </label>
      </div>

      <div *ngIf="(contractData.masterData.sellersPrivate.length + contractData.masterData.sellersCompany.length)>1"  class="half-field">
        <label>
          <h3>
            Daten zur Wohnung oder Geschäftsräumlichkeit
            <fa-icon style="float: none; margin-left: 2px;"
              (click)="openWEGDatenzWohnodGeschMoreVerDlg()" class="accent-color" [icon]="faUniversity">
            </fa-icon>
          </h3>
        </label>
      </div>
    </div>
  </div>

  <h3 *ngIf="parkplatz">Daten zum Parkplatz</h3>

  <div class="half-field">
    <mat-form-field appearance="none" class="no-padding no-line " [class.error]="submitted && immodetail.controls.top.invalid">
      <mat-label>TOP</mat-label>
      <input required matInput maxlength="10" formControlName="top" pattern="^[^\s]+(\s+[^\s]+)*$"/>
    </mat-form-field>
    <mat-form-field appearance="none" class="no-padding no-line" [class.error]="submitted && immodetail.controls.anteil.invalid">
      <mat-label>Anteil</mat-label>
      <input required matInput formControlName="anteil" pattern="\d{1,9}/\d{1,9}"/>
      <label class="text-danger text-left error-message">Der Wert muss im m/n-Format vorliegen</label>
    </mat-form-field>
  </div>
  <div class="half-field" formArrayName="bLaufendeNrAr">
    <mat-form-field *ngFor="let bLNR of bLaufendeNrAr.controls; let i = index" appearance="none"
      class="no-padding no-line" [class.error]="submitted && bLNR.controls.bLaufendeNr.invalid">
      <mat-label>B-LNR {{ i + 1 }}</mat-label>
      <div [formGroup]="bLNR">
        <input required matInput maxlength="10" formControlName="bLaufendeNr" pattern="^[^\s]+(\s+[^\s]+)*$"/>
      </div>
    </mat-form-field>
  </div>

  <h3 *ngIf="parkplatz">weitere Parkplätze</h3>
  <h3 *ngIf="!parkplatz">Parkplätze oder weitere Einheiten</h3>
  <div class="half-field">
    <mat-form-field appearance="none" class="no-padding no-line" [class.error]="submitted && immodetail.controls.parkingPlaceCnt.invalid">
      <mat-label *ngIf="parkplatz">Anzahl weitere Parkplätze</mat-label>
      <mat-label *ngIf="!parkplatz">Anzahl Parkplätze oder weitere Einheiten</mat-label>
      <input required matInput maxlength="1" formControlName="parkingPlaceCnt" pattern="[0-8]" />
    </mat-form-field>

    <div *ngFor="let parkingPlace of parkingPlaces.controls; let i = index">
      <h5 *ngIf="!parkplatz">Parkplatz oder weitere Einheit {{ i + 1 }}:</h5>
      <h5 *ngIf="parkplatz">Parkplatz {{ i + 1 }}:</h5>

      <app-immo-detail-info [submitted]="submitted" [parent]="parkingPlace" *ngIf="!BLaufendeNr2Visible"></app-immo-detail-info>
      <app-immo-detail-info-blnr2 [submitted]="submitted" [parent]="parkingPlace" *ngIf="BLaufendeNr2Visible"></app-immo-detail-info-blnr2>
    </div>
  </div>
</form>
