import { AfterViewInit, Component, Inject, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ContractDataService } from '../shared/contractData.service';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-welcome-schenkung',
  templateUrl: './welcome-schenkung.component.html',
  styleUrls: ['./welcome-schenkung.component.css']
})
export class WelcomeSchenkungComponent implements OnInit, AfterViewInit {
  userId: any = '';
  faArrowRight = faArrowRight;

  constructor(private route: ActivatedRoute, private router: Router, @Inject(ContractDataService) private contractDataService: ContractDataService) {
    const userId = this.route.snapshot.paramMap.get('userId');
    if(userId) {
      this.userId = userId;
      localStorage.setItem('userId', userId);
    }
  }

  ngOnInit() {
    // this.contractDataService.Page = 'schenk';
    this.contractDataService.changePage({ page: 'schenk'});
    this.contractDataService.State = 0;
  }

  ngAfterViewInit() {
    const scrolloptions = { top: 0, left: 0 }
    window.scrollTo(scrolloptions);
  }

  gotoMasterData() {
    localStorage.setItem('primeType','schenkung');
    this.contractDataService.setContractPrimeType('schenkung');
    if(this.userId && this.userId !== '')
      this.router.navigate(['/schenkung/personaldata/'+this.userId]);
    else
      this.router.navigate(['/schenkung/personaldata']);
  }

}
