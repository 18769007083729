<div class="container">
    <div class="full-grid-tile">
      <mat-form-field appearance="none" style="width: 20% !important; float: left">
        <mat-label>Filter nach Ansprechpartner</mat-label>
        <mat-select (selectionChange)="filter($event)" [(value)]="user" style="overflow: hidden;">
          <mat-option [value]="''">Alle</mat-option>
          <mat-option *ngFor="let user of users" [value]="user._id">
            {{ user.firstName }} {{ user.lastName }}
            <span *ngIf="user.lawyer && user.auth.userRole == 1"> Benutzer - {{ user.lawyer.firstName }} {{ user.lawyer.lastName }} </span>
            <span *ngIf="user.auth   && user.auth.userRole == 1"> ({{ user.auth.email }}) </span>
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="none" style="width: 23% !important; float: left; margin-left: 11px !important">
        <mat-label>Filter nach Vertragsart</mat-label>
        <mat-select (selectionChange)="filterContractType($event)" [(value)]="contractType">
          <mat-option value="">Alle</mat-option>
          <mat-option value="mietvertrag">Mietvertrag</mat-option>
          <mat-option value="kaufvertrag">Kaufvertrag</mat-option>
          <mat-option value="schenkung">Schenkung</mat-option>
          <mat-option value="kaufanbot">Kaufanbot</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="none" style="width: 18% !important; float: left; margin-left: 9px !important">
        <mat-label>Filter nach Status</mat-label>
        <mat-select (selectionChange)="filterStatus($event)" [(value)]="status">
          <mat-option value="">Alle</mat-option>
          <mat-option value="Bestatigung offen">Bestatigung offen</mat-option>
          <mat-option value="Bestatigt">Bestatigt</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="none" style="width: 36% !important; float: left; margin-left: 9px !important">
        <mat-label>Suchen</mat-label>
        <input type="text" matInput [(ngModel)]="searchText" (ngModelChange)="searchTextUpdate.next($event)"/>
      </mat-form-field>
      <br>
      <div *ngIf="resultsLength == 0 && !loading" class="bg-white pt-5 pb-5 mt-5 shadow">
        Keine Verträge zum Anzeigen
      </div>

      <div *ngIf="resultsLength == 0 && loading" class="text-center bg-white pt-5 pb-5 mt-5">
        <div class="spinner-border" style="width: 4rem; height: 4rem;">
          <span class="sr-only"></span>
        </div>
      </div>

      <!-- <table mat-table [dataSource]="contracts">
        <ng-container [matColumnDef]="column" *ngFor="let column of displayedColumns">
          <th mat-header-cell *matHeaderCellDef>{{ column }}</th>
          <td mat-cell *matCellDef="let con">{{ con[column] }}</td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let conrow; columns: displayedColumns"></tr>
      </table> -->

      <table mat-table [dataSource]="contracts" class="mat-elevation-z8 w-100" matSort matSortActive="createdAt" matSortDirection="desc" (matSortChange)="sortChange($event)" *ngIf="resultsLength != 0">
        <ng-container [matColumnDef]="column" *ngFor="let column of displayedColumns">
          <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by number">
            <span>
              {{ displayedColumnLabels[column] }}
            </span>
          </th>
          <td mat-cell *matCellDef="let con" class="">
            <div *ngIf="column != 'contactEmail' && column != 'contactId' && column != 'userID' && column != 'contractType' && column != 'createdAt'">
              {{ con[column] }}
            </div>
            <div *ngIf="column == 'contactId' && con[column]">
              {{ con['contactId']['firstName'] }}
              {{ con['contactId']['lastName'] }}
            </div>
            <div *ngIf="column == 'userID' && con[column]">
              {{ con[column]['firstName'] }}
              {{ con[column]['lastName'] }}
            </div>
            <div *ngIf="column == 'contactEmail' && con['contactId']">
              {{ con['contactId']['email'] }}
            </div>
            <div *ngIf="column == 'createdAt'">
              {{ con[column] | date: 'dd/MM/yyyy hh:mm a' }}
            </div>
            <div *ngIf="column == 'contractType'">
              <data *ngIf="con[column] == 'mietvertrag'">Mietvertrag</data>
              <data *ngIf="con[column] == 'kaufvertrag'">Kaufvertrag</data>
              <data *ngIf="con[column] == 'schenkung'">Schenkung</data>
              <data *ngIf="con[column] == 'kaufanbot'">Kaufanbot</data>
            </div>
            <div *ngIf="column == 'action'">
              <button (click)="editContract(con._id)" mat-icon-button>
                <mat-icon style="color: #272844 !important">create</mat-icon>
              </button>
              <!-- <button class="btn btn-primary btn-sm" [matMenuTriggerFor]="menu">
                Download Contract
              </button> -->
              <button [matMenuTriggerFor]="menu" mat-icon-button>
                <mat-icon style="color: #272844 !important">get_app</mat-icon>
              </button>
              &nbsp;
              <a [routerLink]="'/contracts/' + con._id" style="position: relative; top: 8px;">
                <mat-icon style="color: #272844 !important">remove_red_eye</mat-icon>
              </a>
              <mat-menu [overlapTrigger]="false" #menu="matMenu" backdropClass="my-mat-menu" style="width: 100px">
                <button mat-menu-item color="primary" (click)="downloadContract(con._id, 'informationsschreiben_kaufer')">
                  Informationsschreiben Käufer
                </button>
                <button mat-menu-item color="primary" (click)="downloadContract(con._id, 'informationsschreiben_verkaufer')">
                  Informationsschreiben Verkäufer
                </button>
                <button mat-menu-item color="primary" (click)="downloadContract(con._id, 'rangordnung')">
                  Rangordnung
                </button>
                <button mat-menu-item color="primary" (click)="downloadContract(con._id, 'vertrag_url')">
                  Vertag
                </button>
              </mat-menu>
            </div>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let conrow; columns: displayedColumns"></tr>
      </table>

      <mat-paginator *ngIf="resultsLength != 0" (page)="handlePageEvent($event)" [length]="resultsLength" [pageSize]="perPage" [pageSizeOptions]="[5, 10, 25, 50, 100]" class="mb-5 shadow"></mat-paginator>

    </div>
</div>
