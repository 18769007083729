<form fxLayout="column" [formGroup]="parent">
  <div class="half-field">
     <mat-form-field appearance="none" class="no-padding no-line marginRight" [class.validation-error]="submitted">
       <mat-label>Anteil (Bruch: m/n)</mat-label>
      <input matInput maxlength="10" required pattern="\d{1,9}/\d{1,9}" formControlName="anteil" />
      <label class="text-danger text-left error-message">Der Wert muss im m/n-Format vorliegen</label>
    </mat-form-field>
     <mat-form-field appearance="none" class="no-padding no-line marginRight" [class.validation-error]="submitted">
       <mat-label>B-LNR</mat-label>
      <input matInput required formControlName="BLNR" />
    </mat-form-field>
  </div>
</form>
