import { Component, OnInit } from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-dlg-daten-z-wohnod-gesch-more-ver',
  templateUrl: './dlg-daten-z-wohnod-gesch-more-ver.component.html',
  styleUrls: ['./dlg-daten-z-wohnod-gesch-more-ver.component.css']
})
export class DlgDatenZWohnodGeschMoreVerComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<DlgDatenZWohnodGeschMoreVerComponent>) { }

  ngOnInit() {
  }
  
  closeDlg(): void {
    this.dialogRef.close();
  }
}
