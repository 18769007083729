<div class="container">
  <form [formGroup]="contractForm" (ngSubmit)="gotoNext()">
    <div *ngIf="!contract.title" class="text-center bg-white pt-5 pb-5 mt-5">
      <div class="spinner-border" style="width: 4rem; height: 4rem;">
        <span class="sr-only"></span>
      </div>
    </div>
    <div class="full-grid-tile" *ngIf="contract.title">
      <mat-form-field appearance="none" class="no-padding no-line" >
        <mat-label>Title</mat-label>
        <input matInput pattern="^[^\s]+(\s+[^\s]+)*$" formControlName="title"/>
      </mat-form-field>
      <mat-form-field appearance="none" class="no-padding no-line" >
        <div class="myfilebrowser">
          <mat-label style="float: left !important;">Vertrag Pdf</mat-label>
          <mat-toolbar style="background-color: transparent !important;" class="p-0">
            <input matInput [(ngModel)]="vertragPdf" readonly name="vertragPdf" [ngModelOptions]="{standalone: true}" style="width: 50%;"/>
            <div layout="row">
              <!-- <div *ngIf="vertragPdf != ''" style="font-size:15px;">{{ vertragPdf }}</div> -->
              <div flex="20" class="text-left">
                <!-- &nbsp;  -->
              </div>
            </div>
            <button mat-flat-button class="dark-blue-bg" (click)="copyUrl($event, this.vertragPdf)" type="button" *ngIf="vertragPdf != 'No File Selected' && vertragPdf != ''">
              Copy Url
            </button>
          </mat-toolbar>
          <input type="file" id="fileUpload2" #uploadFileInput2 (change)="fileChangeEvent2($event)" accept="application/pdf" />
        </div>
      </mat-form-field>
      <mat-form-field appearance="none" class="no-padding no-line" >
        <mat-label>Json</mat-label>
        <textarea matInput rows="8" cols="80" class="textarea" formControlName="json"></textarea>
      </mat-form-field>

      <mat-form-field appearance="none" class="no-padding no-line">
        <mat-label>Status des Vertrags</mat-label>
        <mat-select class="" name="" formControlName="status">
          <mat-option value="Bestatigung offen">Bestatigung offen</mat-option>
          <mat-option value="Bestatigt">Bestatigt</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field appearance="none" class="no-padding no-line" >
        <mat-label>Contract ID</mat-label>
        <input matInput formControlName="contractID"/>
      </mat-form-field>

      <mat-form-field appearance="none" class="no-padding no-line" >
        <mat-label>Contract Type</mat-label>
        <input matInput formControlName="contractType"/>
      </mat-form-field>

      <mat-form-field appearance="none" class="no-padding no-line" >
        <mat-label>Vertrag url</mat-label>
        <input matInput formControlName="vertrag_url"/>
    </mat-form-field>

      <mat-form-field appearance="none" class="no-padding no-line" >
        <mat-label>Informationsschreiben Käufer</mat-label>
        <input matInput formControlName="informationsschreiben_kaufer"/>
      </mat-form-field>

      <mat-form-field appearance="none" class="no-padding no-line" >
        <mat-label>Informationsschreiben Verkäufer</mat-label>
        <input matInput formControlName="informationsschreiben_verkaufer"/>
      </mat-form-field>

      <mat-form-field appearance="none" class="no-padding no-line" >
        <mat-label>Rangordnung Käufer</mat-label>
        <input matInput formControlName="rangordnung"/>
      </mat-form-field>

      <mat-form-field appearance="none" class="no-padding no-line" >
        <div class="myfilebrowser">
          <mat-toolbar style="background-color: transparent !important;" class="p-0">
            <input matInput [(ngModel)]="myfilename" readonly name="memberContactNo" [ngModelOptions]="{standalone: true}" style="display: none"/>
            <div layout="row">
              <div *ngIf="myfilename != ''" style="font-size:15px;">{{ myfilename }}</div>
              <div flex="20" class="text-left">
                <button mat-flat-button class="dark-blue-bg">
                  Upload Invoice
                </button>
              </div>
            </div>
          </mat-toolbar>
          <input type="file" #UploadFileInput id="fileUpload" (change)="fileChangeEvent($event)" name="fileUpload"
            multiple="multiple" accept="application/pdf" />
        </div>
      </mat-form-field>

      <table mat-table [dataSource]="contractInvoices" class="mat-elevation-z8 w-100" *ngIf="invoices.length != 0" style="width: 60% !important">
        <ng-container [matColumnDef]="column" *ngFor="let column of displayedColumns">
          <th mat-header-cell *matHeaderCellDef>
            <span>
              {{ displayedColumnLabels[column] }}
            </span>
          </th>
          <td mat-cell *matCellDef="let con; let i = index">
            <div *ngIf="column != 'action'">
              {{ con[column] }} {{ i }}
            </div>
            <div *ngIf="column == 'action'">
              <a [href]="environment.NodeAppUrl + '/download-invoice/' + con.fileTitle" style="position: relative; top: 8px;" target="_blank">
                <mat-icon style="color: #272844 !important">remove_red_eye</mat-icon>
              </a>
              <!-- <a class="btn btn-primary btn-sm" [routerLink]="'/contracts'" [queryParams]="{user: con._id}">Contracts</a>
              <mat-icon style="color: red !important">documents</mat-icon>
              &nbsp;
              <a [routerLink]="'/users/update/' + con._id" style="position: relative; top: 8px;">
                <mat-icon style="color: #272844 !important">create</mat-icon>
              </a>
              &nbsp; -->
              <button type="button" (click)="deleteContact($event, con, i)" mat-icon-button>
                <mat-icon class="text-danger">delete</mat-icon>
              </button>
            </div>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let conrow; columns: displayedColumns"></tr>
      </table>
    </div>
    <div class="custom-weiter-div mt-3">
      <button mat-raised-button color="primary" class="custom-weiter bg-button">
        Update
      </button>
    </div>

    <!-- <div class="text-center mb-4 mt-3" style="color: red; display: block; width: 100%;"
      *ngIf="!lawyerForm.valid && lawyerForm.touched">
      Bitte füllen Sie alle Felder aus!<br />
    </div>
    <div class="custom-weiter-div mt-3">
      <button mat-raised-button color="primary" class="custom-weiter bg-button">
        Add
      </button>
      <label style="float: right;">
        <fa-icon class="accent-color custom-weiter" [icon]="faArrowRight"></fa-icon>
      </label>
    </div> -->
  </form>
  <br>

</div>
