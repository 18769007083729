<mat-dialog-content>
  <div class="custom-dlg-head">
    <h4 class="mt-0 mb-0" style="padding: 2%;">Bitte überprüfen Sie die Daten</h4>
  </div>
  <div class="custom-dlg-body">
    <br>
    <h4 class="custom-dlg-h">KONTAKTDATEN</h4>
    <p class="mb-0">{{contractData.contactData.firstName}} {{contractData.contactData.lastName}}</p>
    <p class="mb-0">{{contractData.contactData.eMail}}</p>
    <p class="mb-0">{{contractData.contactData.phoneNumber}}</p>
    <br>
    <div class="yellow-line">
    </div>
    <br>
    <div *ngIf="contractData.primeType != 'kfz'" class="text-leftt">
      <h4 class="custom-dlg-h">Vertragsart</h4>
      <b *ngIf="contractData.primeType == 'miet'">Mietvertrag</b>
      <b *ngIf="!contractData.primeType">Kaufvertrag</b>
      <b *ngIf="contractData.primeType == 'schenkung'">Schenkung</b>
      <b *ngIf="contractData.primeType == 'kaufanbot'">Kaufanbot</b>
      <div>
        <b>Vertragsart:&nbsp;</b>
        <label *ngIf="contractData.contactData.contractOption == 'BBL'">bebaute Liegenschaft</label>
        <label *ngIf="contractData.contactData.contractOption == 'WEG'">Wohnungseigentum</label>
        <label *ngIf="contractData.contactData.contractOption == 'UBL'">unbebaute Liegenschaft</label>
      </div>
    </div>
    <br>
    <div class="yellow-line">
    </div>
    <br>
    <h4 class="custom-dlg-h">VERTRAGSPARTEIEN</h4>
    <div>
      <b *ngIf="contractData.primeType == 'schenkung'">Geschenkgeberseite</b>
      <b *ngIf="contractData.primeType == 'miet'">Vermieterseite:</b>
      <b *ngIf="contractData.primeType != 'miet' && contractData.primeType != 'schenkung'">Verk&auml;uferseite:</b>
      <div *ngFor="let seller of contractData.masterData.sellersPrivate" class="text-leftt">
        <label>{{seller.firstname}} {{seller.lastname}} ({{seller.birthday}})</label><br>
        <label>{{seller.address}}, {{seller.postalCode}} {{seller.city}}</label><br>
        <br>
      </div>
      <div *ngFor="let seller of contractData.masterData.sellersCompany" class="text-leftt">
        <label>{{seller.companyName}} ({{seller.companiesRegisterNumber}})</label><br>
        <label>{{seller.address}}, {{seller.postalCode}} {{seller.city}}</label><br>
        <br>
      </div>
    </div>
    <div>
      <b *ngIf="contractData.primeType == 'miet'">Mieterseite:</b>
      <b *ngIf="contractData.primeType == 'schenkung'">Geschenknehmerseite</b>
      <b *ngIf="contractData.primeType != 'miet' && contractData.primeType != 'schenkung'">K&auml;uferseite:</b>
      <div *ngFor="let buyer of contractData.masterData.buyersPrivate" class="text-leftt">
        <label>{{buyer.firstname}} {{buyer.lastname}} ({{buyer.birthday}})</label><br>
        <label>{{buyer.address}}, {{buyer.postalCode}} {{buyer.city}}</label><br>
        <br>
      </div>
      <div *ngFor="let buyer of contractData.masterData.buyersCompany" class="text-leftt">
        <label>{{buyer.companyName}} ({{buyer.companiesRegisterNumber}})</label><br>
        <label>{{buyer.address}}, {{buyer.postalCode}} {{buyer.city}}</label><br>
        <br>
      </div>
    </div>
    <div *ngIf="contractData.contactData.contractOption === 'UBL' || contractData.contactData.contractOption === 'BBL'">
      <br>
      <b *ngIf="contractData.primeType !='miet'">LIEGENSCHAFTSERWERB:</b>
      <p>Anzahl der vertragsgegenst&auml;ndlichen Liegenschaften (EZ): {{contractData.contactData.AnzahlLS}}</p>
      <div *ngIf="contractData.primeType !='miet'">
        <p *ngIf="contractData.contactData.kaeuferanteile == 'Ja'">Die Käufer erwerben zu gleichen Anteilen.</p>
        <p *ngIf="contractData.contactData.kaeuferanteile == 'Nein'">Die Käufer erwerben nicht zu gleichen Anteilen.</p>
      </div>
    </div>
  </div>
  <div class="custom-dlg-footer">
    <button mat-raised-button style="" (click)="closeDlg()" class="small-btn">
      <fa-icon style="color: inherit; background-color: inherit;" [icon]="faArrowLeft"></fa-icon>Daten
      &Auml;ndern
    </button>
    <br>
    <button class="mt-1 bg-button" mat-raised-button style="" (click)="closeOKDlg()">Alles richtig.
      Weiter&nbsp;<fa-icon style="color: inherit; background-color: inherit;" [icon]="faArrowRight"></fa-icon>
    </button>
  </div>
  </mat-dialog-content>
