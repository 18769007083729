import { Component, OnInit } from '@angular/core';
import { ContractDataService } from '../shared/contractData.service';
import { Router, ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-load-json',
  templateUrl: './load-json.component.html',
  styleUrls: ['./load-json.component.css']
})
export class LoadJsonComponent implements OnInit {
  json : string;
  invalidJson : boolean = false;

  constructor(private route: ActivatedRoute, private contractDataService: ContractDataService, private router: Router) {
    this.contractDataService.changePage({ page : 'loadJson' });
    document.body.className = "load-json-page";
  }

  ngOnInit() {
    let postId = this.route.snapshot.params['postId'];
    if(postId) {
      this.contractDataService.getApi(environment.NodeAppUrl +'/post/'+ postId).subscribe(
        (data: any) => {
          if(data && data.acf && data.acf.json) {
            this.json = data.acf.json;
            this.parseJson();
          }
        });
    }
  }

  parseJson() {
    try{
      let json = JSON.parse(this.json);
      if(json.wegLogic && json.wegLogic.lastenLoeschenCLfdNr && json.wegLogic.lastenLoeschenCLfdNr.length) {

        let lastenclnr = json.wegLogic.lastenLoeschenCLfdNr;
        if(lastenclnr) {
          let clnrs = [];
          for(let clnr of lastenclnr) {
            console.log(clnr);
            if(clnr && clnr['CLNR'] && clnr['CLNR'] != "") {
              let cla = clnr['CLNR'].split(" in EZ ");
              if(cla[0] && cla[0] != "") {
                clnrs.push(cla[0]);
              }
            }
          }
          json.wegLogic.lastenLoeschenCLfdNr = clnrs;
        }
      }

      if(json.wegLogic && json.wegLogic) {
        var einlageZahlArs = [];
        var grundstuecksnummerAr = [];
        for (let index = 0; index < 10; index++) {
          var title = "";
          if(index == 0) {
            title = 'immodetail';
          } else {
            title = 'immodetail' + index;
          }
          // if(json.wegLogic[title]) {
          //   var immo = json.wegLogic[title];
          //   console.log(immo);
          //   if(immo.einlageZahlAr && immo.einlageZahlAr.length)
          //     einlageZahlArs.push(immo['einlageZahlAr'][0]);
          //
          //   if(immo.grundstuecksnummerAr && immo.grundstuecksnummerAr.length)
          //     grundstuecksnummerAr.push(immo['grundstuecksnummerAr'][0]);
          // }

          // if(index > 0) {
          //   if(json['wegLogic'][title]) {
          //     delete json['wegLogic'][title];
          //   }
          // }
        }

        // json['wegLogic']['immodetail']['einlageZahlAr']        = JSON.parse(JSON.stringify(einlageZahlArs));
        // json['wegLogic']['immodetail']['grundstuecksnummerAr'] = JSON.parse(JSON.stringify(grundstuecksnummerAr));
      }

      console.log("json");
      console.log(json);
      console.log(einlageZahlArs);
      console.log(grundstuecksnummerAr);


      if(!json.wegLogic.admin.faggVerzicht)
      json['wegLogic']['admin'] = json.admin;

      localStorage.setItem('contractData', JSON.stringify(json));
      var primeType = "";
      if(json.primeType) {
        primeType = json.primeType;
      } else if(json.contactData.primeType) {
        primeType = json.contactData.primeType;
      }

      localStorage.setItem('primeType', primeType);
      document.body.className = "";


      if(primeType == 'kauf')
        this.router.navigate(['kaufvertrag/personaldata']);

      else if(primeType == 'schenkung')
        this.router.navigate(['schenkung/personaldata']);

      else if(primeType == 'miet')
        this.router.navigate(['mietvertrag/personaldata']);

      else
        this.router.navigate(['kaufvertrag/personaldata']);
    }
    catch(e) {
      this.invalidJson = true;
    }
  }

}
