import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { CurrencyPipe, DatePipe } from '@angular/common';
import { Observable, BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';
import { map, retry } from 'rxjs/operators';
import { LooseCurrencyPipe } from './looseCurrency.pipe';
import * as _ from 'lodash';
import { environment } from 'src/environments/environment';
// import Fraction from 'fraction.js/fraction';
import Fraction from 'fraction.js';

@Injectable({
    providedIn: 'root'
})

export class ContractDataService {
  LSBOOL: boolean[];

  private dataSource = new BehaviorSubject({ page: 'kauf' });
  private dataSource2 = new BehaviorSubject({ loading: false });
  currentPage  = this.dataSource.asObservable();
  loadingState = this.dataSource2.asObservable();

  constructor(
    private http: HttpClient,
    private datepipe: DatePipe,
    private currencyPipe: CurrencyPipe,
    private looseCurrencyPipe: LooseCurrencyPipe,
    private router: Router
  ) {
  }

  // for posting http data
  private AuthHttpOptions = {
    headers: new HttpHeaders({
      'Authorization': 'Basic YWRtaW46IWVuczIwMjAk',
      'Content-Type': 'application/json'
    })
  };

  private httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    }),
    responseType: 'text' as 'text'
  };

  private httpUploadOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/octet-stream'
    })
  };

  private state = 0;

  public get State(): number {
    return this.state;
  }

  public set State(val: number) {
    this.state = val;
  }

  private page = undefined;

  public get Page(): string {
    if(this.page)
      return this.page;
    else
      return '';
  }

  public set Page(val: string) {
    this.page = val;
  }

  changePage(data: any) {
    localStorage.setItem('page', data.page);
    this.dataSource.next(data);
  }

  changeLoading(data: any) {
    this.dataSource2.next(data);
  }

  private mietnavibool: boolean;

  public setMietNaviBool(val: boolean) {
    this.mietnavibool = val;
  }

  public getMietNaviBool(): boolean {
    return this.mietnavibool;
  }

  private contractData = {
    masterData: undefined,
    wegLogic: undefined,
    contactData: undefined,
    anteildata: undefined,
    primeType: undefined, // Primärer Typ -> wird zur Unterscheidung Vertrag oder Kaufanbot benötigt
    id: undefined,
  };

  //undefined für Kauf:
  public setPrimeTypeFixed(val: 'schenkung' | 'miet' | 'kaufanbot' | 'kfz' | undefined) {
    this.setContractPrimeType(val);
  }

  public searchCommunes(filter: string): Observable<any> {
    return this.http.get(
      `https://webhooks.mongodb-stitch.com/api/client/v2.0/app/kvgen-gahig/service/communes/incoming_webhook/filter-communes-v2?filter=${filter}`
      // `https://webhooks.mongodb-stitch.com/api/client/v2.0/app/kvgen-gahig/service/communes/incoming_webhook/filter-communes?filter=${filter}`
    ).pipe(retry(5));
  }

  private Userdata = {
    user: undefined,
    contract: undefined,
  }

  public setUser(user) {
    this.Userdata.user = user;
  }

  public setContract(contract) {
    this.Userdata.contract = contract;
  }

  public getUser() {
    return this.Userdata.user;
  }

  public getContract() {
    return this.Userdata.contract;
  }

  //#region DB SAVE and LOAD
  public saveFromPage(savepage: 'personaldata' | 'weglogic'): boolean {
    return this.SaveToDB(this.getContract(), savepage);
  }

  public SaveToDB(contract, savepage: 'personaldata' | 'weglogic'): boolean {
    if (!contract) {
      contract = this.getNewContractID();
    }

    var con = environment.dburl + contract + '/json';

    return this.SaveDataToDB(con, savepage)
  }

  private makeShortID(length) {
    var result = '';
    var characters = 'abcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  private getNewContractID() {
    let id = this.makeShortID(10);
    this.setContract(id);
    return id;
  }

  private SaveDataToDB(url: string, savepage: 'personaldata' | 'weglogic'): boolean {
    var data = {
      masterData: undefined,
      wegLogic: undefined,
      contactData: undefined,
      primeType: undefined,
      anteildata: undefined,
      page: savepage,
      id: { user: this.getUser(), contract: this.getContract() }
    }

    if (this.contractData.masterData) data.masterData = this.contractData.masterData;
    if (this.contractData.primeType) data.primeType = this.contractData.primeType;
    if (this.contractData.contactData) data.contactData = this.contractData.contactData;
    if (this.contractData.wegLogic) data.wegLogic = this.contractData.wegLogic;
    if (this.contractData.anteildata) data.anteildata = this.contractData.anteildata;

    this.http.post(url, data).subscribe(
      result => {
        console.log('Daten erfolgreich auf DB geladen');
        console.log(result);
        return true;
      },
      err => {
        console.log('Fehler beim Laden auf DB:');
        console.log(err);
        return false;
      }
    );
    return false;

  }


  public LoadFromDB(): 'personaldata' | 'weglogic' | undefined | null {
    let cont = this.getContract();
    if (cont) {
      var con = environment.dburl + cont + '/json';
    }
    else {
      return 'personaldata';
    }

    return this.LoadDataFromDB(con);
  }

  private LoadDataFromDB(url: string): 'personaldata' | 'weglogic' | undefined | null {
    this.http.get(url).subscribe(
      (answ: any) => {
        console.log('Content read from DB:');
        console.log(answ);

        let data = answ.body;

        var gotostr = undefined;
        if (data) {
          if (data.primeType) this.contractData.primeType = data.primeType;

          if (data.page) gotostr = data.page;

          if (data.contactData) this.contractData.contactData = data.contactData;

          if (data.masterData) this.contractData.masterData = data.masterData;

          if (data.wegLogic) this.contractData.wegLogic = data.wegLogic;

          if (data.anteildata) this.contractData.anteildata = data.anteildata;
        }

        return gotostr;
      },
      err => {
        console.log('Error while reading data:');
        console.log(err);
        return null;
      }
    )
    return null;
  }


  //#endregion

  public getContractData() {
    if(localStorage.getItem("contractData")) {
      let contractData = JSON.parse(localStorage.getItem("contractData"));
      this.contractData = contractData;
      return contractData;
    }
    else
      return this.contractData;
  }

  public clearContractData() {
    this.contractData = {
      masterData: undefined,
      wegLogic: undefined,
      contactData: undefined,
      anteildata: undefined,
      primeType: undefined,
      id: undefined,
    };
    localStorage.removeItem("contractData");
    localStorage.removeItem('userLoggedIn');
    localStorage.removeItem('page');
    localStorage.removeItem('primeType');
    localStorage.removeItem('anteilData');
  }

  public setAnteildata(value) {
    this.contractData.anteildata = value;
  }

  public setAnteildata2(v) {
    localStorage.setItem('anteilData',JSON.stringify(v));
  }

  public getAnteildata() {
    return this.contractData.anteildata;
  }

  public ResetMasterDates() {
     // GURB
    // this.contractData.masterData.sellersPrivate.forEach(seller => {
    //   if (seller.saveBirthday) {
    //     seller.formatedBirthday = new Date(seller.saveBirthday);
    //   }
    // });
    // this.contractData.masterData.buyersPrivate.forEach(buyer => {
    //   if (buyer.saveBirthday) {
    //     buyer.formatedBirthday = new Date(buyer.saveBirthday);
    //   }
    // });
  }

  public setContractMasterData(value) {
    this.contractData.masterData = value;

    //Estate zu Company
    this.contractData.masterData.sellersCompanyRightList = this.contractData.masterData.sellersCompany;

    if (this.contractData.masterData.sellersEstate && this.contractData.contactData.estate) {
      this.contractData.masterData.sellersEstate.forEach(element => {
        element.estate = true;
        let a = element.companiesRegisterNumber;
        element.companiesRegisterNumber = this.datepipe.transform(a as string, 'yyyy-MM-dd');
        let b: string = element.companyName;
        let c: string = element.address;
        if (b.indexOf('Verlassenschaft nach ') == -1) element.companyName = 'Verlassenschaft nach ' + b;
        if (c.indexOf('zuletzt wohnhaft in ') == -1) element.address = 'zuletzt wohnhaft in ' + c;
      });
    }
    this.contractData.masterData.sellersCompany = this.contractData.masterData.sellersCompany.concat(this.contractData.masterData.sellersEstate);

    // GURB removed due to date issue,
    //Zusatzvariable Richtiges Datumsformat
    this.contractData.masterData.sellersPrivate.forEach(seller => {
      seller.saveBirthday     = this.datepipe.transform(seller.formatedBirthday as string, 'yyyy-MM-dd');
      seller.birthday         = seller.saveBirthday;
    });

    this.contractData.masterData.buyersPrivate.forEach(buyer => {
      buyer.saveBirthday     = this.datepipe.transform(buyer.formatedBirthday as string, 'yyyy-MM-dd');
      buyer.birthday         = buyer.saveBirthday;
    });

    if (this.contractData.primeType != 'kfz') {
      //Helper für Rechteinhaber
      //Anteildata füllen
      console.log('???????????????');
      console.log(this.contractData);

      // GURB //
      // If their is anteildata, then use it (refresh or loaded case), else from masterdata(new case)
      // if(!this.contractData.anteildata) {
        //   this.contractData.anteildata = this.setAnteilDataFromMasterData();
        // }
      // GURB //

      this.contractData.anteildata = this.setAnteilDataFromMasterData();
      console.log(this.contractData.anteildata);

      this.contractData.masterData.dritte = {
        rightPersonList: this.contractData.masterData.sellersPrivate.concat(this.contractData.masterData.buyersPrivate),
        rightCompanyList: this.contractData.masterData.sellersCompanyRightList.concat(this.contractData.masterData.buyersCompany)
      };
    }

    localStorage.setItem('contractData', JSON.stringify(this.contractData));
    this.setLSBOOL();
    this.setMietNaviBool(false);
  }

  private cloneDeep(item) {
    const copy = _.cloneDeep(item);
    return copy;
  }

  private checkAnteil(itemNo, type, subType, item) {
    console.error('checkAnteil');
    console.log(itemNo, type, subType);
    var masterValue   = this.cloneDeep(item);
    console.log(masterValue);
    if(this.contractData.anteildata && this.contractData.anteildata[itemNo] && this.contractData.anteildata[itemNo][type][subType]) {
      console.log('here');
      var existedAnteil = this.contractData.anteildata[itemNo][type][subType];
      if(existedAnteil && existedAnteil.length) {
        console.log('existedAnteil here');
        console.log(existedAnteil);
        // for (let i in masterValue) {
        //   for (let j in existedAnteil) {
        //     if(masterValue[i]['uid'] === existedAnteil[j]['uid']) {
        //
        //       if(existedAnteil[j]['shareAr'])
        //       masterValue[i]['shareAr'] = existedAnteil[j]['shareAr'];
        //       if(existedAnteil[j]['calcanteil'])
        //       masterValue[i]['calcanteil'] = existedAnteil[j]['calcanteil'];
        //       console.log('masterValue');
        //       console.log(masterValue);
        //       return masterValue;
        //
        //     }
        //   }
        // }
              return existedAnteil;
      } else {
        console.log('existedAnteil not');
        return masterValue;
      }
    } else {
      return masterValue;
    }
  }

  private setAnteilDataFromMasterData() {
    console.log("this.contractData.masterData.buyersPrivate");
    console.log(this.contractData.masterData.buyersPrivate);
    const BuyPriv = this.contractData.masterData.buyersPrivate;
    const BuyComp = this.contractData.masterData.buyersCompany;
    const SelPriv = this.contractData.masterData.sellersPrivate;
    const SelComp = this.contractData.masterData.sellersCompany;

    if (this.contractData.contactData.contractOption === 'WEG') {
      return {
        l1: {
          buyer: {
            buyersPrivate: this.checkAnteil('l1', 'buyer', 'buyersPrivate', BuyPriv),
            buyersCompany: this.checkAnteil('l1', 'buyer', 'buyersCompany', BuyComp)
          },
          seller: {
            sellersPrivate: this.checkAnteil('l1', 'seller', 'sellersPrivate', SelPriv),
            sellersCompany: this.checkAnteil('l1', 'seller', 'sellersCompany', SelComp)
          }
        }
      }
    }

    return {
      l1: {
        buyer: {
          buyersPrivate: this.checkAnteil('l1', 'buyer', 'buyersPrivate', BuyPriv),
          buyersCompany: this.checkAnteil('l1', 'buyer', 'buyersCompany', BuyComp)
        },
        seller: {
          sellersPrivate: this.checkAnteil('l1', 'seller', 'sellersPrivate', SelPriv),
          sellersCompany: this.checkAnteil('l1', 'seller', 'sellersCompany', SelComp)
        }
      },
      l2: {
        buyer: {
          buyersPrivate: this.checkAnteil('l2', 'buyer', 'buyersPrivate', BuyPriv),
          buyersCompany: this.checkAnteil('l2', 'buyer', 'buyersCompany', BuyComp)
        },
        seller: {
          sellersPrivate: this.checkAnteil('l2', 'seller', 'sellersPrivate', SelPriv),
          sellersCompany: this.checkAnteil('l2', 'seller', 'sellersCompany', SelComp)
        }
      },
      l3: {
        buyer: {
          buyersPrivate: this.checkAnteil('l3', 'buyer', 'buyersPrivate', BuyPriv),
          buyersCompany: this.checkAnteil('l3', 'buyer', 'buyersCompany', BuyComp)
        },
        seller: {
          sellersPrivate: this.checkAnteil('l3', 'seller', 'sellersPrivate', SelPriv),
          sellersCompany: this.checkAnteil('l3', 'seller', 'sellersCompany', SelComp)
        }
      },
      l4: {
        buyer: {
          buyersPrivate: this.checkAnteil('l4', 'buyer', 'buyersPrivate', BuyPriv),
          buyersCompany: this.checkAnteil('l4', 'buyer', 'buyersCompany', BuyComp)
        },
        seller: {
          sellersPrivate: this.checkAnteil('l4', 'seller', 'sellersPrivate', SelPriv),
          sellersCompany: this.checkAnteil('l4', 'seller', 'sellersCompany', SelComp)
        }
      }
    };
  }

  public getContractMasterData() {
    if(localStorage.getItem("contractData")) {
      let contractData = JSON.parse(localStorage.getItem("contractData"));
      return contractData.masterData;
    }
    else
      return {};
  }

  public setContractPrimeType(value) {
    this.contractData.primeType = value;
  }

  public getContractPrimeType() {
    if(localStorage.getItem("primeType")) {
      return localStorage.getItem("primeType");
    }
    else {
      console.error('Returning empty primeType');
      return '';
    }
  }

  public setContractWEGLogicData(value, anteilData = null) {
    console.log('in setContractWEGLogicData');
    console.log(this.contractData);
    console.log(value.uebergabeDatum);
    this.contractData.wegLogic = value;
    var data = {};
    const { contactData, primeType, masterData, wegLogic } = this.contractData;
    data['masterData']  = masterData;
    data['wegLogic']    = wegLogic;
    data['contactData'] = contactData;

    let conn = localStorage.getItem("contractData");
    if(conn) {
      let connn = JSON.parse(conn);
      if(connn['postID']) {
        data['postID'] = connn['postID'];
      }
      if(connn['user']) {
        data['user'] = connn['user'];
      }
      if(connn['contractID']) {
        data['contractID'] = connn['contractID'];
      }
    }

    if(anteilData) {
      data['anteildata'] = anteilData;
    }

    data['primeType'] = primeType;
    console.log(data);
    localStorage.setItem('contractData', JSON.stringify(data));
  }

  public getContractWEGLogicData() {
    // return this.contractData.wegLogic;
    if(localStorage.getItem("contractData")) {
      let contractData = JSON.parse(localStorage.getItem("contractData"));
      return contractData.wegLogic;
    }
    else
      return this.contractData.wegLogic;
  }

  public setContractContactData(value) {
    this.contractData.contactData = value;
    localStorage.setItem('contractData', JSON.stringify(this.contractData));
  }

  public getContractContactData() {
    if(localStorage.getItem("contractData")) {
      let contractData = JSON.parse(localStorage.getItem("contractData"));
      return contractData.contactData;
    }
    else
      return this.contractData.contactData;
  }

  public isEmpty(obj) {
    return Object.keys(obj).length === 0;
  }

  public removeShareFormGroup(anteildata) {
    console.error('removeShareFormGroup Start');
    // there is angular form group objects in this, it create issue while stringify
    var immos = ['l1','l2','l3','l4'];
    // var anteils = {'l1' : {},'l2' : {},'l3' : {},'l4' : {}};
    var anteils = Object.assign({}, anteildata);

    if(anteils) {
      for(let immo of immos) {
        console.log('immo is', immo);
        if(anteils[immo]) {
          console.log('immo exist', immo);
          var sellers = anteils[immo]['seller']['sellersPrivate'];
          var sellerCompany = anteils[immo]['seller']['sellersCompany'];
          var buyers  = anteils[immo]['buyer']['buyersPrivate'];
          var buyerCompany  = anteils[immo]['buyer']['buyersCompany'];

          if(sellers && sellers.length) {
            console.log('Has Sellers');
            for(let i in sellers) {
              if(!sellers[i].shareAr) {
                sellers[i]['shareAr'] = [];
              }
              if(sellers[i].shareArDev) {
                delete sellers[i].shareArDev;
              }
            }
          }

          if(sellerCompany && sellerCompany.length) {
            console.log('Has Sellers Company');
            for(let i in sellerCompany) {
              if(!sellerCompany[i].shareAr) {
                sellerCompany[i]['shareAr'] = [];
              }
              if(sellerCompany[i].shareArDev) {
                delete sellerCompany[i].shareArDev;
              }
            }
          }

          if(buyers && buyers.length) {
            console.log('Has Buyers');
            for(let i in buyers) {
              if(!buyers[i].shareAr) {
                buyers[i]['shareAr'] = [];
              }
              if(buyers[i].shareArDev) {
                delete buyers[i].shareArDev;
              }
            }
          }

          if(buyerCompany && buyerCompany.length) {
            console.log('Has Buyers Company');
            for(let i in buyerCompany) {
              if(!buyerCompany[i].shareAr) {
                buyerCompany[i]['shareAr'] = [];
              }
              if(buyerCompany[i].shareArDev) {
                delete buyerCompany[i].shareArDev;
              }
            }
          }

        }
      }
    }
    return anteils;
    console.error('removeShareFormGroup End');
  }

  public replaceCalcanteil() {
    var immos = ['l1','l2','l3','l4'];
    if(this.contractData.anteildata) {
      for(let immo of immos) {
        console.log('immo is', immo);
        if(this.contractData.anteildata[immo]) {
          console.log('immo exist', immo);
          var sellers = this.contractData.anteildata[immo]['seller']['sellersPrivate'];
          var sellerCompany = this.contractData.anteildata[immo]['seller']['sellersCompany'];
          var buyers  = this.contractData.anteildata[immo]['buyer']['buyersPrivate'];
          var buyerCompany  = this.contractData.anteildata[immo]['buyer']['buyersCompany'];

          if(sellers && sellers.length) {
            console.log('Has Sellers');
            for(let i in sellers) {
              if(sellers[i].shareAr && sellers[i].shareAr.length) {
                sellers[i]['calcanteil'] = sellers[i].shareAr[0]['anteil'];
              }
            }
          }

          if(sellerCompany && sellerCompany.length) {
            console.log('Has Sellers Company');
            for(let i in sellerCompany) {
              if(sellerCompany[i].shareAr && sellerCompany[i].shareAr.length) {
                sellerCompany[i]['calcanteil'] = sellerCompany[i].shareAr[0]['anteil'];
              }
            }
          }

          if(buyers && buyers.length) {
            console.log('Has Buyers');
            for(let i in buyers) {
              if(buyers[i].shareAr && buyers[i].shareAr.length) {
                buyers[i]['calcanteil'] = buyers[i].shareAr[0]['anteil'];
              }
            }
          }

          if(buyerCompany && buyerCompany.length) {
            console.log('Has Buyers Company');
            for(let i in buyerCompany) {
              if(buyerCompany[i].shareAr && buyerCompany[i].shareAr.length) {
                buyerCompany[i]['calcanteil'] = buyerCompany[i].shareAr[0]['anteil'];
              }
            }
          }

        }
      }
    }
  }

  public checkSellerShare() {
    console.log('this.contractData.anteildata');
    console.log(this.contractData.anteildata);
    // It removes the seller if he has not any share
    let sellers1      = this.contractData.anteildata['l1']['seller']['sellersPrivate'];
    let sellers1Cmpny = this.contractData.anteildata['l1']['seller']['sellersCompany'];
    let buyers1       = this.contractData.anteildata['l1']['buyer']['buyersPrivate'];
    let buyers1Cmpny  = this.contractData.anteildata['l1']['buyer']['buyersCompany'];

    var sellers2      = undefined;
    var sellers2Cmpny = undefined;
    var buyers2       = undefined;
    var buyers2Cmpny  = undefined;

    var sellers3      = undefined;
    var sellers3Cmpny = undefined;
    var buyers3       = undefined;
    var buyers3Cmpny  = undefined;

    var sellers4      = undefined;
    var sellers4Cmpny = undefined;
    var buyers4       = undefined;
    var buyers4Cmpny  = undefined;

    if(this.contractData.anteildata.l2) {
      sellers2      = this.contractData.anteildata['l2']['seller']['sellersPrivate'];
      sellers2Cmpny = this.contractData.anteildata['l2']['seller']['sellersCompany'];
      buyers2       = this.contractData.anteildata['l2']['buyer']['buyersPrivate'];
      buyers2Cmpny  = this.contractData.anteildata['l2']['buyer']['buyersCompany'];
    }

    if(this.contractData.anteildata.l3) {
      sellers3      = this.contractData.anteildata['l3']['seller']['sellersPrivate'];
      sellers3Cmpny = this.contractData.anteildata['l3']['seller']['sellersCompany'];
      buyers3       = this.contractData.anteildata['l3']['buyer']['buyersPrivate'];
      buyers3Cmpny  = this.contractData.anteildata['l3']['buyer']['buyersCompany'];
    }

    if(this.contractData.anteildata.l4) {
      sellers4      = this.contractData.anteildata['l4']['seller']['sellersPrivate'];
      sellers4Cmpny = this.contractData.anteildata['l4']['seller']['sellersCompany'];
      buyers4       = this.contractData.anteildata['l4']['buyer']['buyersPrivate'];
      buyers4Cmpny  = this.contractData.anteildata['l4']['buyer']['buyersCompany'];
    }

    var sellers1New = [];
    var sellers1CmpnyNew = [];
    var buyers1New = [];
    var buyers1CmpnyNew  = [];


    var sellers2New = [];
    var sellers2CmpnyNew = [];
    var buyers2New = [];
    var buyers2CmpnyNew  = [];

    var sellers3New = [];
    var sellers3CmpnyNew = [];
    var buyers3New = [];
    var buyers3CmpnyNew  = [];

    var sellers4New = [];
    var sellers4CmpnyNew = [];
    var buyers4New = [];
    var buyers4CmpnyNew  = [];

    if(sellers1) {
      console.log('in seller prb 1');
      for(let i in sellers1) {
        if(sellers1[i].shareAr && sellers1[i].shareAr.length) {
          sellers1New.push(sellers1[i]);
        }
      }
      this.contractData.anteildata['l1']['seller']['sellersPrivate'] = sellers1New;
    }
    if(sellers1Cmpny) {
      for(let i in sellers1Cmpny) {
        if(sellers1Cmpny[i].shareAr && sellers1Cmpny[i].shareAr.length) {
          sellers1CmpnyNew.push(sellers1Cmpny[i]);
        }
      }
      this.contractData.anteildata['l1']['seller']['sellersCompany'] = sellers1CmpnyNew;
    }
    if(buyers1) {
      for(let i in buyers1) {
        if(!buyers1[i].shareAr || (buyers1[i].shareAr && buyers1[i].shareAr.length)) {
          buyers1New.push(buyers1[i]);
        }
      }
      this.contractData.anteildata['l1']['buyer']['buyersPrivate'] = buyers1New;
    }

    if(buyers1Cmpny) {
      for(let i in buyers1Cmpny) {
        if(!buyers1Cmpny[i].shareAr || (buyers1Cmpny[i].shareAr && buyers1Cmpny[i].shareAr.length)) {
          buyers1CmpnyNew.push(buyers1Cmpny[i]);
        }
      }
      this.contractData.anteildata['l1']['buyer']['buyersCompany'] = buyers1CmpnyNew;
    }

    if(sellers2) {
      console.log('in sellers2');
      for(let i in sellers2) {
        console.log(i);
        if(sellers2[i].shareAr && sellers2[i].shareAr.length) {
          sellers2New.push(sellers2[i]);
        }
      }
      this.contractData.anteildata['l2']['seller']['sellersPrivate'] = sellers2New;
      console.error(this.contractData.anteildata);
    }

    if(sellers2Cmpny) {
      for(let i in sellers2Cmpny) {
        if(sellers2Cmpny[i].shareAr && sellers2Cmpny[i].shareAr.length) {
          sellers2CmpnyNew.push(sellers2Cmpny[i]);
        }
      }
      this.contractData.anteildata['l2']['seller']['sellersCompany'] = sellers2CmpnyNew;
    }

    if(buyers2) {
      console.log('in buyers2');
      for(let i in buyers2) {
        if(!buyers2[i].shareAr || (buyers2[i].shareAr && buyers2[i].shareAr.length)) {
          buyers2New.push(buyers2[i]);
        }
      }
      this.contractData.anteildata['l2']['buyer']['buyersPrivate'] = buyers2New;
    }
    if(buyers2Cmpny) {
      for(let i in buyers2Cmpny) {
        if(!buyers2Cmpny[i].shareAr || (buyers2Cmpny[i].shareAr && buyers2Cmpny[i].shareAr.length)) {
          buyers2CmpnyNew.push(buyers2Cmpny[i]);
        }
      }
      this.contractData.anteildata['l2']['buyer']['buyersCompany'] = buyers2CmpnyNew;
    }

    if(sellers3) {
      console.log('in sellers3');
      for(let i in sellers3) {
        console.log(i);
        if(sellers3[i].shareAr && sellers3[i].shareAr.length) {
          sellers3New.push(sellers3[i]);
        }
      }
      this.contractData.anteildata['l3']['seller']['sellersPrivate'] = sellers3New;
      console.error(this.contractData.anteildata);
    }

    if(sellers3Cmpny) {
      for(let i in sellers3Cmpny) {
        if(sellers3Cmpny[i].shareAr && sellers3Cmpny[i].shareAr.length) {
          sellers3CmpnyNew.push(sellers3Cmpny[i]);
        }
      }
      this.contractData.anteildata['l3']['seller']['sellersCompany'] = sellers3CmpnyNew;
    }

    if(buyers3) {
      console.log('in buyers3');
      for(let i in buyers3) {
        if(!buyers3[i].shareAr || (buyers3[i].shareAr && buyers3[i].shareAr.length)) {
          buyers3New.push(buyers3[i]);
        }
      }
      this.contractData.anteildata['l3']['buyer']['buyersPrivate'] = buyers3New;
    }

    if(buyers3Cmpny) {
      for(let i in buyers3Cmpny) {
        if(!buyers3Cmpny[i].shareAr || (buyers3Cmpny[i].shareAr && buyers3Cmpny[i].shareAr.length)) {
          buyers3CmpnyNew.push(buyers3Cmpny[i]);
        }
      }
      this.contractData.anteildata['l3']['buyer']['buyersCompany'] = buyers3CmpnyNew;
    }


    if(sellers4) {
      console.log('in sellers4');
      for(let i in sellers4) {
        console.log(i);
        if(sellers4[i].shareAr && sellers4[i].shareAr.length) {
          sellers4New.push(sellers4[i]);
        }
      }
      this.contractData.anteildata['l4']['seller']['sellersPrivate'] = sellers4New;
      console.error(this.contractData.anteildata);
    }

    if(sellers4Cmpny) {
      for(let i in sellers4Cmpny) {
        if(sellers4Cmpny[i].shareAr && sellers4Cmpny[i].shareAr.length) {
          sellers4CmpnyNew.push(sellers4Cmpny[i]);
        }
      }
      this.contractData.anteildata['l4']['seller']['sellersCompany'] = sellers4CmpnyNew;
    }

    if(buyers4) {
      console.log('in buyers4');
      for(let i in buyers4) {
        if(!buyers4[i].shareAr || (buyers4[i].shareAr && buyers4[i].shareAr.length)) {
          buyers4New.push(buyers4[i]);
        }
      }
      this.contractData.anteildata['l4']['buyer']['buyersPrivate'] = buyers4New;
    }

    if(buyers4Cmpny) {
      for(let i in buyers4Cmpny) {
        if(!buyers4Cmpny[i].shareAr || (buyers4Cmpny[i].shareAr && buyers4Cmpny[i].shareAr.length)) {
          buyers4CmpnyNew.push(buyers4Cmpny[i]);
        }
      }
      this.contractData.anteildata['l4']['buyer']['buyersCompany'] = buyers4CmpnyNew;
    }

    if(this.contractData.anteildata.l1) {
      console.log('anzhl', sellers1.length);
      this.contractData.anteildata['l1']['seller']['anzahl'] = sellers1New.length + sellers1CmpnyNew.length;
      this.contractData.anteildata['l1']['buyer']['anzahl'] = buyers1New.length + buyers1CmpnyNew.length;
    }

    if(this.contractData.anteildata.l2) {
      this.contractData.anteildata['l2']['buyer']['anzahl'] = buyers2New.length + buyers2CmpnyNew.length;
      this.contractData.anteildata['l2']['seller']['anzahl'] = sellers2New.length + sellers2CmpnyNew.length;
    }

    if(this.contractData.anteildata.l3) {
      this.contractData.anteildata['l3']['buyer']['anzahl'] = buyers3New.length + buyers3CmpnyNew.length;
      this.contractData.anteildata['l3']['seller']['anzahl'] = sellers3New.length + sellers3CmpnyNew.length;
    }

    if(this.contractData.anteildata.l4) {
      this.contractData.anteildata['l4']['buyer']['anzahl'] = buyers4New.length + buyers4CmpnyNew.length;
      this.contractData.anteildata['l4']['seller']['anzahl'] = sellers4New.length + sellers4CmpnyNew.length;
    }

  }

  // public postData() {
  //   if (this.contractData.contactData.contractOption === 'BBL' || this.contractData.contactData.contractOption === 'UBL') {
  //     //Damit Daten in JSON konvertiert werden können, müssen alle Form gschlüdr raus + unnötige sacha weg
  //     this.shimShare();
  //     this.shimAnteilData();
  //     this.checkSellerShare();
  //   }
  // }

  private shimUBBLAnteileLS1() {
    let tempCounter = 0;
    const alleanteile = [];
    const alleblnr = [];
    this.contractData.anteildata.l1.seller.sellersPrivate.forEach((seller) => {
      const anteile = [];
      const blnr = [];

      let anteilTemp;
      let blnrTemp;

      if (tempCounter === 0) {
        anteilTemp = 'anteil';
        blnrTemp = 'bLaufendeNr';
      } else {
        anteilTemp = 'anteil' + tempCounter;
        blnrTemp = 'bLaufendeNr' + tempCounter;
      }

      tempCounter++;
      seller.shareAr.forEach(item => {
        anteile.push(item.anteil);
        blnr.push(item.BLNR);
        alleanteile.push(item.anteil);
        alleblnr.push(item.BLNR);
      });

      this.contractData.wegLogic.immodetail[anteilTemp] = anteile;
      this.contractData.wegLogic.immodetail[blnrTemp] = blnr;
    });

    this.contractData.anteildata.l1.seller.sellersCompany.forEach((seller) => {
      const anteile = [];
      const blnr = [];

      let anteilTemp;
      let blnrTemp;

      if (tempCounter === 0) {
        anteilTemp = 'anteil';
        blnrTemp = 'bLaufendeNr';
      } else {
        anteilTemp = 'anteil' + tempCounter;
        blnrTemp = 'bLaufendeNr' + tempCounter;
      }

      tempCounter++;

      seller.shareAr.forEach(item => {
        anteile.push(item.anteil);
        blnr.push(item.BLNR);
        alleanteile.push(item.anteil);
        alleblnr.push(item.BLNR);
      });

      this.contractData.wegLogic.immodetail[anteilTemp] = anteile;
      this.contractData.wegLogic.immodetail[blnrTemp] = blnr;
    });
    this.contractData.wegLogic.immodetail.alleAnteile = alleanteile;
    this.contractData.wegLogic.immodetail.alleBlnr = alleblnr;
  }

  private shimUBBLAnteileLS2() {
    let tempCounter = 0;
    const alleanteile = [];
    const alleblnr = [];
    this.contractData.anteildata.l2.seller.sellersPrivate.forEach((seller) => {
      const anteile = [];
      const blnr = [];

      let anteilTemp;
      let blnrTemp;

      if (tempCounter === 0) {
        anteilTemp = 'anteil';
        blnrTemp = 'bLaufendeNr';
      } else {
        anteilTemp = 'anteil' + tempCounter;
        blnrTemp = 'bLaufendeNr' + tempCounter;
      }

      tempCounter++;

      seller.shareAr.forEach(item => {
        anteile.push(item.anteil);
        blnr.push(item.BLNR);
        alleanteile.push(item.anteil);
        alleblnr.push(item.BLNR);
      });

      this.contractData.wegLogic.l2immodetail[anteilTemp] = anteile;
      this.contractData.wegLogic.l2immodetail[blnrTemp] = blnr;
    });

    this.contractData.anteildata.l2.seller.sellersCompany.forEach((seller) => {
      const anteile = [];
      const blnr = [];

      let anteilTemp;
      let blnrTemp;

      if (tempCounter === 0) {
        anteilTemp = 'anteil';
        blnrTemp = 'bLaufendeNr';
      } else {
        anteilTemp = 'anteil' + tempCounter;
        blnrTemp = 'bLaufendeNr' + tempCounter;
      }
      tempCounter++;

      seller.shareAr.forEach(item => {
        anteile.push(item.anteil);
        blnr.push(item.BLNR);
        alleanteile.push(item.anteil);
        alleblnr.push(item.BLNR);
      });

      this.contractData.wegLogic.l2immodetail[anteilTemp] = anteile;
      this.contractData.wegLogic.l2immodetail[blnrTemp] = blnr;
    });
    this.contractData.wegLogic.l2immodetail.alleAnteile = alleanteile;
    this.contractData.wegLogic.l2immodetail.alleBlnr = alleblnr;
  }

  private shimUBBLAnteileLS3() {
    let tempCounter = 0;
    const alleanteile = [];
    const alleblnr = [];
    this.contractData.anteildata.l3.seller.sellersPrivate.forEach((seller) => {
      const anteile = [];
      const blnr = [];
      let anteilTemp;
      let blnrTemp;

      if (tempCounter === 0) {
        anteilTemp = 'anteil';
        blnrTemp = 'bLaufendeNr';
      } else {
        anteilTemp = 'anteil' + tempCounter;
        blnrTemp = 'bLaufendeNr' + tempCounter;
      }

      tempCounter++;

      seller.shareAr.forEach(item => {
        anteile.push(item.anteil);
        blnr.push(item.BLNR);
        alleanteile.push(item.anteil);
        alleblnr.push(item.BLNR);
      });

      this.contractData.wegLogic.l3immodetail[anteilTemp] = anteile;
      this.contractData.wegLogic.l3immodetail[blnrTemp] = blnr;
    });

    this.contractData.anteildata.l3.seller.sellersCompany.forEach((seller) => {
      const anteile = [];
      const blnr = [];

      let anteilTemp;
      let blnrTemp;

      if (tempCounter === 0) {
        anteilTemp = 'anteil';
        blnrTemp = 'bLaufendeNr';
      } else {
        anteilTemp = 'anteil' + tempCounter;
        blnrTemp = 'bLaufendeNr' + tempCounter;
      }

      tempCounter++;

      seller.shareAr.forEach(item => {
        anteile.push(item.anteil);
        blnr.push(item.BLNR);
        alleanteile.push(item.anteil);
        alleblnr.push(item.BLNR);
      });

      this.contractData.wegLogic.l3immodetail[anteilTemp] = anteile;
      this.contractData.wegLogic.l3immodetail[blnrTemp] = blnr;
    });
    this.contractData.wegLogic.l3immodetail.alleAnteile = alleanteile;
    this.contractData.wegLogic.l3immodetail.alleBlnr = alleblnr;
  }

  private shimUBBLAnteileLS4() {
    let tempCounter = 0;
    const alleanteile = [];
    const alleblnr = [];
    this.contractData.anteildata.l4.seller.sellersPrivate.forEach((seller) => {
      const anteile = [];
      const blnr = [];

      let anteilTemp;
      let blnrTemp;

      if (tempCounter === 0) {
        anteilTemp = 'anteil';
        blnrTemp = 'bLaufendeNr';
      } else {
        anteilTemp = 'anteil' + tempCounter;
        blnrTemp = 'bLaufendeNr' + tempCounter;
      }
      tempCounter++;

      seller.shareAr.forEach(item => {
        anteile.push(item.anteil);
        blnr.push(item.BLNR);
        alleanteile.push(item.anteil);
        alleblnr.push(item.BLNR);
      });

      this.contractData.wegLogic.l4immodetail[anteilTemp] = anteile;
      this.contractData.wegLogic.l4immodetail[blnrTemp] = blnr;
    });

    this.contractData.anteildata.l4.seller.sellersCompany.forEach((seller) => {
      const anteile = [];
      const blnr = [];

      let anteilTemp;
      let blnrTemp;

      if (tempCounter === 0) {
        anteilTemp = 'anteil';
        blnrTemp = 'bLaufendeNr';
      } else {
        anteilTemp = 'anteil' + tempCounter;
        blnrTemp = 'bLaufendeNr' + tempCounter;
      }

      tempCounter++;

      seller.shareAr.forEach(item => {
        anteile.push(item.anteil);
        blnr.push(item.BLNR);
        alleanteile.push(item.anteil);
        alleblnr.push(item.BLNR);
      });


      this.contractData.wegLogic.l4immodetail[anteilTemp] = anteile;
      this.contractData.wegLogic.l4immodetail[blnrTemp] = blnr;
    });
    this.contractData.wegLogic.l4immodetail.alleAnteile = alleanteile;
    this.contractData.wegLogic.l4immodetail.alleBlnr = alleblnr;
  }

  public parkingChange(connn) {
    connn.wegLogic.parkingPlaces.forEach(
      (parkingPlace, index) => {


      });

    // Fix 1 zu 1/1
    connn.wegLogic.parkingPlaces.forEach(
      (parkingPlace, index) => {
      });
  }

  public doMietWegChange(connn) {
    var immos = [];
    const privateSellers = connn.masterData.sellersPrivate.length;
    const corporateSellers = connn.masterData.sellersCompany.length;
    const sellers = privateSellers + corporateSellers;

    const privateBuyers = connn.masterData.buyersPrivate.length;
    const corporateBuyers = connn.masterData.buyersCompany.length;
    let asdf;
    if (connn.wegLogic.EP == 'eigentuemeruebertraghalbeanteile') asdf = privateBuyers + corporateBuyers + 1;
    else asdf = privateBuyers + corporateBuyers;
    const buyers = asdf;

    if(connn.wegLogic.immodetail && connn.wegLogic.immodetail.einlageZahlObject) {
      let einlageZahlObject  = this.copyJson(connn.wegLogic.immodetail.einlageZahlObject);
      // let einlAllOthers = einlageZahlObject.splice(0, 1);

      if(einlageZahlObject.length) {
        einlageZahlObject.forEach((ein, key) => {

          if(ein.parkingPlace) {
            let parkings = ein.parkingPlace;

            let { anteil } = ein;
            const anteilArEIN = anteil.split('/');
            let anteilNenner = parseInt(anteilArEIN[1]);

            if(parkings && parkings.length) {
              parkings.forEach((parking, keyy) => {
                console.log(parking);

                console.log(parking['pAnteil']);
                const anteilArP = parking['pAnteil'].split('/');
                const anteilZaehlerP = parseInt(anteilArP[0]);
                let anteilNennerP = parseInt(anteilArP[1]);

                const calcAnteilTempP = ((sellers / buyers) * anteilZaehlerP) / anteilNennerP;
                const y = new Fraction(calcAnteilTempP);

                const multiplyFactorP = anteilNenner / y.d;
                let calcAnteilFractionStringCounterP = y.n * multiplyFactorP;

                if ((calcAnteilFractionStringCounterP * 2) % 2 !== 0) {
                  calcAnteilFractionStringCounterP = calcAnteilFractionStringCounterP * 2;
                  anteilNennerP = anteilNennerP * 2;
                }
                parking['pCalcAnteil'] = calcAnteilFractionStringCounterP.toString() + '/' + anteilNennerP;

                if (parking['pCalcAnteil'] === '1') {
                  parking['pCalcAnteil'] = '1/1';
                }
                if(parking.kaufpreisinEUR) {
                  const m = parking.kaufpreisinEUR;
                  parking['kaufpreisRestore'] = m;
                  try {
                    parking['kaufpreisinEUR'] = this.currencyPipe.transform(m, 'EUR');
                  } catch(err) {
                    console.log('err');
                  }
                }

              });
            }

            if(key == 0) {
              connn.wegLogic['parkingPlaces'] = parkings;
            } else {
              connn.wegLogic['parkingPlaces' + key] = parkings;
            }
          }

          if(key != 0) {
            var immo = {};
            const { top, anteil, bLaufendeNrAr, einlageZahl, grundstuecksnummerAr, parkingPlaceCnt } = ein;
            immo['top'] = top;
            immo['anteil'] = anteil;
            immo['katastralGemeinde'] = connn.wegLogic.immodetail['katastralGemeinde'];
            immo['einlageZahl'] = einlageZahl;
            immo['bLaufendeNrAr'] = bLaufendeNrAr;
            immo['grundstuecksnummerAr'] = grundstuecksnummerAr;
            immo['parkingPlaceCnt'] = parkingPlaceCnt;
            immo['calcAnteil'] = connn.wegLogic.immodetail['calcAnteil'];

            const anteilAr = anteil.split('/');
            const anteilZaehler = parseInt(anteilAr[0]);
            let anteilNenner = parseInt(anteilAr[1]);
            // const calcAnteilTemp = sellers / buyers * (anteilZaehler / anteilNenner);
            const calcAnteilTemp = ((sellers / buyers) * anteilZaehler) / anteilNenner;
            const x = new Fraction(calcAnteilTemp);

            const multiplyFactor = anteilNenner / x.d;
            let calcAnteilFractionStringCounter = x.n * multiplyFactor;

            if ((calcAnteilFractionStringCounter * 2) % 2 !== 0) {
              calcAnteilFractionStringCounter = calcAnteilFractionStringCounter * 2;
              anteilNenner = anteilNenner * 2;
            }

            immo['calcAnteil'] = calcAnteilFractionStringCounter.toString() + '/' + anteilNenner;

            // Fix 1 zu 1/1
            if (immo['calcAnteil'] === '1') {
              immo['calcAnteil'] = '1/1';
            }

            if (immo['calc1Anteil'] === '1') {
              immo['calc1Anteil'] = '1/1';
            }

            let grundstuecksnummer = [];
            if(grundstuecksnummerAr && grundstuecksnummerAr.length) {
              grundstuecksnummerAr.forEach(gst => {
                grundstuecksnummer.push(gst.grundstuecksnummer);
              });
            }
            immo['grundstuecksnummer'] = grundstuecksnummer;

            if (bLaufendeNrAr[0]) {
              immo['bLaufendeNr'] = bLaufendeNrAr[0].bLaufendeNr;
            }

            if (bLaufendeNrAr[1]) {
              immo['bLaufendeNr2'] = bLaufendeNrAr[1].bLaufendeNr;
            }
            connn.wegLogic['immodetail' + key] = immo;
          }
        });
      }
    }
    // if(connn.wegLogic.parkingPlaces && connn.wegLogic.parkingPlaces.length) {
    //   connn.wegLogic.parkingPlaces1 = connn.wegLogic.parkingPlaces;
    // }
    return connn;
  }

  public doMietWegChange2(connn) {
    // 8219
    console.log("OKKKK WEG & MIET");
    console.log(connn);
    var immos = [];
    let grundAll = this.copyJson(connn.wegLogic.immodetail.grundstuecksnummerAr);
    let einlAll  = this.copyJson(connn.wegLogic.immodetail.einlageZahlAr);
    console.log(grundAll);
    console.log(einlAll);

    let grundAllOthers = this.copyJson(grundAll);
    let einlAllOthers  = this.copyJson(einlAll);

    let firstGrund = grundAllOthers.splice(0, 1);
    let firsteinl = einlAllOthers.splice(0, 1);

    console.log(grundAllOthers);
    console.log(einlAllOthers);

    connn.wegLogic['immodetail']['einlageZahl']            = connn.wegLogic.immodetail.einlageZahlAr[0]['einlageZahl'];
    connn.wegLogic['immodetail']['einlageZahlAr']          = firsteinl;
    connn.wegLogic['immodetail']['grundstuecksnummerAr']   = [firstGrund[0]];
    connn.wegLogic['immodetail']['grundstuecksnummer']   = [firstGrund[0]['grundstuecksnummer']];

    if(einlAllOthers.length) {
      einlAllOthers.forEach((ein, i) => {
        let key = i + 1;
        connn.wegLogic['immodetail' + key] = this.copyJson(connn.wegLogic.immodetail);
        connn.wegLogic['immodetail' + key]['einlageZahl'] = ein.einlageZahl;
        connn.wegLogic['immodetail' + key]['einlageZahlAr'] = [ein];
        console.log(grundAllOthers);
        console.log(grundAllOthers[i]);
        if (grundAllOthers.hasOwnProperty(i)) {
          connn.wegLogic['immodetail' + key]['grundstuecksnummerAr'] = [grundAllOthers[i]];
          connn.wegLogic['immodetail' + key]['grundstuecksnummer']     = [grundAllOthers[i]['grundstuecksnummer']];
        }
      });
    }

    // if(connn.wegLogic.parkingPlaces && connn.wegLogic.parkingPlaces.length) {
    //   connn.wegLogic.parkingPlaces1 = connn.wegLogic.parkingPlaces;
    // }
    // console.log(connn.wegLogic);
    return connn;
  }

  public copyJson(v) {
    return JSON.parse(JSON.stringify(v));
  }

  public postData() {
    console.log('in post data');
    console.log(this.contractData);
    if (this.contractData.contactData.contractOption === 'BBL' || this.contractData.contactData.contractOption === 'UBL') {
      //Damit Daten in JSON konvertiert werden können, müssen alle Form gschlüdr raus + unnötige sacha weg
      this.shimShare();
      this.shimAnteilData();
      this.checkSellerShare();

      // Copied From Weglogic, JULY8, Calculation again
      if (this.LSBOOL[0]) {
        this.shimUBBLAnteileLS1();
      }
      if (this.LSBOOL[1]) {
        this.shimUBBLAnteileLS2();
      }
      if (this.LSBOOL[2]) {
        this.shimUBBLAnteileLS3();
      }
      if (this.LSBOOL[3]) {
        this.shimUBBLAnteileLS4();
      }
    }

    this.replaceCalcanteil();
    console.log(this.contractData);

    this.contractData.id = { user: this.getUser(), contract: this.getContract() };

    if(localStorage.getItem('userId'))
      this.contractData['benutzer'] = localStorage.getItem('userId');

      this.contractData['primeType'] = localStorage.getItem('primeType');


      if(localStorage.getItem('user')) {
        var uu = JSON.parse(localStorage.getItem('user'));
        this.contractData['user'] = uu["_id"];
      }

    if(typeof this.contractData.contactData.kaeuferanteile === 'object' ) {
      if(Object.keys(this.contractData.contactData.kaeuferanteile).length === 0) {
        this.contractData.contactData.kaeuferanteile = 'Ja';
      }
    }

    if(this.contractData.contactData.kaeuferanteile === '') {
      this.contractData.contactData.kaeuferanteile = 'Ja';
    }

    localStorage.setItem('contractData', JSON.stringify(this.contractData));
    console.log(this.contractData);
    var contractInput = this.contractData;
    if (this.contractData.contactData.contractOption == "WEG" && this.contractData.primeType == "miet") {
      contractInput = this.doMietWegChange(this.copyJson(this.contractData));
    }
    console.log(contractInput);

    this.http
      .post(environment.NodeAppUrl + '/kvpost', contractInput , this.httpOptions)
      .subscribe(res => {

        setTimeout(() => {
          this.changeLoading({ loading: false });
           // Clears Data in local storage, after database save
          this.clearContractData();
          this.router.navigate(['/finish']);
        }, 1500);

      }, err => {
        this.router.navigate(['/submiterror']);
      });
  }

  private shimAnteilData() {
    if (this.LSBOOL[0]) {

      // if (KlengthPriv == 0) this.contractData.anteildata.l1.buyer.buyersPrivate = undefined;
      // else {
      //   let indexAr = new Array<number>(KlengthPriv);
      //   this.contractData.anteildata.l1.buyer.buyersPrivate.forEach((buyer, index) => {
      //     if (buyer.calcanteil == "-1") indexAr.push(index);
      //   });
      //
      //   indexAr.forEach(index => {
      //     this.contractData.anteildata.l1.buyer.buyersPrivate.splice(index, 1);
      //   });
      //   if (this.contractData.anteildata.l1.buyer.buyersPrivate.length == 0) this.contractData.anteildata.l1.buyer.buyersPrivate = undefined;
      // }

      // if (lengthComp == 0) this.contractData.anteildata.l1.seller.sellersCompany = undefined;
      // else {
      //   let indexAr = new Array<number>(lengthPriv);
      //   this.contractData.anteildata.l1.seller.sellersCompany.forEach((seller, index) => {
      //     if (seller.calcanteil == "-1") indexAr.push(index);
      //   });
      //
      //   indexAr.forEach(index => {
      //     this.contractData.anteildata.l1.seller.sellersCompany.splice(index, 1);
      //   });
      //   if (this.contractData.anteildata.l1.seller.sellersCompany.length == 0) this.contractData.anteildata.l1.seller.sellersCompany = undefined;
      // }

      // if (lengthPriv == 0) this.contractData.anteildata.l1.seller.sellersPrivate = undefined;
      // else {
      //   let indexAr = new Array<number>(lengthPriv);
      //   this.contractData.anteildata.l1.seller.sellersPrivate.forEach((seller, index) => {
      //     if (seller.calcanteil == "-1") indexAr.push(index);
      //   });
      //
      //   indexAr.forEach(index => {
      //     this.contractData.anteildata.l1.seller.sellersPrivate.splice(index, 1);
      //   });
      //   if (this.contractData.anteildata.l1.seller.sellersPrivate.length == 0) this.contractData.anteildata.l1.seller.sellersPrivate = undefined;
      // }
      //
      // if (KlengthComp == 0) this.contractData.anteildata.l1.buyer.buyersCompany = undefined;
      // else {
      //   let indexAr = new Array<number>(KlengthComp);
      //   this.contractData.anteildata.l1.buyer.buyersCompany.forEach((buyer, index) => {
      //     if (buyer.calcanteil == "-1") indexAr.push(index);
      //   });
      //
      //   indexAr.forEach(index => {
      //     this.contractData.anteildata.l1.buyer.buyersCompany.splice(index, 1);
      //   });
      //   if (this.contractData.anteildata.l1.buyer.buyersCompany.length == 0) this.contractData.anteildata.l1.buyer.buyersCompany = undefined;
      // }

      let KlengthPriv;
      let KlengthComp;
      let lengthPriv;
      let lengthComp;


      if (this.contractData.anteildata.l1.buyer.buyersPrivate) KlengthPriv = this.contractData.anteildata.l1.buyer.buyersPrivate.length
      if (this.contractData.anteildata.l1.buyer.buyersCompany) KlengthComp = this.contractData.anteildata.l1.buyer.buyersCompany.length
      if (this.contractData.anteildata.l1.seller.sellersPrivate) lengthPriv = this.contractData.anteildata.l1.seller.sellersPrivate.length;
      if (this.contractData.anteildata.l1.seller.sellersCompany) lengthComp = this.contractData.anteildata.l1.seller.sellersCompany.length;

      this.contractData.anteildata.l1.seller.anzahl = lengthPriv + lengthComp;
      this.contractData.anteildata.l1.buyer.anzahl = KlengthPriv + KlengthComp;
    }
    else { this.contractData.anteildata.l1 = undefined; }

    if (this.LSBOOL[1]) {

      // let KlengthPriv = this.contractData.anteildata.l2.buyer.buyersPrivate.length
      // let KlengthComp = this.contractData.anteildata.l2.buyer.buyersCompany.length
      // let lengthPriv = this.contractData.anteildata.l2.seller.sellersPrivate.length;
      // let lengthComp = this.contractData.anteildata.l2.seller.sellersCompany.length;

      let KlengthPriv;
      let KlengthComp;
      let lengthPriv;
      let lengthComp;

      // if (KlengthPriv == 0) this.contractData.anteildata.l2.buyer.buyersPrivate = undefined;
      // else {
      //   let indexAr = new Array<number>(KlengthPriv);
      //   this.contractData.anteildata.l2.buyer.buyersPrivate.forEach((buyer, index) => {
      //     if (buyer.calcanteil == "-1") indexAr.push(index);
      //   });
      //
      //   indexAr.forEach(index => {
      //     this.contractData.anteildata.l2.buyer.buyersPrivate.splice(index, 1);
      //   });
      //   if (this.contractData.anteildata.l2.buyer.buyersPrivate.length == 0) this.contractData.anteildata.l2.buyer.buyersPrivate = undefined;
      // }
      //
      // if (lengthComp == 0) this.contractData.anteildata.l2.seller.sellersCompany = undefined;
      // else {
      //   let indexAr = new Array<number>(lengthPriv);
      //   this.contractData.anteildata.l2.seller.sellersCompany.forEach((seller, index) => {
      //     if (seller.calcanteil == "-1") indexAr.push(index);
      //   });
      //
      //   indexAr.forEach(index => {
      //     this.contractData.anteildata.l2.seller.sellersCompany.splice(index, 1);
      //   });
      //   if (this.contractData.anteildata.l2.seller.sellersCompany.length == 0) this.contractData.anteildata.l2.seller.sellersCompany = undefined;
      // }
      //
      // if (lengthPriv == 0) this.contractData.anteildata.l2.seller.sellersPrivate = undefined;
      // else {
      //   let indexAr = new Array<number>(lengthPriv);
      //   this.contractData.anteildata.l2.seller.sellersPrivate.forEach((seller, index) => {
      //     if (seller.calcanteil == "-1") indexAr.push(index);
      //   });
      //
      //   indexAr.forEach(index => {
      //     this.contractData.anteildata.l2.seller.sellersPrivate.splice(index, 1);
      //   });
      //   if (this.contractData.anteildata.l2.seller.sellersPrivate.length == 0) this.contractData.anteildata.l2.seller.sellersPrivate = undefined;
      // }
      //
      // if (KlengthComp == 0) this.contractData.anteildata.l2.buyer.buyersCompany = undefined;
      // else {
      //   let indexAr = new Array<number>(KlengthComp);
      //   this.contractData.anteildata.l2.buyer.buyersCompany.forEach((buyer, index) => {
      //     if (buyer.calcanteil == "-1") indexAr.push(index);
      //   });
      //
      //   indexAr.forEach(index => {
      //     this.contractData.anteildata.l2.buyer.buyersCompany.splice(index, 1);
      //   });
      //   if (this.contractData.anteildata.l2.buyer.buyersCompany.length == 0) this.contractData.anteildata.l2.buyer.buyersCompany = undefined;
      // }

      if (this.contractData.anteildata.l2.buyer.buyersPrivate) KlengthPriv = this.contractData.anteildata.l2.buyer.buyersPrivate.length
      if (this.contractData.anteildata.l2.buyer.buyersCompany) lengthComp = this.contractData.anteildata.l2.buyer.buyersCompany.length
      if (this.contractData.anteildata.l2.seller.sellersPrivate) lengthPriv = this.contractData.anteildata.l2.seller.sellersPrivate.length;
      if (this.contractData.anteildata.l2.seller.sellersCompany) lengthComp = this.contractData.anteildata.l2.seller.sellersCompany.length;

      this.contractData.anteildata.l2.seller.anzahl = lengthPriv + lengthComp;
      this.contractData.anteildata.l2.buyer.anzahl = KlengthPriv + KlengthComp;
    }
    else { this.contractData.anteildata.l2 = undefined; }

    if (this.LSBOOL[2]) {

      // let KlengthPriv = this.contractData.anteildata.l3.buyer.buyersPrivate.length
      // let KlengthComp = this.contractData.anteildata.l3.buyer.buyersCompany.length
      // let lengthPriv = this.contractData.anteildata.l3.seller.sellersPrivate.length;
      // let lengthComp = this.contractData.anteildata.l3.seller.sellersCompany.length;

      let KlengthPriv;
      let KlengthComp;
      let lengthPriv;
      let lengthComp;


      // if (KlengthPriv == 0) this.contractData.anteildata.l3.buyer.buyersPrivate = undefined;
      // else {
      //   let indexAr = new Array<number>(KlengthPriv);
      //   this.contractData.anteildata.l3.buyer.buyersPrivate.forEach((buyer, index) => {
      //     if (buyer.calcanteil == "-1") indexAr.push(index);
      //   });
      //
      //   indexAr.forEach(index => {
      //     this.contractData.anteildata.l3.buyer.buyersPrivate.splice(index, 1);
      //   });
      //   if (this.contractData.anteildata.l3.buyer.buyersPrivate.length == 0) this.contractData.anteildata.l3.buyer.buyersPrivate = undefined;
      // }
      //
      // if (lengthComp == 0) this.contractData.anteildata.l3.seller.sellersCompany = undefined;
      // else {
      //   let indexAr = new Array<number>(lengthPriv);
      //   this.contractData.anteildata.l3.seller.sellersCompany.forEach((seller, index) => {
      //     if (seller.calcanteil == "-1") indexAr.push(index);
      //   });
      //
      //   indexAr.forEach(index => {
      //     this.contractData.anteildata.l3.seller.sellersCompany.splice(index, 1);
      //   });
      //   if (this.contractData.anteildata.l3.seller.sellersCompany.length == 0) this.contractData.anteildata.l3.seller.sellersCompany = undefined;
      // }
      //
      // if (lengthPriv == 0) this.contractData.anteildata.l3.seller.sellersPrivate = undefined;
      // else {
      //   let indexAr = new Array<number>(lengthPriv);
      //   this.contractData.anteildata.l3.seller.sellersPrivate.forEach((seller, index) => {
      //     if (seller.calcanteil == "-1") indexAr.push(index);
      //   });
      //
      //   indexAr.forEach(index => {
      //     this.contractData.anteildata.l3.seller.sellersPrivate.splice(index, 1);
      //   });
      //   if (this.contractData.anteildata.l3.seller.sellersPrivate.length == 0) this.contractData.anteildata.l3.seller.sellersPrivate = undefined;
      // }
      //
      // if (KlengthComp == 0) this.contractData.anteildata.l3.buyer.buyersCompany = undefined;
      // else {
      //   let indexAr = new Array<number>(KlengthComp);
      //   this.contractData.anteildata.l3.buyer.buyersCompany.forEach((buyer, index) => {
      //     if (buyer.calcanteil == "-1") indexAr.push(index);
      //   });
      //
      //   indexAr.forEach(index => {
      //     this.contractData.anteildata.l3.buyer.buyersCompany.splice(index, 1);
      //   });
      //   if (this.contractData.anteildata.l3.buyer.buyersCompany.length == 0) this.contractData.anteildata.l3.buyer.buyersCompany = undefined;
      // }

      if (this.contractData.anteildata.l3.buyer.buyersPrivate) KlengthPriv = this.contractData.anteildata.l3.buyer.buyersPrivate.length
      if (this.contractData.anteildata.l3.buyer.buyersCompany) KlengthComp = this.contractData.anteildata.l3.buyer.buyersCompany.length
      if (this.contractData.anteildata.l3.seller.sellersPrivate) lengthPriv = this.contractData.anteildata.l3.seller.sellersPrivate.length;
      if (this.contractData.anteildata.l3.seller.sellersCompany) lengthComp = this.contractData.anteildata.l3.seller.sellersCompany.length;

      this.contractData.anteildata.l3.seller.anzahl = lengthPriv + lengthComp;
      this.contractData.anteildata.l3.buyer.anzahl = KlengthPriv + KlengthComp;
    }
    else { this.contractData.anteildata.l3 = undefined; }

    if (this.LSBOOL[3]) {

      // let KlengthPriv = this.contractData.anteildata.l4.buyer.buyersPrivate.length
      // let KlengthComp = this.contractData.anteildata.l4.buyer.buyersCompany.length
      // let lengthPriv = this.contractData.anteildata.l4.seller.sellersPrivate.length;
      // let lengthComp = this.contractData.anteildata.l4.seller.sellersCompany.length;

      let KlengthPriv;
      let KlengthComp;
      let lengthPriv;
      let lengthComp;


      // if (KlengthPriv == 0) this.contractData.anteildata.l4.buyer.buyersPrivate = undefined;
      // else {
      //   let indexAr = new Array<number>(KlengthPriv);
      //   this.contractData.anteildata.l4.buyer.buyersPrivate.forEach((buyer, index) => {
      //     if (buyer.calcanteil == "-1") indexAr.push(index);
      //   });
      //
      //   indexAr.forEach(index => {
      //     this.contractData.anteildata.l4.buyer.buyersPrivate.splice(index, 1);
      //   });
      //   if (this.contractData.anteildata.l4.buyer.buyersPrivate.length == 0) this.contractData.anteildata.l4.buyer.buyersPrivate = undefined;
      // }
      //
      // if (lengthComp == 0) this.contractData.anteildata.l4.seller.sellersCompany = undefined;
      // else {
      //   let indexAr = new Array<number>(lengthPriv);
      //   this.contractData.anteildata.l4.seller.sellersCompany.forEach((seller, index) => {
      //     if (seller.calcanteil == "-1") indexAr.push(index);
      //   });
      //
      //   indexAr.forEach(index => {
      //     this.contractData.anteildata.l4.seller.sellersCompany.splice(index, 1);
      //   });
      //   if (this.contractData.anteildata.l4.seller.sellersCompany.length == 0) this.contractData.anteildata.l4.seller.sellersCompany = undefined;
      // }
      //
      // if (lengthPriv == 0) this.contractData.anteildata.l4.seller.sellersPrivate = undefined;
      // else {
      //   let indexAr = new Array<number>(lengthPriv);
      //   this.contractData.anteildata.l4.seller.sellersPrivate.forEach((seller, index) => {
      //     if (seller.calcanteil == "-1") indexAr.push(index);
      //   });
      //
      //   indexAr.forEach(index => {
      //     this.contractData.anteildata.l4.seller.sellersPrivate.splice(index, 1);
      //   });
      //   if (this.contractData.anteildata.l4.seller.sellersPrivate.length == 0) this.contractData.anteildata.l4.seller.sellersPrivate = undefined;
      // }
      // if (KlengthComp == 0) this.contractData.anteildata.l4.buyer.buyersCompany = undefined;
      // else {
      //   let indexAr = new Array<number>(KlengthComp);
      //   this.contractData.anteildata.l4.buyer.buyersCompany.forEach((buyer, index) => {
      //     if (buyer.calcanteil == "-1") indexAr.push(index);
      //   });
      //
      //   indexAr.forEach(index => {
      //     this.contractData.anteildata.l4.buyer.buyersCompany.splice(index, 1);
      //   });
      //   if (this.contractData.anteildata.l4.buyer.buyersCompany.length == 0) this.contractData.anteildata.l4.buyer.buyersCompany = undefined;
      // }

      if (this.contractData.anteildata.l4.buyer.buyersPrivate) KlengthPriv = this.contractData.anteildata.l4.buyer.buyersPrivate.length
      if (this.contractData.anteildata.l4.buyer.buyersCompany) KlengthComp = this.contractData.anteildata.l4.buyer.buyersCompany.length
      if (this.contractData.anteildata.l4.seller.sellersPrivate) lengthPriv = this.contractData.anteildata.l4.seller.sellersPrivate.length;
      if (this.contractData.anteildata.l4.seller.sellersCompany) lengthComp = this.contractData.anteildata.l4.seller.sellersCompany.length;

      this.contractData.anteildata.l4.seller.anzahl = lengthPriv + lengthComp;
      this.contractData.anteildata.l4.buyer.anzahl = KlengthPriv + KlengthComp;
    }
    else { this.contractData.anteildata.l4 = undefined; }
  }

  private shimShare() {
    console.log(this.LSBOOL);
    if (this.LSBOOL[0]) {

      if(this.contractData.anteildata.l1.seller.sellersPrivate.length) {
        this.contractData.anteildata.l1.seller.sellersPrivate.forEach(seller => {
          if (seller.shareArDev) seller.shareArDev = undefined;
        });
      }

      if(this.contractData.anteildata.l1.seller.sellersCompany.length) {
        this.contractData.anteildata.l1.seller.sellersCompany.forEach(seller => {
          if (seller.shareArDev) seller.shareArDev = undefined;
        });
      }

      if(this.contractData.anteildata.l1.buyer.buyersPrivate.length) {
        this.contractData.anteildata.l1.buyer.buyersPrivate.forEach(buyer => {
          if (buyer.shareArDev) buyer.shareArDev = undefined;
        });
      }

      if(this.contractData.anteildata.l1.buyer.buyersCompany && this.contractData.anteildata.l1.buyer.buyersCompany.length) {
        this.contractData.anteildata.l1.buyer.buyersCompany.forEach(buyer => {
          if (buyer.shareArDev) buyer.shareArDev = undefined;
        });
      }

    }

    if (this.LSBOOL[1]) {
      if(this.contractData.anteildata.l2.seller.sellersPrivate.length) {
        this.contractData.anteildata.l2.seller.sellersPrivate.forEach(seller => {
          if (seller.shareArDev) seller.shareArDev = undefined;
        });
      }

      if(this.contractData.anteildata.l2.seller.sellersCompany.length) {
        this.contractData.anteildata.l2.seller.sellersCompany.forEach(seller => {
          if (seller.shareArDev) seller.shareArDev = undefined;
        });
      }

      if(this.contractData.anteildata.l2.buyer.buyersPrivate.length) {
        this.contractData.anteildata.l2.buyer.buyersPrivate.forEach(buyer => {
          if (buyer.shareArDev) buyer.shareArDev = undefined;
        });
      }

      if(this.contractData.anteildata.l2.buyer.buyersCompany && this.contractData.anteildata.l2.buyer.buyersCompany.length) {
        this.contractData.anteildata.l2.buyer.buyersCompany.forEach(buyer => {
          if (buyer.shareArDev) buyer.shareArDev = undefined;
        });
      }
    }

    if (this.LSBOOL[2]) {
      if(this.contractData.anteildata.l3.seller.sellersPrivate.length) {
        this.contractData.anteildata.l3.seller.sellersPrivate.forEach(seller => {
          if (seller.shareArDev) seller.shareArDev = undefined;
        });
      }

      if(this.contractData.anteildata.l3.seller.sellersCompany.length) {
        this.contractData.anteildata.l3.seller.sellersCompany.forEach(seller => {
          if (seller.shareArDev) seller.shareArDev = undefined;
        });
      }

      if(this.contractData.anteildata.l3.buyer.buyersPrivate.length) {
        this.contractData.anteildata.l3.buyer.buyersPrivate.forEach(buyer => {
          if (buyer.shareArDev) buyer.shareArDev = undefined;
        });
      }

      if(this.contractData.anteildata.l3.buyer.buyersCompany && this.contractData.anteildata.l3.buyer.buyersCompany.length) {
        this.contractData.anteildata.l3.buyer.buyersCompany.forEach(buyer => {
          if (buyer.shareArDev) buyer.shareArDev = undefined;
        });
      }
    }

    if (this.LSBOOL[3]) {
      if(this.contractData.anteildata.l4.seller.sellersPrivate.length) {
        this.contractData.anteildata.l4.seller.sellersPrivate.forEach(seller => {
          if (seller.shareArDev) seller.shareArDev = undefined;
        });
      }

      if(this.contractData.anteildata.l4.seller.sellersCompany.length) {
        this.contractData.anteildata.l4.seller.sellersCompany.forEach(seller => {
          if (seller.shareArDev) seller.shareArDev = undefined;
        });
      }

      if(this.contractData.anteildata.l4.buyer.buyersPrivate.length) {
        this.contractData.anteildata.l4.buyer.buyersPrivate.forEach(buyer => {
          if (buyer.shareArDev) buyer.shareArDev = undefined;
        });
      }

      if(this.contractData.anteildata.l4.buyer.buyersCompany.length) {
        this.contractData.anteildata.l4.buyer.buyersCompany.forEach(buyer => {
          if (buyer.shareArDev) buyer.shareArDev = undefined;
        });
      }
    }

  }

  // private shimBBLoUBLAnteileNew() {


  //   let tempCounter = 0; // wird gebraucht, da die Arrays ja nun kombiniert werden

  //   console.log('Privatverkauf' + tempCounter);
  //   this.contractData.anteildata.l1.seller.sellersPrivate.forEach((seller, index) => {
  //     const anteile = new Array();
  //     const blnr = new Array();

  //     let anteilTemp;
  //     let blnrTemp;

  //     if (tempCounter === 0) {
  //       anteilTemp = 'anteil';
  //       blnrTemp = 'bLaufendeNr';
  //     } else {
  //       anteilTemp = 'anteil' + tempCounter;
  //       blnrTemp = 'bLaufendeNr' + tempCounter;
  //     }

  //     tempCounter++;


  //     seller.shareAr.forEach(item => {
  //       anteile.push(item.anteil);
  //       blnr.push(item.BLNR);
  //     });

  //     this.contractData.wegLogic.immodetail[anteilTemp] = anteile;
  //     this.contractData.wegLogic.immodetail[blnrTemp] = blnr;
  //     console.log('ANTEILTEMP: ' + anteilTemp);
  //   });



  //   console.log('Firmenverkauf ' + tempCounter);
  //   this.contractData.anteildata.l1.seller.sellersCompany.forEach((seller, index) => {
  //     const anteile = new Array();
  //     const blnr = new Array();

  //     let anteilTemp;
  //     let blnrTemp;

  //     if (tempCounter === 0) {
  //       anteilTemp = 'anteil';
  //       blnrTemp = 'bLaufendeNr';
  //     } else {
  //       anteilTemp = 'anteil' + tempCounter;
  //       blnrTemp = 'bLaufendeNr' + tempCounter;
  //     }

  //     tempCounter++;

  //     seller.shareAr.forEach(item => {
  //       anteile.push(item.anteil);
  //       blnr.push(item.BLNR);
  //     });

  //     this.contractData.wegLogic.immodetail[anteilTemp] = anteile;
  //     this.contractData.wegLogic.immodetail[blnrTemp] = blnr;
  //     console.log('ANTEILTEMP: ' + anteilTemp);
  //   });




  // }

  setLSBOOL() {
    if (this.contractData.contactData.contractOption === 'BBL' || this.contractData.contactData.contractOption === 'UBL') {
      let a = parseInt(this.contractData.contactData.AnzahlLS);
      switch (a) {
        case 1:
          {
            this.LSBOOL = [true, false, false, false];
            break;
          }
        case 2:
          {
            this.LSBOOL = [true, true, false, false];
            break;
          }
        case 3:
          {
            this.LSBOOL = [true, true, true, false];
            break;
          }
        case 4:
          {
            this.LSBOOL = [true, true, true, true];
            break;
          }
      }
    }
    else this.LSBOOL = [true, false, false, false];
  }

  postFile(fileToUpload: File): Observable<boolean> {
    const endpoint = 'your-destination-url';
    const formData: FormData = new FormData();
    formData.append('fileKey', fileToUpload, fileToUpload.name);
    return this.http
      .post(endpoint, formData, this.httpUploadOptions)
      .pipe(map(() => true));
  }

  getApi(url) {
    return this.http.get(url, this.AuthHttpOptions).pipe(retry(1));
  }

}
