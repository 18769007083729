import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { LooseCurrencyPipe } from '../shared/looseCurrency.pipe';
import { ContractDataService } from '../shared/contractData.service';

@Component({
  selector: 'app-immo-detail-info-blnr2',
  templateUrl: './immo-detail-info-blnr2.component.html',
  styleUrls: ['./immo-detail-info-blnr2.component.css']
})
export class ImmoDetailInfoBLNR2Component implements OnInit {

  @Input() parent: UntypedFormGroup;
  @ViewChild('inputRef') inputRef;
  public schenkung: boolean;
  public miet: boolean;
  public primeType;
  @Input() submitted: boolean;

  constructor(private formBuilder: UntypedFormBuilder, private currencyMask: LooseCurrencyPipe, private contractDataService: ContractDataService) { }

  ngOnInit() {

    this.primeType = this.contractDataService.getContractPrimeType();

    if (this.primeType === 'schenkung') {
      this.schenkung = true;
    }
    if (this.primeType === 'miet') this.miet = true;
    else this.miet = false;

    if (!this.miet) {
      this.parent.get('kaufpreisinEUR').valueChanges.subscribe(val => {
        const elRef = this.inputRef.nativeElement;
        // get stored original unmodified value (not including last change)
        const orVal = elRef.getAttribute('data-model-value') || '';
        // modify new value to be equal to the original input (including last change)
        val = val.replace(this.currencyMask.transform(orVal, 'EUR'), orVal);
        // store original unmodified value (including last change)
        elRef.setAttribute('data-model-value', val);
        // set view value using DOM value property
        elRef.value = this.currencyMask.transform(val, 'EUR');
        // update module without emitting event and without changing view model
        this.parent.get('kaufpreisinEUR').setValue(val, {
          emitEvent: false,
          emitModelToViewChange: false
        });
      });
    }
  }
}
