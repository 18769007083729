<mat-dialog-content>
  <div class="custom-dlg-head">
  </div>
  <div class="custom-dlg-body">
    <p><img src="/assets/wegDatenzLS.png" alt="" width="800" /></p>
  </div>
  <div class="custom-dlg-footer">
    <button mat-raised-button mat-button (click)="closeDlg()">OK</button>
  </div>
</mat-dialog-content>
