<mat-dialog-content>
  <div class="custom-dlg-head">
    <h3>
      {{title}}
    </h3>
  </div>
  <div class="custom-dlg-body">
    <br>
    <p>{{message}}</p>
  </div>
  <div class="custom-dlg-footer">
    <button mat-raised-button color="primary" (click)="onConfirm()">Ja</button>
    &nbsp;
    <button mat-raised-button (click)="onDismiss()">Nein</button>
  </div>
</mat-dialog-content>
