import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { LooseCurrencyPipe } from '../shared/looseCurrency.pipe';
import { ContractDataService } from '../shared/contractData.service';

@Component({
  selector: 'app-immo-detail-info',
  templateUrl: './immo-detail-info.component.html',
  styleUrls: ['./immo-detail-info.component.css']
})
export class ImmoDetailInfoComponent implements OnInit {

  @Input() parent: UntypedFormGroup;
  @Input() submitted: boolean;
  @ViewChild('inputRef') inputRef;
  public schenkung: boolean;
  public miet: boolean;
  public primeType;

  constructor(private formBuilder: UntypedFormBuilder, private currencyMask: LooseCurrencyPipe, private contractDataService: ContractDataService) {
  }

  ngOnInit() {
    this.primeType = this.contractDataService.getContractPrimeType();

    if (this.primeType === 'schenkung') {
      this.schenkung = true;
    }
    if (this.primeType === 'miet') this.miet = true;
    else this.miet = false;

    if (!this.miet) {
      this.parent.get('kaufpreisinEUR').valueChanges.subscribe(val => {
        const elRef = this.inputRef.nativeElement;
        const orVal = elRef.getAttribute('data-model-value') || '';
        val = val.replace(this.currencyMask.transform(orVal, 'EUR'), orVal);
        elRef.setAttribute('data-model-value', val);
        elRef.value = this.currencyMask.transform(val, 'EUR');
        this.parent.get('kaufpreisinEUR').setValue(val, {
          emitEvent: false,
          emitModelToViewChange: false
        });
      });
    }
  }
}
