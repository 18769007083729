import { AfterViewInit, Component, Inject, OnInit } from '@angular/core';
import { ContractDataService } from '../shared/contractData.service';

@Component({
  selector: 'app-finished',
  templateUrl: './finished.component.html',
  styleUrls: ['./finished.component.css']
})
export class FinishedComponent implements OnInit, AfterViewInit {

  constructor(@Inject(ContractDataService) private contractDataService: ContractDataService) { }

  ngOnInit() {
    this.contractDataService.State = 4;
    if(localStorage.getItem('page')) {
      this.contractDataService.changePage({ page: localStorage.getItem('page')});
    }
  }

  ngAfterViewInit() {
    let scrolloptions = { top: 0, left: 0 }
    window.scrollTo(scrolloptions);
  }
}
