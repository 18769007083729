<div>
  <h2 style="margin: 2%; text-align: center;">In 24h zum fertigen Schenkungsvertrag! </h2>
  <div style="padding: 5px;" class="custom-top-head-line">
    Der Prozess gliedert sich in folgende Schritte:
    <ul>
      <li>
        Erfassung Stammdaten der Beteiligten
      </li>
      <li>
        Erfassung Liegenschaftsdaten
      </li>
      <li>
        Übermittlung der Daten an die Mähr Rechtsanwalt GmbH zur weiteren Verarbeitung
      </li>

    </ul>

    Die Errichtungskosten des Vertrages samt grundbücherlicher Durchführung betragen Netto 1500€ zzgl. 250€ pro
    sonstigem
    Recht (Fruchtgenussrecht, Wohnrecht, Vorkaufsrecht, etc.).<br>
    Die endgültigen Kosten werden Ihnen vor dem Übermitteln der Daten an die Mähr Rechtsanwalt GmbH dargestellt.<br>
    Die Mähr Rechtsanwalt GmbH garantiert die Übermittlung des Vertragsentwurfes samt Informationsschreiben innert von
    24h
    an Arbeitstagen, ansonsten erfolgt die Vertragserrichtung kostenlos.
    <br>
    <br>
    Die Vertragserrichtung ist derzeit nur für österreichische Liegenschaften möglich.
    <br>
  </div>
  <div class="custom-weiter-div">
    <button mat-raised-button color="primary" class="custom-weiter" (click)="gotoMasterData()">
      Start
    </button>
    <label style="float: right;">
      <fa-icon class="accent-color custom-weiter" [icon]="faArrowRight"></fa-icon>
    </label>
  </div>
  <br>
  <br>
  <br>
  <br>
</div>