import { Component, OnInit } from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-dlg-daten-liegenschaft',
  templateUrl: './dlg-daten-liegenschaft.component.html',
  styleUrls: ['./dlg-daten-liegenschaft.component.css']
})
export class DlgDatenLiegenschaftComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<DlgDatenLiegenschaftComponent>) { }

  ngOnInit() {
  }

  closeDlg(): void {
    this.dialogRef.close();
  }
}
