<div class="container">
  <form #contactForm="ngForm">
    <h3 class="mt-4 mb-4">Ansprechpartner für Vertrag:</h3>
    <div class="full-grid-tile">
      <mat-form-field appearance="none" *ngIf="isUserLoggedIn" style="width: 200px  !important;">
        <mat-label>Select user</mat-label>
        <mat-select (selectionChange)="getUser($event.value)">
          <mat-option *ngFor="let user of users" [value]="user._id">
            {{ user.firstName }} {{ user.lastName }}
            <span *ngIf="user.lawyer && user.auth.userRole == 1"> Lawyer - {{ user.lawyer.firstName }} {{ user.lawyer.lastName }} </span>
            <span *ngIf="user.auth   && user.auth.userRole == 1"> ({{ user.auth.email }}) </span>
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-grid-list [cols]="breakpoint" [rowHeight]="rowHeight" class="two-cols">
        <mat-grid-tile>
          <mat-form-field appearance="none" class="no-padding no-line" [class.error]="submitted && firstName.invalid">
            <mat-label>Vorname (inkl. Titel und Zweitnamen falls vorhanden)</mat-label>
            <input required matInput [(ngModel)]="contactData.firstName" (ngModelChange)="formChange($event)" name="firstName" #firstName="ngModel" pattern="^[^\s]+(\s+[^\s]+)*$"/>
          </mat-form-field>
          <mat-form-field appearance="none" class="no-padding no-line" [class.error]="submitted && lastName.invalid">
            <mat-label>Nachname</mat-label>
            <input required matInput [(ngModel)]="contactData.lastName" (ngModelChange)="formChange($event)" name="lastName" #lastName="ngModel" pattern="^[^\s]+(\s+[^\s]+)*$"/>
          </mat-form-field>
        </mat-grid-tile>
      </mat-grid-list>
      <mat-grid-list [cols]="breakpoint" [rowHeight]="rowHeight" class="two-cols">
        <mat-grid-tile>
          <mat-form-field appearance="none" class="no-padding no-line" [class.error]="submitted && emailaddr.invalid">
            <mat-label>E-Mail</mat-label>
            <input required matInput [(ngModel)]="contactData.eMail" name="emailaddr" #emailaddr="ngModel" pattern="[a-zA-Z0-9][\w\.-]*[a-zA-Z0-9]@[a-zA-Z0-9][\w\.-]*[a-zA-Z0-9]\.[a-zA-Z][a-zA-Z\.]*[a-zA-Z]$" />
          </mat-form-field>
          <mat-form-field appearance="none" class="no-padding no-line" [class.error]="submitted && telefon.invalid">
            <mat-label>Telefon</mat-label>
            <input required matInput [(ngModel)]="contactData.phoneNumber" name="telefon" #telefon="ngModel" pattern="^[^\s]+(\s+[^\s]+)*$"/>
          </mat-form-field>
        </mat-grid-tile>
      </mat-grid-list>
    </div>
    <br>
    <div class="yellow-line mt-2 mb-4"></div>
    <div id='contractoption' class="mb-4">
      <div *ngIf="primeType == 'miet'">
        <label id="example-radio-group-label">
          <h3>Bitte wählen Sie eine der folgenden Varianten: <fa-icon style="float: none; margin-left: 2px;"
              (click)="openVariantDlg()" class="accent-color" [icon]="faUniversity"></fa-icon>
          </h3>
        </label>
        <mat-radio-group name="immotype" aria-labelledby="example-radio-group-label" class="example-radio-group"
          [(ngModel)]="variant" required (change)="VariantChange()" [class.variant-error]="submitted && !variantTouched">
          <mat-radio-button class="example-radio-button" *ngFor="let variant of mietvariants" [value]="variant.value"
            color="primary">
            {{ variant.viewValue }}
          </mat-radio-button>
          <br />
        </mat-radio-group>
      </div>
      <div *ngIf="primeType != 'miet' && primeType != 'kfz'">
        <label id="example-radio-group-label">
          <h3>Bitte wählen Sie eine der folgenden Varianten: <fa-icon style="float: none; margin-left: 2px;"
              (click)="openVariantDlg()" class="accent-color" [icon]="faUniversity"></fa-icon>
          </h3>
        </label>
        <mat-radio-group name="immotype" aria-labelledby="example-radio-group-label" class="example-radio-group"
          [(ngModel)]="variant" required (change)="VariantChange()" [class.variant-error]="submitted && !variantTouched">
          <mat-radio-button class="example-radio-button" *ngFor="let variant of variants" [value]="variant.value"
            color="primary">
            <img [src]="'assets/' + variant.image + '.png'" class="radio-image"/>
            {{ variant.viewValue }}
          </mat-radio-button>
          <br />
        </mat-radio-group>
      </div>
    </div>
  </form>
</div>
<div class="container">
  <div class="yellow-line mt-2 mb-4"></div>

  <h3 *ngIf="!this.schenkung && !this.miet">Daten Verkäufer:</h3>
  <h3 *ngIf="this.schenkung">Daten Geschenkgeber:</h3>
  <h3 *ngIf="this.miet">Daten Vermieter:
    <label class="fa-info-label">
      <fa-icon style="float: none !important;" class="accent-color" [icon]="faInfoCircle" matTooltipPosition="above"
        matTooltip="Insofern ein Fruchtgenussrecht besteht, ist der Berechtigte als Vermieter anzuführen">
      </fa-icon>
    </label>
  </h3>

  <button *ngIf="primeType=='kfz'" mat-raised-button color="primary" class="small-height-button"
    (click)="addSellerComplex('person')" [disabled]="ifMaximumSellers()">
    Privaten Verkäufer hinzufügen
  </button>
  <button *ngIf=" primeType=='kfz'" mat-raised-button color="primary" class="small-height-button"
    (click)="addSellerComplex('company')" [disabled]="ifMaximumSellers()">
    Einzelunternehmer Verkäufer hinzufügen
  </button>
  <button *ngIf="!this.schenkung && !this.miet && primeType!='kfz'" mat-raised-button color="primary" class="small-height-button"
    (click)="addSellerComplex('person')" [disabled]="ifMaximumSellers()">
    Privaten Verkäufer hinzufügen
  </button>
  <button *ngIf="!this.schenkung && !this.miet && primeType!='kfz'" mat-raised-button color="primary" class="small-height-button"
    (click)="addSellerComplex('company')" [disabled]="ifMaximumSellers()">
    Firma Verkäufer hinzufügen
  </button>
  <button *ngIf="this.schenkung" mat-raised-button color="primary" (click)="addSellerComplex('person')" class="small-height-button" [disabled]="ifMaximumSellers()">
    Privaten Geschenkgeber hinzufügen
  </button>
  <button *ngIf="this.schenkung" mat-raised-button color="primary" (click)="addSellerComplex('company')" class="small-height-button" [disabled]="ifMaximumSellers()">
    Firma Geschenkgeber hinzufügen
  </button>
  <button *ngIf="this.miet" mat-raised-button color="primary" (click)="addSellerComplex('person')" class="small-height-button" [disabled]="ifMaximumSellers()">
    Privaten Vermieter hinzufügen
  </button>
  <button *ngIf="this.miet" mat-raised-button color="primary" (click)="addSellerComplex('company')" class="small-height-button" [disabled]="ifMaximumSellers()">
    Firma Vermieter hinzufügen
  </button>
  <!-- *ngIf="!this.kfz" -->
  <button mat-raised-button color="primary" (click)="addSellerComplex('estate')" class="small-height-button" [disabled]="ifMaximumSellers()">
    Verlassenschaft hinzufügen
  </button>

  <div *ngFor="let seller of sellersPrivateFormArray.controls; let i = index">
    <h4 *ngIf="!this.schenkung && !this.miet" class="title-with-icon mb-0">Privater Verkäufer {{ i + 1 }}
      <fa-icon [icon]="faTimes" class="icon" (click)="removeSellerPrivate(i)"></fa-icon>
    </h4>
    <h4 *ngIf="this.schenkung" class="title-with-icon mb-0">Privater Geschenkgeber {{ i + 1 }}
      <fa-icon [icon]="faTimes" class="icon" (click)="removeSellerPrivate(i)"></fa-icon>
    </h4>
    <h4 *ngIf="this.miet" class="title-with-icon mb-0">Privater Vermieter {{ i + 1 }}
      <fa-icon [icon]="faTimes" class="icon" (click)="removeSellerPrivate(i)"></fa-icon>
    </h4>
    <app-person-data-form [parent]="seller" [submitted]="submitted"></app-person-data-form>
  </div>


  <div *ngFor="let seller of sellersCompanyFormArray.controls; let i = index">
    <div *ngIf="!this.schenkung && !this.miet && primeType!='kfz'">
      <h4 class="title-with-icon mb-0">Firma Verkäufer {{ i + 1 }}
        <fa-icon [icon]="faTimes" class="icon" (click)="removeSellerCompany(i)"></fa-icon>
      </h4>
    </div>
    <div *ngIf="primeType=='kfz'">
      <h4 class="title-with-icon mb-0">Einzelunternehmer Verkäufer {{ i + 1 }}
        <fa-icon [icon]="faTimes" class="icon" (click)="removeSellerCompany(i)"></fa-icon>
      </h4>
    </div>
    <div *ngIf="this.schenkung">
      <h4 class="title-with-icon mb-0">Firma Geschenkgeber {{ i + 1 }}
        <fa-icon [icon]="faTimes" class="icon" (click)="removeSellerCompany(i)"></fa-icon>
      </h4>
    </div>
    <div *ngIf="this.miet">
      <h4 class="title-with-icon mb-0">Firma Vermieter {{ i + 1 }}
        <fa-icon [icon]="faTimes" class="icon" (click)="removeSellerCompany(i)"></fa-icon>
      </h4>
    </div>
    <app-company-data-form [parent]="seller" [submitted]="submitted"></app-company-data-form>
  </div>

  <div *ngFor="let seller of sellersEstateFormArray.controls; let i = index">
    <h4 class="title-with-icon mb-0">Verlassenschaft {{ i + 1 }}
      <fa-icon [icon]="faTimes" class="icon" (click)="removeSellerEstate(i)"></fa-icon>
    </h4>
    <app-estate-data-form [parent]="seller" [submitted]="submitted"></app-estate-data-form>
  </div>
  <br/>
  <div class="yellow-line mt-2 mb-4"></div>
  <h3 *ngIf="!this.schenkung && !this.miet">Daten Käufer:</h3>
  <h3 *ngIf="this.schenkung">Daten Geschenknehmer:</h3>
  <h3 *ngIf="this.miet">Daten Mieter:</h3>

  <button *ngIf="!this.schenkung && !this.miet" mat-raised-button color="primary" (click)="addBuyerComplex('person')" class="small-height-button" [disabled]="ifMaximumBuyers()">
    Privaten Käufer hinzufügen &nbsp;&nbsp;&nbsp;&nbsp;
  </button>
  <button *ngIf="primeType=='kfz'" mat-raised-button color="primary"
    (click)="addBuyerComplex('company')" class="small-height-button" [disabled]="ifMaximumBuyers()">
    Einzelunternehmer Käufer hinzufügen &nbsp;&nbsp;&nbsp;&nbsp;
  </button>
  <button *ngIf="!this.schenkung && !this.miet && primeType!='kfz'" mat-raised-button color="primary"
    (click)="addBuyerComplex('company')" class="small-height-button" [disabled]="ifMaximumBuyers()">
    Firma Käufer hinzufügen &nbsp;&nbsp;&nbsp;&nbsp;
  </button>
  <button *ngIf="this.miet" mat-raised-button color="primary" (click)="addBuyerComplex('person')" class="small-height-button" [disabled]="ifMaximumBuyers()">
    Privaten Mieter hinzufügen &nbsp;&nbsp;&nbsp;&nbsp;
  </button>
  <button *ngIf="this.miet" mat-raised-button color="primary" (click)="addBuyerComplex('company')" class="small-height-button" [disabled]="ifMaximumBuyers()">
    Firma Mieter hinzufügen &nbsp;&nbsp;&nbsp;&nbsp;
  </button>
  <button *ngIf="this.schenkung" mat-raised-button color="primary" (click)="addBuyerComplex('person')" class="small-height-button" [disabled]="ifMaximumBuyers()">
    Privaten Geschenknehmer hinzufügen &nbsp;&nbsp;&nbsp;&nbsp;
  </button>
  <button *ngIf="this.schenkung" mat-raised-button color="primary" (click)="addBuyerComplex('company')" class="small-height-button" [disabled]="ifMaximumBuyers()">
    Firma Geschenknehmer hinzufügen &nbsp;&nbsp;&nbsp;&nbsp;
  </button>


  <div *ngFor="let buyer of buyersPrivateFormArray.controls; let i = index">
    <h4 *ngIf="!this.schenkung && !this.miet" class="title-with-icon  mb-0">Privater Käufer {{ i + 1 }}
      <fa-icon [icon]="faTimes" class="icon" (click)="removeBuyerPrivate(i)"></fa-icon>
    </h4>
    <h4 *ngIf="this.schenkung" class="title-with-icon mb-0">Privater Geschenknehmer {{ i + 1 }}
      <fa-icon [icon]="faTimes" class="icon" (click)="removeBuyerPrivate(i)"></fa-icon>
    </h4>
    <h4 *ngIf="this.miet" class="title-with-icon mb-0">Privater Mieter {{ i + 1 }}
      <fa-icon [icon]="faTimes" class="icon" (click)="removeBuyerPrivate(i)"></fa-icon>
    </h4>
    <app-person-data-form [parent]="buyer" [submitted]="submitted"></app-person-data-form>
  </div>


  <div *ngFor="let buyer of buyersCompanyFormArray.controls; let i = index">
    <h4 *ngIf="!this.schenkung && !this.miet && primeType!='kfz'" class="title-with-icon mb-0">Firma Käufer
      {{ i + 1 }}
      <fa-icon [icon]="faTimes" class="icon" (click)="removeBuyerCompany(i)"></fa-icon>
    </h4>
    <h4 *ngIf="primeType=='kfz'" class="title-with-icon mb-0">Einzelunternehmer Käufer {{ i + 1 }}
      <fa-icon [icon]="faTimes" class="icon" (click)="removeBuyerCompany(i)"></fa-icon>
    </h4>
    <h4 *ngIf="this.schenkung" class="title-with-icon mb-0">Firma Geschenknehmer {{ i + 1 }}
      <fa-icon [icon]="faTimes" class="icon" (click)="removeBuyerCompany(i)"></fa-icon>
    </h4>
    <h4 *ngIf="this.miet" class="title-with-icon mb-0">Firma Mieter {{ i + 1 }}
      <fa-icon [icon]="faTimes" class="icon" (click)="removeBuyerCompany(i)"></fa-icon>
    </h4>
    <app-company-data-form [parent]="buyer" [submitted]="submitted"></app-company-data-form>
  </div>


  <div *ngIf="!this.OneBuyer && this.UBLorBBL && primeType !='miet'" id="kaeufoption">
    <label *ngIf="!this.schenkung && !this.miet">Kaufen die Käufer zu gleichen Anteilen? <fa-icon
        style="float: none; margin-left: 2px;" class="accent-color" [icon]="faInfoCircle" matTooltipPosition="above"
        matTooltip="Käufer kaufen zu gleichen Anteilen, dann JA. Käufer kaufen zu ungleichen Teilen, dann NEIN">
      </fa-icon></label>
    <label *ngIf="this.schenkung">Wird zu gleichen Anteilen Geschenkt? <fa-icon style="float: none; margin-left: 2px;"
        class="accent-color" [icon]="faInfoCircle" matTooltipPosition="above"
        matTooltip="Jeder kriegt gleich viel geschenkt, dann JA. Die Anteile werden ungleich verteilt, dann NEIN">
      </fa-icon></label>
    <label *ngIf="this.miet">Wird zu gleichen Anteilen Vermietet? <fa-icon style="float: none; margin-left: 2px;"
        class="accent-color" [icon]="faInfoCircle" matTooltipPosition="above"
        matTooltip="Jeder Mieter mietet den gleich gro&szlig;en Anteil, dann JA. Die Anteile werden ungleich verteilt, dann NEIN">
      </fa-icon></label>
    <br />
    <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group"
      [(ngModel)]="kaeuferanteile" name="kaeuferanteile" required>
      <mat-radio-button class="example-radio-button" *ngFor="let kaeuferanteile of kaeuferanteileOptionen"
        [value]="kaeuferanteile">
        {{kaeuferanteile}}&emsp;
      </mat-radio-button>&emsp;
    </mat-radio-group><br />
  </div>

  <div *ngIf="this.UBLorBBL">
    <br>
    <label>Anzahl der vertragsgegenst&auml;ndlichen Liegenschaften (EZ): {{AnzahlLS}}</label><br>
    <button *ngIf="moreLSBT" mat-raised-button color="primary" (click)="moreLS()">
      weitere Liegenschaft hinzuf&uuml;gen
    </button>
    <button *ngIf="lessLSBT" mat-raised-button color="primary" (click)="lessLS()">
      eine Liegenschaft entfernen
    </button>
  </div>
  <!-- <button class="float-left" mat-raised-button color="primary" (click)="saveDB()">
    Speichern
  </button>
  <br> -->

  <div class="text-center mb-4 mt-3" style="color: red; display: block; width: 100%;"
    *ngIf="(!masterForm.valid || !contactForm.valid) && (contactForm.touched || masterForm.touched)">
    Bitte füllen Sie alle Felder aus!<br />
  </div>
  <br>
  <div class="custom-weiter-div mt-3">
    <button mat-raised-button color="primary" class="custom-weiter bg-button"
      (click)="gotoNext(masterForm.valid && contactForm.valid)">
      Weiter
    </button>
    <label style="float: right;">
      <fa-icon class="accent-color custom-weiter" [icon]="faArrowRight"></fa-icon>
    </label>
  </div>
  <br>
</div>
