import { Component, OnInit, Inject } from '@angular/core';
import { ContractDataService } from '../shared/contractData.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  constructor(
    @Inject(ContractDataService) private contractDataService: ContractDataService
  ) { }

  ngOnInit(): void {
    this.contractDataService.changePage({ page: 'dashboard'});
  }

}
