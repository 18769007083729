import { Component, OnInit } from '@angular/core';
import { DlgDatenZWohnodGeschMoreVerComponent } from '../dlg-daten-z-wohnod-gesch-more-ver/dlg-daten-z-wohnod-gesch-more-ver.component';
import {MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-dlg-daten-z-wohnod-gesch-one-ver',
  templateUrl: './dlg-daten-z-wohnod-gesch-one-ver.component.html',
  styleUrls: ['./dlg-daten-z-wohnod-gesch-one-ver.component.css']
})
export class DlgDatenZWohnodGeschOneVerComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<DlgDatenZWohnodGeschMoreVerComponent>) { }

  ngOnInit() {
  }

  closeDlg(): void {
    this.dialogRef.close();
  }
}
