import { Component, OnInit, Input } from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';

@Component({
  selector: 'app-kaeufer-share-logic',
  templateUrl: './kaeufer-share-logic.component.html',
  styleUrls: ['./kaeufer-share-logic.component.css']
})
export class KaeuferShareLogicComponent implements OnInit {

  @Input() parent: UntypedFormGroup;
  @Input() submitted: boolean;

  constructor(private formBuilder: UntypedFormBuilder) {}

  ngOnInit() {
  }

}
