import { Component, Inject, Input, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import {
  Form,
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { Observable } from 'rxjs';
import {
  map,
  startWith,
  filter,
  switchMap,
  debounce,
  debounceTime,
  tap,
  finalize,
  distinctUntilChanged
} from 'rxjs/operators';
import { ContractDataService } from '../shared/contractData.service';
import { DlgDatenLiegenschaftComponent } from '../dlg-daten-liegenschaft/dlg-daten-liegenschaft.component';
import { MatDialog } from '@angular/material/dialog';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { faQuestion, faUniversity, faUserGraduate } from '@fortawesome/free-solid-svg-icons';
import { DlgWegDatenZurLiegenschaftComponent } from '../dlg-weg-daten-zur-liegenschaft/dlg-weg-daten-zur-liegenschaft.component';
import { DlgDatenZWohnodGeschMoreVerComponent } from '../dlg-daten-z-wohnod-gesch-more-ver/dlg-daten-z-wohnod-gesch-more-ver.component';
import { DlgDatenZWohnodGeschOneVerComponent } from '../dlg-daten-z-wohnod-gesch-one-ver/dlg-daten-z-wohnod-gesch-one-ver.component';
declare const $: any;

export interface GemeindeDaten {
  KGNr: number;
  KGName: string;
  PGNr: number;
  PolName: string;
}

@Component({
  selector: 'app-weglogic-immo-detail',
  templateUrl: './weglogic-immo-detail.component.html',
  styleUrls: ['./weglogic-immo-detail.component.css']
})
export class WEGlogicImmoDetailComponent implements OnInit, AfterViewInit {
  @Input() contractOption: string;
  @Input() parkplatz: boolean;
  @Input() submitted: boolean = false;
  @ViewChild('autoCompleteInput', { read: MatAutocompleteTrigger }) autoComplete: MatAutocompleteTrigger;
  faUniversity = faQuestion;
  faUserGraduate = faUserGraduate;
  contractData;
  // for Autocomplete
  filteredVillages: any[];
  tempVar: any;

  // count bLaufendeNr
  blaufendeNrCnt;

  immodetail = this.formBuilder.group({
    katastralGemeinde: new UntypedFormControl(''),
    einlageZahl: new UntypedFormControl(''),
    // grundstuecksnummer: new FormControl(''),
    grundstuecksnummerAr: this.formBuilder.array([]),
    top: new UntypedFormControl(''),
    anteil: new UntypedFormControl(''),
    bLaufendeNrAr: this.formBuilder.array([]),
    parkingPlaceCnt: new UntypedFormControl('')
  });
  parkingPlaces: UntypedFormArray;
  public BLaufendeNr2Visible: boolean;
  isLoading = false;
  rowHeight = (window.innerWidth <= 768) ? '140px' : '75px';

  ngAfterViewInit() {
    setTimeout(()=> {
      var v = '';
      v = $('.katastralGemeinde').val();
      if(v.includes("undefined")) {
        $('.katastralGemeinde').val('').focus();
      }
    }, 500);

     this.autoComplete.panelClosingActions
       .subscribe(e => {
         this.immodetail
           .get('katastralGemeinde').setValue(null);
       });
   }

  constructor(
    private formBuilder: UntypedFormBuilder,
    @Inject(ContractDataService)
    private contractDataService: ContractDataService,
    public datenLiegenschaftDlg: MatDialog,
    public wegDatenzLSDlg: MatDialog,
    public WEGDatenzWohnodGeschOneVerDlg: MatDialog,
    public WEGDatenzWohnodGeschMoreVerDlg: MatDialog,
  ) {
    setTimeout(()=> {
      console.log('this.immodetail');
    }, 1000);
  }


  private initParkingPlaceEmpty(): UntypedFormGroup {
    if (this.BLaufendeNr2Visible) {
      console.log('Parkplatz mit 2. BLNR');
      return this.formBuilder.group({
        pTOP: [''],
        pAnteil: [''],
        pBLaufendeNr: [''],
        pBLaufendeNr2: [''],
        kaufpreisinEUR: ['']
      });
    } else {
      console.log('Parkplatz ohne 2. BLNR');
      return this.formBuilder.group({
        pTOP: [''],
        pAnteil: [''],
        pBLaufendeNr: [''],
        kaufpreisinEUR: ['']
      });
    }
  }

  private initParkingPlaceWithData(parkingPlace: any): UntypedFormGroup {
    console.log('**** parkingPlace');
    console.log(parkingPlace);
    if (this.BLaufendeNr2Visible) {
      console.log('**** VISIBLE');
      return this.formBuilder.group({
        pTOP: [parkingPlace.pTOP],
        pAnteil: [parkingPlace.pAnteil],
        pBLaufendeNr: [parkingPlace.pBLaufendeNr],
        pBLaufendeNr2: [parkingPlace.pBLaufendeNr2],
        kaufpreisinEUR: [parkingPlace.kaufpreisinEUR]
      });
    } else {
      console.log('**** NOT VISIBLE');
      return this.formBuilder.group({
        pTOP: [parkingPlace.pTOP],
        pAnteil: [parkingPlace.pAnteil],
        pBLaufendeNr: [parkingPlace.pBLaufendeNr],
        kaufpreisinEUR: [parkingPlace.kaufpreisinEUR]
      });
    }
  }

  private initBLNR(): UntypedFormGroup {
    return this.formBuilder.group({ bLaufendeNr: [''] });
  }

  public getValid(): boolean {
    return this.immodetail.valid;
  }

  ngOnInit() {
    // this.autoCompleteInput.value = 'Whale!';
    this.contractData = this.contractDataService.getContractData();
    console.log('this.blaufendeNrCnt');
    this.blaufendeNrCnt = this.contractData.masterData.sellersPrivate.length + this.contractData.masterData.sellersCompany.length;
    console.log(this.blaufendeNrCnt);

    // for (let i = 0; i < this.blaufendeNrCnt; i++) {
    //   this.bLaufendeNrAr.push(this.initBLNR());
    // }

    // GCCRM-8219 STARTS
    // this.setBLNR();
    // this.grundstuecksnummerAr.push(this.initGSTNr());
    // this.einlageZahlAr.push(this.initEinlageZahl());
    // GCCRM-8219 ENDS

    if (
      this.contractData.masterData.sellersPrivate.length > 1 ||
      this.contractData.masterData.sellersCompany.length > 1 || (this.contractData.masterData.sellersCompany.length === 1 && this.contractData.masterData.sellersPrivate.length === 1)
    ) {
      this.BLaufendeNr2Visible = true;
    }
    this.parkingPlaces = new UntypedFormArray([]);

    // fill form if weglogic data is available
    const weglogic = this.contractDataService.getContractWEGLogicData();
    this.fillFormWithData(weglogic);

    if(weglogic && weglogic.immodetail)
      this.immodetail.get('parkingPlaceCnt').setValue(weglogic.immodetail.parkingPlaceCnt);

    this.immodetail.get('parkingPlaceCnt').valueChanges.subscribe(value => {
      this.parkingPlaces.controls.splice(0);
      for (let i = 0; i < value; i++) {
        this.parkingPlaces.push(this.initParkingPlaceEmpty());
        console.log('Hallo Welt!');
        if (i == 7) break;
      }
    });

    this.immodetail
      .get('katastralGemeinde')
      .valueChanges.pipe(
        tap(() => (this.filteredVillages = [])),
        debounceTime(300),
        distinctUntilChanged(),
        tap(() => (this.isLoading = true)),
        switchMap(inputValue =>
          this.contractDataService
            .searchCommunes(inputValue)
            .pipe(finalize(() => (this.isLoading = false)))
        )
      )
      .subscribe(res => {
        this.filteredVillages = res;
      });
  }

  fillFormWithData(weglogic: any) {
    if (weglogic && weglogic.immodetail) {
      this.immodetail = this.initImmodetailFormGroupWithData(
        weglogic.immodetail
      );
    }

    if (weglogic && weglogic.immodetail && weglogic.immodetail.grundstuecksnummerAr) {
      if(weglogic.immodetail.grundstuecksnummerAr.length == 0)
        this.grundstuecksnummerAr.push(this.initGSTNr());
      else
        this.setExpenseCategories(weglogic.immodetail.grundstuecksnummerAr);
    } else {
        this.grundstuecksnummerAr.push(this.initGSTNr());
    }

    // if (weglogic && weglogic.immodetail && weglogic.immodetail.einlageZahlAr) {
    //   if(weglogic.immodetail.einlageZahlAr.length == 0)
    //     this.einlageZahlAr.push(this.initEinlageZahl());
    //   else
    //     this.setEinlageZahlAr(weglogic.immodetail.einlageZahlAr);
    // } else {
    //   console.log("else");
    //   this.einlageZahlAr.push(this.initEinlageZahl());
    // }

    if (weglogic && weglogic.immodetail && weglogic.immodetail.bLaufendeNrAr) {
      this.setBLNR(weglogic.immodetail.bLaufendeNrAr);
    } else {
      this.setBLNR();
    }

    if (weglogic && weglogic.immodetail && weglogic.immodetail.parkingPlaceCnt && weglogic.immodetail.parkingPlaceCnt > 0) {
      weglogic.parkingPlaces.forEach(element =>
        this.parkingPlaces.push(this.initParkingPlaceWithData(element))
      );
    }
  }

  initImmodetailFormGroupWithData(immodetail: any): UntypedFormGroup {
    console.log("immodetail.katastralGemeinde");
    console.log(immodetail.katastralGemeinde);
    return this.formBuilder.group({
      katastralGemeinde: new UntypedFormControl(immodetail.katastralGemeinde),
      einlageZahl: new UntypedFormControl(immodetail.einlageZahl),
      grundstuecksnummerAr: this.formBuilder.array([]),
      // grundstuecksnummerAr: this.setExpenseCategories(immodetail.grundstuecksnummerAr),
      top: new UntypedFormControl(immodetail.top),
      anteil: new UntypedFormControl(immodetail.anteil),
      bLaufendeNrAr: this.formBuilder.array([]),
      // bLaufendeNrAr: this.formBuilder.array([this.initBLNR()]),
      parkingPlaceCnt: new UntypedFormControl(immodetail.parkingPlaceCnt)
    });
  }

  setExpenseCategories(data) {
    let control = <UntypedFormArray>this.immodetail.controls.grundstuecksnummerAr;
    data.forEach(x => {
      control.push(this.formBuilder.group(x));
    });
  }

  setBLNR(data = null) {
    let control = <UntypedFormArray>this.immodetail.controls.bLaufendeNrAr;
    for (let i = 0; i < this.blaufendeNrCnt; i++) {
      // if data, we will fill it, else we will fill with empty
      if (data) {
        if(data[i])
        control.push(this.formBuilder.group(data[i]));
        else
        control.push(this.initBLNR());
      } else {
        control.push(this.initBLNR());
      }
    }
  }

  get bLaufendeNrAr(): UntypedFormArray {
    return this.immodetail.get('bLaufendeNrAr') as UntypedFormArray;
  }

  get grundstuecksnummerAr(): UntypedFormArray {
    return this.immodetail.get('grundstuecksnummerAr') as UntypedFormArray;
  }

  private initGSTNr(): UntypedFormGroup {
    return this.formBuilder.group({ grundstuecksnummer: [''] });
  }

  public addGstNr() {
    this.grundstuecksnummerAr.push(this.initGSTNr());
  }

  private removeGstNr(i) {
    this.grundstuecksnummerAr.removeAt(i);
  }


  displayFn(village?: any): string | undefined {
    const temp = village
      ? village.KGName + ' (' + village.KGNr + ')'
      : undefined;
    return village ? temp : undefined;
  }

  openDatenLiegenschaftDlg(): void {
    const dialogRef = this.datenLiegenschaftDlg.open(DlgDatenLiegenschaftComponent);
  }

  openWEGDatenzLSDlg(): void {
    const dialogRef = this.wegDatenzLSDlg.open(DlgWegDatenZurLiegenschaftComponent);
  }

  openWEGDatenzWohnodGeschOneVerDlg(): void {
    const dialogRef = this.WEGDatenzWohnodGeschOneVerDlg.open(DlgDatenZWohnodGeschOneVerComponent);
  }

  openWEGDatenzWohnodGeschMoreVerDlg(): void {
    const dialogRef = this.WEGDatenzWohnodGeschMoreVerDlg.open(DlgDatenZWohnodGeschMoreVerComponent);
  }

  onSelectKg(type, v) {
    console.log("onSelectKg");
    if(type == 'l1') {
      this.immodetail
        .get('katastralGemeinde').setValue(v);
    }
  }

}
