import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { tap } from 'rxjs';
// import { AuthService } from '../../services/auth.service';
import { VertragStateModel, Modify } from './actions';

@State<VertragStateModel>({
  name: 'auth',
  defaults: {
    tst: null,
    metaData: null,
    contactData: null,
    contractOption: null,
    currentStep: "vertragType",
    primeType: "",
    buyers: [],
    sellers: [],
    noOfSellers: null,
    noOfBuyers: null,
    kaeuferanteile: null,
    AnzahlLS: null,
    moreLSBT: null,
    lessLSBT: null,
    subStep: null,
    newUi: false,
    reload: null
  }
})

@Injectable()
export class VertragState {

  @Selector()
  static auth(state: VertragStateModel): VertragStateModel | undefined {
    return state;
  }
  // constructor(private authService: AuthService) {}
  constructor() {}

  @Action(Modify)
  modify(ctx: StateContext<VertragStateModel>, action: Modify) {
    ctx.patchState({
      ...action.payload
    });
    // return this.authService.login(email, password, type).pipe(
    //   tap((result: { token: string; type: string }) => {
    //     ctx.patchState({
    //       token: result.token,
    //       type: result.type,
    //     });
    //   }),
    // );
  }

}
