import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormGroup, FormControl, UntypedFormBuilder } from '@angular/forms';

@Component({
  selector: 'app-person-data-form',
  templateUrl: './person-data-form.component.html',
  styleUrls: ['./person-data-form.component.css']
})
export class PersonDataFormComponent implements OnInit {
  @Input() parent: UntypedFormGroup;
  @Input() submitted : boolean;
  breakpoint = (window.innerWidth <= 768) ? 1 : 2;
  rowHeight = (window.innerWidth <= 768) ? '140px' : '75px';

  constructor(private formBuilder: UntypedFormBuilder) {}

  ngOnInit() {
  }

  get firstnameFormGroup(): UntypedFormGroup {
    return this.parent.get('firstname') as UntypedFormGroup;
  }

  get lastnameFormGroup(): UntypedFormGroup {
    return this.parent.get('lastname') as UntypedFormGroup;
  }

  get birthdayFormGroup(): UntypedFormGroup {
    return this.parent.get('formatedBirthday') as UntypedFormGroup;
  }

  get addressFormGroup(): UntypedFormGroup {
    return this.parent.get('address') as UntypedFormGroup;
  }

  get postalCodeFormGroup(): UntypedFormGroup {
    return this.parent.get('postalCode') as UntypedFormGroup;
  }

  get cityFormGroup(): UntypedFormGroup {
    return this.parent.get('city') as UntypedFormGroup;
  }
}
