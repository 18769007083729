<div class="text-center">
  <img src="assets/tick.png" alt="Success" style="width: 100px">
</div>
<h3 class="mt-0">Übermittlung erfolgreich!</h3>
<p class="text-center">
Ihre Daten wurden erfolgreich zur weiteren Bearbeitung an die Kanzlei von MMag. Dr. Linus Mähr ermittelt!<br>
Die Kanzlei meldet sich innerhalb eines Werktages mit einem Entwurf des Immobilienkaufvertrags bei Ihnen!<br>
<br>
Achtung: Bitte prüfen Sie auch Ihren SPAM-Ordner auf etwaige Nachrichten von uns.
</p>
