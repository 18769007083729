<div>
  <h2 style="margin: 2%; text-align: center;">In 3 Schritten zum KFZ-Kaufvertrag! </h2>
  <div class="custom-top-head-line" style="padding: 5px;">
    Der Prozess gliedert sich in folgende Schritte:
    <ul>
      <li>
        Erfassung Stammdaten Käufer und Verkäufer
      </li>
      <li>
        Erfassung Daten zum Fahrzeug
      </li>
      <li>
        Übermittlung der Daten an den Server und Empfang des Vertrages per Mail
      </li>

    </ul>

    <br>
    <br>
  </div>
  <div class="custom-weiter-div">
    <button mat-raised-button color="primary" class="custom-weiter" (click)="gotoMasterData()">
      Start
    </button>
    <label style="float: right;">
      <fa-icon class="accent-color custom-weiter" [icon]="faArrowRight"></fa-icon>
    </label>
  </div>
  <br>
  <br>
  <br>
  <br>
</div>