import { Component } from '@angular/core';
import { OnInit, AfterViewInit } from '@angular/core';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { ContractDataService } from './shared/contractData.service';

import { Store } from "@ngxs/store";
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { VertragState } from './core/state/vertrag/state';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent implements OnInit {
  title = 'Kaufvertrag';
  faCheck = faCheck;
  loading = false;
  page;
  subscription?: Subscription;
  newUi = false;

  constructor(public contractDataService: ContractDataService, private store: Store){

  }

  ngOnInit() {
    // this.subscription = this.store.select(VertragState.auth).subscribe(vertrag => {
    //   console.log(vertrag);
    //   this.newUi = vertrag.newUi;
    // });
  };

  ngAfterViewInit() {
    this.contractDataService.loadingState.subscribe((data: any) => {
      this.loading = data.loading;
    });

    this.contractDataService.currentPage.subscribe(data => {
      console.log(data);
      this.page = data.page;
    });
  }

}
