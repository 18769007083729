<mat-dialog-content>
  <div class="custom-dlg-head">
    <h1>Daten</h1>
  </div>
  <div class="custom-dlg-body">
    <p><img src="/assets/ubblDatenzLS.png" alt="" width="800" /></p>
  </div>
  <div class="custom-dlg-footer">
    <button mat-raised-button (click)="closeDlg()">OK</button>
  </div>
</mat-dialog-content>
