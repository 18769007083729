import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { CurrencyPipe, DatePipe, registerLocaleData } from '@angular/common';
import { LOCALE_ID, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import localeDe from '@angular/common/locales/de';
import { TooltipModule } from 'ng2-tooltip-directive';
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { DateAdapter} from '@angular/material/core';
import { SharedModule } from './shared/shared.module';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app.routing';

import { MasterdataComponent } from './masterdata/masterdata.component';
// import { PersonDataFormComponent } from './person-data-form/person-data-form.component';
// import { CompanyDataFormComponent } from './company-data-form/company-data-form.component';
// import { EstateDataFormComponent } from './estate-data-form/estate-data-form.component';
import { WEGlogicComponent } from './weglogic/weglogic.component';
import { BBLlogicComponent } from './bbllogic/bbllogic.component';
import { WEGlogicImmoDetailComponent } from './weglogic-immo-detail/weglogic-immo-detail.component';
import { WEGlogicImmoDetailComponentMietWeg } from './weglogic-immo-detail-miet-weg/weglogic-immo-detail-miet-weg.component';
import { WelcomeComponent } from './welcome/welcome.component';
import { ImmoDetailInfoComponent } from './immo-detail-info/immo-detail-info.component';
import { FinishedComponent } from './finished/finished.component';
import { ImmoDetailInfoBLNR2Component } from './immo-detail-info-blnr2/immo-detail-info-blnr2.component';
import { BbllogicImmoDetailComponent } from './bbllogic-immo-detail/bbllogic-immo-detail.component';
import { BblLogicShareComponent } from './bbl-logic-share/bbl-logic-share.component';
import { GermanDateAdapter } from './shared/germandateadapter';
import { FinishedErrorComponent } from './finished-error/finished-error.component';
import { DlgImmoSteuerComponent } from './dlg-immo-steuer/dlg-immo-steuer.component';
import { DlgWEGComponent } from './dlg-weg/dlg-weg.component';
import { DlgGeldlastenComponent } from './dlg-geldlasten/dlg-geldlasten.component';
import { DlgLoeschenSonstigerLasterComponent } from './dlg-loeschen-sonstiger-laster/dlg-loeschen-sonstiger-laster.component';
import { DlgDatenLiegenschaftComponent } from './dlg-daten-liegenschaft/dlg-daten-liegenschaft.component';
import { WelcomeKaufanbotComponent } from './welcome-kaufanbot/welcome-kaufanbot.component';
import { LooseCurrencyPipe } from './shared/looseCurrency.pipe';
import { WelcomeSchenkungComponent } from './welcome-schenkung/welcome-schenkung.component';
import { ImmoDetailLogicComponent } from './immo-detail-logic/immo-detail-logic.component';
import { KaeuferShareLogicComponent } from './kaeufer-share-logic/kaeufer-share-logic.component';
import { SonstigeRechteLogicComponent } from './sonstige-rechte-logic/sonstige-rechte-logic.component';
import { WelcomeMietComponent } from './welcome-miet/welcome-miet.component';
import { DlgWidmungComponent } from './dlg-widmung/dlg-widmung.component';
import { DlgVorschauPersonaldataComponent } from './dlg-vorschau-personaldata/dlg-vorschau-personaldata.component';
import { DlgVorschauWegComponent } from './dlg-vorschau-weg/dlg-vorschau-weg.component';
import { StartComponent } from './start/start.component';
import { DlgWegDatenZurLiegenschaftComponent } from './dlg-weg-daten-zur-liegenschaft/dlg-weg-daten-zur-liegenschaft.component';
import { DlgDatenZWohnodGeschOneVerComponent } from './dlg-daten-z-wohnod-gesch-one-ver/dlg-daten-z-wohnod-gesch-one-ver.component';
import { DlgDatenZWohnodGeschMoreVerComponent } from './dlg-daten-z-wohnod-gesch-more-ver/dlg-daten-z-wohnod-gesch-more-ver.component';
import { DlgUbblDatenZLsComponent } from './dlg-ubbl-daten-z-ls/dlg-ubbl-daten-z-ls.component';
import { WelcomeKfzComponent } from './welcome-kfz/welcome-kfz.component';
import { SaveDlgComponent } from './save-dlg/save-dlg.component';
import { LoadLogicComponent } from './load-logic/load-logic.component';
import { AuthComponent } from './auth/auth.component';
import { LoadJsonComponent } from './load-json/load-json.component';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ContractsComponent } from './contracts/contracts.component';
import { TokenInterceptor } from './shared/http.token.interceptor';
import { ErrorInterceptor } from './shared/auth-error.interceptor';
import { ContractDetailsComponent } from './contract-details/contract-details.component';
import { HeaderComponent } from './header/header.component';


import { NgxsModule, Store } from '@ngxs/store';
import { StoreModule } from '@ngrx/store';

import { NgxsReduxDevtoolsPluginModule } from "@ngxs/devtools-plugin";
import { stateList } from './core/state/state-list';

import { NgxsStoragePluginModule } from '@ngxs/storage-plugin';

registerLocaleData(localeDe, 'de-DE');
export const DateFormats = {
           parse: {
               dateInput: ['YYYY-MM-DD']
           },
           display: {
               dateInput: 'YYYY-MM-DD',
               monthYearLabel: 'MMM YYYY',
               dateA11yLabel: 'LL',
               monthYearA11yLabel: 'MMMM YYYY',
           },
       };

@NgModule({
    declarations: [
        AppComponent,
        MasterdataComponent,
        WEGlogicComponent,
        BBLlogicComponent,
        WEGlogicImmoDetailComponent,
        WEGlogicImmoDetailComponentMietWeg,
        WelcomeComponent,
        ImmoDetailInfoComponent,
        FinishedComponent,
        ImmoDetailInfoBLNR2Component,
        BbllogicImmoDetailComponent,
        BblLogicShareComponent,
        FinishedErrorComponent,
        DlgImmoSteuerComponent,
        DlgWEGComponent,
        DlgGeldlastenComponent,
        DlgLoeschenSonstigerLasterComponent,
        DlgDatenLiegenschaftComponent,
        WelcomeKaufanbotComponent,
        LooseCurrencyPipe,
        WelcomeSchenkungComponent,
        ImmoDetailLogicComponent,
        KaeuferShareLogicComponent,
        SonstigeRechteLogicComponent,
        WelcomeMietComponent,
        DlgWidmungComponent,
        DlgVorschauPersonaldataComponent,
        DlgVorschauWegComponent,
        StartComponent,
        DlgWegDatenZurLiegenschaftComponent,
        DlgDatenZWohnodGeschOneVerComponent,
        DlgDatenZWohnodGeschMoreVerComponent,
        DlgUbblDatenZLsComponent,
        WelcomeKfzComponent,
        SaveDlgComponent,
        LoadLogicComponent,
        AuthComponent,
        LoadJsonComponent,
        ConfirmDialogComponent,
        DashboardComponent,
        ContractsComponent,
        ContractDetailsComponent,
        HeaderComponent
    ],
    imports: [
        AppRoutingModule,
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        TooltipModule,
        SharedModule,
        NgxsModule.forRoot([...stateList]),
        NgxsReduxDevtoolsPluginModule.forRoot(),
        NgxsStoragePluginModule.forRoot({
              key: 'auth'
        })
    ],
    providers: [
        { provide: LOCALE_ID, useValue: 'de-DE' },
        { provide: DateAdapter, useClass: GermanDateAdapter },
        DatePipe,
        CurrencyPipe,
        LooseCurrencyPipe,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: TokenInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ErrorInterceptor,
            multi: true
        }
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
