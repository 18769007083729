import { Component, Inject, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ContractDataService } from '../shared/contractData.service';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-welcome-kfz',
  templateUrl: './welcome-kfz.component.html',
  styleUrls: ['./welcome-kfz.component.css']
})
export class WelcomeKfzComponent implements OnInit {
  userId: any = '';
  faArrowRight = faArrowRight;

  constructor(private route: ActivatedRoute, private router: Router, @Inject(ContractDataService) private contractDataService: ContractDataService) {
    const userId = this.route.snapshot.paramMap.get('userId');
    if(userId) {
      this.userId = userId;
      localStorage.setItem('userId', userId);
    }
  }

  ngOnInit() {
    this.contractDataService.changePage({ page: 'kfz'});
    this.contractDataService.State = 0;
  }

  ngAfterViewInit() {
    const scrolloptions = { top: 0, left: 0 }
    window.scrollTo(scrolloptions);
  }

  gotoMasterData() {
    localStorage.setItem('primeType','kfz');
    this.contractDataService.setContractPrimeType('kfz');
    if(this.userId && this.userId !== '')
      this.router.navigate(['/personaldata/'+this.userId]);
    else
      this.router.navigate(['/personaldata']);
  }
}
