<div class="container">
  <div class="col-md-8 offset-2">
    <mat-form-field appearance="none" class="no-padding no-line" [class.error]="invalidPassword">
      <input required matInput [(ngModel)]="password" placeholder="Passwort"/>
    </mat-form-field>
    <label *ngIf="invalidPassword" class="text-danger">Passwort ist falsch</label>
  </div>
  <button mat-raised-button color="primary" (click)="login()">
    Anmeldung
  </button>
</div>
<br>
