<div>
  <h2 style="text-align: center;">In 24h zum fertigen Mietvertrag! </h2>
  <div class="custom-top-head-line" style="padding: 5px;">
    Der Prozess gliedert sich in folgende Schritte:
    <ul>
      <li>
        Erfassung Stammdaten der Beteiligten
      </li>
      <li>
        Erfassung Liegenschaftsdaten
      </li>
      <li>
        Übermittlung der Daten an die Mähr Rechtsanwalt GmbH zur weiteren Verarbeitung
      </li>
    </ul>

    Die Errichtungskosten des Vertrages bertragen:
    <ul>
      <li>
        f&uuml;r Wohnungen Brutto &euro; 120,00
      </li>
      <li>
        f&uuml;r andere Miet- oder Pachtvertr&auml;ge (zB Gesch&auml;ftsr&auml;ume) Brutto &euro; 240,00
      </li>
    </ul>

    Die Mähr Rechtsanwalt GmbH garantiert die Übermittlung des Vertragsentwurfes samt Informationsschreiben innert von
    24h an Arbeitstagen, ansonsten erfolgt die Vertragserrichtung kostenlos.
    <br>
    <br>
    Die Vertragserrichtung ist derzeit nur für österreichische Liegenschaften möglich.
    <br>
  </div>
  <div class="custom-weiter-div">
    <button mat-raised-button color="primary" class="custom-weiter" (click)="gotoMasterData()">
      Start
    </button>
    <label style="float: right;">
      <fa-icon class="accent-color custom-weiter" [icon]="faArrowRight"></fa-icon>
    </label>
  </div>
  <br>
  <br>
  <br>
  <br>
</div>