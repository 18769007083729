<div class="top-head">
  <div class="container top-nav">
    <mat-toolbar class="transparent">
      <a routerLink="/start">
        <img src="assets/logo_web_vertragsexpert.png" class="logo"/>
      </a>
      <span class="fill-remaining-space"></span>
      <div fxLayout="row" fxShow="false" fxShow.gt-sm class="toolbar-flex">
        <a mat-raised-button color="primary" routerLink="/admin-panel/lawyers" *ngIf="isUserLoggedIn && userRole == 1">
          Benutzer
        </a>
        <a mat-raised-button color="primary" routerLink="/admin-panel/users" *ngIf="isUserLoggedIn && userRole == 1">
          Ansprechpartner
        </a>
        <a mat-raised-button color="primary" routerLink="/users" *ngIf="isUserLoggedIn && userRole == 2">
          Ansprechpartner
        </a>
        <a mat-raised-button color="primary" routerLink="/contracts" *ngIf="isUserLoggedIn">
          Vertragsliste
        </a>
        <button mat-raised-button color="primary" (click)="redirect()" *ngIf="isUserLoggedIn">
          Neuer Vertrag
        </button>
        <button mat-raised-button color="primary" (click)="redirect()" *ngIf="!isUserLoggedIn">
          Neuer Vertrag
        </button>
        <a mat-raised-button color="primary" href="https://www.vertragsexpert.com/profil_2/1/" target="_blank">
          zurück zum Dashboard
        </a>
        <a mat-raised-button color="primary" href="https://chatting.page/9xjihdeifq3trzc8jzn01ekuqzmtnk4b" target="_blank">
          Hilfe
        </a>
        <a mat-icon-button color="primary" [matMenuTriggerFor]="menu2" *ngIf="isUserLoggedIn" class="bg-dark">
          <mat-icon aria-hidden="false" aria-label="Example home icon" fontIcon="person" style="font-size: 22px; line-height: 19px;">person</mat-icon>
        </a>
      </div>
      <button mat-button [mat-menu-trigger-for]="menu" fxHide="false" fxHide.gt-sm>
        <mat-icon>menu</mat-icon>
      </button>
    </mat-toolbar>
    <mat-menu [overlapTrigger]="false" #menu="matMenu" backdropClass="my-mat-menu">
      <button mat-menu-item color="primary" (click)="redirect()">
        Neuer Vertrag
      </button>
      <button mat-menu-item color="primary">
        zurück zum Dashboard
      </button>
      <button mat-menu-item color="primary">
        Hilfe
      </button>
    </mat-menu>

    <mat-menu [overlapTrigger]="false" #menu2="matMenu">
      <a mat-menu-item color="primary" routerLink="/auth/account" *ngIf="isUserLoggedIn" style="text-decoration: none; font-weight:normal; text-align: left !important;">
        Konto
      </a>
      <button mat-menu-item (click)="logOut()">Ausloggen</button>
      <!-- <button mat-menu-item>Item 2</button> -->
    </mat-menu>

  <!-- <div class="container top-nav">
    <div fxFlex="50%" class="logo-div">
      <img src="assets/logo_web_vertragsexpert.png"/>
    </div>
    <div fxFlex="50%" class="text-right menu-div-right">
    </div>
  </div> -->
  </div>
</div>

<div class="container">
  <header>

    <!--position: fixed;-->
    <!-- <a href="https://ra-maehr.at/" target="_blank">
      <img width="17%" style="margin-bottom: 10px;" alt="Kanzlei M&Auml;hr Logo" src="/assets/logo_ramaehr.jpg">
    </a> -->
    <div style="font-size: small;">
      <div *ngIf="heading != ''">
        <h1>{{ title(heading) }}</h1>
      </div>
      <div *ngIf="heading == ''">
        <h1 *ngIf="page==='auth'">AUTHENTIFIZIERUNG</h1>
        <h1 *ngIf="page==='loadJson'">json laden</h1>
        <h1 *ngIf="page==='kauf'">Kaufvertrag erstellen</h1>
        <h1 *ngIf="page==='miet'">Mietvertrag erstellen</h1>
        <h1 *ngIf="page==='schenkung'">Schenkungsvertrag erstellen</h1>
        <h1 *ngIf="page==='kfz'">KFZ-Kaufvertrag erstellen</h1>
        <h1 *ngIf="page==='kaufanbot'">Kaufanbotsentwurf erstellen</h1>
        <h1 *ngIf="page==='login'">Anmeldung</h1>
        <h1 *ngIf="page==='register'">registrieren</h1>
        <h1 *ngIf="page == 'dashboard'">Armaturenbrett</h1>
        <h1 *ngIf="page == 'contracts'">Verträge</h1>
        <h1 *ngIf="page == 'account'">Kontoeinstellungen</h1>
        <!-- <label *ngIf="page==='kauf'">Der wohl schnellste
          Liegenschafts-Kaufvertrag weltweit und das individuell
          angepasst und zudem professionell.
          Mit jeder Erfahrung haben wir dazugelernt und dieses Wissen in unsere Vertragserrichtung einbezogen. Zudem wird
          jeder Vertrag durch einen Rechtsanwalt vor der Freigabe bis ins Detail geprüft, um den höchstmöglichen
          juristischen Standard bieten zu können. Profitieren Sie von unserem Know-How. Wir ermöglichen eine rasche,
          effektive und wirtschaftliche Abwicklung.</label> -->
        <!-- <label *ngIf="page === 'miet'">Das Mietrecht beinhaltet viele Tücken, welche
          man meist erst erkennt, wenn man vor
          Gericht steht. Essenzieller Bestandteil bei der Vermietung ist der Mietvertrag. Durch einen guten Mietvertrag
          können Streitereien im Vorfeld oft beseitigt werden. Immer der aktuellen Rechtslage angepasst und von einem
          Rechtsanwalt geprüft, bietet der Vertragsexpert auch für spontane Mieterwechsel einen professionellen Vertrag für
          jedes beliebige Objekt (Ein-/Zweifamilienahaus, Eigentumswohnung, Geschäftsräume, etc.). Bereits erstellte
          Verträge und Daten werden im Login-Bereich gespeichert, somit wird eine rasche Abwicklung, auch für den Vermieter
          ermöglicht. Erstellen Sie Ihren individuell angepassten Mietvertrag über den Vertragsexpert und überzeugen Sie
          sich selbst.</label> -->
        <!-- <label *ngIf="page=='schenkung'">Sie möchten Ihre Immobilie in Form einer
          Schenkung übergeben? Auch hierfür bietet
          der Vertragsexpert die perfekte Lösung. Den Schenkungsvertrag erhalten Sie ebenfalls innerhalb von 24h an
          Arbeitstagen generiert und von einem Rechtsanwalt freigegeben.</label> -->
        <!-- <label *ngIf="page=='kaufanbot'">
          Lorem ipsum dolor sit amet, consectetur adipisicing elit. Nesciunt doloremque, assumenda molestias recusandae
          culpa excepturi illo, accusamus qui maiores repellendus sapiente non, natus consequatur explicabo necessitatibus
          quasi corporis porro nemo?
        </label> -->
        <!-- <label *ngIf="page=='kfz'">
          Lorem ipsum dolor sit amet, consectetur adipisicing elit. Nesciunt doloremque, assumenda molestias recusandae
          culpa excepturi illo, accusamus qui maiores repellendus sapiente non, natus consequatur explicabo necessitatibus
          quasi corporis porro nemo?
        </label> -->
        <h3 *ngIf="page=='kauf'" class="mb-4">In nur 3 Schritten zum fertigen
          Kaufvertragsentwurf</h3>
        <h3 *ngIf="page=='miet'" class="mb-4">In nur 3 Schritten zum fertigen
          Mietvertragsentwurf</h3>
        <h3 *ngIf="page=='schenkung'" class="mb-4">In nur 3 Schritten zum fertigen
          SCHENKUNGSVERTRAGSENTWURF</h3>
        <h3 *ngIf="page=='kfz'" class="mb-4">In nur 3 Schritten zum fertigen
          KFZ-Kaufvertragsentwurf</h3>
        <h3 *ngIf="page=='kaufanbot'" class="mb-4">In nur 3 Schritten zum fertigen
          Kaufanbotentwurf</h3>
      </div>

      <div *ngIf="heading == '' && page !== 'loadJson' && page !== 'auth' && page !== 'login' && page !== 'register' && page != 'dashboard' && page != 'contracts' &&  page != 'account'">
        <div class="progress-wrapper" *ngIf="contractDataService.State == 0">
          <div class="progress-open">1</div>
          <div class="progress-open">2</div>
          <div class="progress-open">3</div>
        </div>

        <div class="progress-wrapper" *ngIf="contractDataService.State == 1">
          <div class="progress-active">1</div>
          <div class="progress-open">2</div>
          <div class="progress-open">3</div>
        </div>

        <div class="progress-wrapper" *ngIf="contractDataService.State == 2">
          <div class="progress-finished">1</div>
          <div class="progress-active">2</div>
          <div class="progress-open">3</div>
        </div>

        <div class="progress-wrapper" *ngIf="contractDataService.State == 3">
          <div class="progress-finished">1</div>
          <div class="progress-finished">2</div>
          <div class="progress-active">3</div>
        </div>

        <div class="progress-wrapper" *ngIf="contractDataService.State == 4">
          <div class="progress-finished">1</div>
          <div class="progress-finished">2</div>
          <div class="progress-finished">3</div>
        </div>
      </div>

    </div>
  </header>
</div>
