import { Component, OnInit } from '@angular/core';
import { ContractDataService } from '../shared/contractData.service';
import { AuthService } from '../shared/auth.service';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.css']
})
export class AuthComponent implements OnInit {
  password = '';
  invalidPassword = false;

  constructor(private router: Router, private contractDataService: ContractDataService, private auth : AuthService) {
    this.contractDataService.changePage({ page : 'auth' });
  }

  ngOnInit() {
    if (localStorage.getItem("userLoggedIn") !== null)
    {
      this.router.navigate(['/load-json']);
    }
  }

  login2() {
    this.auth.login({password :this.password}).subscribe((res: any) => {
      console.log(res);
      localStorage.setItem('userLoggedIn', 'true');
      const postId = localStorage.getItem('postId');
      localStorage.removeItem('postId');
      this.router.navigate(['/load-json' + (postId ? ('/' + postId) : '')]);
    },
    (err: any) => {
      this.invalidPassword = true;
      console.log(err);
    });
  }

  login() {
    if(environment.password == this.password) {
      localStorage.setItem('userLoggedIn', 'true');
      const postId = localStorage.getItem('postId');
      localStorage.removeItem('postId');
      this.router.navigate(['/load-json' + (postId ? ('/' + postId) : '')]);
    }
    else
      this.invalidPassword = true;
  }

}
