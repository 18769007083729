import { Component, OnInit } from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-dlg-weg',
  templateUrl: './dlg-weg.component.html',
  styleUrls: ['./dlg-weg.component.css']
})
export class DlgWEGComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<DlgWEGComponent>) { }

  ngOnInit() {
  }

  closeDlg(): void {
    this.dialogRef.close();
  }
}
