import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from "rxjs/operators";
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from './auth.service';


@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(private router: Router, public auth: AuthService, public route : ActivatedRoute) {}
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(catchError(err => {
            var url = this.route.snapshot['_routerState'].url;
            if (err.status === 401) {
              localStorage.removeItem('user');
              this.auth.changeUser(null);
              if(url != '/auth/login')
              this.router.navigate(['/auth/login']);
            }
            return throwError(err);
        })
      )
    }
}
