import {CurrencyPipe} from '@angular/common';
import {Pipe, PipeTransform} from '@angular/core';
const _NUMBER_FORMAT_REGEXP = /^[0-9]*$/;


@Pipe({name: 'looseCurrency'})
export class LooseCurrencyPipe extends CurrencyPipe implements PipeTransform {
  transform(value: any, currencyCode: string, symbolDisplay?: boolean, digits?: string): any {
    if (typeof value === 'number' || _NUMBER_FORMAT_REGEXP.test(value)) {
      console.log('Number val');
      return super.transform(value, currencyCode, symbolDisplay, digits);
    } else {
      console.log('custom val');
      console.log(value);
      if (value !== undefined) {
        var last3 = value.substr(-3);
        if(last3 === ',00') {
          console.log('Last 3');
          value = value.substring(0, value.length - 3);
        }
        console.log(value);
        var strInput = value.replaceAll('.', '');
        var strInput2 = strInput.replaceAll(',', '.');
        var wrongMoneyformat = parseFloat(strInput2);
        // var wrongMoneyformat = strInput2;
        console.log(wrongMoneyformat);
        var correctMoneyformat = super.transform(wrongMoneyformat, currencyCode, symbolDisplay, digits);
        return correctMoneyformat;
      }
      return value;
    }
  }
}
