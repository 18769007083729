<p *ngIf="success==0">
  Laden der Daten...
</p>

<p *ngIf="success==1">
  Laden erfolgreich!
</p>

<p *ngIf="success==2">
  Laden fehlgeschlagen!
</p>