import {Component, Input, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';

@Component({
  selector: 'app-estate-data-form',
  templateUrl: './estate-data-form.component.html',
  styleUrls: ['./estate-data-form.component.css']
})
export class EstateDataFormComponent implements OnInit {

  @Input() parent: UntypedFormGroup;
  @Input() submitted : boolean;
  breakpoint = (window.innerWidth <= 768) ? 1 : 2;
  rowHeight = (window.innerWidth <= 768) ? '140px' : '75px';

  constructor(private formBuilder: UntypedFormBuilder) {}

  ngOnInit() {}

  get companyNameFormGroup(): UntypedFormGroup {
    return this.parent.get('companyName') as UntypedFormGroup;
  }

  get companiesRegisterNumberFormGroup(): UntypedFormGroup {
    return this.parent.get('companiesRegisterNumber') as UntypedFormGroup;
  }

  get addressFormGroup(): UntypedFormGroup {
    return this.parent.get('address') as UntypedFormGroup;
  }

  get postalCodeFormGroup(): UntypedFormGroup {
    return this.parent.get('postalCode') as UntypedFormGroup;
  }

  get cityFormGroup(): UntypedFormGroup {
    return this.parent.get('city') as UntypedFormGroup;
  }
}
