import {Component, Input, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';

@Component({
  selector: 'app-bbl-logic-share',
  templateUrl: './bbl-logic-share.component.html',
  styleUrls: ['./bbl-logic-share.component.css']
})
export class BblLogicShareComponent implements OnInit {

  @Input() parent: UntypedFormGroup;
  @Input() submitted: boolean = false;

  constructor(private formBuilder: UntypedFormBuilder) {}

  ngOnInit() {}

}
