<mat-dialog-content>
  <div class="custom-dlg-head">
    <h1>Geldlastenfrei</h1>
  </div>
  <div class="custom-dlg-body">
    <p>Grunds&auml;tzlich erfolgt die &Uuml;bertragung der Liegenschaftsanteile geldlastenfrei. Es steht den Vertragsparteien jedoch frei, zu vereinbaren, dass bestimmte Pfandrechte von der Verk&auml;uferseite &uuml;bernommen werden. Sollte dies der Fall sein, ist anzugeben, welche Pfandrechte von der Verk&auml;uferseite &uuml;bernommen werden. Dabei ist ma&szlig;geblich, welche C-LNR diese Pfandrechte haben:</p>
    <p>&nbsp;</p>
    <p>Sofern im untenstehenden Beispiel Pfandrecht der Volksbank zu &uuml;bernehmen w&auml;re, w&auml;re C-LNR <strong>18</strong> anzugeben.</p>
    <p>&nbsp;</p>
    <p><img src="/assets/geldlasten.png" alt="" width="800" /></p>
    <br>
  </div>
  <div class="custom-dlg-footer">
    <button mat-raised-button (click)="closeDlg()">OK</button>
  </div>
</mat-dialog-content>
